import { Injectable } from '@angular/core';

@Injectable()
export class ShortCutServiceService {

  // Keycode for enter key
  saveKeyCode: number = 13;

  // Key code for ESC key
  cancelKeyCode: number = 27;

  // Keycode for F4
  saveAndNextKeyCode: number = 115;

  // Keycode for F5
  saveAndExitKeyCode: number = 116;
}
