export const ShareTranslateMessage = [
  {
    text: 'shouldBe6OrMoreCharacters',
    language: {
      arabic: 'يجب ألا تكون كلمة السر أقل من 6 أحرف',
      english: 'Should be 6 or more characters'
    }
  },
  {
    text: 'requirePassword',
    language: {
      arabic: 'يجب ألا تكون كلمة السر أقل من 6 أحرف',
      english: 'Please insert password'
    }
  },
  {
    text: 'regexPassword',
    language: {
      arabic: 'يجب ألا تكون كلمة السر أقل من 6 أحرف',
      english: 'Password should be from 6 to 128 characters'
    }
  },
  {
    text: 'requireConfirmPassword',
    language: {
      arabic: 'لابد ان تتطابق كلمات السر',
      english: 'Please retype password'
    }
  },
  {
    text: 'regexConfirmPassword',
    language: {
      arabic: 'لابد ان تتطابق كلمات السر',
      english: 'Password do not match'
    }
  },
  {
    text: 'requiredUsername',
    language: {
      arabic: 'أسم المستخدم إجباري',
      english: 'Please insert your username'
    }
  },
  {
    text: 'regexdUsername',
    language: {
      arabic: 'يجب ألا يكون أسم المستخدم أكثر من 15 حرف',
      english: 'Username should be from 1 to 15 characters'
    }
  },
  {
    text: 'requiredFirstname',
    language: {
      arabic: 'الإسم الأول إجباري',
      english: 'Please type your first name'
    }
  },
  {
    text: 'regexFirstname',
    language: {
      arabic: 'يجب ألا يكون الإسم الأول أكثر من 50 حرف',
      english: 'First name should be from 1 to 50 characters'
    }
  },
  {
    text: 'requiredLastname',
    language: {
      arabic: 'اسم العائلة إجباري',
      english: 'Please type your last name'
    }
  },
  {
    text: 'regexLastname',
    language: {
      arabic: 'يجب ألا يكون اسم العائلة أكثر من 50 حرف',
      english: 'Last name should be from 1 to 50 characters'
    }
  },
  {
    text: 'requireMobileNumber',
    language: {
      arabic: 'رقم الجوال خطأ',
      english: 'Please insert your mobile number'
    }
  },
  {
    text: 'requireModel',
    language: {
      arabic: 'الرجاء اختيار موديل السياره',
      english: 'Please select a model'
    }
  },
  {
    text: 'timeOut',
    language: {
      arabic: 'حاول مرة أخرى',
      english: 'Time out'
    }
  },
  {
    text: 'regexVerifyCode',
    language: {
      arabic: ' رقم التفعيل المدخل غير صحيح',
      english: 'Invalid verification code '
    }
  },
  {
    text: 'regexMobileNumber',
    language: {
      arabic: 'رقم الجوال المدخل غير صحيح',
      english: 'Invalid mobile number format'
    }
  },
  {
    text: 'requireKilometres',
    language: {
      arabic: 'الرجاء ادخال الممشى بالكيلومتر',
      english: 'Please enter an odometer reading'
    }
  },
  {
    text: 'requireKilometresMin',
    language: {
      arabic: 'الرجاء ادخال رقم يكون يساوي او اكثر من ',
      english: 'Please enter an odometer reading equal or more than '
    }
  },
  {
    text: 'requireKilometresMax',
    language: {
      arabic: 'الرجاء ادخال رقم يكون يساوي او اقل من ',
      english: 'Please enter an odometer reading equal or less than '
    }
  },
  {
    text: 'requireBodyStyle',
    language: {
      arabic: 'الرجاء اختيار نوع السيارة',
      english: 'Please select a body style '
    }
  },
  {
    text: 'requireSeatMin',
    language: {
      arabic: 'الرجاء ادخال رقم يكون يساوي او أكبر من  1',
      english: 'Please enter a number equal to or larger than 1'
    }
  },
  {
    text: 'requireSeatMax',
    language: {
      arabic: '  الرجاء ادخال رقم يكون يساوي او اقل من 99',
      english: 'Please enter a number equal to or less than 99'
    }
  },
  {
    text: 'requireEngineSizeMin',
    language: {
      arabic: 'الرجاء ادخال رقم يكون يساوي او أكبر من  1',
      english: 'Please enter a number equal to or larger than 1'
    }
  },
  {
    text: 'requireEngineSizeMax',
    language: {
      arabic: '  الرجاء ادخال رقم يكون يساوي او اقل من 100000',
      english: 'Please enter a number equal to or less than 100000'
    }
  },
  {
    text: 'regexYear',
    language: {
      arabic: 'الرجاء ادخال سنة الصنع',
      english: 'Please enter a valid 4 digit year'
    }
  },
  {
    text: 'loading',
    language: {
      arabic: 'جار التحميل',
      english: 'Loading... ',
    }
  },
  {
    text: 'signUp',
    language: {
      arabic: ' تسجيل',
      english: ' Sign Up',
    }
  },
  {
    text: 'someThingWentWrong',
    language: {
      arabic: 'حدث خطأ',
      english: ' Something went wrong',
    }
  },
  {
    text: 'continue',
    language: {
      arabic: 'استمر',
      english: 'Continue',
    }
  },
  {
    text: 'saveChanges',
    language: {
      arabic: 'حفظ التغييرات',
      english: 'Save changes'
    }
  },
  {
    text: 'requireVerifyCode',
    language: {
      arabic: 'الرجاء إدخال رمز التحقق',
      english: 'Please insert your verification code'
    }
  },
  {
    text: 'requireCategoryId',
    language: {
      arabic: 'الرجاء اختيار ماركة السياره',
      english: 'Please choose the car brand'
    }
  },
  {
    text: 'createYourAccount',
    language: {
      arabic: 'أنشئ حسابك',
      english: 'Create your account'
    }
  },
  {
    text: 'updatePassword',
    language: {
      arabic: 'حفظ كلمة المرور الجديدة',
      english: 'Update Password'
    }
  },
  {
    text: 'doYouHaveAnAccount',
    language: {
      arabic: 'هل عندك حساب؟ ',
      english: 'Do you have an account?'
    }
  },
  {
    text: 'logIn',
    language: {
      arabic: 'تسجيل الدخول',
      english: 'Login'
    }
  },
  {
    text: 'register',
    language: {
      arabic: 'سـجّـل الأن',
      english: 'Register'
    }
  },
  {
    text: 'resendCode',
    language: {
      arabic: 'أرسل رمز تحقق جديد',
      english: 'Resend code'
    }
  },
  {
    text: 'signOut',
    language: {
      arabic: 'تسجيل الخروج',
      english: 'Sign out'
    }
  },
  {
    text: 'choose',
    language: {
      arabic: 'أختر',
      english: 'Choose'
    }
  },
  {
    text: 'orNearOffer',
    language: {
      arabic: 'او سعر مقارب',
      english: 'Or near offer'
    }
  },
  {
    text: 'askingPrice',
    language: {
      arabic: 'السعر المطلوب',
      english: 'Asking price'
    }
  },
  {
    text: 'regexYoutubeLink',
    language: {
      arabic: 'رابط الفيديو غير صحيح ، انسخ رابط الفيديو من اليوتيوب والصقه',
      english: 'Please insert a valid youtube link'
    }
  },
  {
    text: 'requiredImageLimit20Pic',
    language: {
      arabic: 'تقدر تضيف 20 صورة فقط',
      english: 'Upload 20 photos for free'
    }
  },
  {
    text: 'imageDuplicateMess',
    language: {
      arabic: 'Your image is available.',
      english: 'Your image is available.'
    }
  },
  {
    text: 'imageLimitSize12MBMess',
    language: {
      arabic: 'يجب ألا يتجاوز حجم الصورة 12 ميغابايت',
      english: 'Image size cannot exceed 12MB'
    }
  },
  {
    text: 'imageLimitSize5MBMess',
    language: {
      arabic: 'يجب ألا يتجاوز حجم الصورة 5 ميغابايت',
      english: 'Image size cannot exceed 5MB'
    }
  },
  {
    text: 'imageMinDimensionError',
    language: {
      arabic: 'الصوره حجمها صغير. الطول او العرض لازم يكون ٥٠٠ بكسل على الاقل',
      english: 'The image is small in size. The length or width must be at least 500 pixels\''
    }
  },
  {
    text: 'imageMaxDimensionError',
    language: {
      arabic: 'الصوره حجمها كبير. اقصى حد للطول او العرض هو ٩٠٠٠ بكسل ',
      english: 'The image is large. The maximum length or width is 9,000 pixels.'
    }
  },
  {
    text: 'requiredImageFileType',
    language: {
      arabic: 'امتداد الملف ليس صورة',
      english: 'File extension is not an image has been supported.'
    }
  },
  {
    text: 'imageFileNameTooLarge',
    language: {
      arabic: 'اسم الصوره المرسله غير صحيح',
      english: 'Image file name too large.'
    }
  },
  {
    text: 'requireAskingPrice',
    language: {
      arabic: 'الرجاء ادخال السعر المطلوب',
      english: 'Please insert your asking price.'
    }
  },
  {
    text: 'regexMinAskingPrice',
    language: {
      arabic: 'الرجاء ادخال رقم يكون يساوي او أكبر من  1',
      english: 'Please enter a number equal to or greater than 1'
    }
  },
  {
    text: 'regexMaxAskingPrice',
    language: {
      arabic: 'الرجاء ادخال رقم يكون يساوي او اقل من 1000000000',
      english: 'Please enter a number equal to or less than 1000000000'
    }
  },
  {
    text: 'emptyPhotoMess',
    language: {
      arabic: 'لم يتم اضافة صور',
      english: 'No photos added'
    }
  },
  {
    text: 'emptyVideoMess',
    language: {
      arabic: 'لم يتم اضافة فديو',
      english: 'No video added'
    }
  },
  {
    text: 'loadMore',
    language: {
      arabic: 'مشاهدة المزيد‎',
      english: 'Load More'
    }
  },
  {
    text: 'selling',
    language: {
      arabic: ' الاعلانات المعروضة للبيع',
      english: 'Selling'
    }
  },
  {
    text: 'buying',
    language: {
      arabic: 'لاعلانات المراقبة المنتهيه',
      english: 'Buying'
    }
  },
  {
    text: 'myTajr',
    language: {
      arabic: 'حسابي ',
      english: 'My-Tajr'
    }
  },
  {
    text: 'watchlist',
    language: {
      arabic: ' الاعلانات المراقبة',
      english: 'Watchlist'
    }
  },
  {
    text: 'unSold',
    language: {
      arabic: ' الاعلانات المنتهية ',
      english: 'UnSold'
    }
  },
  {
    text: 'withdrawnlistings',
    language: {
      arabic: ' الاعلانات المحذوفة',
      english: 'Withdrawn listings'
    }
  },
  {
    text: 'edit',
    language: {
      arabic: 'عدل بياناتي',
      english: 'Edit'
    }
  },
  {
    text: 'watchListMessage',
    language: {
      arabic: '{numberOfWatchers} :عدد مراقبين الإعلان',
      english: '{numberOfWatchers} people added this item to watchlist.'
    }
  },
  {
    text: 'itemHasBeenDeleted',
    language: {
      arabic: `الإعلان محذوف`,
      english: 'This item has been deleted.'
    }
  },
  {
    text: 'itemHasBeenExpired',
    language: {
      arabic: `الإعلان انتهى`,
      english: 'This item has been expired.'
    }
  },
  {
    text: 'itemAddedToWatchlist',
    language: {
      arabic: 'تمت إضافة الإعلان إلى قائمة المراقبة',
      english: 'This item added to your watchlist.'
    }
  },
  {
    text: 'itemRemovedSuccessFromWatchlist',
    language: {
      arabic: 'تمت الإزالة بنجاح',
      english: 'This item has been removed from your watchlist.'
    }
  },
  {
    text: 'yourWatchListIsEmpty',
    language: {
      arabic: 'قائمة المراقبه فارغة',
      english: 'Your Watchlist is empty'
    }
  },
  {
    text: 'keepTrackOfListingsYouLikeMess',
    language: {
      arabic: 'تتبع الإعلانات التي تعجبك من خلال إضافتها إلى قائمة المراقبه الخاصة بك وستظهر هن',
      english: 'Keep track of listings you like by adding them to your Watchlist and they\'ll show up here'
    }
  },
  {
    text: 'startTheNewListingBtn',
    language: {
      arabic: 'ابدأ الإعلان',
      english: 'Start The New Listing'
    }
  },
  {
    text: 'updateListingBtn',
    language: {
      arabic: 'حدث الإعلان',
      english: 'Update Listing'
    }
  },
  {
    text: 'confirm',
    language: {
      arabic: 'تأكيد',
      english: 'Confirm'
    }
  },
  {
    text: 'doYouWantToDeleteThisListing',
    language: {
      arabic: 'هل تريد حذف الإعلان؟ لا يمكن استعادة الأعلان بعد الحذف',
      english: 'Do you want to delete this listing ? This can not undo.'
    }
  },
  {
    text: 'yes',
    language: {
      arabic: 'نعم',
      english: 'Yes'
    }
  },
  {
    text: 'no',
    language: {
      arabic: 'لا',
      english: 'No'
    }
  },
  {
    text: 'editListingSuccessful',
    language: {
      arabic: 'تم تعديل الإعلان بنجاح',
      english: 'Edit listing successful'
    }
  },
  {
    text: 'yourClassifiedHasStarted',
    language: {
      arabic: ' بدأ إعلانك',
      english: 'Your classified has started'
    }
  },
  {
    text: 'thisListingHasBeenDeletedSuccessful',
    language: {
      arabic: 'تم حذف الإعلان بنجاح',
      english: 'This listing has been deleted successful.'
    }
  },
  {
    text: 'youHaveLoggedOutSuccessfully',
    language: {
      arabic: 'تم تسجيل الخروج بنجاح',
      english: 'You have logged out successfully.'
    }
  },
  {
    text: 'youHaveLoggedInSuccessfully',
    language: {
      arabic: 'تم تسجيل الدخول بنجاح',
      english: 'You have logged in successfully.'
    }
  },
  {
    text: 'yourPasswordHasBeenChangedSuccessfully',
    language: {
      arabic: 'تم تغيير كلمة المرور بنجاح، الرجاء تسجيل دخول من جديد',
      english: 'Your password has been changed successfully. Please login again.'
    }
  },
  {
    text: 'yourProfileHasBeenChangedSuccessfully',
    language: {
      arabic: 'تم تحديث حسابك الشخصي بنجاح، الرجاء تسجيل دخول جديد',
      english: 'Your profile has been changed successfully'
    }
  },
  {
    text: 'searchResultsFor',
    language: {
      arabic: 'نتيجة البحث لـ: ',
      english: 'Search results for: '
    }
  },
  {
    text: 'narrowYourSearchFor',
    language: {
      arabic: 'حدد نطاق البحث لـ: ',
      english: 'Narrow your search for '
    }
  },
  {
    text: 'in',
    language: {
      arabic: 'في',
      english: 'in'
    }
  },
  {
    text: 'recentlyViewed',
    language: {
      arabic: 'شوهدت مؤخراً',
      english: 'Recently Viewed'
    }
  },
  {
    text: 'reportHasBeenSentSuccess',
    language: {
      arabic: 'تم الإبلاغ عن الإعلان',
      english: 'Your report has been sent successfully.'
    }
  },
  {
    text: 'waitToResendOtpMess',
    language: {
      arabic: 'انتظر {timer}ث لإرسال رقم سري صالح لمرة واحدة',
      english: 'wait {timer}s to resend OTP'
    }
  },
];
