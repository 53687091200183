<!-- blue line  -->
<div class="bg-primary pt-2"></div>
<!--content-->
<div class="bg-light">
  <div class="container-fluid">



    <div class="row">
      <div class="col-12 col-sm-10 col-md-8 col-lg-6 offset-sm-1 offset-md-2 offset-lg-3 text-right pb-5">
        <form [formGroup]="newPasswordForm" (keyup)="errorMessage = ''"
              (ngSubmit)="newPasswordForm.valid && newPassword()">
          <!-- title -->
          <div class="row">
            <div class="col mt-5">
              <h4 class="text-warning">انشئ كلمة سر جديدة</h4>
            </div>
          </div>
          <!-- text "We will ask you for your password every time you log in" -->
          <div class="row">
            <div class="col mt-4">
              <p class="text-secondary">
                سوف نسألك عن كلمة السر كلما سجلت الدخول.
              </p>
            </div>
          </div>
          <!-- password -->
          <div class="row">
            <div class="col mt-3">
              <label class="font-weight-bold text-secondary" for="password-lg">
                كلمة السر الجديدة
              </label>
              <input type="password" class="form-control text-right" maxlength="128" id="password-lg"
                     formControlName="password"
                     autocomplete="on"
                     [placeholder]="placeholderText">
            </div>
          </div>
          <!-- error password -->
          <div class="row ">
            <div class="col position-absolute mt-1">
              <small class="text-danger pt-3"
                     *ngIf="(newPasswordForm.controls.password.dirty || submitted)  && !newPasswordForm.controls.password.valid">
            <span *ngIf="newPasswordForm.controls.password.errors?.required">
              {{requirePasswordMessage}}</span>
                <span *ngIf="newPasswordForm.controls.password.errors.pattern">
              {{regexPasswordMessage}}</span>
              </small>
              <small class="text-danger pt-3">{{errorMessage}}</small>
            </div>
          </div>
          <!-- confirm password -->
          <div class="row">
            <div class="col mt-5">
              <label class="font-weight-bold text-secondary" for="confirmPassword-lg">
                أعد كتابة كلمة السر
              </label>
              <input type="password" class="form-control text-right" maxlength="128" id="confirmPassword-lg"
                     formControlName="confirmPassword"
                     autocomplete="on">
            </div>
          </div>
          <!-- error confirm password -->
          <div class="row ">
            <div class="col position-absolute mt-1">
              <small class="text-danger pt-3"
                     *ngIf="(newPasswordForm.controls.confirmPassword.dirty ||  submitted)  &&
                    !newPasswordForm.controls.confirmPassword.valid &&
                     newPasswordForm.controls.password.valid">
                         <span *ngIf="newPasswordForm.controls.confirmPassword.errors?.required">
              {{requireConfirmPasswordMessage}}</span>
                <span *ngIf="newPasswordForm.controls.confirmPassword.errors.notEquivalent">
              {{regexConfirmPasswordMessage}}</span>
              </small>
            </div>
          </div>
          <!-- submit button -->
          <div class="row">
            <div class="col mt-5">
              <button type="submit" class="btn btn-primary btn-block" *ngIf="!loading">{{saveChangesMess}}</button>
              <button class="btn alert-primary text-white btn-block d-flex align-items-center justify-content-center "
                      *ngIf="loading" disabled>
                {{loadingMess}}
                <span class="spinner-border spinner-border-sm mx-2"></span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- blue line  -->
<div class="bg-primary pt-2"></div>
