export class ImageModel {
  photo_id: string;
  file_name: string;
  photo_urls: string;
  checkBoxValue: string;
  source: string;
  file_type: string;
  photo_data: string;
  progress: number;
}
