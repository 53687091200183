<div class="row" *ngIf="progress < 100">
  <div class="col">
    <div class="progress h-100">
      <div class="progress-bar bg-primary pt-1" role="progressbar" aria-valuenow="0"
           aria-valuemin="0" aria-valuemax="100" [style.width]="progress + '%'">
      </div>
      <div class="progress-bar bg-light pt-1" role="progressbar" aria-valuenow="0"
           aria-valuemin="0" aria-valuemax="100" [style.width]="100 - progress + '%'">
      </div>
    </div>
  </div>
</div>
