<!-- blue line  -->
<div class="bg-primary pt-2"></div>

<!-- content-->
<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-sm-10 col-md-8 col-lg-8 offset-sm-1 offset-md-2 offset-md-2  text-right">
      <!-- title -->
      <div class="row">
        <div class="col mt-5">
          <h4 class="text-warning">
            <!-- -->تمت العملية بنجاح </h4>
        </div>
      </div>
      <!-- Your classified has started. -->
      <div class="row">
        <div class="col mt-3">
          <p class="font-weight-bold text-primary mb-0 d-flex align-content-center mb-1" dir="rtl">
            <span class="material-icons text-primary font-weight-bold">
              check
            </span>&nbsp;
            {{listingResultMess}}
          </p>
        </div>
      </div>
      <!-- View my classified -->
      <div class="row">
        <div class="col">
          <p class="text-secondary d-flex align-content-center mb-1" dir="rtl">
            <span class="material-icons text-primary font-weight-bold">
              arrow_left
            </span>&nbsp;
            <a [routerLink]="navigateService.generateListingPageUrl(listingId, showAttributesValue('title', 'strings'))" class="text-secondary">
              <!--View my classified  -->عرض إعلاني</a>
          </p>
        </div>
      </div>
      <!-- List a similar item -->
      <div class="row">
        <div class="col">
          <p class="text-secondary d-flex align-content-center mb-1" dir="rtl">
            <span class="material-icons text-primary font-weight-bold">
              arrow_left
            </span>&nbsp;
            <a routerLink="/list-an-item" class="text-secondary">
              <!--List a similar item  -->اضف اعلان مماثل </a>
          </p>
        </div>
      </div>
      <!-- My tajer -->
      <div class="row">
        <div class="col">
          <p class="text-secondary d-flex align-content-center mb-1" dir="rtl">
            <span class="material-icons text-primary font-weight-bold">
              arrow_left
            </span>&nbsp;
            <a routerLink="/my-tajr" class="text-secondary">
              <!--My Tajer -->حسابي</a>
          </p>
        </div>
      </div>
      <div class="pt-5"></div>
      <div class="dropdown-divider"></div>
      <!-- Let friends know about your listing -->
      <div class="row">
        <div class="col">
          <p class="text-warning mb-0 h5" dir="rtl">
            <!-- Let friends know about your listing.-->شارك إعلانك
          </p>
        </div>
      </div>
      <!--share button -->
      <div class="row">
        <div class="col mt-1">
          <a href="javascript:void(0);"><img class="img-fluid"
              src="../../../assets/images/icon-images/whatsapp-icon-24.png"></a>
          <a href="javascript:void(0);"><img class="img-fluid ml-3 mr-3"
              src="../../../assets/images/icon-images/twitter-icon-24.png"></a>
          <a href="javascript:void(0);"><img class="img-fluid"
              src="../../../assets/images/icon-images/gmail-icon-24.png"></a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- blank page -->
<div class="pt-5 pb-5"></div>
<!-- blue line  -->
<div class="bg-primary mt-3 pt-2"></div>
