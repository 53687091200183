import {Component, OnInit, Input} from '@angular/core';
import {DefaultMenuTypeA} from '../../modules/models/default.menu.type.a';
import {LanguageService} from '../../services/language.service';
import {SEARCH_PAGE} from '../../shared/global';
import {Util} from '../../utils/utils';
import {Router} from '@angular/router';

@Component({
  selector: 'app-default-listing-menu',
  templateUrl: './default-listing-menu.component.html',
  styleUrls: ['./default-listing-menu.component.css']
})
export class DefaultListingMenuComponent implements OnInit {
  // format menu and label
  public menu: DefaultMenuTypeA;
  public leftMenuLabel: string;
  public rightMenuLabel: string;
  // list of menu
  public listOfMenu = [
    {
      index: '0',
      leftMenuLabel: {
        arabic: 'عرض الكل',
        english: 'View all'
      },
      leftMenuLink: 'closing_soon',
      rightMenuLabel: {
        arabic: 'تنتهي قريبا',
        english: 'Closing soon'
      },
      rightMenuLink: 'closing_soon',
    },
    {
      index: '1',
      leftMenuLabel: {
        arabic: 'عرض الكل',
        english: 'View all'
      },
      leftMenuLink: 'latest',
      rightMenuLabel: {
        arabic: 'اخر الإعلانات',
        english: 'Lastest listings'
      },
      rightMenuLink: 'latest',
    },
    {
      index: '2',
      leftMenuLabel: {
        arabic: 'عرض الكل',
        english: 'View all'
      },
      leftMenuLink: '',
      rightMenuLabel: {
        arabic: 'إعلانات السيارات',
        english: 'Car Ads'
      },
      rightMenuLink: '',
    },
    {
      index: '3',
      leftMenuLabel: {
        arabic: 'عرض الكل',
        english: 'View all'
      },
      leftMenuLink: '',
      rightMenuLabel: {
        arabic: 'قطع غيار السيارات',
        english: 'Car parts Ads'
      },
      rightMenuLink: '',
    },
  ];
  // input value fron father component
  @Input('index') index: number = 0;
  @Input('category_id') category_id: string = '';

  constructor(
    // get language message from local
    private languageService: LanguageService,
    private router: Router
  ) {
  }

  ngOnInit() {
    //  receive and show value from list of menu
    this.menu = this.listOfMenu[this.index];
    this.leftMenuLabel = this.languageService.messageFromServer(this.menu.leftMenuLabel);
    this.rightMenuLabel = this.languageService.messageFromServer(this.menu.rightMenuLabel);
  }

  /**
   * navigate to search  page
   * @param querySearch query to search
   */
  navigateToSearchPage(keySearch: string, category_id : string) {
    let queryParams;
    queryParams = {};
    if (Util.isNotNullOrEmpty(keySearch)) {
      queryParams.sortBy = keySearch
    }
    if (Util.isNotNullOrEmpty(category_id )) {
      queryParams.categoryId = category_id
    }
    this.router.navigate([SEARCH_PAGE], {
      queryParams
    });
  }
}


