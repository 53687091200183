<!-- blue line  -->
<div class="bg-primary pt-2"></div>
<!--content-->
<div class="bg-light">
  <div class="container-fluid pb-5">

    <!-- text [Contact us] -->
    <div class="row pt-4 mt-2">
      <div class="col text-right">
        <h3 class="font-weight-bold text-warning">Contact us</h3>
      </div>
    </div>
    <!-- text [fill in the details] -->
    <div class="row pt-4 mt-2">
      <div class="col text-right">
        <p class="text-secondary">Please fill in the details below, providing as much information as possible.
          We'll do our best to get back to you within 3 - 5 days. We receive a lot ofemails,
          so we recommend you search our site help first.
        </p>
      </div>
    </div>
    <!-- text [I am enquiring about]  -->
    <div class="row pt-4 mt-2">
      <div class="col text-right">
        <label class="font-weight-bold text-secondary">I am enquiring about</label>
        <select class="form-control w-75 ml-auto" dir="rtl">
          <option> Please select a subject</option>
          <option> App Feedback</option>
          <option value="1"> Account Info</option>
          <option> Payments &amp; Refunds</option>
          <option> Trade Disputes</option>
          <option> Buying</option>
          <option> Selling</option>
          <option> Policies</option>
          <option> Message Board</option>
          <option> Financial Disputes</option>
          <option> Inappropriate Behaviour</option>
          <option> Suspicious Behaviour</option>
          <option> Corporate Enquiries</option>
        </select>
      </div>
    </div>
    <!-- Detail -->
    <div class="row pt-4 ">
      <div class="col text-right">
        <label class="font-weight-bold text-secondary">Detail</label> <br>
        <textarea rows="3" class="form-control text-right w-75 ml-auto"></textarea>
        <small class="form-text">
          <font color="red">This field is required</font>
        </small>
      </div>
    </div>
    <!-- [Send enquiry] button -->
    <div class="row pt-4 pb-4 mb-2">
      <div class="col text-right">
        <a href class="btn btn-primary">Send enquiry</a>
      </div>
    </div>
  </div>
</div>
<!-- blue line  -->
<div class="bg-primary pt-2"></div>
