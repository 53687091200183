<!-- blue line  -->
<div class="bg-primary pt-2"></div>
<!--content-->
<div class="bg-light">
  <div class="container-fluid">

    <div class="row">
      <div class="col-12 col-sm-10 col-md-8 col-lg-6 offset-sm-1 offset-md-2 offset-lg-3 text-right pb-5">
        <form [formGroup]="registerDetailsForm" (keyup)="errorMessage = ''" (ngSubmit)="registerDetails()">
          <div class="row">
            <div class="col mt-5">
              <h4 class="text-right text-warning">التسجيل بالموقع</h4>
            </div>
          </div>
          <!-- password -->
          <div class="row">
            <div class="col mt-3">
              <span class="font-weight-bold text-danger"> * </span>
              <label for="password-xl" class="font-weight-bold text-secondary">كلمة السر</label>
              <input id="password-xl" type="password" maxlength="128" class="form-control text-right"
                     formControlName="password"
                     autocomplete="on"
                     [placeholder]="placeholderText">
            </div>
          </div>
          <!-- error password -->
          <div class="row ">
            <div class="col position-absolute mt-1">
              <small class="text-danger pt-3"
                     *ngIf="(registerDetailsForm.controls.password.dirty || submitted)  && !registerDetailsForm.controls.password.valid">
            <span *ngIf="registerDetailsForm.controls.password.errors?.required">
              {{requirePasswordMessage}}</span>
                <span *ngIf="registerDetailsForm.controls.password.errors.pattern">
              {{regexPasswordMessage}}</span>
              </small>
            </div>
          </div>
          <!-- cornfirm password -->
          <div class="row">
            <div class="col mt-4">
              <span class="font-weight-bold text-danger"> * </span>
              <label for="confirmPassword-xl" class="font-weight-bold text-secondary">أعد كتابة كلمة السر
              </label>
              <input id="confirmPassword-xl"
                     type="password" maxlength="128"
                     class="form-control text-right"
                     formControlName="confirmPassword"
                     autocomplete="on">
            </div>
          </div>
          <!-- error confirm password -->
          <div class="row ">
            <div class="col position-absolute mt-1">
              <small class="text-danger pt-3"
                     *ngIf="(registerDetailsForm.controls.confirmPassword.dirty ||  submitted)  &&
                    !registerDetailsForm.controls.confirmPassword.valid &&
                     registerDetailsForm.controls.password.valid">
                         <span *ngIf="registerDetailsForm.controls.confirmPassword.errors?.required">
              {{requireConfirmPasswordMessage}}</span>
                <span *ngIf="registerDetailsForm.controls.confirmPassword.errors.notEquivalent">
              {{regexConfirmPasswordMessage}}</span>
              </small>
            </div>
          </div>
          <!-- username -->
          <div class="row">
            <div class="col mt-4">
              <span class="font-weight-bold text-danger"> * </span>
              <label for="username-xl" class="font-weight-bold text-secondary">أسم المستخدم
              </label>
              <input id="username-xl"
                     type="text"
                     maxlength="15"
                     class="form-control text-right"
                     formControlName="username"
                     autocomplete="on">
            </div>
          </div>
          <!-- error username -->
          <div class="row ">
            <div class="col position-absolute mt-1">
              <small class="text-danger pt-3"
                     *ngIf="(registerDetailsForm.controls.username.dirty || submitted)  && !registerDetailsForm.controls.username.valid">
            <span *ngIf="registerDetailsForm.controls.username.errors?.required">
              {{requiredUsernameMessage}}</span>
                <span *ngIf="registerDetailsForm.controls.username.errors.pattern">
              {{regexUsernameMessage}}</span>
              </small>
            </div>
          </div>
          <!-- first name -->
          <div class="row">
            <div class="col mt-4">
              <span class="font-weight-bold text-danger"> * </span>
              <label for="firstName-xl" class="font-weight-bold text-secondary">الاسم الأول
              </label>
              <input id="firstName-xl"
                     type="text"
                     maxlength="50"
                     class="form-control text-right"
                     formControlName="first_name"
                     autocomplete="on">
            </div>
          </div>
          <!-- error first name -->
          <div class="row ">
            <div class="col position-absolute mt-1">
              <small class="text-danger pt-3"
                     *ngIf="(registerDetailsForm.controls.first_name.dirty || submitted)  && !registerDetailsForm.controls.first_name.valid">
            <span *ngIf="registerDetailsForm.controls.first_name.errors?.required">
              {{requiredFirstnameMessage}}</span>
                <span *ngIf="registerDetailsForm.controls.first_name.errors.pattern">
              {{regexFirstnameMessage}}</span>
              </small>
            </div>
          </div>
          <!-- last name -->
          <div class="row">
            <div class="col mt-4">
              <span class="font-weight-bold text-danger"> * </span>
              <label for="lastName-xl" class="font-weight-bold text-secondary">اسم العائلة
              </label>
              <input id="lastName-xl"
                     type="text"
                     maxlength="50"
                     class="form-control text-right"
                     formControlName="last_name"
                     autocomplete="on">
            </div>
          </div>
          <!-- error last name -->
          <div class="row ">
            <div class="col position-absolute mt-1">
              <small class="text-danger pt-3"
                     *ngIf="(registerDetailsForm.controls.last_name.dirty || submitted)  && !registerDetailsForm.controls.last_name.valid">
            <span *ngIf="registerDetailsForm.controls.last_name.errors?.required">
              {{requiredLastnameMessage}}</span>
                <span *ngIf="registerDetailsForm.controls.last_name.errors.pattern">
              {{regexLastnameMessage}}</span>
              </small>
            </div>
          </div>
          <!-- location -->
          <div class="row">
            <div class="col mt-4">
              <span class="font-weight-bold text-danger"> * </span>
              <label for="closestDistrict-xl" class="font-weight-bold text-secondary">اقرب محافظة
              </label>
              <select dir="rtl" id="closestDistrict-xl" class="form-control "
                      [(ngModel)]='selected'
                      formControlName="location"
                      autocomplete="on">
                <option *ngFor="let location of locationList"
                        [value]='location.value'>
                  {{location.localityMess}}
                </option>
              </select>
            </div>
          </div>
          <!-- error register details response from server -->
          <div class="row ">
            <div class="col position-absolute text-center mt-1">
              <span class="text-danger">{{errorMessage}}</span>
            </div>
          </div>
          <!-- register button -->
          <div class="row">
            <div class="col mt-5">
              <button type="submit" class="btn btn-primary btn-block"
                      *ngIf="!loading">{{createYourAccountMess}}</button>
              <button class="btn alert-primary text-white btn-block d-flex align-items-center justify-content-center "
                      *ngIf="loading" disabled>
                {{loadingMess}}
                <span class="spinner-border spinner-border-sm mx-2"></span>
              </button>
            </div>
          </div>
          <!-- login button -->
          <div class="row">
            <div class="col mt-5">
              <p class="text-right text-secondary border-top pt-4">هل عندك حساب؟ <a routerLink="/login"
                                                                                    class="ml-1">
                تسجيل الدخول
              </a>
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- blue line  -->
<div class="bg-primary pt-2"></div>
