import {Component, ElementRef, HostListener, OnInit} from '@angular/core';
import {makeStateKey, TransferState} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {LanguageService} from '../../services/language.service';
import {ProductService} from '../../services/product.service';

import {
  ACTION,
  CREATE,
  EDIT,
  ItemAttributes,
  LIST_AN_ITEM_PHOTOS,
  NOT_EMPTY_REGEX,
  LIST_AN_ITEM,
  SELL_SIMILIAR,
  SMOOTH,
  TAJR_LIST_AN_ITEM,
  FIRST_ITEM,
  MAX_CATEGORY_LEVEL,
  TAJR_ALL_CATEGORIES_RESOURCE,
  CATEGORIES_DETAILS_RESOURCE,
  TAJR_CATEGORIES_DETAILS,
  ListingAttributes,
  LIST_AN_ITEM_ERROR_TYPE,
  CATEGORY_ICON
} from '../../shared/global';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CategoryDetails} from '../../modules/models/category.details';
import {Options} from '../../modules/models/options';
import {ListAnItemModules} from '../../modules/models/list.an.item.modules';
import {Categories} from '../../modules/models/categories';
import {ActivatedRoute, Router} from '@angular/router';
import {Util} from '../../utils/utils';
import {NotificationServices} from "../../services/notification.services";
import {ListAnItemService} from "../../services/list.an.item.service";
import {CategoryService} from "../../services/category.service";



@Component({
  selector: 'app-list-an-item',
  templateUrl: './list-an-item.component.html',
  styleUrls: ['./list-an-item.component.css'],
  providers: []
})
export class ListAnItemComponent implements OnInit {
  //  subscription to observable request get member's listings
  public categoryDetailSubscription: Subscription;
  public categoryDetailData: CategoryDetails;
  // create all categories dynamic form
  public activeCategoriesForm: FormGroup;
  public formDatalist = [];
  public submitted = false;
  // select button default value
  public loadingModelStatus = false;
  // width of screen size on browser
  public screenWidth: number;
  public listingId = '';
  public action = CREATE;
  public categories: Categories;

  /**
   * listAnItemModules: item detail model, default value is empty
   * this value use to show input, select default value on browser
   */
  public listAnItemModules: ListAnItemModules;

  constructor(
    private languageService: LanguageService,
    private productService: ProductService,
    private notify: NotificationServices,
    private listAnItemService: ListAnItemService,
    private categoryService: CategoryService,
    private elementRef: ElementRef,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private transferState: TransferState,
  ) {}

  ngOnInit() {
    // get screen of device
    this.screenWidth = window.innerWidth;

    this.categoryDetailData = this.activatedRoute.snapshot.data[CATEGORIES_DETAILS_RESOURCE];
    const tajrCategoriesData = this.activatedRoute.snapshot.data[TAJR_ALL_CATEGORIES_RESOURCE];
    const prexistIds = !!this.listAnItemService.getActiveIDFromSS() ? this.listAnItemService.getActiveIDFromSS() : this.categoryDetailData?.id;
    this.categories = this.categoryService.initCategoriesFromData(tajrCategoriesData, MAX_CATEGORY_LEVEL, prexistIds)

    const tajrListAnItem = this.listAnItemService.tajrListAnItem;
    this.listAnItemModules = this.getListAnItemModule(tajrListAnItem)
    this.activeCategoriesForm = this.getActiveFormControl();

    // get value from url, get action with listing item, default action is create new item
    this.activatedRoute.queryParamMap.subscribe((queryParams) => {
      if (queryParams.has(ACTION)) {
        this.action = queryParams.get(ACTION);
      }
      // check item info save success for all steps in action is edit/sellSimilar
      const isPreExistSessionData = !this.listAnItemService.tajrListAnItem
      || !this.listAnItemService.tajrListAnItemPhotos
      || !this.listAnItemService.tajrListAnItemDetails;
      switch (this.action) {
        case EDIT:
          // Check in local item details must be available
          if (isPreExistSessionData) 
          {
            this.listAnItemService.clearAllListAnItemSessionData();
            this.notify.showInfoNotificationMessage(
              "Your listing can not edit. You will be redirected to create new listing page."
            );
            this.router.navigate([LIST_AN_ITEM]);
          }
          // disable tab select before send request
          this.activeCategoriesForm.controls.categoryId.disable();
          break;
        case SELL_SIMILIAR:
          // Check in local item details must be available
          if (isPreExistSessionData) {
            this.listAnItemService.clearAllListAnItemSessionData();
            this.notify.showInfoNotificationMessage(
              "Your listing can not sell similar. You will be redirected to create new listing page."
            );
            this.router.navigate([LIST_AN_ITEM]);
          }
          break;
        default:
          break;
      }
    });
  }

  /**
   * use HostListener to  updated on resize:
   * @param event event when change width of device
   */
  @HostListener('window:resize', ['$event'])
  getScreenSize(event) {
    this.screenWidth = window.innerWidth;
  }

  /**
   * Get maxlength value from server response to put on input tab in browser
   * @param attributeId attribute Id
   */
  validatorsMaxLength(attributeId: string) {
    if (Util.isNullOrEmpty(this.categoryDetailData) || Util.isNullOrEmpty(this.categoryDetailData.attributes)) {
      return;
    }

    const attribute = this.categoryDetailData.attributes.find(item => item.id === attributeId);

    if (Util.isNotNullOrEmpty(attribute) && Util.isNotNullOrEmpty(attribute.max_length)) {
      return Number(attribute.max_length);
    }
  }

  /**
   * Get min value from server response to put on input tab in browser
   * @param attributeId attribute Id
   */
  validatorsLower(attributeId: string): number {
    if (Util.isNullOrEmpty(this.categoryDetailData) || Util.isNullOrEmpty(this.categoryDetailData.attributes)) {
      return;
    }

    const attribute = this.categoryDetailData.attributes.find(item => item.id === attributeId);

    if (Util.isNotNullOrEmpty(attribute) && Util.isNotNullOrEmpty(attribute.lower)) {
      return Number(attribute.lower);
    }
  }

  /**
   * Get max value from server response to put on input tab in browser
   * @param attributeId attribute Id
   */
  validatorsUpper(attributeId: string) {

    if (Util.isNullOrEmpty(this.categoryDetailData) || Util.isNullOrEmpty(this.categoryDetailData.attributes)) {
      return;
    }

    const attribute = this.categoryDetailData.attributes.find(item => item.id === attributeId);

    if (Util.isNotNullOrEmpty(attribute) && Util.isNotNullOrEmpty(attribute.upper)) {
      return Number(attribute.upper);
    }
  }

  /**
   * event when user select item on tab [vehicleType]
   * will sendrequest to get new ite categories details
   * GET https://api.tajr.sa/v1/category-details/:category_id
   */
  getCategoryDetailsByID(preload = false) {
    const categoryIdSlitStr = !!this.listAnItemModules.categoryId ? this.listAnItemModules.categoryId.split('-')[2] : this.categories.getCategoryActiveIDBylevel(3).split('-')[2];
    this.loadingModelStatus = true;
    this.categoryDetailSubscription = this.productService.getCategoryDetailsByID(categoryIdSlitStr).subscribe(data => {
      if (data) {
        this.categoryDetailData = data;
        this.activeCategoriesForm = this.getActiveFormControl();
      }
      this.loadingModelStatus = false;
    });
  }
  isIncludeCategory(catId) {
    if (catId == 'carSpecification') return this.categories.getCategoryActiveIDBylevel(2) == "0001-0100";
    if (catId == 'title') return this.categoryDetailData.attributes.find(atrr =>  atrr.id === catId)?.is_automatically_created === 'false';
    return this.categoryDetailData.attributes.find(atrr => atrr.id === catId);
  }
  isRequiredCategory(catId) {
    return this.categoryDetailData.attributes.find(atrr => atrr.id === catId)?.is_required_for_selling === 'true';
  }
  getListAnItemModule(preValue = null) {
    const catLv2ID = this.categories.getCategoryActiveIDBylevel(2);
    if (Util.isNullOrEmpty(this.formDatalist[catLv2ID])) this.formDatalist[catLv2ID] = new ListAnItemModules();
    if (preValue) this.formDatalist[catLv2ID] = preValue;
    return this.formDatalist[catLv2ID];
  }
  getActiveFormControl() {
    const categoryIDLv2 = this.categories.getCategoryActiveIDBylevel(2);
    const categoryControl = {
      categoryId: [this.listAnItemModules.categoryId || '', Validators.compose([
        Validators.required,
        Validators.pattern(NOT_EMPTY_REGEX)
      ])]
    }
    const formOptionsFromData = this.listAnItemService.getFormOptionsFromData(
      this.categoryDetailData, ListingAttributes.LIST_AN_ITEM[categoryIDLv2], this.listAnItemModules, categoryControl);
    return this.formBuilder.group(formOptionsFromData);
  }
  disableFormForPreload() {
    // reset value for model option
    if (this.activeCategoriesForm.controls.model) {
      this.activeCategoriesForm.controls.model.disable();
    }
    if (this.activeCategoriesForm.controls.year) {
      this.activeCategoriesForm.controls.year.disable();
    }
  }
  enableFormForPreload(preload) {
    // reset value for model option
    if (this.activeCategoriesForm.controls.model) {
      this.activeCategoriesForm.controls.model.enable();
    }
    if (this.activeCategoriesForm.controls.year) {
      this.activeCategoriesForm.controls.year.enable();
    }
  }

  /**
   *  show attribute label follow language response from server
   */
  showAttributesLable(attributesId: string) {
    let attributesLable = '';
    // return if don't has value
    if (Util.isNullOrEmpty(this.categoryDetailData) || Util.isNullOrEmpty(this.categoryDetailData.attributes)) {
      return;
    }

    const attribute = this.categoryDetailData.attributes.find(item => item.id === attributesId);

    // select label want to show from response
    if (Util.isNotNullOrEmpty(attribute) && Util.isNotNullOrEmpty(attribute.display_name)) {
      attributesLable = this.languageService.messageFromServer(attribute.display_name);
    }
    return attributesLable;
  }

  /**
   *  show option value want to show from response
   */
  showSelectTabOptionItems(attributesId: string): Array<Options> {
    let optionItems: Array<Options> = [];
    // return if don't has value
    if (Util.isNullOrEmpty(this.categoryDetailData) || Util.isNullOrEmpty(this.categoryDetailData.attributes)) {
      return;
    }

    const attribute = this.categoryDetailData.attributes.find(item => item.id === attributesId);

    // select option value want to show from response
    if (Util.isNotNullOrEmpty(attribute) && Util.isNotNullOrEmpty(attribute.options)) {
      optionItems = attribute.options;
    }
    return optionItems;
  }

  /**
   * auto generate a car text title make it follow a standard car name
   */
  generatedTitleForCarCatergories(): string {
    let generatedTitle = '';

    // manufacturer name from get category details by ID response
    if (Util.isNotNullOrEmpty(this.categoryDetailData.name)) {
      generatedTitle += this.wrap_dir('rtl', this.languageService.messageFromServer(this.categoryDetailData.name)) + ' ';
    }

    // model from  model option select by user
    const model = this.activeCategoriesForm.get(ItemAttributes.MODEL);
    if (model) {
      const modelValue = model.value;
      const modelOption = this.showSelectTabOptionItems(ItemAttributes.MODEL).find(item => item.value === modelValue && item.id !== 'Other');
      if (Util.isNotNullOrEmpty(modelValue) && Util.isNotNullOrEmpty(modelOption) && Util.isNotNullOrEmpty(modelOption.display)) {
        generatedTitle += this.wrap_dir('rtl', this.languageService.messageFromServer(modelOption.display)) + ' ';
      }

      // modelDetail from user input
      if (Util.isNotNullOrEmpty(this.activeCategoriesForm.get(ItemAttributes.MODEL_DETAIL).value)) {
        generatedTitle += this.wrap_dir('rtl', this.activeCategoriesForm.get(ItemAttributes.MODEL_DETAIL).value) + ' ';
      }

    }

    // year from  year option select by user
    const year = this.activeCategoriesForm.get(ItemAttributes.YEAR);
    if (year && Util.isNotNullOrEmpty(year.value)) {
      generatedTitle += this.wrap_dir('rtl', year.value);
    }

    return generatedTitle;
  }

  getErrorMessage(key, type) {
    if (key == 'categoryId') return this.languageService.findMessageByLanguage('requireCategoryId');
    const errorType = LIST_AN_ITEM_ERROR_TYPE[type];
    const attributeToShow = this.categoryDetailData?.attributes?.find(attr => attr.id == key);
    if (!attributeToShow) return '';
    return this.languageService.messageFromServer(attributeToShow.error[errorType])
  }

  getCategoryIcon = (id) => CATEGORY_ICON[id]

  // sorts out right to left string
  wrap_dir(dir, str) {
    if (dir === 'rtl') return '\u202B' + str + '\u202C';
    return '\u202A' + str + '\u202C';
  }

  onSubmit() {
    // value to show error
    this.submitted = true;
    if (this.activeCategoriesForm.valid) {
      switch (this.action) {
        case EDIT:
          if (this.activeCategoriesForm.get(ItemAttributes.CATEGORY_ID).value != this.listAnItemModules.categoryId) {
            this.notify.showWarnmingNotificationMessage('This listing category has been changed.');
            window.scroll({top: 0, behavior: SMOOTH});
            return;
          }
          break;
        default:
          break;
      }
      // form object to save in session
      const formObj = {};
      Object.keys(this.activeCategoriesForm.controls).forEach(key => {
        if (Util.isNotNullOrEmpty(this.activeCategoriesForm.controls[key].value)) {
          // Convert all activeCategoriesForm input value to string and push in formObj
          formObj[key] = this.activeCategoriesForm.controls[key].value.toString();
        } else {
          formObj[key] = '';
        }
      });
      // set value for key [title] attribute, its value will show in step 3
      if (!formObj[ItemAttributes.TITLE]) formObj[ItemAttributes.TITLE] = this.generatedTitleForCarCatergories();
      switch (this.action) {
        case EDIT:
          formObj[ItemAttributes.LISTING_ID] = this.listAnItemModules.listingId;
          break;
        default:
          break;
      }
      // save item information in local
      this.listAnItemService.setDataToStorage(TAJR_LIST_AN_ITEM, formObj);


      const CATEGORIES = makeStateKey<CategoryDetails>(TAJR_CATEGORIES_DETAILS);
      this.transferState.set(CATEGORIES, this.categoryDetailData);

      switch (this.action) {
        case EDIT:
          // go to list-an-item-pho-to page with action edit
          this.router.navigate([LIST_AN_ITEM_PHOTOS], {queryParams: {action: EDIT}});
          break;
        case SELL_SIMILIAR:
          // go to list-an-item-pho-to page  with action sellSimilar
          this.router.navigate([LIST_AN_ITEM_PHOTOS], {queryParams: {action: SELL_SIMILIAR}});
          break;
        default:
          // go to list-an-item-pho-to page with action create
          this.router.navigate([LIST_AN_ITEM_PHOTOS], {queryParams: {action: CREATE}});
          break;
      }

    } else {
      // scroll to the top browser
      this.scrollToError();

    }
  }

  /**
   *  scroll to input has invalid value follow class "ng-invalid" was added by angular form
   */
  scrollToError(): void {
    // get the first input invalid, it is the second element has class ".ng-invalid" in angular form
    const firstElementWithError = document.querySelectorAll('.ng-invalid')[1];
    // scroll to that element if it exit
    if (firstElementWithError) {
      firstElementWithError.scrollIntoView({behavior: SMOOTH, block: 'center'});
    }
  }

  handleSelectCategoryLv2(id) {
    if (this.categories.getCategoryActiveIDBylevel(2) !== id) {
      this.categories.setCategoryActiveIDBylevel(2, id);
      this.listAnItemModules = this.getListAnItemModule();
      this.getCategoryDetailsByID(true);
    }
  }
}
