<!-- login modal -->
<div class="container-fluid" [ngClass]="screenWidth <= 575 ? 'px-0':'' ">
  <div class="row">
    <div class="col">
      <!-- menu and close button on login modal-->
      <div class="d-flex justify-content-between align-items-center pt-3">
        <a href="javascript:void(0);"
           (click)="closeModal()">
          <img class="img-fluid" src="../../../assets/images/icon-images/tajr-icon-000042.png">
        </a>
        <h5 class="modal-title text-warning"><!--Report this listing -->الإبلاغ عن هذا الاعلان</h5>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <form class="text-right">
        <div class="container-fluid p-0">
          <div class="row pt-4">
            <div class="col px-0">
              <cdk-virtual-scroll-viewport class="viewport" *ngIf="reportReasons.length > 0">
                <div *cdkVirtualFor="let reportReason of reportReasons">
                  <a href="javascript:void(0);" class="p-3 border nav-link text-secondary"
                     [ngClass]="reportReason.subject_id === selectedSubjectId? 'bg-light border-primary':''"
                  (click)="selectReport(reportReason.subject_id)">
                    {{languageService.messageFromServer(reportReason.description)}}
                  </a>
                </div>
              </cdk-virtual-scroll-viewport>
            </div>
          </div>
          <div class="row">
            <div class="col px-0 pt-3">
              <button class="btn btn-primary px-5" [ngClass]="selectedSubjectId? '':'disabled bg-primary'"
              (click)="sendReport()">
                {{confirmMess}}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>


