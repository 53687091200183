import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {makeStateKey, TransferState} from '@angular/platform-browser';
import {
  EDIT,
  ItemAttributes, LIST_AN_ITEM, ListingStatus, SELL_SIMILIAR, SMOOTH,
  TAJR_LIST_AN_ITEM, TAJR_LIST_AN_ITEM_DETAILS,
  TAJR_LIST_AN_ITEM_PHOTOS,
  TAJR_CATEGORIES_DETAILS
} from '../shared/global';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {ProductService} from './product.service';
import {NotificationServices} from './notification.services';
import {ListingDetails} from '../modules/models/listingDetails';
import {Util} from '../utils/utils';
import {ListAnItemModules} from '../modules/models/list.an.item.modules';
import {CategoryDetails} from '../modules/models/category.details';
import {LanguageService} from './language.service';
import {SessionStorageService} from "../server-side-rendering/storages/session.storage.service";
import {isPlatformBrowser} from "@angular/common";
import {Hits} from "../modules/models/hits";
import {environment} from "../../environments/environment";
import {TimeService} from "./time.service";
import {TitleService} from "./title.service";

class ListAnItemPhotosAndVideo {
  photos: Array<Photo> = [];
  embedded_content_options: EmbeddedContentOptions [];
}

class EmbeddedContentOptions {
  id = '';
  value = '';
}

class Photo {
  position = '';
  photo_id = '';
}

@Injectable({
  providedIn: 'root'
})
export class ListingService {
  someThingWentWrongMessage = this.languageService.findMessageByLanguage('someThingWentWrong');
  deletedMess = this.languageService.findMessageByLanguage('itemHasBeenDeleted');
  thisListingHasBeenDeletedSuccessfulMess = this.languageService.findMessageByLanguage('thisListingHasBeenDeletedSuccessful');

  public listingDetailsRequest: Subscription;
  public listingDetails: ListingDetails;
  public errorMessage = '';
  public photosAndVideo: ListAnItemPhotosAndVideo;

  /**
   * listingDetails: item details save in local, ex: session
   * this value is item details already create listing success
   * this value will be set to [listAnItemModules] to show item detaile when user edit listing
   */
  public itemDetails: ListingDetails;

  /**
   * listAnItemModules: item detail model, default value is empty
   * this value use to show input, select default value on browser
   */
  public listAnItemModules: ListAnItemModules;
  public allCategoriesForm: ListAnItemModules;
  private categoryDetailsById: CategoryDetails;
  public listingId: string;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    // Router service use to get value from url
    private route: ActivatedRoute,
    private router: Router,
    private productService: ProductService,
    private notify: NotificationServices,
    private languageService: LanguageService,
    private sessionStorageService: SessionStorageService,
    // parse time service
    private timeService: TimeService,
    private titleService: TitleService,
    private transferState: TransferState,
  ) {
  }

  public editListingService(listingId: string, categoryDetailsById: CategoryDetails, action: string) {
    const actionsList = [EDIT, SELL_SIMILIAR];
    this.listingId = listingId;
    this.categoryDetailsById = categoryDetailsById;
    if (Util.isNotNullOrEmpty(action) && actionsList.includes(action)) {
      const CATEGORIES = makeStateKey<CategoryDetails>(TAJR_CATEGORIES_DETAILS);
      this.transferState.set(CATEGORIES, this.categoryDetailsById);
      switch (action) {
        case EDIT:
          // send to get listing details by id
          this.listingDetailsRequest = this.productService.getListingDetailsForEdit(this.listingId).subscribe(data => {
            if (200 === data.status && data.body) {
              this.itemDetails = data.body;
              // check and prevent the case item has been deleted
              if (Util.isNullOrEmpty(data.body.dd)) {
                this.generateListingDetailsForListAnItem(action);
              } else {
                // if this item already deleted will show notification on browser
                this.notify.showInfoNotificationMessage(this.deletedMess);
              }
            }
          }, err => {
            // get error message response from server
            if (err && err.error) {
              // show error
              this.errorMessage = this.languageService.messageFromServer(err.error.message);
            } else {
              // service error message
              this.errorMessage = this.someThingWentWrongMessage;
            }
            // show error
            this.notify.showWarnmingNotificationMessage(this.errorMessage);
            // scroll to top to see error response from serve
            if (isPlatformBrowser(this.platformId)) {
              window.scroll({top: 0, behavior: SMOOTH});
            }
          });
          break;
        case SELL_SIMILIAR:
          // send to get listing details by id
          this.listingDetailsRequest = this.productService.getListingDetails(this.listingId).subscribe(data => {
            if (200 === data.status && data.body) {
              this.itemDetails = data.body;
              this.generateListingDetailsForListAnItem(action);
            }
          }, err => {
            // get error message response from server
            if (err && err.error) {
              // show error
              this.errorMessage = this.languageService.messageFromServer(err.error.message);
            } else {
              // service error message
              this.errorMessage = this.someThingWentWrongMessage;
            }
            // show error
            this.notify.showWarnmingNotificationMessage(this.errorMessage);
            // scroll to top to see error response from serve
            if (isPlatformBrowser(this.platformId)) {
              window.scroll({top: 0, behavior: SMOOTH});
            }
          });
          break;
        default:
          break;
      }
    }
  }

  /**
   * generate listing detail for list-an-item page step 1
   * @param allCategoriesForm form in list-an-item page
   */
  public generateListingDetailsForListAnItem(action: string) {

    this.listAnItemModules = new ListAnItemModules();
    this.allCategoriesForm = new ListAnItemModules();

    Object.keys(this.allCategoriesForm).forEach(attributesId => {
      let attributeType = '';
      let attributeValue = '';
      let attribute;
      attributesId = attributesId.charAt(0).toLowerCase() + attributesId.slice(1);
      // get type of that attribute
      if (attributesId === ItemAttributes.CATEGORY_ID && Util.isNotNullOrEmpty(this.itemDetails.category_id)) {
        // set attributeValue use to add in listAnItemModules show on tab select categoryId
        attributeValue = this.itemDetails.category_id;
        this.listAnItemModules[attributesId] = attributeValue;
        return;
      }

      if ((Util.isNotNullOrEmpty(this.categoryDetailsById) && Util.isNotNullOrEmpty(this.categoryDetailsById.attributes.find(item => item.id === attributesId)))) {
        attributeType = this.categoryDetailsById.attributes.find(item => item.id === attributesId).type;
      }

      switch (attributeType) {

        case 'string':
          attribute = this.itemDetails.strings?.find(item => item.string_attribute_id === attributesId);
          if (Util.isNotNullOrEmpty(attribute)) {
            if (Util.isNullOrEmpty(attribute.option_id) && Util.isNotNull(attribute.value) && Util.isNotNullOrEmpty(attribute.value[0])) {
              attributeValue = attribute.value[0];
            } else if (Util.isNotNullOrEmpty(attribute.option_id)) {
              const optionAllowed = this.categoryDetailsById.attributes.find(
                (item) => item.id === attributesId
              ).options;

              const canUseOldValue = optionAllowed.find(
                (item) => item.value === attribute.option_id
              );

              attributeValue = !!canUseOldValue ? attribute.option_id: null;
            }
          }

          break;

        case 'date':
          attribute = this.itemDetails.dates?.find(item => item.date_attribute_id === attributesId);
          if (Util.isNotNullOrEmpty(attribute)) {
            if (Util.isNullOrEmpty(attribute.option_id) && Util.isNotNull(attribute.value) && Util.isNotNullOrEmpty(attribute.value[0])) {
              attributeValue = attribute.value[0];
            } else if (Util.isNotNullOrEmpty(attribute.option_id)) {
              attributeValue = attribute.option_id;
            }
          }

          break;

        case 'bool':
          attribute = this.itemDetails.bool?.find(item => item.bool_attribute_id === attributesId);
          if (Util.isNotNullOrEmpty(attribute)) {
            if (Util.isNotNullOrEmpty(attribute.value)) {
              attributeValue = attribute.value;
            }
          }

          break;

        case 'number':
          attribute = this.itemDetails.numbers?.find(item => item.number_attribute_id === attributesId);
          if (Util.isNotNullOrEmpty(attribute)) {
            if (Util.isNullOrEmpty(attribute.option_id) && Util.isNotNull(attribute.value) && Util.isNotNullOrEmpty(attribute.value[0])) {
              attributeValue = attribute.value[0];
            } else if (Util.isNotNullOrEmpty(attribute.option_id)) {
              attributeValue = attribute.option_id;
            }
          }

          break;
      }

      if (Util.isNullOrEmpty(attributeValue)) {
        attributeValue = '';
      }

      this.listAnItemModules[attributesId] = attributeValue;

    });
    switch (action) {
      case EDIT:
        this.listAnItemModules.listingId = this.listingId ;
        this.listAnItemModules.allowed_durations = this.itemDetails.category_details.allowed_durations;
        this.listAnItemModules.durations = this.itemDetails.category_details.durations;
        this.listAnItemModules.duration = this.itemDetails.duration;
        break;
      default:
        break;
    }

    // save listing detail for list-an-item page step 1
    this.sessionStorageService.set(TAJR_LIST_AN_ITEM, JSON.stringify(this.listAnItemModules));
    // save listing detail for list-an-item page step 3
    this.sessionStorageService.set(TAJR_LIST_AN_ITEM_DETAILS, JSON.stringify(this.listAnItemModules));

    this.photosAndVideo = new ListAnItemPhotosAndVideo();
    this.photosAndVideo.photos = [];
    this.photosAndVideo.embedded_content_options = [];

    if (Util.isNotNull(this.itemDetails.photos)) {
      Object.keys(this.itemDetails.photos).forEach(key => {
        if (Util.isNotNullOrEmpty(this.itemDetails.photos[key])) {
          const photoItem = new Photo();
          photoItem.position = key.toString();
          photoItem.photo_id = this.itemDetails.photos[key];
          // this.photosList.push(photoItem);
          this.photosAndVideo.photos.push(photoItem);
        }
      });
    }

    if (Util.isNotNull(this.itemDetails.embedded_content_options)) {
      for (const option of this.itemDetails.embedded_content_options) {
        const embeddedContentOptions = new EmbeddedContentOptions();
        embeddedContentOptions.id = option.id;
        embeddedContentOptions.value = option.value[0];
        // this.photosList.push(photoItem);
        this.photosAndVideo.embedded_content_options.push(embeddedContentOptions);
      }
    }

    // save listing detail for list-an-item page step 2
    this.sessionStorageService.set(TAJR_LIST_AN_ITEM_PHOTOS, JSON.stringify(this.photosAndVideo));

    this.router.navigate([LIST_AN_ITEM], {queryParams: {'action': action}});
  }

  /**
   * Withdraw a listing by ID
   * @param istingId listingId
   */
  public withdrawListing(listingId: string) {
    // send request to withdraw listing
    // send to withdraw listing by id
    this.listingDetailsRequest = this.productService.withdrawListing(listingId).subscribe(data => {
      if (200 === data.status) {
        this.notify.showInfoNotificationMessage(this.thisListingHasBeenDeletedSuccessfulMess);
        // set 1s will redirec to home page
        setTimeout(() => {
          this.router.navigate(['/']);
        }, 1000);
      }
    }, err => {
      // get error message response from server
      if (err && err.error) {
        // show error
        this.errorMessage = this.languageService.messageFromServer(err.error.message);
      } else {
        // service error message
        this.errorMessage = this.someThingWentWrongMessage;
      }
      // show error
      this.notify.showInfoNotificationMessage(this.errorMessage);
      // scroll to top to see error response from serve
      if (isPlatformBrowser(this.platformId)) {
        window.scroll({top: 0, behavior: SMOOTH});
      }
    });
  }

  /**
   * check listing status: active, expired, withdraw
   */
  checkListingStatus(item: Hits): string {
    let status = ListingStatus.ACTIVE;
    if (Util.isNullOrEmpty(item)) {
      return status;
    }
    if (Util.isNotNullOrEmpty(item.dd)) {
      status = ListingStatus.DELETED;
    } else {
      const current = Date.now();
      const endDate = Date.parse(item.endDate);
      const difference = this.timeService.convertMS(endDate - current);

      if (difference.day >= 0) {
        status = ListingStatus.ACTIVE;
      }
      if (difference.day < 0) {
        status = ListingStatus.EXPIRED;
      }
    }

    return status;
  }


  /**
   * Price message show on list
   */
  composedPrice(listingDetails: Hits): string {
    let orNearOffer: string;
    let orNearOfferText: string;
    let askingPrice: string;
    let askingPriceText: string;
    let composedPrice: string;

    if (listingDetails.orNearOffer != null) {
      orNearOffer = listingDetails.orNearOffer;
      orNearOfferText = this.languageService.findMessageByLanguage('orNearOffer');
    }

    askingPriceText = this.languageService.findMessageByLanguage('askingPrice');

    if (listingDetails.askingPrice != null) {
      askingPrice = listingDetails.askingPrice;
    }

    if (orNearOffer === 'true') {
      composedPrice = this.languageService.wrapDir('rtl', askingPrice) + this.languageService.wrapDir('rtl', ' ر.س ') + this.languageService.wrapDir('rtl', orNearOfferText);
    } else {
      composedPrice = this.languageService.wrapDir('rtl', askingPriceText) + this.languageService.wrapDir('rtl', ' ') + this.languageService.wrapDir('rtl', askingPrice) + this.languageService.wrapDir('rtl', ' ر.س ');
    }
    return composedPrice;
  }

  /**
   * endDate text show on list
   */
  endDateText(endDate: string): string {
    let endDateText = '';
    let endTime;
    // parse endDate
    endTime = Date.parse(endDate);
    //  Date.now() returns the number of milliseconds elapsed since January 1, 1970 00:00:00 UTC
    let current = Date.now();
    // the time zone difference, in minutes, from current locale (host system settings) to UTC
    let difference = this.timeService.convertMS(endTime - current);

    if (difference.day < 1) {
      // hours
      if (difference.hour > 0) {
        if (difference.hour === 1) {
          endDateText = this.languageService.wrapDir('rtl', 'الاعلان ينتهي بعد ساعه');
        } else {

          if (difference.hour === 2) {
            endDateText = this.languageService.wrapDir('rtl', 'الاعلان ينتهي بعد ساعتين');
          }
          if (difference.hour >= 3 && difference.hour < 11) {
            endDateText = this.languageService.wrapDir('rtl', 'الإعلان ينتهي بعد ') + this.languageService.wrapDir('rtl', difference.hour) + this.languageService.wrapDir('rtl', ' ساعات');
          }

          if (difference.hour > 10) {
            endDateText = this.languageService.wrapDir('rtl', 'الإعلان ينتهي بعد ') + this.languageService.wrapDir('rtl', difference.hour) + this.languageService.wrapDir('rtl', ' ساعه');
          }
        }
      } else {
        // minutes
        if (difference.minute > 0) {

          if (difference.minute === 1) {
            endDateText = this.languageService.wrapDir('rtl', 'الاعلان ينتهي بعد دقيقة');
          } else {

            if (difference.minute === 2) {
              endDateText = this.languageService.wrapDir('rtl', 'الاعلان ينتهي بعد دقيقتين');
            }
            if (difference.minute >= 3 && difference.minute < 11) {
              endDateText = this.languageService.wrapDir('rtl', 'الإعلان ينتهي بعد ') + this.languageService.wrapDir('rtl', difference.minute) + this.languageService.wrapDir('rtl', ' دقائق');
            }

            if (difference.minute > 10) {
              endDateText = this.languageService.wrapDir('rtl', 'الإعلان ينتهي بعد ') + this.languageService.wrapDir('rtl', difference.minute) + this.languageService.wrapDir('rtl', ' دقيقة');
            }
          }
        } else {
          endDateText = this.languageService.wrapDir('rtl', 'الإعلان انتهى');
        }
      }
    } else if (difference.day === 1) {
      endDateText = this.languageService.wrapDir('rtl', 'الاعلان ينتهي بعد يوم');
    } else {

      if (difference.day === 2) {
        endDateText = this.languageService.wrapDir('rtl', 'الإعلان ينتهي بعد يومين');
      }
      if (difference.day >= 3 && difference.day < 11) {
        endDateText = this.languageService.wrapDir('rtl', 'الإعلان ينتهي بعد ') + this.languageService.wrapDir('rtl', difference.day) + this.languageService.wrapDir('rtl', ' أيام');
      }

      if (difference.day > 10) {
        endDateText = this.languageService.wrapDir('rtl', 'الإعلان ينتهي بعد ') + this.languageService.wrapDir('rtl', difference.day) + this.languageService.wrapDir('rtl', ' يوم');
      }
    }
    return endDateText;
  }

  /**
   * Show item photo on list
   * @param photoId photoId response from serve
   */
  showPhoto(photoId: string, title: string) {
    const noImageUrl = '../../../assets/images/item-images/tajr-item000001.png';
    let titleMess = title ? this.titleService.generateArabicLable(title) : this.titleService.generateArabicLable(this.titleService.defaultTitle);
    if (photoId !== undefined && photoId !== null) {
      return environment.baseUrl + '/v1/photos/' + photoId + '/sizes/233x176/' + titleMess;
    }
    return noImageUrl;
  }
}
