import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFireMessaging } from '@angular/fire/messaging';
import {ChatService} from '../../services/chat.service';
import { ChatListComponent } from '../chat-list/chat-list.component'; 


@Component({
	selector: 'app-chat-full',
	templateUrl: './chat-full.component.html',
	styleUrls: ['./chat-full.component.css']
})
export class ChatFullComponent implements OnInit {
	public conversationID = '';
	public fcmToken = null;
	public newConversationSellerID = '';
	public viewChatList = true;
	public viewChatDetail = true;
	@ViewChild('listComp') listComp: ChatListComponent;
	constructor(
		private afMessaging: AngularFireMessaging,
		private chatService: ChatService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private location: Location
		) { 
		if (this.router.url.includes('new')) {
			this.conversationID = null;
			this.newConversationSellerID = this.activatedRoute.snapshot.paramMap.get('id')
		}
		else if (this.activatedRoute.snapshot.paramMap.get('id')) {
			this.conversationID = this.activatedRoute.snapshot.paramMap.get('id')
			this.newConversationSellerID = null;
		}
	}

	ngOnInit(): void {
		this.fcmToken = localStorage['tajr-fcm-token'];
		this.getFcmToken();
		this.checkDisplayCondition();
	}

	checkDisplayCondition() {
		const isSmallScreen = window.innerWidth <= 768;
		if (!isSmallScreen) {
			this.viewChatList = true;
			this.viewChatDetail = true;
		}
		else {
			if (this.conversationID || this.newConversationSellerID) {
				this.viewChatList = false;
				this.viewChatDetail = true;
			}
			else {
				this.viewChatList = true;
				this.viewChatDetail = false;
			}
		}
	}

	checkExpire() {
		const expireTimestamp = localStorage['tajr-fcm-expire'];
		return !(expireTimestamp && new Date().getTime() <= expireTimestamp)
	}

	getFcmToken() {
		if (this.fcmToken != null) {
			console.log('Token already acquired! Using the existing one: token=', this.fcmToken);
			if (this.checkExpire()) {
				this.requestNewToken();
			}
			else {

			}
		}
		else this.requestNewToken();
	}

	requestNewToken() {
		this.afMessaging.requestToken.subscribe(
			token => {
				this.fcmToken = token;
				localStorage['tajr-fcm-token'] = token;
				this.registrationToken();
			},
			err => {
				console.error('Unable to get permission to notify incoming messages');
			}
			);
	}

	registrationToken() {
		this.chatService.registrationToken(this.fcmToken).subscribe(data => {
			if (200 === data.status && data.body) {
				localStorage['tajr-fcm-expire'] = new Date().getTime() + 86400000*30;
				console.log("Register successfully")
			}
		}, err => {
			this.fcmToken = null;
			localStorage.removeItem('tajr-fcm-token');
			localStorage.removeItem('tajr-fcm-expire');
			console.error('Error register token, unable to notify incoming messages');
		});;
	}

	deleteToken() {
		this.fcmToken = null;
		localStorage.removeItem('tajr-fcm-token');
		localStorage.removeItem('tajr-fcm-expire');
		this.chatService.deleteToken(this.fcmToken).subscribe(data => {
			console.log("Delete token successfully")
		}, err => {
			
		});;
	}

	clickConversation(conversationID) {
		this.conversationID = conversationID;
		this.updateQuery(conversationID)
	}

	updateQuery(id) {
		this.router.navigate(['/chat', id], { relativeTo: this.activatedRoute});
	}

	changeNewConverURL(message) {
		this.location.replaceState('/chat/' + message.conversation_id);
		this.updateChatListNew(message)
	}

	updateChatList(message) {
		this.listComp.updateConversation(message);
	}

	updateChatListNew(message) {
		this.listComp.updateNewConversation(message);
	}

	updateChatListSeen(seenRes) {
		this.listComp.updateSeenConversation(seenRes);
	}

}
