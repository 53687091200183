<div class="contacts-list">
	<div class="inbox-categories">
		<div data-toggle="tab" *ngFor="let tab of tabs" [class.active]="tab == activeTab" (click)="changeTab($event)" [id]="tab"> {{ tab == "buying" ? "شراء " : "بيع "}} </div>
	</div>
	<div class="tab-content">
		<div class="contacts-outter-wrapper tab-pane">
			<form class="panel-search-form info form-group has-feedback no-margin-bottom">
				<input type="text" class="form-control" name="search" placeholder="Search" (keyup)="searchConversation($event)">
				<span class="fa fa-search form-control-icon"></span>
			</form>
			<div class="contacts-outter">
				<ul class="list-unstyled contacts" [ngClass]="activeTab">
					<li data-toggle="tab" data-target="#inbox-message-1" *ngFor="let conversation of conversations" [class.active]="conversation.conversation_id === conversationID" (click)="handleClickConversation(conversation.conversation_id)" [class.hide]="!getConversationListName(conversation).includes(searchString)">
						<div *ngIf="!checkSeenConversation(conversation)" class="message-count"></div>
						<img alt="" class="img-circle medium-image" src="https://bootdey.com/img/Content/avatar/avatar1.png">

						<div class="vcentered info-combo">
							<h3 class="no-margin-bottom name">{{ getConversationListName(conversation) }}</h3>
							<h5>{{ conversation.last_message }}</h5>
						</div>
						<div class="contacts-add">
							<span class="message-time">{{ getConversationShortDate(conversation) }}</span>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</div>