import {AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {DatePipe} from '@angular/common';
import {Subscription} from 'rxjs';
import {HttpHelperService} from '../../services/http-helper.service';
import {TimeService} from '../../services/time.service';
import {LanguageService} from '../../services/language.service';
import {ITEM_PER_PAGE, SearchConstant, TAJR_PERSONAL_DETAILS} from '../../shared/global';
import {ProfileDetails} from '../../modules/models/profile.details';
import {SearchListingDetails} from '../../modules/models/search.listing.details';
import {SearchService} from '../../services/search.service';
import {TabsetComponent} from 'ngx-bootstrap/tabs';
import {ActivatedRoute, Router} from '@angular/router';
import {SearchModel} from '../../modules/models/search.model';
import {NavigateService} from '../../services/navigate.service';
import {SessionStorageService} from "../../server-side-rendering/storages/session.storage.service";
import {ListingService} from "../../services/listing.service";
import {TitleService} from "../../services/title.service";

@Component({
  selector: 'app-selling',
  templateUrl: './selling.component.html',
  styleUrls: ['./selling.component.css'],
  providers: [DatePipe]
})
export class SellingComponent implements OnInit, AfterViewInit {
  loadMoreMessage = this.languageService.findMessageByLanguage('loadMore');
  loadingMess = this.languageService.findMessageByLanguage('loading');
  sellingMess = this.languageService.findMessageByLanguage('selling');
  unSoldMess = this.languageService.findMessageByLanguage('unSold');
  withdrawnnMess = this.languageService.findMessageByLanguage('withdrawnlistings');
  sourceTabLabel = this.languageService.findMessageByLanguage('myTajr');
  deletedMess = this.languageService.findMessageByLanguage('itemHasBeenDeleted');
  expiredMess = this.languageService.findMessageByLanguage('itemHasBeenExpired');

  // user details receive from server
  public profileDetails: ProfileDetails;
  // user details save from cookie
  public userDetails: ProfileDetails;
  //  subscription to observable request get member's listings
  private subscription: Subscription;
  private unsoldSubscription: Subscription;
  // member listing
  private getListings: SearchModel;
  public memberListing: SearchListingDetails;
  // select item number show on carousel
  public getListingFromItem = 0;
  public getListingToItem = ITEM_PER_PAGE;
  public totalNumberItem = 0;
  public currentNumberItem = 0;
  // width of screen size on browser
  public screenWidth: number;
  // attribute to show loading button
  loading = false;
  // select button default value
  public selected = 'latest';
  numberItemOfListingsMess = '';
  public currentTabMess = this.sellingMess;
  public tab: number;
  // Pagination attribute
  public totalItems: number;
  public currentPage = 1;
  public showBoundaryLinks = true;
  public maxSize = 5;

  constructor(
    // get language message from local
    private languageService: LanguageService,
    // service send request to login
    private searchService: SearchService,
    // contain api
    private httpHelper: HttpHelperService,
    private elementRef: ElementRef,
    // service convert date format
    private datePipe: DatePipe,
    // parse time service
    private timeService: TimeService,
    // service use to get value from router
    private activatedroute: ActivatedRoute,
    private router: Router,
    public navigateService: NavigateService,
    private sessionStorageService: SessionStorageService,
    public listingService: ListingService,
    public titleService: TitleService,
  ) {
  }

  @ViewChild('staticTabs') staticTabs: TabsetComponent;

  /**
   * change tab when user select or redirect
   * @param tabId tab when active
   */
  selectTabFromUrl(tabId: number) {
    if (this.staticTabs && this.staticTabs.tabs[tabId] !== undefined) {
      this.staticTabs.tabs[tabId].active = true;
      let isFromUrl = true;
      this.onSelect(tabId, isFromUrl);
    }
  }

  /**
   * change currentTabMess show on browser
   * @param tabId tab when active
   */
  onSelect(tabId: number, isFromUrl?: boolean): void {
    if (!isFromUrl) {
      this.currentPage = 1;
    }
    // get member selling listing
    this.getListingToItem = ITEM_PER_PAGE;
    this.memberListing = null;
    this.tab = tabId;
    switch (this.tab) {
      // tab selling
      case 0:
        this.currentTabMess = this.sellingMess;
        this.getMemberSellingListing();
        break;

      // tab unsold
      case 1:
        this.currentTabMess = this.unSoldMess;
        this.getMemberUnsoldListing(SearchConstant.EXPIRED_LISTINGS);
        break;

      // tab withdrawn
      case 2:
        this.currentTabMess = this.withdrawnnMess;
        this.getMemberUnsoldListing(SearchConstant.WITHDRAWN_LISTINGS);
        break;

      default:
        break;
    }
  }

  ngOnInit() {
    // get width of device
    this.screenWidth = window.innerWidth;
    this.getListings = new SearchModel();
    // if cookie contain user details
    if (this.sessionStorageService.get(TAJR_PERSONAL_DETAILS)) {
      this.userDetails = JSON.parse(this.sessionStorageService.get(TAJR_PERSONAL_DETAILS));
      // if user has information response
      if (this.userDetails !== undefined) {
        // get user details
        this.profileDetails = this.userDetails;
      }
    }
    // get value from url
    this.activatedroute.queryParamMap.subscribe((queryParams) => {
      if (queryParams.has(SearchConstant.PAGE)) {
        this.currentPage = Number(queryParams.get(SearchConstant.PAGE));
      }

      if (queryParams.has(SearchConstant.TAB)) {
        this.tab = Number(queryParams.get(SearchConstant.TAB));
        // show tab 0: selling tab, tab 1 : unsold tab
        this.selectTabFromUrl(this.tab);
      } else {
        this.tab = 0;
        this.selectTabFromUrl(this.tab);
      }
    });
  }

  ngAfterViewInit(): void {
    this.selectTabFromUrl(this.tab);
  }

  /**
   * use HostListener to  updated on resize:
   * @param event tab when selected
   */
  @HostListener('window:resize', ['$event'])
  getScreenSize(event) {
    this.screenWidth = window.innerWidth;
  }

  /**
   * Get listings. This function will call when loading page and when on change select button
   */
  getMemberSellingListing() {
    // import getListings detail parameters with "filter_by" is "latest" to get listing.
    this.getListings.sort_by = this.selected;
    this.getListings.from = ((this.currentPage - 1) * this.getListingToItem).toString();
    this.getListings.size = this.getListingToItem.toString();
    this.getListings.member_id = this.profileDetails.member_id;
    this.getListings.filter_by = '';
    this.currentPage = 1;

    // send request to get listing
    this.subscription = this.searchService.searchGeneralListing(this.getListings).subscribe(data => {
      // if user has information response
      if (200 === data.status && data.body) {
        this.memberListing = data.body;
        // set value for memberListing
        this.totalNumberItem = Number(this.memberListing.total);
        this.currentNumberItem = (this.currentPage - 1) * this.getListingToItem + this.memberListing.listing_ids.length;
        this.numberItemOfListingsMess = this.languageService.wrapDir('rtl', 'عرض ') + this.languageService.wrapDir('rtl', this.currentNumberItem) + this.languageService.wrapDir('rtl', ' إعلان ');
      }
    });
  }

  /**
   * Get member unsold listings. This function will call when loading page and when on change select button
   * @param filterBy example : expired_listings, withdrawn_listings
   */
  getMemberUnsoldListing(filterBy: string) {
    // import getListings detail parameters with "filter_by" is "latest" to get listing.
    this.getListings.sort_by = this.selected;
    this.getListings.from = ((this.currentPage - 1) * this.getListingToItem).toString();
    this.getListings.size = this.getListingToItem.toString();
    this.getListings.filter_by = filterBy;

    // send request to get listing
    this.unsoldSubscription = this.searchService.searchMemberListings(this.getListings).subscribe(data => {
      // if user has information response
      if (200 === data.status && data.body) {
        // set value for memberListing
        this.memberListing = data.body;
        this.totalNumberItem = Number(this.memberListing.total);
        this.currentNumberItem = (this.currentPage - 1) * this.getListingToItem + this.memberListing.listing_ids.length;
        this.numberItemOfListingsMess = this.languageService.wrapDir('rtl', 'عرض ') + this.languageService.wrapDir('rtl', this.currentNumberItem) + this.languageService.wrapDir('rtl', ' إعلان ');
      }
    });
  }

  /**
   * On change pagination
   *
   * @param event event change pagination
   */
  onChangepagination(event: any) {
    this.currentPage = event.page;
    if ((this.currentPage - 1) * this.getListingToItem == Number(this.getListings.from)) {
      return;
    }
    let urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams[SearchConstant.PAGE] = this.currentPage;
    urlTree.queryParams[SearchConstant.TAB] = this.tab;

    this.router.navigateByUrl(urlTree);
  }

  /**
   * Load more item when change pagination
   */
  onChangepaginationSellingPage(event: any) {
    this.loading = true;
    this.currentPage = event.page;
    this.getListings.from = ((this.currentPage - 1) * this.getListingToItem).toString();
    this.getListings.size = this.getListingToItem.toString();
    this.getListings.member_id = this.profileDetails.member_id;
    this.getListings.filter_by = '';
    // send request to get listing
    this.subscription = this.searchService.searchGeneralListing(this.getListings).subscribe(data => {
      this.loading = false;
      // if user has information response
      if (200 == data.status && data.body) {
        // push item to memberListing
        if (data.body.hits != undefined && data.body.hits.length > 0) {
          this.memberListing = data.body;
          this.totalItems = ((this.currentPage - 1) * this.getListingToItem) + this.memberListing.listing_ids.length;
          this.numberItemOfListingsMess = this.languageService.wrapDir('rtl', 'عرض ') + this.languageService.wrapDir('rtl', this.totalItems) + this.languageService.wrapDir('rtl', ' إعلان ');
        }
      }
    });
  }

  /**
   * load more unsold item in unsold tab
   * Don't use onChangepaginationSellingPage() because that Api difference now
   * @param filterBy example : expired_listings, withdrawn_listings
   */
  onChangepaginationUnsoldPage(event: any, filterBy: string) {
    this.loading = true;
    this.currentPage = event.page;
    this.getListings.from = ((this.currentPage - 1) * this.getListingToItem).toString();
    this.getListings.size = this.getListingToItem.toString();
    this.getListings.filter_by = filterBy;
    this.getListings.member_id = '';
    // send request to get listing
    this.unsoldSubscription = this.searchService.searchMemberListings(this.getListings).subscribe(data => {
      this.loading = false;
      // if user has information response
      if (200 == data.status && data.body) {
        // push item to memberListing
        if (data.body.hits != undefined && data.body.hits.length > 0) {
          this.memberListing = data.body;
          this.totalItems = ((this.currentPage - 1) * this.getListingToItem) + this.memberListing.listing_ids.length;
          this.numberItemOfListingsMess = this.languageService.wrapDir('rtl', 'عرض ') + this.languageService.wrapDir('rtl', this.totalItems) + this.languageService.wrapDir('rtl', ' إعلان ');
        }
      }
    });
  }
}
