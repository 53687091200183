<!-- blue line  -->
<div class="bg-primary pt-2"></div>
<!-- Progress Menu -->
<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-lg-10 col-xl-8 offset-lg-1 offset-xl-2 ">
      <div class="row mt-3">
        <div class="col text-right p-0">
          <a href="javascript:void(0);" class="text-decoration-none text-secondary font-weight-bold"
             [ngClass]="screenWidth >768?'':'small'" dir="rtl"> 4.
            تأكيد
            معلومات الإعلان</a>
        </div>
        <div class="col text-right p-0">
          <a class="text-decoration-none text-secondary "
             (click)="navigateToListAnItemPage('/list-an-item-details')"
             [ngClass]="screenWidth >768?'':'small'" dir="rtl"> 3.
            معلومات
            الإعلان</a>
        </div>
        <div class="col text-right p-0">
          <a class="text-decoration-none text-secondary"
             (click)="navigateToListAnItemPage('/list-an-item-photos')"
             [ngClass]="screenWidth >768?'':'small'" dir="rtl"> 2.
            الصور</a>
        </div>
        <div class="col text-right">
          <a class="text-decoration-none text-secondary"
             (click)="navigateToListAnItemPage('/list-an-item')"
             [ngClass]="screenWidth >768?'':'small'"
             dir="rtl">1.
            معلومات السيارة</a>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col">
          <div class="progress h-100">
            <div class="progress-bar bg-light  pt-1" role="progressbar" aria-valuenow="0"
                 aria-valuemin="0" aria-valuemax="100">
            </div>
            <div class="addPhotos bg-warning w-100 pt-1" role="progressbar" aria-valuenow="0"
                 aria-valuemin="0" aria-valuemax="100">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- content -->
<div class="container-fluid mt-2 ">
  <div class="row" dir="rtl">
    <div class="col"></div>
    <div class="col-12 col-lg-10 col-xl-8 offset-lg-1 offset-xl-2 text-right">
      <!-- Confirm -->
      <div class="row pt-1">
        <div class="col text-right">
          <p class="text-secondary mb-2" dir="rtl">
            <small>
              <!--Check that your listing details are correct. -->تأكد ان معلومات الإعلان
              صحيحه.
            </small>
          </p>
          <p class="text-danger small mb-0" dir="rtl">
            {{errorMessage}}
          </p>
        </div>
      </div>
      <!-- listing deatails -->
      <form class="bg-light border rounded p-3">
        <h4 class="text-warning text-right">
          <!--Listing Details -->معلومات الإعلان</h4>
        <div class="dropdown-divider"></div>
        <!-- Listing Type -->
        <div class="row">
          <div class="col-12 col-sm-4 col-md-4 text-right align-self-center">
            <label class="font-weight-bold text-secondary listingType-displayName mb-0">
              {{showAttributesLable('listingType')}}
            </label>
          </div>
          <div class="col text-right">
            <p class="text-right text-secondary mb-0 listingType">
              {{listAnItemModules.listingType}}
            </p>
          </div>
        </div>
        <div class="dropdown-divider"></div>
        <!-- Category  -->
        <div class="row">
          <div class="col-12 col-sm-4 col-md-4 text-right align-self-center">
            <label class="font-weight-bold text-secondary mb-0">
              <!-- Category-->فئة الإعلان </label>
          </div>
          <div class="col text-right">
            <p class="text-right text-secondary mb-0 category" dir="rtl">
              {{categoriesBreadcrumb}}
            </p>
          </div>
        </div>
        <div class="dropdown-divider"></div>
        <!-- title -->
        <div class="row">
          <div class="col-12 col-sm-4 col-md-4 text-right align-self-center">
            <label class="font-weight-bold text-secondary mb-0 title-displayName">
              {{showAttributesLable('title')}}
            </label>
          </div>
          <div class="col">
            <p class="text-right text-secondary mb-0 title">
              {{listAnItemModules.title}}
            </p>
          </div>
        </div>
        <div class="dropdown-divider"></div>
        <!-- subtitle -->
        <div class="row">
          <div class="col-12 col-sm-4 col-md-4 text-right align-self-center">
            <label class="font-weight-bold text-secondary mb-0 subtitle-displayName">
              {{showAttributesLable('subtitle')}}
            </label>
          </div>
          <div class="col">
            <p class="text-right text-secondary mb-0 subtitle">
              {{listAnItemModules.subtitle}}
            </p>
          </div>
        </div>
        <div class="dropdown-divider"></div>
        <!-- Asking price -->
        <div class="row">
          <div class="col-12 col-sm-4 col-md-4 text-right align-self-center">
            <label class="font-weight-bold text-secondary mb-0 askingPrice-displayName">
              {{showAttributesLable('askingPrice')}}
            </label>
            <span class="text-danger"> * </span>
          </div>
          <div class="col">
            <p class="text-right text-secondary mb-0 askingPrice">
              {{listAnItemModules.askingPrice | currency : " "}}  ر.س
            </p>
          </div>
        </div>
        <div class="dropdown-divider"></div>
        <!-- orNearOffer -->
        <div class="row">
          <div class="col-12 col-sm-4 col-md-4 text-right align-self-center">
            <label class="text-secondary mb-0 mt-1 orNearOffer-displayName">
              {{showAttributesLable('orNearOffer')}}
            </label>
          </div>
          <div class="col">
            <p class="text-right text-secondary mb-0 orNearOffer">
              {{listAnItemModules.orNearOffer}}
            </p>
          </div>
        </div>
        <!-- On road costs -->
        <div *ngIf="isIncludeCategory('onRoadCostsIncluded')">
          <div class="dropdown-divider"></div>
          <div class="row">
            <div class="col-12 col-sm-4 col-md-4 text-right align-self-center">
              <label class="font-weight-bold text-secondary mb-0 onRoadCostsIncluded-displayName">
                {{showAttributesLable('onRoadCostsIncluded')}}
              </label>
              <span class="text-danger"> * </span><br>
              <small class="text-secondary">
                <!--(e.g. current WoF, Registration)-->( مثلاً ... شامله الفحص الدوري وأستماره ساريه
                )
              </small>
            </div>
            <div class="col text-right align-self-center">
              <p class="text-right text-secondary mb-0 onRoadCostsIncluded">
                {{listAnItemModules.onRoadCostsIncluded}}
              </p>
            </div>
          </div>
        </div>
        <div class="dropdown-divider"></div>
        <!-- Listing Duration -->
        <div class="row">
          <div class="col-12 col-sm-4 col-md-4 text-right align-self-center">
            <!-- label Listing Duration-->
            <label class="font-weight-bold text-secondary mb-0 endDate-displayName">
              {{showAttributesLable('endDate')}}
            </label>
            <span class="text-danger"> * </span>
          </div>
          <div class="col ">
            <p class="text-right text-secondary mb-0 allowed_durations endDate" dir="rtl">
              {{ getDurationDisplay(endDate) }}
            </p>
          </div>
        </div>
        <div class="dropdown-divider"></div>
        <!-- Display phone numbers -->
        <div class="row">
          <div class="col-12 col-sm-4 col-md-4 text-right align-self-center">
            <label class="font-weight-bold text-secondary showMobileNumber-displayName">
              {{showAttributesLable('showMobileNumber')}}
            </label>
          </div>
          <div class="col text-right">
            <p class="text-right text-secondary mb-0 showMobileNumber">
              {{listAnItemModules.showMobileNumber}}
            </p>
          </div>
        </div>
        <div class="dropdown-divider"></div>
        <!-- Best contact time -->
        <div class="row">
          <div class="col-12 col-sm-4 col-md-4 text-right align-self-center">
            <label class="font-weight-bold text-secondary mb-0 bestContactTime-displayName">
              {{showAttributesLable('bestContactTime')}}
            </label>
          </div>
          <div class="col">
            <p class="text-right text-secondary mb-0 bestContactTime">
              {{listAnItemModules.bestContactTime}}
            </p>
          </div>
        </div>
        <div class="dropdown-divider"></div>
        <!-- Photos -->
        <div class="row">
          <div class="col-12 col-sm-4 col-md-4 text-right align-self-center">
            <label class="font-weight-bold text-secondary mb-0 ">
              <!--photos-->الصور
            </label>
          </div>
          <div class="col text-right">
            <div class="row addPhotos" *ngIf="0 < photosList.length">
              <div class="col-auto pl-0"><p> ✓</p></div>
              <div class="col-3 col-sm-3 col-md-2"
                   *ngFor="let photo of photosList">
                <div class="embed-responsive embed-responsive-4by3">
                  <div class="embed-responsive-item ">
                    <img class="img-fluid tajrImage" [src]="photo">
                  </div>
                </div>
              </div>
              <div class="col"></div>
            </div>
            <!-- text-show photo -->
            <div class="row">
              <div class="col text-secondary text-right mt-1 addNoteForPhotos">
                <span *ngIf="!(0 < photosList.length)">
                   {{emptyPhotoMess}}
                </span>
                <span *ngIf="0 < photosList.length">
                 {{notEmptyPhotoMess}}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="dropdown-divider"></div>
        <!-- videos -->
        <div class="row">
          <div class="col-12 col-sm-4 col-md-4 text-right align-self-center">
            <label class="font-weight-bold text-secondary mb-0 ">
              <!-- videos -->فيديو
            </label>
          </div>
          <div class="col">
            <div class="row ">
              <div class="col-3 col-sm-3 col-md-2">
                <div
                  class="embed-responsive embed-responsive-4by3 text-secondary addVideo "
                  *ngIf="undefined !==  videoUrl && 0 < videoUrl.length">
                  <img class="img-fluid embed-responsive-item" [src]="videoUrl">
                </div>
              </div>
              <div class="col"></div>
            </div>
            <!-- add Note For Video -->
            <div class="row">
              <div class="col text-secondary text-right addNoteForVideo"
                   *ngIf=" undefined === videoUrl || '' === videoUrl ">
                {{emptyVideoMess}}
              </div>
            </div>
          </div>
        </div>
      </form>
      <br>
      <!-- Car Details -->
      <form class="bg-light border rounded p-3">
        <!-- Car Details -->
        <h4 class="text-warning text-right">معلومات السيارة</h4>
        <div class="dropdown-divider"></div>
        <!-- car -->
        <div class="row">
          <div class="col-12 col-sm-4 col-md-4 text-right align-self-center">
            <label
              class="font-weight-bold text-secondary mb-0 vehicleDetailsName-displayName">
              {{categoryLv2Label}}
            </label>
            <span class="text-danger"> * </span>
          </div>
          <div class="col">
            <p class="text-right text-secondary mb-0 vehicleDetailsName">
              {{categoryLv3Label}}
            </p>
          </div>
        </div>
        <div *ngIf="isIncludeCategory('model')">
          <div class="dropdown-divider"></div>
          <!-- attributes[35].model -->
          <div class="row">
            <div class="col-12 col-sm-4 col-md-4 text-right align-self-center">
              <label
                class="font-weight-bold text-secondary mb-0 model-displayName">
                {{showAttributesLable('model')}}
              </label>
              <span class="text-danger"> * </span>
            </div>
            <div class="col">
              <p class="text-right text-secondary mb-0 model">
                {{listAnItemModules.model}}
              </p>
            </div>
          </div>
        </div>
        <div *ngIf="isIncludeCategory('modelDetail')">
          <div class="dropdown-divider"></div>
          <!-- attributes[3].Model detail -->
          <div class="row">
            <div class="col-12 col-sm-4 col-md-4 text-right align-self-center">
              <label class="font-weight-bold text-secondary mb-0 modelDetail-displayName">
                {{showAttributesLable('modelDetail')}}
              </label>
            </div>
            <div class="col">
              <p class="text-right text-secondary mb-0 modelDetail">
                {{listAnItemModules.modelDetail}}
              </p>
            </div>
          </div>
        </div>
        <div *ngIf="isIncludeCategory('year')">
          <div class="dropdown-divider"></div>
          <!-- attributes[10].year -->
          <div class="row">
            <div class="col-12 col-sm-4 col-md-4 text-right align-self-center">
              <label
                class="font-weight-bold text-secondary mb-0 year-displayName">
                {{showAttributesLable('year')}}
              </label>
              <span class="text-danger"> * </span>
            </div>
            <div class="col">
              <p class="text-right text-secondary mb-0 year">
                {{listAnItemModules.year}}
              </p>
            </div>
          </div>
        </div>
        <div *ngIf="isIncludeCategory('kilometers')">
          <div class="dropdown-divider"></div>
          <!-- attributes[18].Kilometres  -->
          <div class="row">
            <div class="col-12 col-sm-4 col-md-4 text-right align-self-center">
              <label
                class="font-weight-bold text-secondary mb-0 kilometers-displayName">
                {{showAttributesLable('kilometers')}}
              </label>
              <span class="text-danger"> * </span>
            </div>
            <div class="col">
              <p class="text-right text-secondary mb-0 kilometers">
                {{listAnItemModules.kilometers}}
              </p>
            </div>
          </div>
        </div>
        <div *ngIf="isIncludeCategory('exteriorColor')">
          <div class="dropdown-divider"></div>
          <!-- attributes[7].exteriorColor -->
          <div class="row">
            <div class="col-12 col-sm-4 col-md-4 text-right align-self-center">
              <label class="font-weight-bold text-secondary mb-0 exteriorColor-displayName">
                {{showAttributesLable('exteriorColor')}}
              </label>
            </div>
            <div class="col">
              <p class="text-right text-secondary mb-0 exteriorColor">
                {{listAnItemModules.exteriorColor}}
              </p>
            </div>
          </div>
        </div>
        <div *ngIf="isIncludeCategory('doors')">
          <div class="dropdown-divider"></div>
          <!-- attributes[11].doors -->
          <div class="row">
            <div class="col-12 col-sm-4 col-md-4 text-right align-self-center">
              <label class="font-weight-bold text-secondary mb-0 doors-displayName">
                {{showAttributesLable('doors')}}
              </label>
            </div>
            <div class="col">
              <p class="text-right text-secondary mb-0 doors">
                {{listAnItemModules.doors}}
              </p>
            </div>
          </div>
        </div>
        <div *ngIf="isIncludeCategory('bodyStyle')">
          <div class="dropdown-divider"></div>
          <!-- attributes[6].Body style -->
          <div class="row">
            <div class="col-12 col-sm-4 col-md-4 text-right align-self-center">
              <label class="font-weight-bold text-secondary mb-0 bodyStyle-displayName">
                {{showAttributesLable('bodyStyle')}}
              </label>
              <span class="text-danger"> * </span>
            </div>
            <div class="col">
              <p class="text-right text-secondary mb-0 bodyStyle">
                {{listAnItemModules.bodyStyle}}
              </p>
            </div>
          </div>
        </div>
        <div *ngIf="isIncludeCategory('seats')">
          <div class="dropdown-divider"></div>
          <!-- attributes[16].seat -->
          <div class="row">
            <div class="col-12 col-sm-4 col-md-4 text-right align-self-center">
              <label class="font-weight-bold text-secondary mb-0 seats-displayName">
                {{showAttributesLable('seats')}}
              </label>
            </div>
            <div class="col">
              <p class="text-right text-secondary mb-0 seats">
                {{listAnItemModules.seats}}
              </p>
            </div>
          </div>
        </div>
        <div *ngIf="isIncludeCategory('fuelType')">
          <div class="dropdown-divider"></div>
          <!-- attributes[0].Fuel type -->
          <div class="row">
            <div class="col-12 col-sm-4 col-md-4 text-right align-self-center">
              <label class="font-weight-bold text-secondary mb-0 fuelType-displayName">
                {{showAttributesLable('fuelType')}}
              </label>
            </div>
            <div class="col">
              <p class="text-right text-secondary mb-0 fuelType">
                {{listAnItemModules.fuelType}}
              </p>
            </div>
          </div>
        </div>
        <div *ngIf="isIncludeCategory('cylinders')">
          <div class="dropdown-divider"></div>
          <!-- attributes[21].cylinders -->
          <div class="row">
            <div class="col-12 col-sm-4 col-md-4 text-right align-self-center">
              <label class="font-weight-bold text-secondary mb-0 cylinders-displayName">
                {{showAttributesLable('cylinders')}}
              </label>
            </div>
            <div class="col">
              <p class="text-right text-secondary mb-0 cylinders">
                {{listAnItemModules.cylinders}}
              </p>
            </div>
          </div>
        </div>
        <div *ngIf="isIncludeCategory('engineSize')">
          <div class="dropdown-divider"></div>
          <!-- attributes[20].engineSize -->
          <div class="row">
            <div class="col-12 col-sm-4 col-md-4 text-right align-self-center">
              <label class="font-weight-bold text-secondary mb-0 engineSize-displayName">
                {{showAttributesLable('engineSize')}}
              </label>
            </div>
            <div class="col">
              <p class="text-right text-secondary mb-0 engineSize">
                {{listAnItemModules.engineSize}}
              </p>
            </div>
          </div>
        </div>
        <div *ngIf="isIncludeCategory('transmission')">
          <div class="dropdown-divider"></div>
          <!-- attributes[12].transmission -->
          <div class="row">
            <div class="col-12 col-sm-4 col-md-4 text-right align-self-center">
              <label class="font-weight-bold text-secondary mb-0 transmission-displayName">
                {{showAttributesLable('transmission')}}
              </label>
            </div>
            <div class="col">
              <p class="text-right text-secondary mb-0 transmission">
                {{listAnItemModules.transmission}}
              </p>
            </div>
          </div>
        </div>
        <div *ngIf="isIncludeCategory('fourWheelDrive')">
          <div class="dropdown-divider"></div>
          <!-- attributes[25].4WD -->
          <div class="row">
            <div class="col-12 col-sm-4 col-md-4 text-right align-self-center">
              <label class="font-weight-bold text-secondary mb-0 4wd-displayName">
                {{showAttributesLable('fourWheelDrive')}}
              </label>
            </div>
            <div class="col ">
              <p class="text-right text-secondary mb-0 4wd">
                {{listAnItemModules.fourWheelDrive}}
              </p>
            </div>
          </div>
        </div>
        <div *ngIf="isIncludeCategory('numberOfOwners')">
          <div class="dropdown-divider"></div>
          <!-- attributes[14].Number of owners -->
          <div class="row">
            <div class="col-12 col-sm-4 col-md-4 text-right align-self-center">
              <label class="font-weight-bold text-secondary mb-0 numberOfOwners-displayName">
                {{showAttributesLable('numberOfOwners')}}
              </label>
            </div>
            <div class="col">
              <p class="text-right text-secondary mb-0 numberOfOwners">
                {{listAnItemModules.numberOfOwners}}
              </p>
            </div>
          </div>
        </div>
        <div *ngIf="isIncludeCategory('importHistory')">
          <div class="dropdown-divider"></div>
          <!-- attributes[15].Import history -->
          <div class="row">
            <div class="col-12 col-sm-4 col-md-4 text-right align-self-center">
              <label class="font-weight-bold text-secondary mb-0 importHistory-displayName">
                {{showAttributesLable('importHistory')}}
              </label>
            </div>
            <div class="col">
              <p class="text-right text-secondary mb-0 importHistory">
                {{listAnItemModules.importHistory}}
              </p>
            </div>
          </div>
        </div>
        <div *ngIf="isIncludeCategory('mvpiExpires')">
          <div class="dropdown-divider"></div>
          <!-- attributes[9].mvpiExpires -->
          <div class="row">
            <div class="col-12 col-sm-4 col-md-4 text-right align-self-center">
              <label class="font-weight-bold text-secondary mb-0 mvpiExpires-displayName">
                {{showAttributesLable('mvpiExpires')}}
              </label>
            </div>
            <div class="col">
              <p class="text-right text-secondary mb-0 mvpiExpires">
                {{listAnItemModules.mvpiExpires}}
              </p>
            </div>
          </div>
        </div>
        <div *ngIf="isIncludeCategory('numberPlate')">
          <div class="dropdown-divider"></div>
          <!-- attributes[8].numberPlate -->
          <div class="row">
            <div class="col-12 col-sm-4 col-md-4 text-right align-self-center">
              <label class="font-weight-bold text-secondary mb-0 numberPlate-displayName">
                {{showAttributesLable('numberPlate')}}
              </label>
            </div>
            <div class="col ">
              <p class="text-right text-secondary mb-0 numberPlate">
                {{listAnItemModules.numberPlate}}
              </p>
            </div>
          </div>
        </div>
        <div *ngIf="isIncludeCategory('description')">
        <div class="dropdown-divider"></div>
        <!-- Description -->
          <div class="row h-100">
            <div class="col-12 col-sm-4 col-md-4 text-right align-self-center">
              <label class="font-weight-bold text-secondary mb-0 description-displayName">
                {{showAttributesLable('description')}}
              </label>
            </div>
            <div class="col h-100">
              <p class="text-right text-secondary mb-0 description text-break"
                 *ngIf="listAnItemModules.description === 'x'">
                x
              </p>
              <textarea *ngIf="listAnItemModules.description !== 'x'"
                cdkTextareaAutosize
                        class="text-secondary text-right border-0 bg-light text-description overflow-hidden" readonly
                        dir="rtl">{{listAnItemModules.description}}
                </textarea>
            </div>
          </div>
        </div>
      </form>
      <!-- Extras -->
      <form class="bg-light border rounded pt-3 pl-3 pr-3 pb-0"
            *ngIf="false">
        <!-- Extras title -->
        <h4 class="text-warning text-right">
          <!-- Extras-->إضافات الإعلان‎ </h4>
        <div class="dropdown-divider mb-0"></div>
        <div *ngFor="let fee of tajrListAnItemFees.fees">
          <div class="row pt-2" dir="rtl">
            <div class="col-12 col-sm-4 col-md-4 text-right align-self-center">
              <label class="font-weight-bold text-secondary mb-0">ر{{fee.display_name.arabic}}</label>
            </div>
            <div class="col-12 col-sm">
              <p class="text-right text-secondary mb-0" [ngClass]="{'pt-3': fee.purchased == 'true'}">
                <span *ngIf="fee.purchased != 'true'">
                     {{fee.credit | currency: " "}} ر.س
                </span>

                <span matBadge="✓" matBadgeColor="primary" matBadgeOverlap="false" matBadgeSize="small" matBadgePosition="after" *ngIf="fee.purchased == 'true'">
                  {{fee.credit | currency: " "}} ر.س
                </span>
              </p>
            </div>
          </div>
          <div class="dropdown-divider mb-0"></div>
        </div>
      </form>
<!--      <br>-->
      <!-- total fees -->
      <form class="bg-light border rounded p-3" *ngIf="false">
        <div class="row">
          <div class="col-12 col-sm-4 col-md-4 text-right align-self-center">
            <label class="font-weight-bold text-secondary mb-0">
              <!--Total fees -->مجموع الرسوم</label>
          </div>
          <div class="col-12 col-sm">
            <p class="text-right text-secondary mb-0 total_cost">
              {{tajrListAnItemFees?.total_cost | currency: " "}} ر.س
            </p>
          </div>
        </div>
      </form>
<!--      <br>-->
      <!-- My account balance -->
      <form class="bg-light border rounded p-3" *ngIf="false">
        <div class="row">
          <div class="col-12 col-sm-4 col-md-4 text-right align-self-center">
            <label class="font-weight-bold text-secondary mb-0">
              <!--My account balance -->رصيدي
            </label>

          </div>
          <div class="col-12 col-sm">
            <p class="text-right text-secondary mb-0 myAccountBalance">
              0.00 ر.س
            </p>
          </div>
        </div>
      </form>
      <!-- edit and Start my Classified button   -->
      <div class="row">
        <div class="col-12 col-sm-auto mt-3">
          <button class="btn btn-primary px-5 sendRequestBtn"
                  [ngClass]="screenWidth >575?'':'btn-block'"
                  (click)="onSubmit()"
                  *ngIf="!loading">
            <!--Startnew listing -->{{confirmBtnLabel}}
          </button>
          <button class="btn alert-primary text-white px-3 d-flex align-items-center "
                  *ngIf="loading" disabled>
            <span class="spinner-border spinner-border-sm mx-2"></span>
            {{loadingMess}}
          </button>
        </div>
        <div class="col-12 col-sm-auto mt-3" [ngClass]="screenWidth >575?'p-0':''">
          <button routerLink="/list-an-item" class="btn btn-primary" [ngClass]="screenWidth >575?'':'btn-block'">
            <!--Edit -->عدل بيانات الإعلان
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- blue line  -->
<div class="bg-primary mt-5 pt-2"></div>
