import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-selling',
  templateUrl: './dashboard-selling.component.html',
  styleUrls: ['./dashboard-selling.component.css']
})
export class DashboardSellingComponent implements OnInit {
// Filter when get listing use to data binding to show item on watchlist
  latest = 'latest';
  closingSoon = 'closing_soon';
  constructor() { }

  ngOnInit() {
  }

}
