<!-- blue line  -->
<div class="bg-primary pt-2"></div>
<!--content-->
<div class="bg-light">
  <div class="container-fluid">

    <div class="row">
      <div class="col pt-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb p-0 pt-1 category bg-light" dir="rtl">
            <a routerLink="/my-tajr" class="text-secondary">
              <small>
                <!--My Tajer -->
                {{sourceTabLabel}}
              </small>
            </a>&gt;
            <a class="text-secondary">
              <small>&nbsp;
                {{currentTabMess}}
              </small>
            </a>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col pt-1">
        <h4 class="text-warning text-right">
          <!-- Selling -->بيع</h4>
      </div>
    </div>

    <div class="row">
      <div class="col">

        <tabset #staticTabs dir="rtl" class="navTabs">
          <!-- selling tab-->
          <tab (selectTab)="onSelect(0)">
            <ng-template tabHeading>
              <b class="text-secondary font-weight-normal"
                 [ngClass]="screenWidth > 575? '':'small'"> الاعلانات المعروضة للبيع</b>
            </ng-template>

            <!--tab selling content-->
            <!--number of listing-->
            <div class="row" *ngIf="totalNumberItem > 0 && memberListing != null">
              <div class="col">
                <p class="text-primary text-right mb-0 mt-2 px-2">
                  <small>{{numberItemOfListingsMess}}</small>
                </p>
              </div>
            </div>
            <!-- sort -->
            <div class="row" dir="ltr" *ngIf="totalNumberItem > 0">
              <div class="col">
                <div class="d-flex justify-content-between align-content-center bg-light p-2 mt-2 mb-3">
                  <div class="d-flex justify-content-start align-items-center">
                  </div>
                  <div class="d-flex justify-content-end align-items-center">
                    <div class="form-group text-right">
                      <label class="font-weight-bold text-warning mb-0">
                        <!--sort -->ترتيب حسب‎</label>
                      <select dir="rtl" class="form-control form-control-sm text-dark py-0"
                              [(ngModel)]='selected'
                              (change)="currentPage = 1;getMemberSellingListing()">
                        <option value="latest">
                          <!--Latest listings-->اخر الإعلانات
                        </option>
                        <option value="closing_soon">
                          <!--Closing soon-->تنتهي قريبا
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- selling items area when has item  -->
            <div *ngIf="totalNumberItem > 0">
              <div>
                <div class="row">
                  <div class="col col-lg-10 offset-lg-2">
                    <ul class="list-unstyled" *ngIf="memberListing != null" dir="ltr">
                      <a class="media border rounded-lg carouselStyle position-relative bg-white row m-0 mb-3"
                          *ngFor="let slide of memberListing.hits; index as index"
                          [routerLink]="navigateService.generateListingPageUrl(slide.listing_id, slide.title)">
                        <mat-card
                          class="align-self-end text-left p-2 shadow-none d-none d-sm-block mb-1 ml-1 position-absolute"
                          dir="rtl">
                          <mat-card-title class="font-weight-bold text-dark text-break mb-0 h6" dir="rtl">
                            ‬ {{listingService.composedPrice(slide)}}
                          </mat-card-title>
                        </mat-card>
                        <div class="media-body col-8 col-md-9 p-2" dir="rtl">
                          <mat-card class="text-right shadow-none p-0">

                            <mat-card-subtitle class="small mb-0"
                                               *ngIf="listingService.checkListingStatus(slide) == 'deleted' ">
                            <span class="badge badge-danger px-2 py-1">
                                   {{deletedMess}}
                            </span>
                            </mat-card-subtitle>
                            <mat-card-subtitle class="small mb-0"
                                               *ngIf="listingService.checkListingStatus(slide) == 'expired' ">
                            <span class="badge badge-primary px-2 py-1">
                                   {{expiredMess}}
                            </span>
                            </mat-card-subtitle>
                            <mat-card-content class="text-dark mb-2 cardGalleryLineBreak" dir="rtl"
                                              [ngClass]="screenWidth <= 575 ? 'small' : ''">
                              {{slide.locality_arabic}} | {{ listingService.endDateText(slide.endDate) }}
                            </mat-card-content>

                            <mat-card-title class="font-weight-bold text-dark cardGalleryLineBreak"
                                            [ngClass]="screenWidth <= 575 ? 'FONT_SIZE_14' : 'h6'">
                              {{slide.title}}
                            </mat-card-title>
                            <mat-card-subtitle class="text-dark text-break align-self-end  cardGalleryLineBreak"
                                               *ngIf="slide.subtitle != null">
                              {{slide.subtitle}}
                            </mat-card-subtitle>
                            <mat-card-title class="font-weight-bold text-dark text-break text-left d-sm-none"
                                            [ngClass]="screenWidth <= 575 ? 'FONT_SIZE_14' : 'h6'">
                              ‬ {{listingService.composedPrice(slide)}}
                            </mat-card-title>
                          </mat-card>
                        </div>
                        <mat-card
                          class="col-4 col-sm-4 col-md-3 embed-responsive embed-responsive-16by9 shadow-none align-self-center"
                          dir="rtl">
                          <img class="img-fluid embed-responsive-item w-100 h-100 tajrImage"
                               [src]="listingService.showPhoto(slide.photo_1, slide.title)"
                               [alt]="slide.photo_1 != null ? titleService.generateArabicLable(slide.title): titleService.generateArabicLable(titleService.defaultTitle)">
                        </mat-card>
                        <!-- card icon -->
                        <img class="img-fluid p-1 position-absolute tajrCardIcon"
                             *ngIf="slide?.watchlist_id"
                             src="../../../assets/images/icon-images/tajr-icon-000041.png">
                      </a>
                    </ul>
                  </div>
                </div>

                <div class="row" dir="ltr" *ngIf="loading == false && totalNumberItem > getListingToItem && tab == 0">
                  <div class="col-2 d-none d-lg-block"></div>
                  <div class="col" dir="rtl">
                    <pagination class="d-flex justify-content-center"
                                [boundaryLinks]="showBoundaryLinks"
                                [totalItems]="totalNumberItem"
                                (pageChanged)="onChangepagination($event)"
                                [maxSize]="maxSize"
                                [itemsPerPage]="getListingToItem"
                                [(ngModel)]="currentPage"
                                previousText="&lsaquo;"
                                nextText="&rsaquo;"
                                firstText="&laquo;"
                                lastText="&raquo;">
                    </pagination>
                  </div>
                </div>

              </div>
            </div>
            <!-- selling items area when empty -->
            <div *ngIf="totalNumberItem === 0">
              <div class="container-fluid" dir="ltr">
                <div class="row">
                  <div class="col text-center">
                    <br>
                    <br>
                    <br>

                    <img class="img-fluid pb-3 pt-4"
                         [width]="screenWidth > 575? '128':'64'"
                         src="../../../assets/images/icon-images/tajr-icon-000064.png">
                    <h4 class="font-weight-normal pb-2">Garage getting full?</h4>
                    <p>
                      List your stuff on Tajer to make some room.
                    </p>

                    <br>
                    <br>
                    <br>

                  </div>
                </div>
              </div>
            </div>
          </tab>
          <!-- unsold tab-->
          <tab (selectTab)="onSelect(1)">
            <ng-template tabHeading>
              <b class="text-secondary font-weight-normal"
                 [ngClass]="screenWidth > 575? '':'small'"> الاعلانات المنتهية </b>
            </ng-template>
            <!--unsold tab content-->
            <!--number of listing-->
            <div class="row" *ngIf="totalNumberItem > 0">
              <div class="col">
                <p class="text-primary text-right mb-0 mt-2 px-2">
                  <small>{{numberItemOfListingsMess}}</small>
                </p>
              </div>
            </div>
            <!-- unsold items area when has item  -->
            <div *ngIf="totalNumberItem > 0">
              <div>
                <div class="row">
                  <div class="col col-lg-10 offset-lg-2 pt-2">
                    <ul class="list-unstyled" *ngIf="memberListing != null" dir="ltr">
                      <a class="media border rounded-lg carouselStyle position-relative bg-white row m-0 mb-3"
                          *ngFor="let slide of memberListing.hits; index as index"
                          [routerLink]="navigateService.generateListingPageUrl(slide.listing_id, slide.title)">
                        <mat-card
                          class="align-self-end text-left p-2 shadow-none d-none d-sm-block mb-1 ml-1 position-absolute"
                          dir="rtl">
                          <mat-card-title class="font-weight-bold text-dark text-break mb-0 h6" dir="rtl">
                            ‬ {{listingService.composedPrice(slide)}}
                          </mat-card-title>
                        </mat-card>
                        <div class="media-body col-8 col-md-9 p-2" dir="rtl">
                          <mat-card class="text-right shadow-none p-0">

                            <mat-card-subtitle class="small mb-0"
                                               *ngIf="listingService.checkListingStatus(slide) == 'deleted' ">
                            <span class="badge badge-danger px-2 py-1">
                                   {{deletedMess}}
                            </span>
                            </mat-card-subtitle>
                            <mat-card-subtitle class="small mb-0"
                                               *ngIf="listingService.checkListingStatus(slide) == 'expired' ">
                            <span class="badge badge-primary px-2 py-1">
                                   {{expiredMess}}
                            </span>
                            </mat-card-subtitle>
                            <mat-card-content class="text-dark mb-2 cardGalleryLineBreak" dir="rtl"
                                              [ngClass]="screenWidth <= 575 ? 'small' : ''">
                              {{slide.locality_arabic}} | {{ listingService.endDateText(slide.endDate) }}
                            </mat-card-content>

                            <mat-card-title class="font-weight-bold text-dark cardGalleryLineBreak"
                                            [ngClass]="screenWidth <= 575 ? 'FONT_SIZE_14' : 'h6'">
                              {{slide.title}}
                            </mat-card-title>
                            <mat-card-subtitle class="text-dark text-break align-self-end  cardGalleryLineBreak"
                                               *ngIf="slide.subtitle != null">
                              {{slide.subtitle}}
                            </mat-card-subtitle>
                            <mat-card-title class="font-weight-bold text-dark text-break text-left d-sm-none"
                                            [ngClass]="screenWidth <= 575 ? 'FONT_SIZE_14' : 'h6'">
                              ‬ {{listingService.composedPrice(slide)}}
                            </mat-card-title>
                          </mat-card>
                        </div>
                        <mat-card
                          class="col-4 col-sm-4 col-md-3 embed-responsive embed-responsive-16by9 shadow-none align-self-center"
                          dir="rtl">
                          <img class="img-fluid embed-responsive-item w-100 h-100 tajrImage"
                               [src]="listingService.showPhoto(slide.photo_1, slide.title)"
                               [alt]="slide.photo_1 != null ? titleService.generateArabicLable(slide.title): titleService.generateArabicLable(titleService.defaultTitle)">
                        </mat-card>
                        <!-- card icon -->
                        <img class="img-fluid p-1 position-absolute tajrCardIcon"
                             *ngIf="slide?.watchlist_id"
                             src="../../../assets/images/icon-images/tajr-icon-000041.png">
                      </a>
                    </ul>
                  </div>
                </div>

                <!-- pagination  -->
                <div class="row" dir="ltr" *ngIf="loading == false && totalNumberItem > getListingToItem && tab == 1">
                  <div class="col-2 d-none d-lg-block"></div>
                  <div class="col" dir="rtl">
                    <pagination class="d-flex justify-content-center"
                                [boundaryLinks]="showBoundaryLinks"
                                [totalItems]="totalNumberItem"
                                (pageChanged)="onChangepagination($event)"
                                [maxSize]="maxSize"
                                [itemsPerPage]="getListingToItem"
                                [(ngModel)]="currentPage"
                                previousText="&lsaquo;"
                                nextText="&rsaquo;"
                                firstText="&laquo;"
                                lastText="&raquo;">
                    </pagination>
                  </div>
                </div>

              </div>
            </div>
            <!-- unsold items area when empty -->
            <div *ngIf="totalNumberItem === 0">
              <div class="container-fluid">
                <div class="row" dir="ltr">
                  <div class="col text-center">
                    <br>
                    <br>
                    <br>

                    <img class="img-fluid pb-3 pt-4"
                         [width]="screenWidth > 575? '128':'64'"
                         src="../../../assets/images/icon-images/tajr-icon-000063.png">
                    <h4 class="font-weight-normal pb-2">No unsold listings</h4>
                    <p>
                      Listings are archived after 45 days.
                    </p>
                    <br>
                    <br>
                    <br>

                  </div>
                </div>
              </div>
            </div>
          </tab>
          <!--Withdrawn listings tab-->
          <tab (selectTab)="onSelect(2)">
            <ng-template tabHeading>
              <b class="text-secondary font-weight-normal"
                 [ngClass]="screenWidth > 575? '':'small'"> الاعلانات المحذوفة</b>
            </ng-template>

            <!--tab Withdrawn listings content-->
            <!--number of listing-->
            <div class="row" *ngIf="totalNumberItem > 0">
              <div class="col">
                <p class="text-primary text-right mb-0 mt-2 px-2">
                  <small>{{numberItemOfListingsMess}}</small>
                </p>
              </div>
            </div>
            <!-- Withdrawn listings items area when has item -->
            <div *ngIf="totalNumberItem > 0">
              <div>
                <div class="row">
                  <div class="col col-lg-10 offset-lg-2 pt-2">
                    <ul class="list-unstyled" *ngIf="memberListing != null" dir="ltr">
                      <a class="media border rounded-lg carouselStyle position-relative bg-white row m-0 mb-3"
                          *ngFor="let slide of memberListing.hits; index as index"
                          [routerLink]="navigateService.generateListingPageUrl(slide.listing_id, slide.title)">
                        <mat-card
                          class="align-self-end text-left p-2 shadow-none d-none d-sm-block mb-1 ml-1 position-absolute"
                          dir="rtl">
                          <mat-card-title class="font-weight-bold text-dark text-break mb-0 h6" dir="rtl">
                            ‬ {{listingService.composedPrice(slide)}}
                          </mat-card-title>
                        </mat-card>
                        <div class="media-body col-8 col-md-9 p-2" dir="rtl">
                          <mat-card class="text-right shadow-none p-0">

                            <mat-card-subtitle class="small mb-0"
                                               *ngIf="listingService.checkListingStatus(slide) == 'deleted' ">
                            <span class="badge badge-danger px-2 py-1">
                                   {{deletedMess}}
                            </span>
                            </mat-card-subtitle>
                            <mat-card-subtitle class="small mb-0"
                                               *ngIf="listingService.checkListingStatus(slide) == 'expired' ">
                            <span class="badge badge-primary px-2 py-1">
                                   {{expiredMess}}
                            </span>
                            </mat-card-subtitle>
                            <mat-card-content class="text-dark mb-2 cardGalleryLineBreak" dir="rtl"
                                              [ngClass]="screenWidth <= 575 ? 'small' : ''">
                              {{slide.locality_arabic}} | {{ listingService.endDateText(slide.endDate) }}
                            </mat-card-content>

                            <mat-card-title class="font-weight-bold text-dark cardGalleryLineBreak"
                                            [ngClass]="screenWidth <= 575 ? 'FONT_SIZE_14' : 'h6'">
                              {{slide.title}}
                            </mat-card-title>
                            <mat-card-subtitle class="text-dark text-break align-self-end  cardGalleryLineBreak"
                                               *ngIf="slide.subtitle != null">
                              {{slide.subtitle}}
                            </mat-card-subtitle>
                            <mat-card-title class="font-weight-bold text-dark text-break text-left d-sm-none"
                                            [ngClass]="screenWidth <= 575 ? 'FONT_SIZE_14' : 'h6'">
                              ‬ {{listingService.composedPrice(slide)}}
                            </mat-card-title>
                          </mat-card>
                        </div>
                        <mat-card
                          class="col-4 col-sm-4 col-md-3 embed-responsive embed-responsive-16by9 shadow-none align-self-center"
                          dir="rtl">
                          <img class="img-fluid embed-responsive-item w-100 h-100 tajrImage"
                               [src]="listingService.showPhoto(slide.photo_1, slide.title)"
                               [alt]="slide.photo_1 != null ? titleService.generateArabicLable(slide.title): titleService.generateArabicLable(titleService.defaultTitle)">
                        </mat-card>
                        <!-- card icon -->
                        <img class="img-fluid p-1 position-absolute tajrCardIcon"
                             *ngIf="slide?.watchlist_id"
                             src="../../../assets/images/icon-images/tajr-icon-000041.png">
                      </a>
                    </ul>
                  </div>
                </div>

                <!--pagination -->
                <div class="row" dir="ltr" *ngIf="loading == false && totalNumberItem > getListingToItem && tab == 2">
                  <div class="col-2 d-none d-lg-block"></div>
                  <div class="col" dir="rtl">
                    <pagination class="d-flex justify-content-center"
                                [boundaryLinks]="showBoundaryLinks"
                                [totalItems]="totalNumberItem"
                                (pageChanged)="onChangepagination($event)"
                                [maxSize]="maxSize"
                                [itemsPerPage]="getListingToItem"
                                [(ngModel)]="currentPage"
                                previousText="&lsaquo;"
                                nextText="&rsaquo;"
                                firstText="&laquo;"
                                lastText="&raquo;">
                    </pagination>
                  </div>
                </div>

              </div>
            </div>
            <!-- Withdrawn listings items area when empty -->
            <div *ngIf="totalNumberItem === 0">
              <div class="container-fluid" dir="ltr">
                <div class="row">
                  <div class="col text-center">
                    <br>
                    <br>
                    <br>

                    <img class="img-fluid pb-3 pt-4"
                         [width]="screenWidth > 575? '128':'64'"
                         src="../../../assets/images/icon-images/tajr-icon-000065.png">
                    <h4 class="font-weight-normal pb-2">No Withdrawn listings</h4>
                    <p>
                      Listings are archived after 45 days.
                    </p>

                    <br>
                    <br>
                    <br>

                  </div>
                </div>
              </div>
            </div>
          </tab>
        </tabset>

      </div>
    </div>

    <!--the spinner when page load not finished-->
    <div class="d-flex justify-content-center min-vh-100 py-5 my-5" *ngIf="!memberListing">
      <mat-spinner class="mt-5"></mat-spinner>
    </div>

  </div>
</div>
<!-- blue line  -->
<div class="bg-primary pt-2"></div>
