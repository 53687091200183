import {Observable, of, Subscription} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpHelperService} from 'src/app/services/http-helper.service';
import {ApiReferenceService} from 'src/app/shared/api-reference.service';
import {UserRegisterDetails} from '../modules/models/user.register.details';
import {SearchModel} from '../modules/models/search.model';
import {SearchService} from './search.service';
import {UserEditDetails} from '../modules/models/user.edit.details';
import {UserChangePassword} from '../modules/models/user.change.password';
import {SessionStorageService} from '../server-side-rendering/storages/session.storage.service';
import {LocalStorageService} from '../server-side-rendering/storages/local.storage.service';

@Injectable({
  providedIn: 'root'
})

export class AccountService {
  public getListings: SearchModel;
  constructor(
    private http: HttpHelperService,
    private apiReference: ApiReferenceService,
    // service send request to login
    private searchService: SearchService,
    private sessionStorageService: SessionStorageService,
    private localStorageService: LocalStorageService
  ) {
  }

  /**
   * Used to validate the login credentials.
   * @param loginDetails - entered login details.
   */
  validateUser(loginDetails: any): Observable<any> {
    return this.http.LoginAuthentication(this.apiReference.loginUser, loginDetails).pipe(response => {
      return response;
    });
  }

  /**
   * user details
   * @param accessToken -user login token
   */
  getUserDetail(): Observable<any> {
    return this.http.getLoggedInUserData(this.apiReference.getUserDetails).pipe(userDetails => {
      return userDetails;
    });
  }

  /**
   * user details
   * @param accessToken -user login token
   */
  getUserPublicDetails(memberId: string): Observable<any> {
    const url = this.apiReference.getUserPublicDetails + memberId;
    return this.http.getUserPublicData(url).pipe(userDetails => {
      return userDetails;
    });
  }

  /**
   * uer logout
   */
  logOut(): Observable<any> {
    return this.http.LogOut(this.apiReference.revokeToken).pipe(response => {
      return response;
    });
  }

  /**
   * Registration - Insert a new mobile number.
   * @param mobileNumber -new mobile number.
   */
  registration(mobileNumber: string): Observable<any> {
    return this.http.RegistrationNewMobileNumber(this.apiReference.insertNewMobileNumber, mobileNumber).pipe(response => {
      return response;
    });
  }

  /**
   * Registration - Retrieve the status of a verification code against a mobile number.
   * @param mobileNumber -new mobile number.
   */
  registrationConfirm(mobileNumber: string, verificationCode: string): Observable<any> {
    const url = this.apiReference.verifyNewMobileNumber + mobileNumber + '/codes/' + verificationCode;
    return this.http.RegistrationConfirmNewMobileNumber(url).pipe(response => {
      return response;
    });
  }

  /**
   * Registration -Register details
   * @param userRegisterDetails -user register details.
   */
  registerDetails(userRegisterDetails: UserRegisterDetails): Observable<any> {
    const url = this.apiReference.registerDetails + userRegisterDetails.mobile_number;
    return this.http.RegisterUserDetails(url, userRegisterDetails).pipe(response => {
      return response;
    });
  }

  /**
   * Edit user details
   * @param userEditDetails -user edit details.
   */
  editDetails(userEditDetails: UserEditDetails): Observable<any> {
    const url = this.apiReference.editDetails;
    return this.http.EditUserDetails(url, userEditDetails).pipe(response => {
      return response;
    });
  }

  /**
   * User change password
   * @param userChangePassword -user change password details.
   */
  changePassword(userChangePassword: UserChangePassword): Observable<any> {
    const url = this.apiReference.changePassword;
    return this.http.ChangeUserPassword(url, userChangePassword).pipe(response => {
      return response;
    });
  }

  /**
   * Registration -Register details
   * @param userRegisterDetails -user register details.
   */
  resetPassword(mobileNumber: string): Observable<any> {
    const url = this.apiReference.resetPassword + mobileNumber + '/passwords';
    return this.http.ResetPassword(url).pipe(response => {
      return response;
    });
  }

  /**
   * Registration -Register details
   * @param userRegisterDetails -user register details.
   */
  resetPasswordConfirm(mobileNumber: string, verificationCode: string): Observable<any> {
    const url = this.apiReference.resetPasswordConfirm + mobileNumber + '/passwords/codes/' + verificationCode;
    return this.http.resetPasswordConfirm(url).pipe(response => {
      return response;
    });
  }

  /**
   * Registration -Register details
   * @param userRegisterDetails -user register details.
   */
  newPassword(mobileNumber: string, verificationCode: string, password: string): Observable<any> {
    const url = this.apiReference.newPassword + mobileNumber + '/passwords';
    return this.http.newPasswordReq(url, mobileNumber, verificationCode, password).pipe(response => {
      return response;
    });
  }

  /**
   * Photos - Get meta data.
   */
  getPhotosMetaData(photoId: string): Observable<any> {
    const url = this.apiReference.photoMetaData + photoId;
    return this.http.getAllPhotosIdByMembers(url).pipe(response => {
      return response;
    });
  }

  /**
   * Photos - Get all photo IDs by member.
   */
  getAllPhotosIdByMember(pageState: string): Observable<any> {
    const url = this.apiReference.allPhotosIdByMember + pageState;
    return this.http.getAllPhotosIdByMembers(url).pipe(response => {
      return response;
    });
  }

  /**
   * Photos - Delete photo by member.
   */
  deletePhotoByMember(photoId: string): Observable<any> {
    const url = this.apiReference.deletePhoto + photoId;
    return this.http.deletePhotoByMember(url).pipe(response => {
      return response;
    });
  }

  // https://api.tajr.sa/v1/registration/:mobile_number/codes/:verification_code
  getToken() {
    return this.localStorageService.get('token');
  }

  /**
   * send request to add item to member watchlist
   *
   * @param listingId listing id
   */
  addToWatchlist(listingId: string) {
    return this.http.addNewWatchList(this.apiReference.addToWatchListUrl, listingId).pipe(response => {
      return response;
    });
  }

  /**
   * send request to remove item to member watchlist
   *
   * @param listingId listing id
   */
  removeToWatchList(listingId: string) {
    const url = this.apiReference.removeWatchListUrl + listingId;
    return this.http.removeWatchList(url).pipe(response => {
      return response;
    });
  }

  /**
   * send request to get member watchlist
   *
   */
  getMemberWatchlist(data: any) {
    return this.http.getMemberWatchlist(this.apiReference.getMemberWatchListUrl, data).pipe(response => {
      return response;
    });
  }

  /**
   * Get recently viewed listings belong to a member
   */
  getRecentlyViewedListings(getListings) {
    return this.http.getMemberRecentListings(this.apiReference.getMemberRecentListingsUrl, getListings).pipe(response => {
      return response;
    });
  }

  /**
   * Get listing summary of member
   */
  getMemberListingSummary() {
    return this.http.getMemberListingSummary(this.apiReference.getMemberListingSummary).pipe(response => {
      return response;
    });
  }
}

