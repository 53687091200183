import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpHelperService} from 'src/app/services/http-helper.service';
import {ApiReferenceService} from 'src/app/shared/api-reference.service';
import {GetListings} from '../modules/models/get.listings';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  // listings parameters details send to server
  public getListings: GetListings;

  constructor(
    // sent request service helper
    private http: HttpHelperService,
    // contain api
    private apiReference: ApiReferenceService,
  ) {
    // this.http.setAuthHeaders();
  }

  /**
   * Get all listings, given the filter
   */
  getListingsItem(getListings): Observable<any> {
    return this.http.getListings(this.apiReference.getListings, getListings).pipe(response => {
      return response;
    });
  }

  getExistListingsItem(getListings) {
    return this.http.getExistListings(this.apiReference.getListings, getListings);
  }

  /**
   * Get all listings, without passing any parameters
   */
  getListingsItemWithoutPassing(): Observable<any> {
    return this.http.getListings(this.apiReference.getListings).pipe(response => {
      return response;
    });
  }

  /**
   * Get  listings by id
   */
  getListingsById(listingsId: string): Observable<any> {
    return this.http.listingDetails(this.apiReference.getListingById, listingsId).pipe(response => {
      return response;
    });
  }

  /**
   * Categories - Get all categories
   */
  getAllCategories() {

    return this.http.getAllCategories(this.apiReference.getAllCategories);

  }

  /**
   * Category Details By ID
   */
  getCategoryDetailsByID(categoryId: string) {
    const url = this.apiReference.getCategoryDetailsByID.replace("{categoryId}", categoryId);
    return this.http.getCategoryDetailsByID(url);
  }

  /**
   * get localities
   */
  getLocalities() {
    return this.http.getLocalities(this.apiReference.getLocalities);
  }

  /**
   * get localities By ID
   */
  getLocalitiesById(localId: any): Observable<any> {
    return this.http.getLocalities(this.apiReference.getLocalities + '/' + localId).pipe(response => {
      return response;
    });
  }

  /**
   * get fees for new listing
   */
  getFeesForNewListing(listing: any): Observable<any> {
    return this.http.getFeesForNewListing(this.apiReference.getFeesForNewListing, listing).pipe(response => {
      return response;
    });
  }

  /**
   * get fees for edit listing
   */
  getFeesForEditListing(listingId: string, listing: any): Observable<any> {
    let url = this.apiReference.getFeesForEditListing.replace("{listingId}", listingId);
    return this.http.getFeesForEditListing(url, listing).pipe(response => {
      return response;
    });
  }

  /**
   * create new listing
   */
  createNewListing(listing: any): Observable<any> {
    return this.http.createNewListing(this.apiReference.createNewListing, listing).pipe(response => {
      return response;
    });
  }

  /**
   * edit listing
   */
  editListing(listing: any, listingId: string): Observable<any> {
    let url = this.apiReference.editListingApi.replace("{listingId}", listingId);
    return this.http.editListing(url, listing).pipe(response => {
      return response;
    });
  }

  /**
   * Get listing details by ID
   */
  getListingDetails(listingId: any): Observable<any> {
    let url = this.apiReference.getListingDetailsById + listingId;
    return this.http.getListingDetails(url).pipe(response => {
      return response;
    });
  }

  /**
   * Get listing details for edit by ID
   */
  getListingDetailsForEdit(listingId: any): Observable<any> {
    let url = this.apiReference.getListingDetailsForEdit.replace("{listingId}", listingId);
    return this.http.getListingDetailsForEdit(url).pipe(response => {
      return response;
    });
  }

  /**
   * Withdraw  listing by ID
   */
  withdrawListing(listingId: any): Observable<any> {
    let url = this.apiReference.withdrawListingByID + listingId;
    return this.http.withdrawListingById(url).pipe(response => {
      return response;
    });
  }
}
