import {BrowserModule, BrowserTransferStateModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { UrlSerializer} from '@angular/router';
import {FormsModule, ReactiveFormsModule, FormBuilder} from '@angular/forms';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MaterialModule} from './modules/material/material.module';
import {ModalModule} from 'ngx-bootstrap/modal';
import {CollapseModule} from 'ngx-bootstrap//collapse';
import {HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule} from '@angular/common/http';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {DatePipe} from '@angular/common';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';


// service
import {TokenInterceptorService} from './services/token.interceptor.service';
import {ProcessHttpmsgService} from './services/process.httpmsg.service';
import {HttpHelperService} from 'src/app/services/http-helper.service';
import {ShortCutServiceService} from './shared/short-cut-service.service';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CustomizerService} from './services/customizer.service';
import {TimeService} from './services/time.service';
import {AuthguardService} from './services/authguard.service';
import {AuthService} from './services/auth.service';
import {PreventMemberService} from './services/prevent.member.service';
import { CustomUrlSerializer } from './services/custom.urlserializer.service'
import { TabsModule } from 'ngx-bootstrap/tabs';
// main components
import {AppComponent} from './app.component';
import {DashboardDefaultComponent} from './views/dashboard-default/dashboard-default.component';
import {DashboardNotfoundpageComponent} from './views/dashboard-notfoundpage/dashboard-notfoundpage.component';
import {DashboardLoginComponent} from './views/membership-area/dashboard-login/dashboard-login.component';
import {DashboardRegisterComponent} from './views/membership-area/dashboard-register/dashboard-register.component';
import {DashboardTncComponent} from './views/membership-area/dashboard-tnc/dashboard-tnc.component';
// element components
import {HeaderComponent} from './components/header/header.component';
import {DefaultSearchBoardComponent} from './components/default-search-board/default-search-board.component';
import {DefaultListingMenuComponent} from './components/default-listing-menu/default-listing-menu.component';
import {DefaultListingGalleryComponent} from './components/default-listing-gallery/default-listing-gallery.component';
import {FooterComponent} from './components/footer/footer.component';
import {TncComponent} from './components/tnc/tnc.component';
import {RegisterComponent} from './components/register/register.component';
import {DashboardRegisterConfirmComponent} from './views/membership-area/dashboard-register-confirm/dashboard-register-confirm.component';
import {RegisterConfirmComponent} from './components/register-confirm/register-confirm.component';
import {DashboardRegisterDetailsComponent} from './views/membership-area/dashboard-register-details/dashboard-register-details.component';
import {RegisterDetailsComponent} from './components/register-details/register-details.component';
import {LoginComponent} from './components/login/login.component';
import {DashboardCommunityComponent} from './views/membership-area/dashboard-community/dashboard-community.component';
import {CommunityComponent} from './components/community/community.component';
import {DashboardResetPasswordComponent} from './views/membership-area/dashboard-reset-password/dashboard-reset-password.component';
import {ResetPasswordComponent} from './components/reset-password/reset-password.component';
import {DashboardResetPasswordConfirmComponent} from './views/membership-area/dashboard-reset-password-confirm/dashboard-reset-password-confirm.component';
import {ResetPasswordConfirmComponent} from './components/reset-password-confirm/reset-password-confirm.component';
import {DashboardNewPasswordComponent} from './views/membership-area/dashboard-new-password/dashboard-new-password.component';
import {NewPasswordComponent} from './components/new-password/new-password.component';
import {ToastrModule} from 'ngx-toastr';
import {DashboardMyTajrComponent} from './views/membership-area/dashboard-my-tajr/dashboard-my-tajr.component';
import {MyTajrComponent} from './components/my-tajr/my-tajr.component';
import {DashboardWatchlistComponent} from './views/membership-area/dashboard-watchlist/dashboard-watchlist.component';
import {WatchlistComponent} from './components/watchlist/watchlist.component';
import {DashboardSellingComponent} from './views/membership-area/dashboard-selling/dashboard-selling.component';
import {SellingComponent} from './components/selling/selling.component';
import {DashboardListAnItemComponent} from './views/membership-area/dashboard-list-an-item/dashboard-list-an-item.component';
import {ListAnItemComponent} from './components/list-an-item/list-an-item.component';
import {DashboardListAnItemPhotosComponent} from './views/membership-area/dashboard-list-an-item-photos/dashboard-list-an-item-photos.component';
import {ListAnItemPhotosComponent} from './components/list-an-item-photos/list-an-item-photos.component';
import {DraganddropDirective} from './services/draganddrop.directive';
import {ProgressComponent} from './components/progress/progress.component';
import {ScrollDispatcher, ScrollingModule} from '@angular/cdk/scrolling';
import {DashboardListAnItemDetailsComponent} from './views/membership-area/dashboard-list-an-item-details/dashboard-list-an-item-details.component';
import {ListAnItemDetailsComponent} from './components/list-an-item-details/list-an-item-details.component';
import {DashboardListAnItemConfirmComponent} from './views/membership-area/dashboard-list-an-item-confirm/dashboard-list-an-item-confirm.component';
import {ListAnItemConfirmComponent} from './components/list-an-item-confirm/list-an-item-confirm.component';
import {DashboardListAnItemSuccessComponent} from './views/membership-area/dashboard-list-an-item-success/dashboard-list-an-item-success.component';
import {ListAnItemSuccessComponent} from './components/list-an-item-success/list-an-item-success.component';
import {DashboardListingComponent} from './views/dashboard-listing/dashboard-listing.component';
import {DashboardSearchPageComponent} from './views/dashboard-search-page/dashboard-search-page.component';
import {ListingComponent} from './components/listing/listing.component';
import {NotfoundComponent} from './components/notfound/notfound.component';
import {ListingItemsCarouselModalComponent} from './components/modals/listing-items-carousel-modal/listing-items-carousel-modal.component';
import { SearchPageComponent } from './components/search-page/search-page.component';
import { DashboardMemberListingsComponent } from './views/dashboard-member-listings/dashboard-member-listings.component';
import { MemberListingsComponent } from './components/member-listings/member-listings.component';
import { QuickCarSearchComponent } from './components/quick-car-search/quick-car-search.component';
import { DashboardFavouritesComponent } from './views/membership-area/dashboard-favourites/dashboard-favourites.component';
import { FavouritesComponent } from './components/favourites/favourites.component';
import { DashboardProhibitedGoodsComponent } from './views/dashboard-prohibited-goods/dashboard-prohibited-goods.component';
import { ProhibitedGoodsComponent } from './components/prohibited-goods/prohibited-goods.component';
import { DashboardContactComponent } from './views/dashboard-contact/dashboard-contact.component';
import { ContactComponent } from './components/contact/contact.component';
import { DashboardSettingsComponent } from './views/dashboard-settings/dashboard-settings.component';
import { SettingsComponent } from './components/settings/settings.component';
import { DashboardNotificationComponent } from './views/membership-area/dashboard-notification/dashboard-notification.component';
import { NotificationComponent } from './components/notification/notification.component';
import { DashboardBuyingComponent } from './views/membership-area/dashboard-buying/dashboard-buying.component';
import { BuyingComponent } from './components/buying/buying.component';
import { DashboardMyProfileComponent } from './views/membership-area/dashboard-my-profile/dashboard-my-profile.component';
import { MyProfileComponent } from './components/my-profile/my-profile.component';
import { DashboardMyPhotosComponent } from './views/membership-area/dashboard-my-photos/dashboard-my-photos.component';
import { MyPhotosComponent } from './components/my-photos/my-photos.component';
import { ConfirmDeleteListingModalComponent } from './components/modals/confirm-delete-listing-modal/confirm-delete-listing-modal.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { ListingService } from './services/listing.service';
import { AccountService } from './services/account.service';
import { DashboardEditProfileComponent } from './views/membership-area/dashboard-edit-profile/dashboard-edit-profile.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { DashboardChangePasswordComponent } from './views/membership-area/dashboard-change-password/dashboard-change-password.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { LoginModalComponent } from './components/modals/login-modal/login-modal.component';
import {NavigateService} from './services/navigate.service';
import {MatCardModule} from '@angular/material/card';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {AppShellRenderComponent} from './components/app-shell-render/app-shell-render.component';
import {AppShellNoRenderDirective} from './server-side-rendering/directives/app-shell-norender.directive';
import {AppShellRenderDirective} from './server-side-rendering/directives/app-shell-render.directive';
import {LocalStorageService} from './server-side-rendering/storages/local.storage.service';
import {SessionStorageService} from './server-side-rendering/storages/session.storage.service';
import {AllCategoriesResolve} from './server-side-rendering/resolves/all.categories.resolve';
import {CategoriesDetailsResolve} from './server-side-rendering/resolves/categories.details.resolve';
import {LocalitiesResolve} from './server-side-rendering/resolves/localities.resolve';
import {SwiperModule} from 'swiper/angular';
import { DefaultListingRecentlyViewedComponent } from './components/default-listing-recently-viewed/default-listing-recently-viewed.component';
import { DashboardMotorsComponent } from './views/dashboard-motors/dashboard-motors.component';
import { DashboardRecentlyViewedListingsComponent } from './views/dashboard-recently-viewed-listings/dashboard-recently-viewed-listings.component';
import { RecentlyViewedListingsComponent } from './components/recently-viewed-listings/recently-viewed-listings.component';
import { ListingReportModalComponent } from './components/modals/listing-report-modal/listing-report-modal.component';
import {ReportReasonsResolve} from './server-side-rendering/resolves/report.reasons.resolve';
import { DefaultRandomListingResultsComponent } from './components/default-random-listing-results/default-random-listing-results.component';
import {PaginationModule} from 'ngx-bootstrap';
import {TitleService} from './services/title.service';
import {HomeLatestResolve} from './server-side-rendering/resolves/home.latest.resolve';
import {HomeClosingSoonResolve} from './server-side-rendering/resolves/home.closing.soon.resolve';
import {HomeQuickCarSearchResolve} from './server-side-rendering/resolves/home.quick.car.search.resolve';
import {CommonHelperService} from './shared/common.helper.service';
import { DashboardChatComponent } from './views/dashboard-chat/dashboard-chat.component';
import { ChatFullComponent } from './components/chat-full/chat-full.component';
import { ChatListComponent } from './components/chat-list/chat-list.component';
import { ChatDetailComponent } from './components/chat-detail/chat-detail.component';

import { firebase } from '../environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  declarations: [
    AppShellNoRenderDirective,
    AppShellRenderDirective,
    AppComponent,
    DashboardDefaultComponent,
    DashboardNotfoundpageComponent,
    DashboardLoginComponent,
    DashboardRegisterComponent,
    HeaderComponent,
    DefaultSearchBoardComponent,
    QuickCarSearchComponent,
    SearchPageComponent,
    DefaultListingMenuComponent,
    DefaultListingGalleryComponent,
    FooterComponent,
    DashboardTncComponent,
    TncComponent,
    RegisterComponent,
    DashboardRegisterConfirmComponent,
    RegisterConfirmComponent,
    DashboardRegisterDetailsComponent,
    RegisterDetailsComponent,
    LoginComponent,
    DashboardCommunityComponent,
    CommunityComponent,
    DashboardResetPasswordComponent,
    ResetPasswordComponent,
    DashboardResetPasswordConfirmComponent,
    ResetPasswordConfirmComponent,
    DashboardNewPasswordComponent,
    NewPasswordComponent,
    DashboardMyTajrComponent,
    MyTajrComponent,
    DashboardWatchlistComponent,
    WatchlistComponent,
    DashboardSellingComponent,
    SellingComponent,
    DashboardListAnItemComponent,
    ListAnItemComponent,
    DashboardListAnItemPhotosComponent,
    ListAnItemPhotosComponent,
    DraganddropDirective,
    ProgressComponent,
    DashboardListAnItemDetailsComponent,
    ListAnItemDetailsComponent,
    DashboardListAnItemConfirmComponent,
    ListAnItemConfirmComponent,
    DashboardListAnItemSuccessComponent,
    ListAnItemSuccessComponent,
    DashboardListingComponent,
    DashboardSearchPageComponent,
    ListingComponent,
    NotfoundComponent,
    ListingItemsCarouselModalComponent,
    DashboardMemberListingsComponent,
    MemberListingsComponent,
    DashboardFavouritesComponent,
    FavouritesComponent,
    DashboardProhibitedGoodsComponent,
    ProhibitedGoodsComponent,
    DashboardContactComponent,
    ContactComponent,
    DashboardSettingsComponent,
    SettingsComponent,
    DashboardNotificationComponent,
    NotificationComponent,
    DashboardBuyingComponent,
    BuyingComponent,
    DashboardMyProfileComponent,
    MyProfileComponent,
    DashboardMyPhotosComponent,
    MyPhotosComponent,
    ConfirmDeleteListingModalComponent,
    DashboardEditProfileComponent,
    EditProfileComponent,
    DashboardChangePasswordComponent,
    ChangePasswordComponent,
    LoginModalComponent,
    AppShellRenderComponent,
    DefaultListingRecentlyViewedComponent,
    DashboardMotorsComponent,
    DashboardRecentlyViewedListingsComponent,
    RecentlyViewedListingsComponent,
    ListingReportModalComponent,
    DefaultRandomListingResultsComponent,
    DashboardChatComponent,
    ChatFullComponent,
    ChatListComponent,
    ChatDetailComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserTransferStateModule,
    ReactiveFormsModule,
    FormsModule,
    MatAutocompleteModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    CollapseModule,
    MatDialogModule,
    HttpClientModule,
    HttpClientXsrfModule,
    ModalModule.forRoot(),
    CarouselModule.forRoot(),
    ToastrModule.forRoot(),
    TabsModule.forRoot(),
    MatCardModule,
    NgbModule,
    DragDropModule,
    ScrollingModule,
    SwiperModule,
    PaginationModule.forRoot(),
    AngularFireModule.initializeApp(firebase),
    AngularFireMessagingModule,
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: false,
    // }),
    ServiceWorkerModule.register('firebase-messaging-sw.js', { enabled: true }),
  ],
  providers: [
    ProcessHttpmsgService,
    HttpHelperService,
    ShortCutServiceService,
    CustomizerService,
    TimeService,
    AuthguardService,
    AuthService,
    MatDialog,
    ListingService,
    FormBuilder,
    PreventMemberService,
    AccountService,
    NavigateService,
    DatePipe,
    ScrollDispatcher,
    LocalStorageService,
    SessionStorageService,
    AllCategoriesResolve,
    CategoriesDetailsResolve,
    LocalitiesResolve,
    ReportReasonsResolve,
    HomeLatestResolve,
    HomeClosingSoonResolve,
    HomeQuickCarSearchResolve,
    TitleService,
    CommonHelperService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    { provide: UrlSerializer, useClass: CustomUrlSerializer }
  ],
  entryComponents: [
    ListingItemsCarouselModalComponent,
    ConfirmDeleteListingModalComponent,
    LoginModalComponent,
    ListingReportModalComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})

export class AppModule {
}
