import {Component, OnInit} from '@angular/core';
import {SMOOTH} from '../../shared/global';

@Component({
  selector: 'app-community',
  templateUrl: './community.component.html',
  styleUrls: ['./community.component.css']
})
export class CommunityComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
