
import {CategoryModel} from './category.model';
import {
  FIRST_ITEM,
} from '../../shared/global';

export class Categories {
  public categories: CategoryModel[] = [];
  public categoryMaxLevel: number;

  constructor(
    _categories,
    _categoryMaxLevel
  ){
    this.categories = _categories
    this.categoryMaxLevel = _categoryMaxLevel;
  }

  public getCategoryBylevel(level) {
    //Level is natural order
    return this.categories[level-1]?.activeCategory || {};
  }

  public getCategoryActiveIDBylevel(level) {
    //Level is natural order
    return this.categories[level-1]?.activeCategoryID;
  }

  public getCategoryListBylevel(level) {
    //Level is natural order
    return this.categories[level-1]?.list || [];
  }

  public setCategoryActiveIDBylevel(level, newID) {
    //Level is natural order
    this.categories = this.categories.map((category, i) => {
      if (i < level - 1) return category;
      if (i == level - 1) {
        const activeCategory = category.list.find(({ id }) => id == newID);
        this.categories[i+1].list = activeCategory?.subcategories;
        return {
          ...category,
          activeCategoryID: newID,
          activeCategory 
        }
      }
      return {
        ...category,
        activeCategoryID: category.list[FIRST_ITEM]?.id,
        activeCategory: category.list[FIRST_ITEM]
      }
    })
  }
}
