import {GET, POST, PUT, DELETE, EMPTY_VALUE} from '../shared/global';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {HttpClient, HttpHeaders, HttpParams, HttpRequest} from '@angular/common/http';
import {LoginDetails} from '../modules/models/login.details';
import {UserRegisterDetails} from '../modules/models/user.register.details';
import {Util} from '../utils/utils';
import {UserEditDetails} from '../modules/models/user.edit.details';
import {UserChangePassword} from '../modules/models/user.change.password';

@Injectable({
  providedIn: 'root'
})
export class HttpHelperService {
  httpParams: HttpParams;
  httpParamss: HttpParams;
  httpHeader: HttpHeaders;

  constructor(private http: HttpClient) {
  }

  /**
   *  login
   * @param url - api
   * @param data -- login details
   */
  LoginAuthentication(url: string, data: LoginDetails): Observable<any> {
    this.httpParams = new HttpParams().set('mobile_number', data.mobile_number).set('password', data.password);
    const req = new HttpRequest(POST, url, this.httpParams, {
      withCredentials: true
    });
    return this.http.request(req);
  }

  /**
   * get user login information
   * @param url -- api
   * @param token - access token
   */
  getLoggedInUserData(url): Observable<any> {
    const req = new HttpRequest(GET, url, {
      withCredentials: true
    });
    return this.http.request(req);
  }

  /**
   * get user public information
   * @param url -- api
   */
  getUserPublicData(url): Observable<any> {
    const req = new HttpRequest(GET, url, {
      withCredentials: true
    });
    return this.http.request(req);
  }

  /**
   * user log out
   * @param url --api
   */
  LogOut(url: string): Observable<any> {
    const req = new HttpRequest(PUT, url, EMPTY_VALUE, {
      withCredentials: true
    });
    return this.http.request(req);
  }

  /**
   * Listings
   * @param url --Get listings
   * @param params? --httpParams
   */
  getListings(url: string, data?: any): Observable<any> {
    // add request params
    const httpParamss = new HttpParams({fromObject: this.clearnRequest(data)});
    const req = new HttpRequest(GET, url, {params: httpParamss, withCredentials: true});
    // send request
    return this.http.request(req);
  }

  getExistListings(url: string, data?: any): Observable<any> {
    // add request params
    const httpParamss = new HttpParams({fromObject: this.clearnRequest(data)});
    // send request
    return this.http.get<any>(url, {params: httpParamss, withCredentials: true});
  }

  getMemberRecentListings(url: string, data?: any): Observable<any> {
    // add request params
    const httpParamss = new HttpParams({fromObject: this.clearnRequest(data)});
    const req = new HttpRequest(GET, url, {params: httpParamss, withCredentials: true});
    // send request
    return this.http.request(req);
  }
  /**
   * Listing by id
   * @param url --Get listings
   * @param id --id
   */
  listingDetails(url: string, id: any): Observable<any> {
    url = url + id;
    const req = new HttpRequest(GET, url, {params: this.httpParamss, withCredentials: true});
    // send request
    return this.http.request(req);
  }

  /**
   *  Registration New MobileNumber
   * @param url - api
   * @param data -- login details
   */
  RegistrationNewMobileNumber(url: string, mobile_number: string): Observable<any> {
    this.httpParams = new HttpParams().set('mobile_number', mobile_number);
    const req = new HttpRequest(POST, url, this.httpParams, {
      withCredentials: true
    });
    return this.http.request(req);
  }

  /**
   *  Registration Confirm New MobileNumber
   * @param url - api
   * @param data -- login details
   */
  RegistrationConfirmNewMobileNumber(url: string): Observable<any> {
    const req = new HttpRequest(GET, url, this.httpParams, {
      withCredentials: true
    });
    return this.http.request(req);
  }

  /**
   *  Localities - Retrieve all localities.
   * @param url - api
   */
  getLocalities(url: string): Observable<any> {

    return this.http.get<any>(url);

  }

  /**
   * Registration - Update mobile number status and create a member.
   * @param url --Api
   * @param params? --httpParams
   */
  RegisterUserDetails(url: string, data: UserRegisterDetails): Observable<any> {
    // add request params
    const req = new HttpRequest(PUT, url, this.clearnRequest(data), {withCredentials: true});
    // send request
    return this.http.request(req);
  }

  /**
   * Edit user details
   * @param url --Api
   * @param params? --httpParams
   */
  EditUserDetails(url: string, data: UserEditDetails): Observable<any> {
    // add request params
    const req = new HttpRequest(PUT, url, data, {withCredentials: true});
    // send request
    return this.http.request(req);
  }

  /**
   * Change user password
   * @param url --Api
   * @param params? --httpParams
   */
  ChangeUserPassword(url: string, data: UserChangePassword): Observable<any> {
    // add request params
    const req = new HttpRequest(PUT, url, data, {withCredentials: true});
    // send request
    return this.http.request(req);
  }

  /**
   * Members - Generate password reset verification code.
   * @param url --Api
   */
  ResetPassword(url: string): Observable<any> {
    // add request params
    const req = new HttpRequest(POST, url, {withCredentials: true});
    // send request
    return this.http.request(req);
  }

  /**
   *  Reset Password Confirm
   * @param url - api
   */
  resetPasswordConfirm(url: string): Observable<any> {
    const req = new HttpRequest(GET, url, this.httpParams, {
      withCredentials: true
    });
    return this.http.request(req);
  }

  /**
   *  Registration New MobileNumber
   * @param url - api
   * @param data -- login details
   */
  newPasswordReq(url: string, mobileNumber: string, verificationCode: string, password: string): Observable<any> {
    this.httpParams = new HttpParams().set('mobile_number', mobileNumber).set('verification_code', verificationCode).set('new_password', password);
    const req = new HttpRequest(PUT, url, this.httpParams, {
      withCredentials: true
    });
    return this.http.request(req);
  }

  /**
   * Get all categories
   */
  getAllCategories(url: string): Observable<any> {

    return this.http.get<any>(url);

  }

  /**
   * Photos - Get all photo IDs by member.
   */
  getAllPhotosIdByMembers(url: string): Observable<any> {
    const req = new HttpRequest(GET, url, {withCredentials: true});
    // send request
    return this.http.request(req);
  }

  /**
   * Photos - Get all photo IDs by member.
   */
  deletePhotoByMember(url: string): Observable<any> {
    const req = new HttpRequest(DELETE, url, {withCredentials: true});
    // send request
    return this.http.request(req);
  }

  /**
   * Photos - Get meta data.
   */
  getPhotosMetaData(url: string): Observable<any> {
    const req = new HttpRequest(GET, url, {withCredentials: true});
    // send request
    return this.http.request(req);
  }

  getCategoryDetailsByID(url: string): Observable<any> {

    return this.http.get<any>(url);

  }

  /**
   * get fees for new listing
   * @param listing list-an-item attribute
   */
  getFeesForNewListing(url: string, data: any): Observable<any> {
    const req = new HttpRequest(POST, url, data, {
      withCredentials: true,
      reportProgress: true
    });
    // send request
    return this.http.request(req);
  }

  /**
   * get fees for edit listing
   * @param listing list-an-item attribute
   */
  getFeesForEditListing(url: string, data: any): Observable<any> {
    const req = new HttpRequest(POST, url, data, {
      withCredentials: true,
      reportProgress: true
    });
    // send request
    return this.http.request(req);
  }

  /**
   * create new listing
   * @param listing list-an-item attribute
   */
  createNewListing(url: string, data: any): Observable<any> {
    const req = new HttpRequest(POST, url, data, {
      withCredentials: true,
      reportProgress: true
    });
    // send request
    return this.http.request(req);
  }

  /**
   * edit listing
   * @param url edit listing api
   */
  editListing(url: string, data: any): Observable<any> {
    const req = new HttpRequest(PUT, url, data, {
      withCredentials: true,
      reportProgress: true
    });
    // send request
    return this.http.request(req);
  }

  /**
   * Listings - Get listing details by id
   * @param url url contains listing id
   */
  getListingDetails(url: string): Observable<any> {
    const req = new HttpRequest(GET, url, {withCredentials: true});
    // send request
    return this.http.request(req);
  }

  /**
   * Listings - Edit a listing: Get listing details.
   * @param url url contains listing id
   */
  getListingDetailsForEdit(url: string): Observable<any> {
    const req = new HttpRequest(GET, url, {withCredentials: true});
    // send request
    return this.http.request(req);
  }

  /**
   * Listings - Withdraw a listing by ID
   * @param url url contains listing id
   */
  withdrawListingById(url: string): Observable<any> {
    const req = new HttpRequest(DELETE, url, {withCredentials: true});
    // send request
    return this.http.request(req);
  }

  /**
   * Listings - Search listing
   * @param url url contains listing id
   */
  searchListing(url: string, data: any): Observable<any> {
    // add request params
    const httpParamss = new HttpParams({fromObject: this.clearnRequest(data)});
    const req = new HttpRequest(GET, url, {params: httpParamss, withCredentials: true});
    // send request
    return this.http.request(req);
  }

  /**
   * clear request param, not send parame with empty value
   */
  clearnRequest(data: any): any {
    const formObj = {};
    if (Util.isNotNullOrEmpty(data)) {
      Object.keys(data).forEach(key => {
        if (Util.isNotNullOrEmpty(data[key])) {
          formObj[key] = data[key].toString();
        }
      });
    }

    return formObj;
  }

  /**
   *  Members - Add a new listing to watchlist
   */
  addNewWatchList(url: string, data: any): Observable<any> {
    this.httpParams = new HttpParams().set('listing_id', data);
    const req = new HttpRequest(POST, url, this.httpParams, {
      withCredentials: true,
      reportProgress: true
    });
    // send request
    return this.http.request(req);
  }

  /**
   *  Members - Remove a listing from watchlist by watchlist ID.
   */
  removeWatchList(url: string): Observable<any> {
    const req = new HttpRequest(DELETE, url, {withCredentials: true});
    // send request
    return this.http.request(req);
  }

  /**
   *  Members - Get all items of watchlist belong to a member
   */
  getMemberWatchlist(url: string, data: any): Observable<any> {
    const httpParamss = new HttpParams({fromObject: this.clearnRequest(data)});
    const req = new HttpRequest(GET, url, {params: httpParamss, withCredentials: true});
    // send request
    return this.http.request(req);
  }

  getAllReportReasons(url: string): Observable<any> {
    return this.http.get<any>(url);
  }

  createReport(url: string, data: any): Observable<any> {
    this.httpParams = new HttpParams().set('listing_id', data.listing_id).set('report_reason_id', data.report_reason_id);
    const req = new HttpRequest(POST, url, this.httpParams, {
      withCredentials: true
    });
    return this.http.request(req);
  }

  /**
   *  Members - Get member listing summary
   */
  getMemberListingSummary(url: string): Observable<any> {
    const req = new HttpRequest(GET, url, {withCredentials: true});
    // send request
    return this.http.request(req);
  }

  /**
   *  Chat - Get all conversations
   */
  getAllConversations(url: string, params: any): Observable<any> {
    const httpParamss = new HttpParams({fromObject: this.clearnRequest(params)});
    const req = new HttpRequest(GET, url, {params: httpParamss, withCredentials: true});
    // send request
    return this.http.request(req);
  }

  getConversationMessages(url: string, params: any): Observable<any> {
    const httpParamss = new HttpParams({fromObject: this.clearnRequest(params)});
    const req = new HttpRequest(GET, url, {params: httpParamss, withCredentials: true});
    // send request
    return this.http.request(req);
  }

  registrationToken(url: string, registration_token: any): Observable<any> {
    const req = new HttpRequest(POST, url, { registration_token }, {
      withCredentials: true
    });
    return this.http.request(req);
  }

  deleteToken(url: string, registration_token: any): Observable<any> {
    const req = new HttpRequest(DELETE, url, { registration_token }, {
      withCredentials: true
    });
    return this.http.request(req);
  }

  sendMessage(url: string, data: any): Observable<any> {
    const req = new HttpRequest(POST, url, data, {
      withCredentials: true
    });
    return this.http.request(req);
  }

  updateLastSeen(url: string): Observable<any> {
    const req = new HttpRequest(PUT, url, EMPTY_VALUE, {
      withCredentials: true
    });
    return this.http.request(req);
  }
}

