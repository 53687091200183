import {Component, OnInit} from '@angular/core';
import {TitleService} from '../../services/title.service';

@Component({
  selector: 'app-dashboard-search-page',
  templateUrl: './dashboard-search-page.component.html',
  styleUrls: ['./dashboard-search-page.component.css']
})
export class DashboardSearchPageComponent implements OnInit {

  constructor(
    private  titleService: TitleService,
  ) {
  }

  ngOnInit() {
    this.titleService.removeDataStructure();
    this.titleService.createDataStructure();
  }

}
