import {makeStateKey, TransferState} from '@angular/platform-browser';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AllMotorsCategories} from '../../modules/models/all.motors.categories';
import {ProductService} from '../../services/product.service';
import {ListAnItemService} from "../../services/list.an.item.service";
import {TAJR_CATEGORIES_DETAILS, TAJR_LIST_AN_ITEM, TAJR_DEFAULT_CATEGORY} from '../../shared/global';
import {first, tap} from "rxjs/operators";
import {CategoryDetails} from "../../modules/models/category.details";
import {SessionStorageService} from "../storages/session.storage.service";

@Injectable()
export class CategoriesDetailsResolve implements Resolve<CategoryDetails> {
  public categoryIdSlitStr = '';
  public defaultCategoryId= TAJR_DEFAULT_CATEGORY;
  

  constructor(
    private productService: ProductService,
    private transferState: TransferState,
    private sessionStorageService: SessionStorageService,
    private listAnItemService: ListAnItemService,
  ) {
    const sessionStorage = this.sessionStorageService.get(TAJR_LIST_AN_ITEM) && JSON.parse(this.sessionStorageService.get(TAJR_LIST_AN_ITEM));
    if (sessionStorage) {
      this.defaultCategoryId = this.listAnItemService.tajrListAnItemCategoryID;
    }
    // get categories details by default ID request
    this.categoryIdSlitStr = this.defaultCategoryId.split('-')[2];
  }

  /**
   *
   * @param route acctivated route snapshot
   * @param state router state
   */
  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<CategoryDetails> {

    const CATEGORIES = makeStateKey<CategoryDetails>(TAJR_CATEGORIES_DETAILS);
    if (this.transferState.hasKey(CATEGORIES)) {
      const course = this.transferState.get(CATEGORIES, null);
      return of(course);
    } else {
      return this.productService.getCategoryDetailsByID(this.categoryIdSlitStr)
        .pipe(
          first(),
          tap(data => {
            this.transferState.set(CATEGORIES, data);
          })
        );
    }
  }
}
