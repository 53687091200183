import {Injectable} from '@angular/core';

import {
  FIRST_ITEM,
  MAX_CATEGORY_LEVEL
} from '../shared/global';
import {Util} from '../utils/utils';
import {CategoryModel} from '../modules/models/category.model'
import {Categories} from '../modules/models/categories'

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  public initCategoriesFromData(data = [], maxLevel = 3, idsString = '') {
    let categories = [];
    const ids = this.getActiveIDList(idsString)
    for (let i = 0; i < maxLevel; i++) {
      categories.push(new CategoryModel())
    }
    //Raw data = List category level 0
    categories[0].list = [ ...data ];

    //Get category by level from raw data
    categories = categories.map((category, i) => {
      const activeCategory = this.getItemFromData(category.list, ids[i]);
      if (activeCategory?.subcategories && i < categories.length) categories[i+1].list = [ ...activeCategory?.subcategories ];
      return {
        ...category,
        activeCategoryID: ids[i] || category.list[FIRST_ITEM]?.id,
        activeCategory
      }
    })
    return new Categories(
      categories,
      maxLevel
    )
  }

  public getCategoryBylevel(data, level, ids) {
    let li = 0;
    let catItem, catData = data;
    while (li < level) {
      catItem = this.getItemFromData(catData, ids[li]);
      catData = catItem?.subcategories || [];
      li++;
    }
    return catItem;
  }

  public getItemFromData(data = [], id = null) {
    if (!!id) return this.findObjectById(data, id);
    return data[FIRST_ITEM];
  }

  public checkIfIDMatch(currentID = '', id = '') {
    return this.getCatIDLv2(currentID) == this.getCatIDLv2(id);
  }

  public getCatIDLv2(id = '') {
    const ids = id.split('-');
    return ids.length >= 2 ? `${ids[0]}-${ids[1]}` : id;
  }

  public findObjectById(arrayData, id) {
    return arrayData.find(ele => `${ele.id}` === `${id}`);
  }

  public getActiveIDList(idsString) {
    if (!idsString) return [];
    const ids = idsString.split("-");
    const idList = [];
    for (let i = 0; i < MAX_CATEGORY_LEVEL; i++) {
      let _id = ids[0];
      for (let j = 1; j <= i; j++) {
        _id += `-${ids[j]}`
      }
      idList.push(_id);
    }
    return idList;
  }
}
