<!-- blue line  -->
<div class="bg-primary pt-2"></div>
<!-- Progress Menu -->
<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-lg-10 col-xl-8 offset-lg-1 offset-xl-2 ">
      <div class="row mt-3">
        <div class="col text-right p-0">
          <a href="javascript:void(0);" class="text-decoration-none text-secondary"
             [ngClass]="screenWidth >768?'':'small'" dir="rtl"> 4.
            تأكيد
            معلومات الإعلان</a>
        </div>
        <div class="col text-right p-0">
          <a href="javascript:void(0);" class="text-decoration-none text-secondary font-weight-bold"
             [ngClass]="screenWidth >768?'':'small'" dir="rtl"> 3.
            معلومات
            الإعلان</a>
        </div>
        <div class="col text-right p-0">
          <a class="text-decoration-none text-secondary"
             (click)="navigateToListAnItemPage('/list-an-item-photos')"
             [ngClass]="screenWidth >768?'':'small'" dir="rtl"> 2.
            الصور</a>
        </div>
        <div class="col text-right">
          <a class="text-decoration-none text-secondary"
             (click)="navigateToListAnItemPage('/list-an-item')"
             [ngClass]="screenWidth >768?'':'small'"
             dir="rtl">1.
            معلومات السيارة</a>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col">
          <div class="progress h-100">
            <div class="progress-bar bg-light w-25 pt-1" role="progressbar" aria-valuenow="0"
                 aria-valuemin="0" aria-valuemax="100">
            </div>
            <div class="progress-bar bg-warning w-75 pt-1" role="progressbar" aria-valuenow="0"
                 aria-valuemin="0" aria-valuemax="100">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--content-->
<div class="container-fluid mt-2">
  <div class="row pt-1">
    <div class="col-12 col-lg-10 col-xl-8 offset-lg-1 offset-xl-2 text-right">
      <p class="text-secondary mb-2" dir="rtl"><small> جميع الخانات المتبوعه بنجمه هي خانات
        مطلوبة لأنزال الإعلان </small>(<span class="text-danger">*</span>)
      </p>
      <p class="text-danger small mb-0" dir="rtl">
        {{errorMessage}}
      </p>
      <!-- form -->
      <form class="bg-light border rounded p-3"
            [formGroup]="activeCategoriesForm"
            (ngSubmit)="onSubmit()">
        <h4 class="text-warning text-right">
          <!--Listing Details -->معلومات الإعلان</h4>
        <div class="dropdown-divider"></div>
        <!-- Listing Type -->
        <div class="row">
          <div class="col-12 text-right">
            <label class="font-weight-bold text-secondary mb-0">
              {{showAttributesLable('listingType')}}
              <!--Listing Type--></label>
          </div>
          <div class="col-12 text-right">
            <!-- text-List a classified -->
            <p class="font-weight-bold text-secondary mb-2" for="exampleRadios1">
              <!--classified-->عادي
            </p>
            <ul class="text-secondary pr-4 mb-0" dir="rtl">
              <li>
                <small>
                  <!--Buyers negotiate directly-->
                  المشتري يتفاوض مباشره مع البائع
                </small>
              </li>
              <li>
                <small>
                  <!--Relist free until sold-->أعلن مجانًا حتى يتم البيع
                </small>
              </li>
              <li>
                <small>
                  <!--20 photos-->٢٠ صورة
                </small>
              </li>
            </ul>
          </div>
        </div>
        <div class="dropdown-divider"></div>
        <!-- title -->
        <div class="row">
          <div class="col-12 text-right">
            <label class="font-weight-bold text-secondary">
              {{showAttributesLable('title')}}
            </label>
          </div>
          <div class="col-12">
            <input class="form-control border-0 text-right bg-light py-0" value="{{tajrListAnItem?.title}}" disabled>
          </div>
        </div>
        <div class="dropdown-divider"></div>
        <!-- subtitle -->
        <div class="row">
          <div class="col-12 text-right">
            <label for="subtitle" class="font-weight-bold text-secondary">
              {{showAttributesLable('subtitle')}}
            </label>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col text-right">
                <input class="form-control text-right py-0"
                       id="subtitle"
                       [maxLength]="validatorsMaxLength('subtitle')"
                       [(ngModel)]="listAnItemModules.subtitle"
                       formControlName="subtitle"
                       autocomplete="on">
              </div>
            </div>
            <!-- errorSubtitle-->
            <div class="row ">
              <div class="col text-right mt-1">
                <small class="text-danger errorSubtitle"></small>
              </div>
            </div>
          </div>
        </div>
        <div class="dropdown-divider"></div>
        <!-- Asking price -->
        <div class="row">
          <div class="col-12 text-right">
            <span class="text-danger">*</span>
            <label for="askingPrice"
                   class="font-weight-bold text-secondary mb-0">
              {{showAttributesLable('askingPrice')}}
            </label>
          </div>
          <div class="col-12 text-right">
            <input class="form-control text-right py-0" id="askingPrice"
                   id="askingPrice"
                   type="number"
                   placeholder="0 ر.س"
                   [min]="validatorsLower('askingPrice')"
                   [max]="validatorsUpper('askingPrice')"
                   [(ngModel)]="listAnItemModules.askingPrice"
                   formControlName="askingPrice"
                   autocomplete="on">
          </div>
        </div>
        <!-- errorAskingPrice-->
        <div class="row ">
          <div class="col-12 text-right">
            <small class="text-danger pt-3"
                   *ngIf="(activeCategoriesForm.controls.askingPrice.dirty || submitted)  && !activeCategoriesForm.controls.askingPrice.valid">
            <span *ngIf="activeCategoriesForm.controls.askingPrice.errors?.required">
              {{getErrorMessage('askingPrice', 'required')}}
            </span>
              <span *ngIf="activeCategoriesForm.controls.askingPrice.errors.min">
              {{getErrorMessage('askingPrice', 'min')}}
              </span>
              <span *ngIf="activeCategoriesForm.controls.askingPrice.errors.max">
              {{getErrorMessage('askingPrice', 'max')}}
              </span>
            </small>
          </div>
        </div>
        <!-- orNearOffer-->
        <div class="row ">
          <div class="col-12 text-right">
            <label for="orNearOffer" class="text-secondary mb-0 mt-1">
              {{showAttributesLable('orNearOffer')}}
            </label> &nbsp;
            <input id="orNearOffer" type="checkbox"
                   [(ngModel)]="listAnItemModules.orNearOffer"
                   formControlName="orNearOffer"
                   autocomplete="on">
          </div>
        </div>

        <div *ngIf="isIncludeCategory('onRoadCostsIncluded')">
          <div class="dropdown-divider"></div>
          <!-- On road costs -->
          <div class="row">
            <div class="col-12 text-right">
              <label for="onRoadCostsIncluded" class="font-weight-bold text-secondary mb-0">
                {{showAttributesLable('onRoadCostsIncluded')}}
              </label><br>
              <small class="text-secondary">
                <!--(e.g. current WoF, Registration)-->( مثلاً ... شامله الفحص الدوري وأستماره ساريه
                )</small>
            </div>
            <div class="col-12 text-right align-self-center">
              <label for="onRoadCostsIncluded" class="text-secondary mb-0">
                <!--Yes-->نعم
              </label> &nbsp;<input id="onRoadCostsIncluded" type="checkbox"
                                    [ngModel]="listAnItemModules.onRoadCostsIncluded === 'true'"
                                    formControlName="onRoadCostsIncluded"
                                    autocomplete="on">
            </div>
          </div>
        </div>
        <div class="dropdown-divider"></div>
        <!-- Listing Duration -->
        <div class="row">
          <div class="col-12 text-right">
            <!-- label Listing Duration-->
            <span class="text-danger">*</span>
            <label class="font-weight-bold text-secondary ">
              {{showAttributesLable('endDate')}}
            </label>
          </div>
          <div class="col-12 text-right align-self-center">
            <!-- duration -->
            <div class="row">
              <div class="col">
                <select class="form-control text-secondary py-0 duration"
                        id="duration" dir="rtl"
                        [(ngModel)]="listAnItemModules.duration"
                        formControlName="duration"
                        autocomplete="on"
                        *ngIf="getDurationsList() && getDurationsList().length > 0">
                  <option *ngFor="let item of getDurationsList()"
                          value="{{item.number_of_days}}">{{item.display_name.arabic}}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="dropdown-divider"></div>
        <!-- Display phone numbers -->
        <div class="row">
          <div class="col-12 text-right">
            <label for="showMobileNumber"
                   class="font-weight-bold text-secondary">
              {{showAttributesLable('showMobileNumber')}}
            </label>
          </div>
          <div class="col-12 text-right">
            <input type="tel" class="form-control text-secondary py-0" dir="rtl"
                   placeholder="أدخل رقم جوالك مثلا 0500010008"
                   id="showMobileNumber"
                   [(ngModel)]="listAnItemModules.showMobileNumber"
                   [maxLength]="validatorsMaxLength('showMobileNumber')"
                   formControlName="showMobileNumber"
                   autocomplete="on">
          </div>
        </div>
        <div class="dropdown-divider"></div>
        <!-- Best contact time -->
        <div class="row">
          <div class="col-12 text-right align-self-center">
            <label for="bestContactTime"
                   class="font-weight-bold text-secondary mb-0">
              {{showAttributesLable('bestContactTime')}}
            </label>
          </div>
          <div class="col-12 text-right align-self-center">
            <select id="bestContactTime" class="form-control text-secondary py-0"
                    dir="rtl"
                    [(ngModel)]="listAnItemModules.bestContactTime"
                    formControlName="bestContactTime"
                    autocomplete="on">
              <option value="">أختر</option>
              <option *ngFor="let item of showSelectTabOptionItems('bestContactTime')"
                      value="{{item.value}}">{{item.display.arabic}}
              </option>
            </select>
          </div>
        </div>

        <!--  Confirm button -->
        <div class="row mt-3">
          <div class="col text-right" dir="rtl">
            <button type="submit" class="btn btn-primary px-5" *ngIf="!loading">{{continueMess}}</button>
            <button class="btn alert-primary text-white px-3 d-flex align-items-center "
                    *ngIf="loading" disabled>
              <span class="spinner-border spinner-border-sm mx-2"></span>
              {{loadingMess}}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- blue line  -->
<div class="bg-primary mt-5 pt-2"></div>
