import {Component, DoCheck, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {HttpHelperService} from 'src/app/services/http-helper.service';
import {AccountService} from '../../services/account.service';
import {LanguageService} from '../../services/language.service';
import {TimeService} from '../../services/time.service';
import {Timer} from '../../shared/global';
import {CommonHelperService} from '../../shared/common.helper.service';

@Component({
  selector: 'app-register-confirm',
  templateUrl: './register-confirm.component.html',
  styleUrls: ['./register-confirm.component.css']
})
export class RegisterConfirmComponent implements OnInit, DoCheck {
  // message show when valid modal input value
  requireVerifyCodeMessage = this.languageService.findMessageByLanguage('requireVerifyCode');
  regexVerifyCodeMessage = this.languageService.findMessageByLanguage('regexVerifyCode');
  someThingWentWrongMessage = this.languageService.findMessageByLanguage('someThingWentWrong');
  loadingMess = this.languageService.findMessageByLanguage('loading');
  continueMess = this.languageService.findMessageByLanguage('continue');
  waitToResendOtpMess = this.languageService.findMessageByLanguage('waitToResendOtpMess');

  // verify code input regex
  verifyCodeRegexPattern = /^\d*$/;
  // form builder to manage login modal form
  registerConfirmForm: FormGroup;
  // attribute to show loading button
  loading = false;
  // attribute to show resend code status button
  resendCodeStatus = false;
  // error message response from server
  errorMessage: string;
  errorResendCodeMessage: string;
  // mobileNumber received from url
  mobileNumber: string;
  mobileNumberMess: string;

  constructor(
    // service use to get value from router
    private activatedroute: ActivatedRoute,
    // manage form input
    private formBuilder: FormBuilder,
    // service send request to login
    private accountService: AccountService,
    // contain api
    private httpHelper: HttpHelperService,
    // get language message from local
    private languageService: LanguageService,
    // go to redirect to register-confirm page
    private router: Router,
    public timeService: TimeService,
    public commonHelperService: CommonHelperService,
  ) {
    // Form validation
    this.registerConfirmForm = this.formBuilder.group({
      // input mobile_number: default value empty, require has value and has 10 digit number.
      verification_code: ['', Validators.compose([
        Validators.required,
        Validators.pattern(this.verifyCodeRegexPattern)])]
    });
  }

  ngOnInit() {
    // get value from url
    this.activatedroute.queryParamMap.subscribe((queryParams) => {
      if (queryParams.has('mobileNumber')) {
        this.mobileNumber = queryParams.get('mobileNumber');
        // set default value
        this.mobileNumberMess = '';
        for (let i = 0; i < this.mobileNumber.length; i++) {
          if (i > 3 && i < this.mobileNumber.length - 2) {
            this.mobileNumberMess = this.mobileNumberMess.concat('*');
          } else {
            this.mobileNumberMess = this.mobileNumberMess.concat(this.mobileNumber.charAt(i));
          }
        }
      } else {
        this.router.navigate(['/register']);
      }
    });

    this.timeService.startTimer(Timer.MINUTE_SECOND);
  }

  ngDoCheck(): void {
    // if register input change value will reset error message from server
    if (this.registerConfirmForm.dirty) {
      // empty error
      this.errorMessage = '';
      this.errorResendCodeMessage = '';
    }
  }

  /**
   * Registration - Insert a new mobile number.
   */
  registerConfirm() {
    // reset previous error message
    this.errorMessage = '';
    this.errorResendCodeMessage = '';
    // If registerConfirmForm input is valid
    if (this.registerConfirmForm.valid) {
      // change loading status to show loading when sending request
      this.loading = true;
      // send request
      this.accountService.registrationConfirm(this.mobileNumber, this.registerConfirmForm.value.verification_code).subscribe(verifyCodeValidation => {
        // if user login success and response has user information in body
        if (verifyCodeValidation.status === 200) {
          // format https:// ***/register-confirm/mobile_number/verification_code : Use for development
          this.router.navigate(['/register-details'],
            {queryParams: {mobileNumber: this.mobileNumber, verificationCode: this.registerConfirmForm.value.verification_code}});
          // close loading status on button
          this.loading = false;
        }
      }, err => {
        // User login fail, close loading status on button
        this.loading = false;
        // get error message response from server
        if (err && err.error) {
          // reset login modal form input
          this.registerConfirmForm.reset();
          // show error
          this.errorMessage = this.languageService.messageFromServer(err.error.message);
        } else {
          // service error message
          this.errorMessage = this.someThingWentWrongMessage;
        }
      });
    }
  }

  /**
   * Resend register Code.
   */
  resendRegisterCode() {
    // reset previous error message
    this.errorMessage = '';
    this.errorResendCodeMessage = '';
    // change loading status to show loading when sending request
    this.resendCodeStatus = true;
    // send request
    this.accountService.registration(this.mobileNumber).subscribe(res => {
      // if user login success and response has user information in body
      if (res.status === 200 && res.body) {
        this.timeService.pauseTimer();
        this.timeService.startTimer(Timer.MINUTE_SECOND);
        // redirect to register-confirm page with mobile_number paste on url
        if (res.body.verification_code != null) {
          // format https:// ***/register-confirm/mobile_number/verification_code : Use for development
          this.router.navigate(['/register-confirm'],
            {queryParams: {mobileNumber: res.body.mobile_number, verificationCode: res.body.verification_code}});

        } else {
          // format https:// ***/register-confirm/mobile_number : Use for product
          this.router.navigate(['/register-confirm'], {queryParams: {mobileNumber: res.body.mobile_number}});
        }
        // close loading status on button
        this.resendCodeStatus = false;
      }
    }, err => {
      // User login fail, close loading status on button
      this.resendCodeStatus = false;
      // get error message response from server
      if (err && err.error) {
        // reset login modal form input
        this.registerConfirmForm.reset();
        // show error
        this.errorResendCodeMessage = this.languageService.messageFromServer(err.error.message);
      } else {
        // service error message
        this.errorResendCodeMessage = this.someThingWentWrongMessage;
      }
    });
  }

}
