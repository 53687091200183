<div class="tab-content message-wrapper">
	<div *ngIf="conversation" class="tab-pane message-body active" id="inbox-message-1">
		<div class="message-top">
			<div class="row conversation-info">
				<div class="col-1">
					<img alt="" class="img-circle medium-image" src="https://bootdey.com/img/Content/avatar/avatar1.png">
				</div>
				<div class="col-10 vcentered info-combo">
					<h3 class="no-margin-bottom name">{{ conversation.conversation_name }} محادثه مع </h3>
				</div>
			</div>
		</div>
		<div class="message-chat">
			<div #scollContainer class="chat-body">
				<div class="message" [ngClass]="memberID == message.receiver_id ? 'info' : 'my-message'" #messagesEle *ngFor="let message of messages">
					<div class="message-body">
						<div *ngIf="checkSeenCondition(message)" class="seen-check">
							<i class="fa fa-check-circle"></i>
						</div>
						<div class="message-info">
							<h4>{{ message.sender_username }}</h4>
							<h5> <i class="fa fa-clock-o"></i>{{ getConversationShortDate(message) }}</h5>
						</div>
						<hr>
						<div class="message-text">
							{{ message.text }}
						</div>
					</div>
					<br>
				</div>
			</div>
			<div class="chat-footer">
				<div class="chat-footer-wrapper">
					<textarea class="send-message-text" placeholder="اكتب رسالة " [(ngModel)]="text" (focus)="updateLastSeen()" (keyup.enter)="sendMessage()" dir="rtl"></textarea>
					<button type="button" class="send-message-button btn-info" (click)="sendMessage()"> <i class="fa fa-send"></i> </button>
				</div>
			</div>
		</div>
	</div>
</div>