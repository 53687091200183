import {Component, ElementRef, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {DatePipe} from '@angular/common';
import {Subscription} from 'rxjs';

import {ProductService} from '../../services/product.service';
import {HttpHelperService} from '../../services/http-helper.service';
import {TimeService} from '../../services/time.service';
import {LanguageService} from '../../services/language.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CLOSING_SOON, HP_CLOSING_SOON_RS, HP_LATEST_RS, LATEST, TAJR_ALL_CATEGORIES_RESOURCE} from '../../shared/global';
import {SearchModel} from '../../modules/models/search.model';
import {SearchListingDetails} from '../../modules/models/search.listing.details';
import {Hits} from '../../modules/models/hits';
import {Util} from '../../utils/utils';
import {TitleService} from '../../services/title.service';
import {NavigateService} from '../../services/navigate.service';
import SwiperCore, {EffectFade, Virtual} from 'swiper/core';
import {Navigation, Pagination, Scrollbar, A11y} from 'swiper/core';
import {ListingService} from '../../services/listing.service';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Virtual, EffectFade]);

@Component({
  selector: 'app-default-listing-gallery',
  templateUrl: './default-listing-gallery.component.html',
  styleUrls: ['./default-listing-gallery.component.css'],
  providers: [DatePipe]
})
export class DefaultListingGalleryComponent implements OnInit, OnDestroy {
  // input value fron father component
  @Input('filterBy') filterBy: string;
  // model OOP
  private getListings: SearchModel;
  // Observables use to receive response
  private subscription: Subscription;
  public listingDetailsRequest: Subscription;
  // width of screen size on browser
  public screenWidth: number;
  public searchListing: SearchListingDetails;
  public tajrAllCategories;
  public slidesPerView = 5;
  public spaceBetween = 8;
  public allowTouchMove = false;

  constructor(
    private elementRef: ElementRef,
    // service convert date format
    private datePipe: DatePipe,
    // service send request
    private productService: ProductService,
    // contain api
    private httpHelper: HttpHelperService,
    // parse time service
    private timeService: TimeService,
    // language and sorts arabic message
    private languageService: LanguageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public titleService: TitleService,
    public navigateService: NavigateService,
    public listingService: ListingService,
  ) { }

  ngOnInit() {
    // get width of device
    this.screenWidth = window.innerWidth;
    // receive value from data transfer Api
    this.tajrAllCategories = this.activatedRoute.snapshot.data[TAJR_ALL_CATEGORIES_RESOURCE];
    this.titleService.setCategories(this.tajrAllCategories);

    //  Get listings by search item Api
    switch (this.filterBy) {
      case CLOSING_SOON:
        this.searchListing = this.activatedRoute.snapshot.data[HP_CLOSING_SOON_RS];
        break;
      case LATEST:
        this.searchListing = this.activatedRoute.snapshot.data[HP_LATEST_RS];
        break;
    }
    /* Update SEO tab */
    this.searchListing.hits.forEach(hit => {
      this.titleService.setHomePageDataStructure(hit);
    });

    this.responsiveCarousel();
  }

  ngOnDestroy(): void {
    // unsubscribe if observable with get latest listing request available
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  /**
   * use HostListener to  updated on resize:
   * @param event tab when selected
   */
  @HostListener('window:resize', ['$event'])
  getScreenSize(event) {
    this.screenWidth = window.innerWidth;
    this.responsiveCarousel();
  }

  /**
   * Set item per slide follow screen size
   * On desktop screen will disable touch
   */
  responsiveCarousel() {
    if (this.screenWidth <= 575) {
      this.slidesPerView = 2.1;
      this.spaceBetween = 0;
    } else if (this.screenWidth <= 768) {
      this.slidesPerView = 3.2;
      this.spaceBetween = 8;
    } else if (this.screenWidth <= 991) {
      this.slidesPerView = 4.2;
      this.spaceBetween = 8;
    } else {
      this.slidesPerView = 5;
      this.spaceBetween = 8;
    }

    this.screenWidth <= 991 ? this.allowTouchMove = true : this.allowTouchMove = false;
  }

  /**
   * endDate text show on list
   */
  endDateText(endDate: string): string {
    let endDateText = '';
    let endTime;
    // parse endDate
    endTime = Date.parse(endDate);
    //  Date.now() returns the number of milliseconds elapsed since January 1, 1970 00:00:00 UTC
    const current = Date.now();
    // the time zone difference, in minutes, from current locale (host system settings) to UTC
    const difference = this.timeService.convertMS(endTime - current);

    if (difference.day < 1) {
      // hours
      if (difference.hour > 0) {
        if (difference.hour === 1) {
          endDateText = this.languageService.wrapDir('rtl', 'الاعلان ينتهي بعد ساعه');
        } else {

          if (difference.hour === 2) {
            endDateText = this.languageService.wrapDir('rtl', 'الاعلان ينتهي بعد ساعتين');
          }
          if (difference.hour >= 3 && difference.hour < 11) {
            endDateText = this.languageService.wrapDir('rtl', 'الإعلان ينتهي بعد ') + this.languageService.wrapDir('rtl', difference.hour) + this.languageService.wrapDir('rtl', ' ساعات');
          }

          if (difference.hour > 10) {
            endDateText = this.languageService.wrapDir('rtl', 'الإعلان ينتهي بعد ') + this.languageService.wrapDir('rtl', difference.hour) + this.languageService.wrapDir('rtl', ' ساعه');
          }
        }
      } else {
        // minutes
        if (difference.minute > 0) {

          if (difference.minute === 1) {
            endDateText = this.languageService.wrapDir('rtl', 'الاعلان ينتهي بعد دقيقة');
          } else {

            if (difference.minute === 2) {
              endDateText = this.languageService.wrapDir('rtl', 'الاعلان ينتهي بعد دقيقتين');
            }
            if (difference.minute >= 3 && difference.minute < 11) {
              endDateText = this.languageService.wrapDir('rtl', 'الإعلان ينتهي بعد ') + this.languageService.wrapDir('rtl', difference.minute) + this.languageService.wrapDir('rtl', ' دقائق');
            }

            if (difference.minute > 10) {
              endDateText = this.languageService.wrapDir('rtl', 'الإعلان ينتهي بعد ') + this.languageService.wrapDir('rtl', difference.minute) + this.languageService.wrapDir('rtl', ' دقيقة');
            }
          }
        } else {
          endDateText = this.languageService.wrapDir('rtl', 'الإعلان انتهى');
        }
      }
    } else if (difference.day === 1) {
      endDateText = this.languageService.wrapDir('rtl', 'الاعلان ينتهي بعد يوم');
    } else {

      if (difference.day === 2) {
        endDateText = this.languageService.wrapDir('rtl', 'الإعلان ينتهي بعد يومين');
      }
      if (difference.day >= 3 && difference.day < 11) {
        endDateText = this.languageService.wrapDir('rtl', 'الإعلان ينتهي بعد ') + this.languageService.wrapDir('rtl', difference.day) + this.languageService.wrapDir('rtl', ' أيام');
      }

      if (difference.day > 10) {
        endDateText = this.languageService.wrapDir('rtl', 'الإعلان ينتهي بعد ') + this.languageService.wrapDir('rtl', difference.day) + this.languageService.wrapDir('rtl', ' يوم');
      }
    }
    return endDateText;
  }

  /**
   * Price message show on list
   */
  composedPrice(listingDetails: Hits): string {
    let orNearOffer: string;
    let orNearOfferText: string;
    let askingPrice: string;
    let askingPriceText: string;
    let composedPrice = '';
    if (Util.isNullOrEmpty(listingDetails)) {
      return composedPrice;
    }
    if (Util.isNotNullOrEmpty(listingDetails.orNearOffer)) {
      orNearOffer = listingDetails.orNearOffer;
      orNearOfferText = this.languageService.findMessageByLanguage('orNearOffer');
    }

    askingPriceText = this.languageService.findMessageByLanguage('askingPrice');

    if (Util.isNotNullOrEmpty(listingDetails.askingPrice)) {
      askingPrice = listingDetails.askingPrice;
    }

    if (orNearOffer === 'true') {
      composedPrice = this.languageService.wrapDir('rtl', askingPrice) + this.languageService.wrapDir('rtl', ' ر.س ') + this.languageService.wrapDir('rtl', orNearOfferText);
    } else {
      composedPrice = this.languageService.wrapDir('rtl', askingPriceText) + this.languageService.wrapDir('rtl', ' ') + this.languageService.wrapDir('rtl', askingPrice) + this.languageService.wrapDir('rtl', ' ر.س ');
    }
    return composedPrice;
  }
}
