<!-- blue line  -->
<div class="bg-primary pt-2"></div>
<!--content-->
<div class="container-fluid">

  <div class="row">
    <div class="col pt-3">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb bg-white p-0 pt-1 category" dir="rtl">
          <a routerLink="/my-tajr" class="text-secondary">
            <small>
              <!--My Tajer -->
              {{sourceTabLabel}}
            </small>
          </a>&gt;
          <a class="text-secondary">
            <small>&nbsp;
              {{currentTabLabel}}
            </small>
          </a>
        </ol>
      </nav>
    </div>
  </div>

  <div class="row">
    <div class="col pt-1">
      <h4 class="text-warning text-right">
        <!--Buying-->
        شراء
      </h4>
    </div>
  </div>

  <!--tabs-->
  <div class="row">
    <div class="col">

      <tabset #staticTabs dir="rtl" class="navTabs">
        <!-- buying tab-->
        <tab (selectTab)="onSelect(0)">
          <ng-template tabHeading>
            <b class="text-secondary font-weight-normal"
               [ngClass]="screenWidth > 575? '':'small'">
              لاعلانات المراقبة المنتهيه
            </b>
          </ng-template>
          <!--buying tab content-->
          <!--buying item when empty-->
          <div class="row">
            <div class="col text-center">
              <br>
              <br>
              <br>

              <img class="img-fluid pb-3 pt-4"
                   [width]="screenWidth > 575? '128':'64'"
                   src="../../../assets/images/icon-images/tajr-icon-000054.png">
              <h4 class="font-weight-normal pb-2">No closed listings</h4>
              <p class="text-secondary">Expired listings and items you lost will appear here. They're archived
                after 45 days.</p>

              <br>
              <br>
              <br>

            </div>
          </div>
        </tab>
      </tabset>

    </div>
  </div>

</div>
<!-- blue line  -->
<div class="bg-primary mt-3 pt-2"></div>
