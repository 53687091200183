<!-- blue line  -->
<div class="bg-primary pt-2"></div>

<div class="container-fluid">

  <div class="row">
    <div class="col pt-3">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb bg-white p-0 pt-1 category" dir="rtl">
          <a routerLink="/my-tajr" class="text-secondary">
            <small>
              <!--My Tajer -->
              {{sourceTabLabel}}
            </small>
          </a>&gt;
          <a class="text-secondary">
            <small>&nbsp;
              Search
            </small>
          </a>
        </ol>
      </nav>
    </div>
  </div>

  <div class="row">
    <div class="col pt-1">
      <h4 class="text-warning text-right">
        <!--Favourites-->
        قائمة المفضلة
      </h4>
    </div>
  </div>

  <!--tabs-->
  <div class="row">
    <div class="col">

      <tabset #staticTabs dir="rtl" class="navTabs">
        <!-- Search tab-->
        <tab>
          <ng-template tabHeading>
            <p class="font-weight-normal d-flex align-items-center mb-0"
               [ngClass]="screenWidth > 575? 'h5':'h6'">
              Search
              <span class="material-icons">search</span>
            </p>
          </ng-template>
          <!--Search tab content-->
          <!--search item when empty-->
          <div class="row ">
            <div class="col text-center">
              <br>
              <br>
              <br>

              <img class="img-fluid pb-3 pt-4"
                   [width]="screenWidth > 575? '128':'64'"
                   src="../../../assets/images/icon-images/tajr-icon-000023.png">
              <h4 class="font-weight-normal pb-2">No saved searches</h4>
              <p class="text-secondary mb-0">Save a search to be notified when there are new listings that match
                your criteria.
              </p>

              <br>
              <br>
              <br>

            </div>
          </div>
          <!--search item when has item-->
          <div class="row d-none">
            <div class="col">
              <!--sort button-->
              <div class="d-flex justify-content-between align-items-center py-1 mb-0" dir="rtl">
                <p class="text-secondary mb-0">2 Favourite searches</p>
                <select class="form-control form-control-lg w-50 py-0" dir="rtl">
                  <option selected="">All</option>
                  <option value="1">Market place</option>
                  <option value="2">Jobs</option>
                  <option value="3">Motors</option>
                  <option value="4">Property</option>
                </select>
              </div>
            </div>
          </div>
          <!--item-->
          <div class="row d-none">
            <!-- item 1 -->
            <div class="col-12 col-md-6 col-xl-4 mt-3">
              <div class=" bg-white shadow text-right p-3">
                <a href="" class="nav-link font-weight-bold p-0 pb-3">To Rent</a>
                <a href="">To Rent</a>
                <p class="text-secondary mb-0 pt-3">Rent per week: up to $450 per week</p>
                <p class="text-secondary mb-0">Bedrooms: up to 2</p>
                <p class="text-secondary mb-0">Suburbs: Takapuna and surrounding suburbs</p>
                <form>
                  <div class="form-group row pb-3">
                    <div class="col">
                      <select class="form-control form-control-lg py-0" dir="rtl">
                        <option selected="">Don't email me</option>
                        <option value="1">Email me everyday</option>
                        <option value="2">Email me every 3 day</option>
                        <option value="3">Email me once a week</option>
                      </select>
                    </div>
                    <div class="col-auto d-flex align-items-center pr-0">
                      <span class="material-icons">mail_outline</span>
                    </div>
                  </div>
                </form>
                <div class="d-flex justify-content-between pb-2">
                  <a href="" class="btn bg-light text-secondary shadow border ml-3 w-50">
                    Remove
                  </a>
                  <a href="" class="btn btn-primary text-white shadow border mr-3 w-50">
                    View
                  </a>
                </div>
              </div>
            </div>
            <!-- item 2 -->
            <div class="col-12 col-md-6 col-xl-4 mt-3">
              <div class=" bg-white shadow text-right p-3">
                <a href="" class="nav-link font-weight-bold p-0 pb-3">"mac mini"</a>
                <a href="">Desktops</a>
                <p class="text-secondary position-absolute mt-3 mb-3"></p>
                <div class="pt-5 mt-5">
                  <form>
                    <div class="form-group row pb-3">
                      <div class="col">
                        <select class="form-control form-control-lg py-0" dir="rtl">
                          <option selected="">Don't email me</option>
                          <option value="1">Email me everyday</option>
                          <option value="2">Email me every 3 day</option>
                          <option value="3">Email me once a week</option>
                        </select>
                      </div>
                      <div class="col-auto d-flex align-items-center pr-0">
                        <span class="material-icons">mail_outline</span>
                      </div>
                    </div>
                  </form>
                  <div class="d-flex justify-content-between pb-2">
                    <a href="" class="btn bg-light text-secondary shadow border ml-3 w-50">
                      Remove
                    </a>
                    <a href="" class="btn btn-primary text-white shadow border mr-3 w-50">
                      View
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <!-- item 3 -->
            <div class="col-12 col-md-6 col-xl-4 mt-3">
              <div class=" bg-white shadow text-right p-3">
                <a href="" class="nav-link font-weight-bold p-0 pb-3">"iphone 5"</a>
                <a href="">All categories</a>
                <p class="text-secondary position-absolute mt-3 mb-3"></p>
                <div class="pt-5 mt-5">
                  <form>
                    <div class="form-group row pb-3">
                      <div class="col">
                        <select class="form-control form-control-lg py-0" dir="rtl">
                          <option selected="">Don't email me</option>
                          <option value="1">Email me everyday</option>
                          <option value="2">Email me every 3 day</option>
                          <option value="3">Email me once a week</option>
                        </select>
                      </div>
                      <div class="col-auto d-flex align-items-center pr-0">
                        <span class="material-icons">mail_outline</span>
                      </div>
                    </div>
                  </form>
                  <div class="d-flex justify-content-between pb-2">
                    <a href="" class="btn bg-light text-secondary shadow border ml-3 w-50">
                      Remove
                    </a>
                    <a href="" class="btn btn-primary text-white shadow border mr-3 w-50">
                      View
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </tab>

        <!-- Categories tab-->
        <tab>
          <ng-template tabHeading>
            <p class="font-weight-normal d-flex align-items-center mb-0"
               [ngClass]="screenWidth > 575? 'h5':'h6'">
              Categories
              <span class="material-icons">border_all</span>
            </p>
          </ng-template>
          <!--Categories tab content-->
          <!--Categories item when empty item -->
          <div class="row">
            <div class="col text-center ">
              <br>
              <br>
              <br>

              <img class="img-fluid pb-3 pt-4" [width]="screenWidth > 575? '128':'64'"
                   src="../../../assets/images/icon-images/tajr-icon-000043.png">
              <h4 class="font-weight-normal pb-2">No saved categories</h4>
              <p class="text-secondary mb-0">Save a category to be notified when there are new listings.</p>

              <br>
              <br>
              <br>

            </div>
          </div>
          <!--Categories item when has item -->
          <!-- Favourite categories -->
          <div class="row d-none">
            <div class="col">
              <p class="text-secondary text-right pt-0 pb-3"> Favourite categories</p>
            </div>
          </div>
          <!-- item -->
          <div class="row pb-4 d-none">
            <!-- item 1 :left -->
            <div class="col-12 col-md-6 col-xl-4 mt-3">
              <div class=" bg-white shadow text-right p-3">
                <p class="font-weight-bold text-primary">Cables & leads </p>
                <p class="text-secondary pt-2 pb-5">
                  <a href>
                    <small class="text-secondary"> Menu 4</small>
                  </a> /
                  <a href>
                    <small class="text-secondary"> Menu 3</small>
                  </a> /
                  <a href>
                    <small class="text-secondary"> Menu 2</small>
                  </a> /
                  <a href>
                    <small class="text-secondary"> Menu 1</small>
                  </a>
                </p>
                <form>
                  <div class="form-group row pb-3">
                    <div class="col">
                      <select class="form-control form-control-lg py-0" dir="rtl">
                        <option selected="">Don't email me</option>
                        <option value="1">Email me everyday</option>
                        <option value="2">Email me every 3 day</option>
                        <option value="3">Email me once a week</option>
                      </select>
                    </div>
                    <div class="col-auto d-flex align-items-center pr-0">
                      <span class="material-icons">mail_outline</span>
                    </div>
                  </div>
                </form>
                <div class="d-flex justify-content-between pb-2">
                  <a href="" class="btn bg-light text-secondary shadow border ml-3 w-50">
                    Remove
                  </a>
                  <a href="" class="btn btn-primary text-white shadow border mr-3 w-50">
                    View
                  </a>
                </div>
              </div>
            </div>
            <!-- item 2 -->
            <div class="col-12 col-md-6 col-xl-4 mt-3">
              <div class=" bg-white shadow text-right p-3">
                <p class="font-weight-bold text-primary">Honda </p>
                <p class="text-secondary pt-2 pb-5">
                  <a href>
                    <small class="text-secondary"> Menu 4</small>
                  </a> /
                  <a href>
                    <small class="text-secondary"> Menu 3</small>
                  </a> /
                  <a href>
                    <small class="text-secondary"> Menu 2</small>
                  </a> /
                  <a href>
                    <small class="text-secondary"> Menu 1</small>
                  </a>
                </p>
                <form>
                  <div class="form-group row pb-3">
                    <div class="col">
                      <select class="form-control form-control-lg py-0" dir="rtl">
                        <option selected="">Don't email me</option>
                        <option value="1">Email me everyday</option>
                        <option value="2">Email me every 3 day</option>
                        <option value="3">Email me once a week</option>
                      </select>
                    </div>
                    <div class="col-auto d-flex align-items-center pr-0">
                      <span class="material-icons">mail_outline</span>
                    </div>
                  </div>
                </form>
                <div class="d-flex justify-content-between pb-2">
                  <a href="" class="btn bg-light text-secondary shadow border ml-3 w-50">
                    Remove
                  </a>
                  <a href="" class="btn btn-primary text-white shadow border mr-3 w-50">
                    View
                  </a>
                </div>
              </div>
            </div>
            <!-- item 3 -->
            <div class="col-12 col-md-6 col-xl-4 mt-3">
              <div class=" bg-white shadow text-right p-3">
                <p class="font-weight-bold text-primary">Iphone 5 </p>
                <p class="text-secondary pt-2 pb-5">
                  <a href>
                    <small class="text-secondary"> Menu 4</small>
                  </a> /
                  <a href>
                    <small class="text-secondary"> Menu 3</small>
                  </a> /
                  <a href>
                    <small class="text-secondary"> Menu 2</small>
                  </a> /
                  <a href>
                    <small class="text-secondary"> Menu 1</small>
                  </a>
                </p>
                <form>
                  <div class="form-group row pb-3">
                    <div class="col">
                      <select class="form-control form-control-lg py-0" dir="rtl">
                        <option selected="">Don't email me</option>
                        <option value="1">Email me everyday</option>
                        <option value="2">Email me every 3 day</option>
                        <option value="3">Email me once a week</option>
                      </select>
                    </div>
                    <div class="col-auto d-flex align-items-center pr-0">
                      <span class="material-icons">mail_outline</span>
                    </div>
                  </div>
                </form>
                <div class="d-flex justify-content-between pb-2">
                  <a href="" class="btn bg-light text-secondary shadow border ml-3 w-50">
                    Remove
                  </a>
                  <a href="" class="btn btn-primary text-white shadow border mr-3 w-50">
                    View
                  </a>
                </div>
              </div>
            </div>
          </div>
        </tab>
        <!-- Seller tab-->
        <tab>
          <ng-template tabHeading>
            <p class="font-weight-normal d-flex align-items-center mb-0"
               [ngClass]="screenWidth > 575? 'h5':'h6'">
              Seller
              <span class="material-icons">person</span>
            </p>
          </ng-template>
          <!--Seller tab content-->
          <!--seller content when empty -->
          <div class="row ">
            <div class="col text-center ">
              <br>
              <br>
              <br>

              <img class="img-fluid pb-3 pt-4" [width]="screenWidth > 575? '128':'64'"
                   src="../../../assets/images/icon-images/tajr-icon-000044.png">
              <h4 class="font-weight-normal pb-2">No saved sellers</h4>
              <p class="text-secondary mb-0">Save a seller to be notified when they create new listings.</p>

              <br>
              <br>
              <br>

            </div>
          </div>
          <!--seller content when not empty -->
          <div class="row d-none">
            <div class="col">
              <p class="text-secondary text-right pt-0 pb-3"> 3 favourite search</p>
            </div>
          </div>
          <!-- seller -->
          <div class="row pb-4 d-none">
            <!-- seller 1 -->
            <div class="col-12 col-md-6 col-xl-4 mt-3">
              <div class=" bg-white shadow text-right p-3">
                <form dir="ltr">
                  <div class="form-group row pb-2">
                    <div class="col text-right pr-0">
                      <p class="mb-0 "><a routerLink="/my-profile" class="text-secondary">bugsy<span
                        class="btn font-weight-normal p-0">(433 <img class="ing-fluid"
                                                                     src="../../../assets/images/icon-images/tajr-icon-000045.png">)</span></a>
                      </p>
                      <p class="mb-0 "><a href class="text-secondary">1 current listing</a></p>
                    </div>
                    <div class="col-auto">
                      <div class="d-flex justify-content-center align-items-center">
                        <h3 class="position-absolute text-info">M</h3>
                        <img class="img-fluid " src="../../../assets/images/icon-images/tajr-icon-000046.png">
                      </div>
                    </div>
                  </div>
                </form>
                <form>
                  <div class="form-group row pb-3">
                    <div class="col">
                      <select class="form-control form-control-lg py-0" dir="rtl">
                        <option selected="">Don't email me</option>
                        <option value="1">Email me everyday</option>
                        <option value="2">Email me every 3 day</option>
                        <option value="3">Email me once a week</option>
                      </select>
                    </div>
                    <div class="col-auto d-flex align-items-center pr-0">
                      <span class="material-icons">mail_outline</span>
                    </div>
                  </div>
                </form>
                <div class="d-flex justify-content-between pb-2">
                  <a href="" class="btn bg-light text-secondary shadow border ml-3 w-50">
                    Remove
                  </a>
                  <a href="" class="btn btn-primary text-white shadow border mr-3 w-50">
                    View
                  </a>
                </div>
              </div>
            </div>
            <!-- seller 2 -->
            <div class="col-12 col-md-6 col-xl-4 mt-3">
              <div class=" bg-white shadow text-right p-3">
                <form dir="ltr">
                  <div class="form-group row pb-2">
                    <div class="col text-right pr-0">
                      <p class="mb-0 "><a routerLink="/my-profile" class="text-secondary">bugsy<span
                        class="btn font-weight-normal p-0">(433 <img class="ing-fluid"
                                                                     src="../../../assets/images/icon-images/tajr-icon-000045.png">)</span></a>
                      </p>
                      <p class="mb-0 "><a href class="text-secondary">1 current listing</a></p>
                    </div>
                    <div class="col-auto">
                      <div class="d-flex justify-content-center align-items-center">
                        <h3 class="position-absolute text-success">B</h3>
                        <img class="img-fluid " src="../../../assets/images/icon-images/tajr-icon-000047.png">
                      </div>
                    </div>
                  </div>
                </form>
                <form dir="ltr">
                  <div class="form-group row pb-3">
                    <div class="col">
                      <select class="form-control form-control-lg py-0" dir="rtl">
                        <option selected="">Don't email me</option>
                        <option value="1">Email me everyday</option>
                        <option value="2">Email me every 3 day</option>
                        <option value="3">Email me once a week</option>
                      </select>
                    </div>
                    <div class="col-auto d-flex align-items-center pr-0">
                      <span class="material-icons">mail_outline</span>
                    </div>
                  </div>
                </form>
                <div class="d-flex justify-content-between pb-2">
                  <a href="" class="btn bg-light text-secondary shadow border ml-3 w-50">
                    Remove
                  </a>
                  <a href="" class="btn btn-primary text-white shadow border mr-3 w-50">
                    View
                  </a>
                </div>
              </div>
            </div>
            <!-- item 3 -->
            <div class="col-12 col-md-6 col-xl-4 mt-3">
              <div class=" bg-white shadow text-right p-3">
                <form dir="ltr">
                  <div class="form-group row pb-2">
                    <div class="col text-right pr-0">
                      <p class="mb-0 "><a routerLink="my-profile" class="text-secondary">bugsy<span
                        class="btn font-weight-normal p-0">(433 <img class="ing-fluid"
                                                                     src="../../../assets/images/icon-images/tajr-icon-000045.png">)</span></a>
                      </p>
                      <p class="mb-0 "><a href class="text-secondary">1 current listing</a></p>

                    </div>
                    <div class="col-auto">
                      <div class="d-flex justify-content-center align-items-center">
                        <h3 class="position-absolute text-primary">L</h3>
                        <img class="img-fluid " src="../../../assets/images/icon-images/tajr-icon-000048.png">
                      </div>

                    </div>

                  </div>
                </form>
                <form>
                  <div class="form-group row pb-3">
                    <div class="col">
                      <select class="form-control form-control-lg py-0" dir="rtl">
                        <option selected="">Don't email me</option>
                        <option value="1">Email me everyday</option>
                        <option value="2">Email me every 3 day</option>
                        <option value="3">Email me once a week</option>
                      </select>
                    </div>
                    <div class="col-auto d-flex align-items-center pr-0">
                      <span class="material-icons">mail_outline</span>
                    </div>
                  </div>
                </form>
                <div class="d-flex justify-content-between pb-2">
                  <a href="" class="btn bg-light text-secondary shadow border ml-3 w-50">
                    Remove
                  </a>
                  <a href="" class="btn btn-primary text-white shadow border mr-3 w-50">
                    View
                  </a>
                </div>
              </div>
            </div>
          </div>
        </tab>
      </tabset>

    </div>
  </div>
</div>

<!-- blue line  -->
<div class="bg-primary mt-3 pt-2"></div>
