<div class="bg-light"  *ngIf="carlistings && carlistings.length> 0">
  <div class="container-fluid">
    <div class="row py-2">
      <div class="col" dir="rtl">
        <swiper
          slidesPerView='auto'
          [spaceBetween]="8"
          class="bg-light">
          <ng-template swiperSlide *ngFor="let carlisting of carlistings"
                       class="card-header p-0 w-auto border-0 MARGIN_LEFT_8">
            <button (click)="navigateToSearchPage(carlisting.name.arabic, carlisting.id)" type="button"
                    class="btn btn-xs btn-primary rounded-pill p-2">
              <small>{{carlisting?.name.arabic}}
                <span class="badge badge-light rounded-circle">{{carlisting?.doc_count}}</span>
              </small>
            </button>
          </ng-template>
        </swiper>
      </div>
    </div>
  </div>
</div>
