import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiReferenceService {
  baseUrl = environment.baseUrl;

  constructor() {
  }

  // account api
  getUserDetails = this.baseUrl + '/v1/members/me';
  loginUser = this.baseUrl + '/v1/members/me/access-token';
  revokeToken = this.baseUrl + '/v1/members/me/access-token';
  insertNewMobileNumber = this.baseUrl + '/v1/registration';
  verifyNewMobileNumber = this.baseUrl + '/v1/registration/';
  getLocalities = this.baseUrl + '/v1/membership-and-motors-localities';
  registerDetails = this.baseUrl + '/v1/registration/';
  editDetails = this.baseUrl + '/v1/members/me';
  changePassword = this.baseUrl + '/v1/members/me/passwords';
  resetPassword = this.baseUrl + '/v1/members/';
  resetPasswordConfirm = this.baseUrl + '/v1/members/';
  newPassword = this.baseUrl + '/v1/members/';
  allPhotosIdByMember = this.baseUrl + '/v1/photos/?page_state=';
  photoMetaData = this.baseUrl + '/v1/photos/';
  uploadPhoto = this.baseUrl + '/v1/photos';
  getUserPublicDetails = this.baseUrl + '/v1/members/id/';
  deletePhoto = this.baseUrl + '/v1/photos/';
  addToWatchListUrl = this.baseUrl + '/v1/members/me/watchlist';
  removeWatchListUrl = this.baseUrl + '/v1/members/me/watchlist/';
  getMemberWatchListUrl = this.baseUrl + '/v1/members/me/watchlist';
  getMemberRecentListingsUrl = this.baseUrl + '/v1/members/me/recently-viewed-listings';
  getMemberListingSummary = this.baseUrl + '/v1/members/me/listing-summary';

  // product api
  getListings = this.baseUrl + '/v1/search';
  getListingById = this.baseUrl + '/v1/listings/';
  getAllCategories = this.baseUrl + '/v1/categories';
  getCategoryDetailsByID = this.baseUrl + '/v1/category-details/{categoryId}';
  getFeesForNewListing = this.baseUrl + '/v1/listings/fees';
  getFeesForEditListing = this.baseUrl + '/v1/listings/{listingId}/fees';
  createNewListing = this.baseUrl + '/v1/listings';
  editListingApi = this.baseUrl + '/v1/listings/{listingId}';
  getListingDetailsById = this.baseUrl + '/v1/listings/';
  getListingDetailsForEdit = this.baseUrl + '/v1/listings/{listingId}/edit';
  withdrawListingByID = this.baseUrl + '/v1/listings/';

  // report Api
  getReportReasons = this.baseUrl + '/v1/report-reasons';
  createReportReasons = this.baseUrl + '/v1/reports';

  // search api
  search = this.baseUrl + '/v1/search';
  searchMember = this.baseUrl + '/v1/search/members/me';

  // chat api
  getAllConversations = this.baseUrl + '/v1/chat/conversations';
  getConversationMessages = this.baseUrl + '/v1/chat/messages';
  registrationToken = this.baseUrl + '/v1/chat/registration_token';
}
