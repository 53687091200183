import {Component, HostListener, OnInit} from '@angular/core';
import {ProductService} from 'src/app/services/product.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HP_ALL_LISTING_RS, SEARCH_PAGE} from '../../shared/global';
import SwiperCore, {A11y, EffectFade, Navigation, Pagination, Scrollbar, Virtual} from 'swiper/core';
import {SearchListingDetails} from '../../modules/models/search.listing.details';
import {Util} from '../../utils/utils';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Virtual, EffectFade]);

@Component({
  selector: 'app-quick-car-search',
  templateUrl: './quick-car-search.component.html',
  styleUrls: ['./quick-car-search.component.css']
})
export class QuickCarSearchComponent implements OnInit {
  // width of screen size on browser
  public screenWidth: number;
  carlistings = [];
  public searchListing: SearchListingDetails;

  constructor(
    // service send request
    private productService: ProductService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.screenWidth = window.innerWidth;
    this.searchListing = this.activatedRoute.snapshot.data[HP_ALL_LISTING_RS];
    if (Util.isNotNullOrEmpty(this.searchListing.aggregations.CategoryLevel1.buckets[0].CategoryLevel2.buckets[0].CategoryLevel3.buckets)) {
      this.carlistings = this.searchListing.aggregations.CategoryLevel1.buckets[0].CategoryLevel2.buckets[0].CategoryLevel3.buckets;
    }
  }

  navigateToSearchPage(queryString: string, categoryIdStr: string) {
    this.router.navigate([SEARCH_PAGE], {
      queryParams: {
        query: queryString,
        categoryId: categoryIdStr
      }
    });
  }

  /**
   * use HostListener to  updated on resize:
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  getScreenSize(event) {
    this.screenWidth = window.innerWidth;
  }

}

