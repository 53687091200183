export const AR = 'ar';
export const EN = 'en';
export const GET = 'get';
export const PUT = 'put';
export const POST = 'post';
export const DELETE = 'delete';
export const EDIT = 'edit';
export const SELL_SIMILIAR = 'sellSimilar';
export const CREATE = 'create';
export const VIEW = 'view';
export const TAJR_PERSONAL_DETAILS = 'tajr-profile';
export const PREVIOUS_URL = 'previousUrl';
export const TAJR_ALL_CATEGORIES = 'tajr-allCategories';
export const TAJR_CATEGORIES_DETAILS = 'tajr-categoriesDetails';
export const TAJR_LIST_AN_ITEM = 'tajr-listAnItem';
export const TAJR_LIST_AN_ITEM_PHOTOS = 'tajr-listAnItemPhotos';
export const TAJR_LIST_AN_ITEM_DETAILS = 'tajr-listAnItemDetails';
export const TAJR_DEFAULT_CATEGORY = '0001-0100-0200';
export const TAJR_LIST_AN_ITEM_FEES = 'tajr-listAnItemFees';
export const TAJR_EDIT_LISTING_ITEM = 'tajr-editListingItem';
export const TAJR_ALL_LOCALITIES = 'tajr-allLocalities';
export const TAJR_REPORT_RESONS = 'tajr-report-reasons';
export const TAJR_VERSION = 'tajr-version';
export const BOTTOM = 'bottom';
export const YOUTUBE = 'YouTube';
export const YOUTUBE_URL = 'https://img.youtube.com/vi/';
export const YOUTUBE_FILE_TYPE = '/0.jpg';
export const YOUTUBE_REGEX_URL = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
export const NOT_EMPTY_REGEX = /^[a-zA-Z0-9 ]*/;
export const MOBILE_NUMBER_REGEX = /^[0]\d{9}$/;
export const NUMBER_REGEX = /^\d+$/;
export const PASSWORD_REGEX = /^[*]*/;
export const DEFAULT_CATEGORY_ID = '0001-0100-0200';
export const DEFAULT_CATEGORY_LEVER_1_ID = '0001';
export const DEFAULT_CATEGORY_LEVER_2_ID = '0001-0100';
export const SMOOTH = 'smooth';
export const ACTION = 'action';
export const VALUE = 'value';
export const ID = 'id';
export const LISTING_ID = 'listingId';
export const EMPTY_VALUE = '';
export const LATEST = 'latest';
export const CLOSING_SOON = 'closing_soon';
export const DATA_STRUCTURE_SCHEMA = 'dataStructureSchema';
export const FIRST_ITEM = 0;
export const TAJR_ALL_CATEGORIES_RESOURCE = 'allCategoriesRs';
export const CATEGORIES_DETAILS_RESOURCE = 'categoriesDetailsRs';
export const REPORT_REASONS_RESOURCE = 'reportReasonsRs';
export const LOCALITIES_RESOURCE = 'localitiesRs';
export const ITEM_PER_PAGE = 30;
export const HP_CLOSING_SOON = 'HP_CLOSING_SOON';
export const HP_LATEST = 'HP_LATEST';
export const HP_CLOSING_SOON_RS = 'hpClosingSoonRs';
export const HP_LATEST_RS = 'hpLatestRs';
export const HP_ALL_LISTING = 'HP_ALL_LISTING';
export const HP_ALL_LISTING_RS = 'hpAllListingsRs';

export const MAX_CATEGORY_LEVEL = 3;
export const CATEGORY_LEVEL_1 = 1;
export const CATEGORY_LEVEL_2 = 2;
export const CATEGORY_LEVEL_3 = 3;

export const LIST_AN_ITEM = '/list-an-item';
export const LIST_AN_ITEM_DETAILS = '/list-an-item-details';
export const LIST_AN_ITEM_PHOTOS = '/list-an-item-photos';
export const LIST_AN_ITEM_CONFIRM = '/list-an-item-confirm';
export const LIST_AN_ITEM_SUCCESS = '/list-an-item-success';
export const NOT_FOUND_PAGE = '/notfound';
export const LISTING_PAGE = '/listing';
export const HOME_PAGE = '/';
export const SEARCH_PAGE = '/search';
export const RECENTLY_VIEWD_LISTING_PAGE = '/recently-viewed-listings';

export const AttributeConstant = {
  TRUE: 'true',
  FALSE: 'false',
};

export const SearchConstant = {
  EXPIRED_LISTINGS: 'expired_listings',
  WITHDRAWN_LISTINGS: 'withdrawn_listings',
  QUERY: 'query',
  FROM: 'from',
  SIZE: 'size',
  SORT_BY: 'sortBy',
  CATEGORY_ID: 'categoryId',
  LOCALITY_ID: 'localityId',
  DISTRICT_ID: 'districtId',
  MEMBER_ID: 'memberId',
  PAGE: 'page',
  TAB: 'tab',
};

export const ListingStatus = {
  ACTIVE: 'active',
  DELETED: 'deleted',
  EXPIRED: 'expired',
};

export const ItemAttributes = {
  CATEGORY_ID: 'categoryId',
  LISTING_ID: 'listingId',
  MODEL: 'model',
  MODEL_DETAIL: 'modelDetail',
  EXTERIOR_COLOR: 'exteriorColor',
  BODY_STYLE: 'bodyStyle',
  FUEL_TYPE: 'fuelType',
  MVPI_EXPIRES: 'mvpiExpires',
  NUMBER_PLATE: 'numberPlate',
  DESCRIPTION: 'description',
  YEAR: 'year',
  KILOMETERS: 'kilometers',
  DOORS: 'doors',
  SEATS: 'seats',
  CYLINDERS: 'cylinders',
  ENGINE_SIZE: 'engineSize',
  TRANSMISSION: 'transmission',
  NUMBER_OF_OWNERS: 'numberOfOwners',
  IMPORT_HISTORY: 'importHistory',
  FOUR_WHEEL_DRIVE: 'fourWheelDrive',
  MOJAZ_INSPECTION_REPORT: 'mojazInspectionReport',
  DRIVER_AIRBAG: 'driverAirbag',
  SUN_ROOF: 'sunroof',
  PASSENGER_AIR_BAG: 'passengerAirbag',
  ALARM: 'alarm',
  POWER_STEERING: 'powerSteering',
  ABS_BRAKES: 'absBrakes',
  AIR_CONDITIONING: 'airConditioning',
  CENTRAL_LOCKING: 'centralLocking',
  TITLE: 'title',
  SUB_TITLE: 'subtitle',
  SHOW_MOBILE_NUMBER: 'showMobileNumber',
  BEST_CONTAC_TTIME: 'bestContactTime',
  ASKING_PRICE: 'askingPrice',
  OR_NEAR_OFFER: 'orNearOffer',
  ON_ROAD_COSTS_INCLUDED: 'onRoadCostsIncluded',
  END_DATE: 'endDate',
  END_DATE_DISPLAY: 'endDateDisplay',
  DURATION: 'duration',
  LISTING_TYPE: 'listingType',
  DELETED_DATE: 'dd',
  PHOTO_ID: 'photo_id',
  FILE_NAME: 'file_name',
  PHOTO_URLS: 'photo_urls',
  POSITION: 'position',
  PHOTO_508X380: '508x380',
  USER_FILE_SOURCE: 'user',
  MODAL_FILE_SOURCE: 'modal',
  MEMBER_ID: 'memberId',
  USERNAME: 'username'
};

export const Timer = {
  MINUTE_SECOND: 60,
};

export const Social = {
  TWITTER_SHARE_LINK: 'https://twitter.com/intent/tweet?text={text}',
  WHATSAPP_SHARE_LINK: 'https://api.whatsapp.com/send/?text={text}',
  // INSTAGRAM_SHARE_LINK: 'instagram?text={text}',
  // SNAPCHAT_SHARE_LINK: 'snapchat?text={text}',
};

export const ListingAttributes = {
  LIST_AN_ITEM: {
    '0001-0100': [
      'fuelType',
      'modelDetail',
      'description',
      'mvpiExpires',
      'bodyStyle',
      'exteriorColor',
      'numberPlate',
      'year',
      'doors',
      'transmission',
      'numberOfOwners',
      'importHistory',
      'seats',
      'kilometers',
      'engineSize',
      'cylinders',
      'mojazInspectionReport',
      'driverAirbag',
      'sunroof',
      'fourWheelDrive',
      'passengerAirbag',
      'alarm',
      'powerSteering',
      'absBrakes',
      'airConditioning',
      'model',
      'centralLocking',
    ],
    '0001-0877': [
      'title',
      'description'
    ],
  },
  LIST_AN_ITEM_DETAIL: {
    '0001-0100': [
      'subtitle',
      'showMobileNumber',
      'bestContactTime',
      'askingPrice',
      'orNearOffer',
      'onRoadCostsIncluded',
    ],
    '0001-0877': [
      'subtitle',
      'showMobileNumber',
      'bestContactTime',
      'askingPrice',
      'orNearOffer',
    ],
  },
}
export const LIST_AN_ITEM_ERROR_TYPE = {
  required: 'required',
  min: 'less_than_lower_error',
  max: 'more_than_upper_error',
  length: 'more_than_max_length'
}

export const CATEGORY_ICON = {
  '0001-0100': 'fa fa-car',
  '0001-0877': 'fa fa-wrench',
}