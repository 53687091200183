<!-- blue line  -->
<div class="bg-primary pt-2"></div>
<!-- Progress Menu -->
<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-lg-10 col-xl-8 offset-lg-1 offset-xl-2 ">
      <div class="row mt-3">
        <div class="col text-right p-0">
          <a href="javascript:void(0);" class="text-decoration-none text-secondary"
             [ngClass]="screenWidth >768?'':'small'" dir="rtl"> 4.
            تأكيد
            معلومات الإعلان</a>
        </div>
        <div class="col text-right p-0">
          <a href="javascript:void(0);" class="text-decoration-none text-secondary"
             [ngClass]="screenWidth >768?'':'small'" dir="rtl"> 3.
            معلومات
            الإعلان</a>
        </div>
        <div class="col text-right p-0">
          <a href="javascript:void(0);" class="text-decoration-none text-secondary"
             [ngClass]="screenWidth >768?'':'small'" dir="rtl"> 2.
            الصور</a>
        </div>
        <div class="col text-right ">
          <a routerLink="/list-an-item" class="text-decoration-none text-secondary font-weight-bold"
             [ngClass]="screenWidth >768?'':'small'"
             dir="rtl">1.
            معلومات السيارة</a>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col">
          <div class="progress h-100">
            <div class="progress-bar bg-light w-75 pt-1" role="progressbar" aria-valuenow="0"
                 aria-valuemin="0" aria-valuemax="100">
            </div>
            <div class="progress-bar bg-warning w-25 pt-1" role="progressbar" aria-valuenow="0"
                 aria-valuemin="0" aria-valuemax="100">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- list an item form -->
<div class="container-fluid mt-2 ">
  <div class="row pt-1 category-lv2">
      <div class="col-12 col-lg-10 col-xl-8 offset-lg-1 offset-xl-2 text-right category-lv2-options" dir="rtl">
        <div 
          *ngFor="let catLv2 of categories.getCategoryListBylevel(2)"
          class="btn category-lv2-option"
          [ngClass]="categories.getCategoryActiveIDBylevel(2) === catLv2.id ? 'active' : ''"
          id="catLv2.id"
          (click)="handleSelectCategoryLv2(catLv2.id)"
        >
          <div 
          [ngClass]="getCategoryIcon(catLv2.id)"></div>
          <div clas="btn-text">{{ catLv2.name.arabic }}</div>
        </div>
      </div>
  </div>
  <div class="row pt-1">
    <div class="col-12 col-lg-10 col-xl-8 offset-lg-1 offset-xl-2 text-right">
      <p class="text-secondary mb-2" dir="rtl"><small> جميع الخانات المتبوعه بنجمه هي خانات
        مطلوبة لأنزال الإعلان </small>(<span class="text-danger">*</span>)</p>
    </div>
  </div>
  <!-- list an item form -->
  <div class="row">
    <div class="col-12 col-lg-10 col-xl-8 offset-lg-1 offset-xl-2"
    *ngIf="!!categories.getCategoryListBylevel(2)">
      <form class="bg-light border rounded p-3"
            [formGroup]="activeCategoriesForm"
            (ngSubmit)="onSubmit()">
        <!-- Car Details -->
        <h4 class="text-warning text-right">معلومات السيارة</h4>
        <div class="dropdown-divider"></div>
        <!-- car -->
        <div class="row">
          <div class="col-12 text-right align-self-center">
            <span class="text-danger">*</span> <label
            class="font-weight-bold text-secondary vehicleType-displayName"
            for="vehicleType">{{categories.getCategoryBylevel(2).name.arabic}}</label>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col">
                <select class="form-control text-secondary vehicleType py-0"
                        dir="rtl" id="vehicleType"
                        [(ngModel)]='listAnItemModules.categoryId'
                        (change)="getCategoryDetailsByID(false)"
                        formControlName="categoryId"
                        autocomplete="on">
                  <option value="">أختر</option>
                  <option *ngFor="let category of categories.getCategoryListBylevel(3)"
                          value="{{category.id}}">{{category.name.arabic}}
                  </option>
                </select>
              </div>
            </div>
            <!-- errorCarDetails-->
            <div class="row ">
              <div class="col text-right mt-1">
                <small class="text-danger pt-3"
                       *ngIf="(activeCategoriesForm.controls.categoryId.dirty || submitted)  && !activeCategoriesForm.controls.categoryId.valid">
                  <span *ngIf="activeCategoriesForm.controls.categoryId.errors?.required">
                  {{getErrorMessage('categoryId', 'required')}}
                  </span>
                    <span *ngIf="activeCategoriesForm.controls.categoryId.errors?.pattern">
                  {{getErrorMessage('categoryId', 'required')}}
                  </span>
                </small>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="isIncludeCategory('title')">
          <div class="dropdown-divider"></div>
          <!-- attributes[3].Model detail -->
          <div class="row">
            <div class="col-12 text-right align-self-center">
              <span class="text-danger" *ngIf="isRequiredCategory('title')">*</span>
              <label class="font-weight-bold text-secondary title-displayName"
                     for="title">
                {{showAttributesLable('title')}}
              </label>
            </div>
            <div class="col-12">
              <input class="form-control text-secondary text-right title py-0"
                     id="title"
                     [(ngModel)]='listAnItemModules.title'
                     [maxLength]="validatorsMaxLength('title')"
                     formControlName="title"
                     autocomplete="on">
            </div>
          </div>
          <!-- errorModelDetail -->
          <div class="row">
            <div class="col text-right mt-1">
              <small class="text-danger pt-3"
                     *ngIf="submitted  && !activeCategoriesForm.controls.title.valid">
                <span *ngIf="activeCategoriesForm.controls.title.errors?.required">
                  {{getErrorMessage('title', 'required')}}
                </span>
              </small>
            </div>
          </div>
        </div>
        <div *ngIf="isIncludeCategory('model')">
          <div class="dropdown-divider"></div>
          <!-- attributes[35].model -->
          <div class="row">
            <div class="col-12 text-right align-self-center">
              <span class="text-danger">*</span> <label
              class="font-weight-bold text-secondary model-displayName" for="model">
              {{showAttributesLable('model')}}
            </label>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col">
                  <select class="form-control text-secondary model py-0" dir="rtl"
                          id="model"
                          [(ngModel)]='listAnItemModules.model'
                          formControlName="model"
                          autocomplete="on">
                    <option value="">أختر</option>
                    <option *ngFor="let model of showSelectTabOptionItems('model')"
                            value="{{model.value}}">{{model.display.arabic}}
                    </option>
                  </select>
                </div>
              </div>
              <!-- errorModel -->
              <div class="row">
                <div class="col text-right mt-1">
                  <small class="text-danger pt-3"
                         *ngIf="submitted  && !activeCategoriesForm.controls.model.valid">
                    <span *ngIf="activeCategoriesForm.controls.model.errors?.required">
                    {{getErrorMessage('model', 'required')}}
                    </span>
                    <span *ngIf="activeCategoriesForm.controls.model.errors?.pattern">
                    {{getErrorMessage('model', 'required')}}
                    </span>
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="isIncludeCategory('modelDetail')">
          <div class="dropdown-divider"></div>
          <!-- attributes[3].Model detail -->
          <div class="row">
            <div class="col-12 text-right align-self-center">
              <label class="font-weight-bold text-secondary modelDetail-displayName"
                     for="modelDetail">
                {{showAttributesLable('modelDetail')}}
              </label>
            </div>
            <div class="col-12">
              <input class="form-control text-secondary text-right modelDetail py-0"
                     id="modelDetail"
                     [(ngModel)]='listAnItemModules.modelDetail'
                     [maxLength]="validatorsMaxLength('modelDetail')"
                     formControlName="modelDetail"
                     autocomplete="on">
            </div>
          </div>
          <!-- errorModelDetail -->
          <div class="row ">
            <div class="col text-right mt-1">
              <small class="text-danger errorModelDetail"></small>
            </div>
          </div>
        </div>
        <div *ngIf="isIncludeCategory('year')">
          <div class="dropdown-divider"></div>
          <!-- attributes[10].year -->
          <div class="row">
            <div class="col-12 text-right align-self-center">
              <span class="text-danger">*</span> <label
              class="font-weight-bold text-secondary year-displayName" for="year">
              {{showAttributesLable('year')}}
            </label>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col">
                         <select class="form-control text-secondary model py-0" dir="rtl"
                          id="year"
                          [(ngModel)]='listAnItemModules.year'
                          formControlName="year"
                          autocomplete="on">
                    <option value="">أختر</option>
                    <option *ngFor="let year of showSelectTabOptionItems('year')"
                            value="{{year.value}}">{{year.display.arabic}}
                    </option>
                  </select>
                </div>
              </div>
              <!-- errorYear -->
              <div class="row ">
                <div class="col text-right mt-1">
                  <small class="text-danger pt-3"
                         *ngIf="submitted  && !activeCategoriesForm.controls.year.valid">
                    <span *ngIf="activeCategoriesForm.controls.year.errors?.required">
                      {{getErrorMessage('year', 'required')}}
                    </span>
                      <span *ngIf="activeCategoriesForm.controls.year.errors?.pattern">
                      {{getErrorMessage('year', 'required')}}
                    </span>
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="isIncludeCategory('kilometers')">
          <div class="dropdown-divider"></div>
          <!-- attributes[18].Kilometres  -->
          <div class="row">
            <div class="col-12 text-right align-self-center">
              <span class="text-danger">*</span> <label
              class="font-weight-bold text-secondary kilometers-displayName" for="kilometers">
              {{showAttributesLable('kilometers')}}
            </label>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col">
                  <input class="form-control text-secondary text-right kilometers py-0"
                         type="number" id="kilometers"
                         placeholder="0"
                         [(ngModel)]='listAnItemModules.kilometers'
                         formControlName="kilometers"
                         autocomplete="on">
                </div>
              </div>
              <!-- errorKilometres -->
              <div class="row ">
                <div class="col text-right mt-1 ">
                  <small class="text-danger errorKilometres">
                    <small class="text-danger pt-3"
                           *ngIf="submitted  && !activeCategoriesForm.controls.kilometers.valid">
                  <span *ngIf="activeCategoriesForm.controls.kilometers.errors?.required">
                    {{getErrorMessage('kilometers', 'required')}}
                  </span>
                      <span *ngIf="activeCategoriesForm.controls.kilometers.errors?.min">
                      {{getErrorMessage('kilometers', 'min')}}
                    </span>
                      <span *ngIf="activeCategoriesForm.controls.kilometers.errors?.max">
                     {{getErrorMessage('kilometers', 'max')}}
                    </span>
                    </small>
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="isIncludeCategory('exteriorColor')">
          <div class="dropdown-divider"></div>
          <!-- attributes[7].exteriorColor -->
          <div class="row">
            <div class="col-12 text-right align-self-center">
              <label class="font-weight-bold text-secondary exteriorColor-displayName"
                     for="exteriorColor">
                {{showAttributesLable('exteriorColor')}}
              </label>
            </div>
            <div class="col-12">
              <input class="form-control text-secondary text-right exteriorColor py-0"
                     id="exteriorColor"
                     [(ngModel)]='listAnItemModules.exteriorColor'
                     [maxLength]="validatorsMaxLength('exteriorColor')"
                     formControlName="exteriorColor"
                     autocomplete="on">
            </div>
          </div>
          <!-- errorExteriorColor -->
          <div class="row ">
            <div class="col text-right mt-1 ">
              <small class="text-danger errorExteriorColor"></small>
            </div>
          </div>
        </div>
        <div *ngIf="isIncludeCategory('doors')">
          <div class="dropdown-divider"></div>
          <!-- attributes[11].doors -->
          <div class="row">
            <div class="col-12 text-right align-self-center">
              <label class="font-weight-bold text-secondary doors-displayName" for="doors">
                {{showAttributesLable('doors')}}
              </label>
            </div>
            <div class="col-12">
              <select class="form-control text-secondary doors py-0"
                      dir="rtl"
                      id="doors"
                      [(ngModel)]='listAnItemModules.doors'
                      formControlName="doors"
                      autocomplete="on">
                <option value="">أختر</option>
                <option *ngFor="let item of showSelectTabOptionItems('doors')"
                        value="{{item.value}}">{{item.display.arabic}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div *ngIf="isIncludeCategory('bodyStyle')">
          <div class="dropdown-divider"></div>
          <!-- attributes[6].Body style -->
          <div class="row">
            <div class="col-12 text-right align-self-center">
              <span class="text-danger">*</span> <label
              class="font-weight-bold text-secondary bodyStyle-displayName" for="bodyStyle">
              {{showAttributesLable('bodyStyle')}}
            </label>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col">
                  <select class="form-control text-secondary bodyStyle py-0" dir="rtl"
                          id="bodyStyle"
                          [(ngModel)]='listAnItemModules.bodyStyle'
                          formControlName="bodyStyle"
                          autocomplete="on">
                    <option value="">أختر</option>
                    <option *ngFor="let item of showSelectTabOptionItems('bodyStyle')"
                            value="{{item.value}}">{{item.display.arabic}}
                    </option>
                  </select>
                </div>
              </div>
              <!-- errorBodyStyle-->
              <div class="row ">
                <div class="col text-right mt-1">
                  <small class="text-danger errorBodyStyle">
                    <small class="text-danger pt-3"
                           *ngIf="submitted && !activeCategoriesForm.controls.bodyStyle.valid">
                  <span *ngIf="activeCategoriesForm.controls.bodyStyle.errors?.required">
                  {{getErrorMessage('bodyStyle', 'required')}}
                  </span>
                      <span *ngIf="activeCategoriesForm.controls.bodyStyle.errors?.pattern">
                  {{getErrorMessage('bodyStyle', 'required')}}
                  </span>
                    </small>
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="isIncludeCategory('seats')">
          <div class="dropdown-divider"></div>
          <!-- attributes[16].seat -->
          <div class="row">
            <div class="col-12 text-right align-self-center">
              <label class="font-weight-bold text-secondary seats-displayName" for="seats">
                {{showAttributesLable('seats')}}
              </label>
            </div>
            <div class="col-12">
              <input class="form-control text-secondary text-right seats py-0"
                     type="number" id="seats"
                     placeholder="0"
                     [(ngModel)]='listAnItemModules.seats'
                     [min]="validatorsLower('seats')"
                     [max]="validatorsUpper('seats')"
                     formControlName="seats"
                     autocomplete="on">
            </div>
          </div>
          <!-- errorSeats-->
          <div class="row ">
            <div class="col text-right mt-1">
              <small class="text-danger errorSeats">
                <small class="text-danger pt-3"
                       *ngIf="submitted  && !activeCategoriesForm.controls.seats.valid">
                      <span *ngIf="activeCategoriesForm.controls.seats.errors?.min">
                      {{getErrorMessage('seats', 'min')}}
                    </span>
                  <span *ngIf="activeCategoriesForm.controls.seats.errors?.max">
                     {{getErrorMessage('seats', 'max')}}
                    </span>
                </small>
              </small>
            </div>
          </div>
        </div>
        <div *ngIf="isIncludeCategory('fuelType')">
          <div class="dropdown-divider"></div>
          <!-- attributes[0].Fuel type -->
          <div class="row">
            <div class="col-12 text-right align-self-center">
              <label class="font-weight-bold text-secondary fuelType-displayName" for="fuelType">
                {{showAttributesLable('fuelType')}}
              </label>
            </div>
            <div class="col-12">
              <select class="form-control text-secondary fuelType py-0" dir="rtl"
                      id="fuelType"
                      [(ngModel)]='listAnItemModules.fuelType'
                      formControlName="fuelType"
                      autocomplete="on">
                <option value="">أختر</option>
                <option *ngFor="let item of showSelectTabOptionItems('fuelType')"
                        value="{{item.value}}">{{item.display.arabic}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div *ngIf="isIncludeCategory('cylinders')">
          <div class="dropdown-divider"></div>
          <!-- attributes[21].cylinders -->
          <div class="row">
            <div class="col-12 text-right align-self-center">
              <label class="font-weight-bold text-secondary cylinders-displayName" for="cylinders">
                {{showAttributesLable('cylinders')}}
              </label>
            </div>
            <div class="col-12">
              <select class="form-control text-secondary cylinders py-0" dir="rtl"
                      id="cylinders"
                      [(ngModel)]='listAnItemModules.cylinders'
                      formControlName="cylinders"
                      autocomplete="on">
                <option value="">أختر</option>
                <option *ngFor="let item of showSelectTabOptionItems('cylinders')"
                        value="{{item.value}}">{{item.display.arabic}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div *ngIf="isIncludeCategory('engineSize')">
          <div class="dropdown-divider"></div>
          <!-- attributes[20].engineSize -->
          <div class="row">
            <div class="col-12 text-right align-self-center">
              <label class="font-weight-bold text-secondary  engineSize-displayName"
                     for="engineSize">
                {{showAttributesLable('engineSize')}}
              </label>
            </div>
            <div class="col-12">
              <input class="form-control text-secondary text-right engineSize py-0"
                     type="number" id="engineSize"
                     placeholder="0"
                     [(ngModel)]='listAnItemModules.engineSize'
                     formControlName="engineSize"
                     autocomplete="on">
            </div>
          </div>
          <!-- errorEngineSize -->
          <div class="row ">
            <div class="col text-right mt-1">
              <small class="text-danger errorEngineSize">
                <small class="text-danger pt-3"
                       *ngIf="submitted  && !activeCategoriesForm.controls.engineSize.valid">
                      <span *ngIf="activeCategoriesForm.controls.engineSize.errors?.min">
                      {{getErrorMessage('engineSize', 'min')}}
                    </span>
                  <span *ngIf="activeCategoriesForm.controls.engineSize.errors?.max">
                     {{getErrorMessage('engineSize', 'max')}}
                    </span>
                </small>
              </small>
            </div>
          </div>
        </div>
        <div *ngIf="isIncludeCategory('transmission')">
          <div class="dropdown-divider"></div>
          <!-- attributes[12].transmission -->
          <div class="row">
            <div class="col-12 text-right align-self-center">
              <label class="font-weight-bold text-secondary transmission-displayName"
                     for="transmission">
                {{showAttributesLable('transmission')}}
              </label>
            </div>
            <div class="col-12">
              <select class="form-control text-secondary transmission py-0" dir="rtl"
                      id="transmission"
                      [(ngModel)]='listAnItemModules.transmission'
                      formControlName="transmission"
                      autocomplete="on">
                <option value="">أختر</option>
                <option *ngFor="let item of showSelectTabOptionItems('transmission')"
                        value="{{item.value}}">{{item.display.arabic}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div *ngIf="isIncludeCategory('fourWheelDrive')">
          <div class="dropdown-divider"></div>
          <!-- attributes[25].fourWheelDrive -->
          <div class="row">
            <div class="col text-right align-self-center">
              <label class="text-secondary mb-0" for="fourWheelDrive">
                <!-- Yes-->نعم &nbsp;
                <input type="checkbox" id="fourWheelDrive"
                       [(ngModel)]="listAnItemModules.fourWheelDrive"
                       formControlName="fourWheelDrive"
                       autocomplete="on">
              </label>
            </div>
            <div class="col-auto text-right align-self-center">
              <label class="font-weight-bold text-secondary fourWheelDrive-displayName" for="fourWheelDrive">
                {{showAttributesLable('fourWheelDrive')}}
              </label>
            </div>
          </div>
        </div>
        <div *ngIf="isIncludeCategory('numberOfOwners')">
          <div class="dropdown-divider"></div>
          <!-- attributes[14].Number of owners -->
          <div class="row">
            <div class="col-12 text-right align-self-center">
              <label class="font-weight-bold text-secondary numberOfOwners-displayName"
                     for="numberOfOwners">
                {{showAttributesLable('numberOfOwners')}}
              </label>
            </div>
            <div class="col-12">
              <select class="form-control text-secondary numberOfOwners py-0" dir="rtl"
                      id="numberOfOwners"
                      [(ngModel)]='listAnItemModules.numberOfOwners'
                      formControlName="numberOfOwners"
                      autocomplete="on">
                <option value="">أختر</option>
                <option *ngFor="let item of showSelectTabOptionItems('numberOfOwners')"
                        value="{{item.value}}">{{item.display.arabic}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div *ngIf="isIncludeCategory('importHistory')">
          <div class="dropdown-divider"></div>
          <!-- attributes[15].Import history -->
          <div class="row">
            <div class="col-12 text-right align-self-center">
              <label class="font-weight-bold text-secondary importHistory-displayName"
                     for="importHistory">
                {{showAttributesLable('importHistory')}}
              </label>
            </div>
            <div class="col-12">
              <select class="form-control text-secondary importHistory py-0" dir="rtl"
                      id="importHistory"
                      [(ngModel)]='listAnItemModules.importHistory'
                      formControlName="importHistory"
                      autocomplete="on">
                <option value="">أختر</option>
                <option *ngFor="let item of showSelectTabOptionItems('importHistory')"
                        value="{{item.value}}">{{item.display.arabic}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div *ngIf="isIncludeCategory('mvpiExpires')">
          <div class="dropdown-divider"></div>
          <!-- attributes[9].mvpiExpires-displayName -->
          <div class="row">
            <div class="col-12 text-right align-self-center">
              <label class="font-weight-bold text-secondary mvpiExpires-displayName"
                     for="mvpiExpires">
                {{showAttributesLable('mvpiExpires')}}
              </label>
            </div>
            <div class="col-12">
              <select class="form-control text-secondary mvpiExpires py-0" dir="rtl"
                      id="mvpiExpires"
                      [(ngModel)]='listAnItemModules.mvpiExpires'
                      formControlName="mvpiExpires"
                      autocomplete="on">
                <option value="">أختر</option>
                <option *ngFor="let item of showSelectTabOptionItems('mvpiExpires')"
                        value="{{item.value}}">{{item.display.arabic}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div *ngIf="isIncludeCategory('numberPlate')">
          <div class="dropdown-divider"></div>
          <!-- attributes[8].numberPlate -->
          <div class="row">
            <div class="col-12 text-right align-self-center">
              <label class="font-weight-bold text-secondary numberPlate-displayName"
                     for="numberPlate">
                {{showAttributesLable('numberPlate')}}
              </label>
            </div>
            <div class="col-12 text-right align-self-center">
              <input class="form-control text-secondary text-right numberPlate py-0"
                     id="numberPlate"
                     [(ngModel)]='listAnItemModules.numberPlate'
                     [maxLength]="validatorsMaxLength('numberPlate')"
                     formControlName="numberPlate"
                     autocomplete="on">
            </div>
          </div>
          <!-- errorNumberPlate -->
          <div class="row ">
            <div class="col text-right mt-1">
              <small class="text-danger errorNumberPlate"></small>
            </div>
          </div>
        </div>
        <div *ngIf="isIncludeCategory('carSpecification')">
          <div class="dropdown-divider"></div>
          <!-- Vehicle Features -->
          <div class="row">
            <div class="col-12 text-right">
              <label class="font-weight-bold text-secondary ">مواصفات السيارة</label>
            </div>
            <div class="col-12">
              <div class="row vehicleFeaturesItems">
                <div class="col-12 col-sm-6 text-right" *ngIf="isIncludeCategory('mojazInspectionReport')">
                  <label class="text-secondary mb-0"> {{showAttributesLable('mojazInspectionReport')}}
                    <input type="checkbox"
                           [(ngModel)]="listAnItemModules.mojazInspectionReport"
                           formControlName="mojazInspectionReport" autocomplete="on">
                  </label>
                </div>
                <div class="col-12 col-sm-6 text-right" *ngIf="isIncludeCategory('driverAirbag')">
                  <label class="text-secondary mb-0">{{showAttributesLable('driverAirbag')}}
                    <input type="checkbox"
                           [(ngModel)]="listAnItemModules.driverAirbag"
                           formControlName="driverAirbag" autocomplete="on">
                  </label>
                </div>
                <div class="col-12 col-sm-6 text-right" *ngIf="isIncludeCategory('sunroof')">
                  <label class="text-secondary mb-0">{{showAttributesLable('sunroof')}}
                    <input type="checkbox"
                           [(ngModel)]="listAnItemModules.sunroof"
                           formControlName="sunroof" autocomplete="on">
                  </label>
                </div>
                <div class="col-12 col-sm-6 text-right" *ngIf="isIncludeCategory('passengerAirbag')">
                  <label class="text-secondary mb-0"> {{showAttributesLable('passengerAirbag')}}&nbsp;
                    <input type="checkbox"
                           [(ngModel)]="listAnItemModules.passengerAirbag"
                           formControlName="passengerAirbag" autocomplete="on">
                  </label>
                </div>
                <div class="col-12 col-sm-6 text-right" *ngIf="isIncludeCategory('alarm')">
                  <label class="text-secondary mb-0">{{showAttributesLable('alarm')}}&nbsp;
                    <input type="checkbox"
                           [(ngModel)]="listAnItemModules.alarm"
                           formControlName="alarm" autocomplete="on">
                  </label>
                </div>
                <div class="col-12 col-sm-6 text-right" *ngIf="isIncludeCategory('powerSteering')">
                  <label class="text-secondary mb-0">{{showAttributesLable('powerSteering')}}
                    <input type="checkbox"
                           [(ngModel)]="listAnItemModules.powerSteering"
                           formControlName="powerSteering" autocomplete="on">
                  </label>
                </div>
                <div class="col-12 col-sm-6 text-right" *ngIf="isIncludeCategory('absBrakes')">
                  <label class="text-secondary mb-0">{{showAttributesLable('absBrakes')}}
                    <input type="checkbox"
                           [(ngModel)]="listAnItemModules.absBrakes"
                           formControlName="absBrakes" autocomplete="on">
                  </label>
                </div>
                <div class="col-12 col-sm-6 text-right" *ngIf="isIncludeCategory('airConditioning')">
                  <label class="text-secondary mb-0">{{showAttributesLable('airConditioning')}}
                    <input type="checkbox"
                           [(ngModel)]="listAnItemModules.airConditioning"
                           formControlName="airConditioning" autocomplete="on">
                  </label>
                </div>
                <div class="col-12 col-sm-6 text-right" *ngIf="isIncludeCategory('centralLocking')">
                  <label class="text-secondary mb-0"> {{showAttributesLable('centralLocking')}}
                    <input type="checkbox"
                           [(ngModel)]="listAnItemModules.centralLocking"
                           formControlName="centralLocking" autocomplete="on">
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="isIncludeCategory('description')">
          <div class="dropdown-divider"></div>
          <!-- Description -->
          <div class="row">
            <div class="col-12 text-right">
              <span class="text-danger" *ngIf="isRequiredCategory('description')">*</span>
              <label class="font-weight-bold text-secondary description-displayName"
                     for="description">
                {{showAttributesLable('description')}}
              </label><br>
              <small class="text-secondary">أضف اي تفاصيل أخرى عن السياره</small>
            </div>
            <div class="col-12">
                                 <textarea class="form-control text-secondary text-right description"
                                           rows="15"
                                           id="description"
                                           [(ngModel)]="listAnItemModules.description"
                                           [maxLength]="validatorsMaxLength('description')"
                                           formControlName="description"
                                           autocomplete="on">
                </textarea>
            </div>
          </div>
          <!------Error------>
          <div class="row">
            <div class="col text-right mt-1">
              <small class="text-danger pt-3"
                     *ngIf="submitted  && !activeCategoriesForm.controls.description.valid">
                <span *ngIf="activeCategoriesForm.controls.description.errors?.required">
              {{getErrorMessage('description', 'required')}}
              </span>
                <span *ngIf="activeCategoriesForm.controls.description.errors?.pattern">
              {{getErrorMessage('description', 'required')}}
              </span>
              </small>
            </div>
          </div>
        </div>
        <!-- Next button -->
        <div class="row  mt-3">
          <div class="col text-right">
            <button type="submit" class="btn btn-primary px-5">استمر</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- blue line  -->
<div class="bg-primary mt-3 pt-2"></div>
