import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Util} from '../utils/utils';

@Injectable({
  providedIn: 'root'
})
export class NavigateService {
  constructor(
    // service use to get value from router
    private activatedroute: ActivatedRoute,
    private router: Router,
  ) {
  }

  /**
   * Generate Listing Page Url follow format : /:listingId/:listingTitle
   * @param listingId listing Id
   * @param listingTitle listing Title
   */
  public generateListingPageUrl(listingId: string, listingTitle: string): string {
    // convert string to lower case
    listingTitle = listingTitle ? listingTitle.toLowerCase(): '';
    // replace arabic blank space with "-"
    let replacedStr = listingTitle.replace(/[\u202B+\u202C+\u202A+\u0020+\ +\s!@#\$%\^\&*\)\(\<\>\?\,\.\/\\\|+=._-\s+]/g, '-');
    // replace double "-" in replacedStr
    replacedStr = replacedStr.split('--').sort().join('-');
    let str = '';
    // format string again with title has multi string like this "---"
    for (let i = 0; i < replacedStr.length; i++) {
      if (i === 0 && replacedStr[0] === '-') {
        continue;
      }
      if (i > 0 && replacedStr[i] === '-' && replacedStr[i - 1] === '-') {
        continue;
      }
      str = str + replacedStr[i];
    }
    // redirect to listing space
    const url = '/listings/' + listingId + '/' + str;
    return url;
  }

  /**
   * Navigate to Listing Page
   * @param listingId listing Id
   * @param listingTitle listing Title
   */
  public navigateListingPageUrl(listingId: string, listingTitle: string) {
    // convert string to lower case
    listingTitle = listingTitle.toLowerCase();
    // replace arabic blank space with "-"
    let replacedStr = listingTitle.replace(/[\u202B+\u202C+\u202A+\u0020+\ +\s\s+]/g, '-');
    // replace double "-" in replacedStr
    replacedStr = replacedStr.split('--').sort().join('-');
    let str = '';
    // format string again with title has multi string like this "---"
    for (let i = 0; i < replacedStr.length; i++) {
      if (i == 0 && replacedStr[0] == '-') {
        continue;
      }
      if (i > 0 && replacedStr[i] == '-' && replacedStr[i - 1] == '-') {
        continue;
      }
      str = str + replacedStr[i];
    }
    // redirect to listing space
    const url = '/listings/' + listingId + '/' + str;
    this.router.navigateByUrl(url);
  }

  /**
   * navigate to member listing page follow format : /:member_id/:username
   * @param listing url
   */
  public navigateToMemberListingPage(memberId: string, username: string) {
    if (Util.isNullOrEmpty(memberId) || Util.isNullOrEmpty(username)) {
      return;
    }
    const url = '/members/' + memberId + '/' + username;
    this.router.navigate([url]);
  }
}
