
<!-- blue line  -->
<div class="bg-primary pt-2"></div>
<!--content-->
<div class="bg-light">
  <div class="container-fluid">

    <div class="row">
      <div class="col col-lg-10  offset-lg-2 text-right">
        <h4 class="font-weight-bold text-warning">Community</h4>
        <a href="javascript:void(0);" class="text-decoration-none text-dark pb-0 h5">
          <img class="img-fluid pr-1" src="../../../assets/images/icon-images/tajr-icon-000016.png"> Site announcements
        </a>
        <hr class="my-2">
        <a href="javascript:void(0);" class="text-decoration-none text-dark pb-0 h5">
          <img class="img-fluid pr-1" src="../../../assets/images/icon-images/tajr-icon-000016.png"> Message board
        </a>
        <hr class="my-2">
        <a href="javascript:void(0);" class="text-decoration-none text-dark pb-0 h5">
          <img class="img-fluid pr-1" src="../../../assets/images/icon-images/tajr-icon-000016.png"> Newsroom
        </a>
        <hr class="my-2">
        <a href="javascript:void(0);" class="text-decoration-none text-dark pb-0 h5">
          <img class="img-fluid pr-1" src="../../../assets/images/icon-images/tajr-icon-000016.png"> Site stats
        </a>
        <hr class="my-2">
        <a href="javascript:void(0);" class="text-decoration-none text-dark pb-0 h5">
          <img class="img-fluid pr-1" src="../../../assets/images/icon-images/tajr-icon-000016.png"> Consumer advice
        </a>
        <hr class="my-2">
        <a href="javascript:void(0);" class="text-decoration-none text-dark pb-0 h5">
          <img class="img-fluid pr-1" src="../../../assets/images/icon-images/tajr-icon-000016.png"> Environment guide
        </a>
        <hr class="my-2">
      </div>
    </div>
  </div>
</div>
<!-- blue line  -->
<div class="bg-primary pt-2"></div>
