<div class="d-flex justify-content-between align-items-center ">
  <a href="javascript:void(0);"
     (click)="closeModal()">
    <img class="img-fluid" src="../../../assets/images/icon-images/tajr-icon-000042.png">
  </a>
  <h5 class="modal-title text-warning">{{confirmMess}}</h5>
</div>

<mat-dialog-title>
  <form (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col mt-4">
        <p class="text-secondary text-right">
          {{doYouWantToDeleteThisListingMess}}
        </p>
      </div>
    </div>
    <!-- confirm button -->
    <div class="row mt-5">
      <div class="col pr-1">
        <button type="submit" class="btn btn-primary btn-block">
          {{yesMess}}
        </button>
      </div>
      <div class="col pl-1">
        <button class="btn btn-primary btn-block " mat-dialog-close>{{noMess}}</button>
      </div>
    </div>
  </form>
</mat-dialog-title>

