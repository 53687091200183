import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-community',
  templateUrl: './dashboard-community.component.html',
  styleUrls: ['./dashboard-community.component.css']
})
export class DashboardCommunityComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
