<!-- blue line  -->
<div class="bg-primary mt-2 pt-2"></div>

<!-- Screen on large screen -->
<div class="container-fluid d-none d-sm-block" *ngIf="profileDetails">
  <div class="row">
    <div class="col">
      <div class="row">
        <div class="col">
          <div class="row mt-5">
            <div class="col text-right mt-3">
              <!-- 1.Selling -->
              <p class="text-warning mb-0">
                <!-- Selling -->بيع
              </p>
              <hr class="mt-2">
              <p class="mb-0">
                <span class="text-secondary">({{memberListingSummary?.selling_listings.doc_count}})</span>&nbsp;&nbsp;
                <!-- selling -->
                <a class="text-secondary" routerLink="/selling">
                  <small> الاعلانات المعروضة للبيع</small>
                </a>
              </p>
              <p class="mb-0">
                <span class="text-secondary soldNumber">({{memberListingSummary?.expired_listings.doc_count}})</span>&nbsp;
                <!-- sold -->
                <a class="text-secondary" href="javascript:void(0);" (click)="navigateToSellingPage('/selling', '1')">
                  <small> الاعلانات المنتهية</small>
                </a>
              </p>
              <p class="mb-0">
                <span class="text-secondary soldNumber">({{memberListingSummary?.withdrawn_listings.doc_count}})</span>&nbsp;
                <!-- Withdrawn listings -->
                <a class="text-secondary" href="javascript:void(0);" (click)="navigateToSellingPage('/selling', '2')">
                  <small> الاعلانات المحذوفة</small>
                </a>
              </p>
              <!-- 2.Buying -->
              <p class="text-warning mt-4 mb-0">
                <!-- Buying -->شراء
              </p>
              <hr class="mt-2">
              <p class="mb-0">
                <span class="text-secondary watchlistNumber">({{memberListingSummary?.watchlist.doc_count}})</span>&nbsp;
                <!-- watch list -->
                <a class="text-secondary" routerLink="/watchlist">
                  <small> الاعلانات المراقبة</small>

                </a>
              </p>
              <!-- 3.Tajr account-->
<!--              <p class="text-warning mt-4 mb-0">-->
<!--                &lt;!&ndash; Tajr account&ndash;&gt;رصيد تاجر-->
<!--              </p>-->
<!--              <hr class="mt-2">-->
<!--              <p class="text-secondary mb-1 d-flex justify-content-end align-items-center figure-img">-->
<!--                <button class="btn btn-primary btn-sm mr-5" type="button">-->
<!--                  <small>-->
<!--                    &lt;!&ndash; Add credit&ndash;&gt;أشحن رصيدك-->
<!--                  </small>-->
<!--                </button>&nbsp;-->
<!--                <small class="font-weight-bold text-dark"> ر.س</small>&nbsp;-->
<!--                <span class="font-weight-bold text-dark myAccountBalance">0</span>-->
<!--              </p>-->
<!--              <p class="text-primary mb-0" dir="rtl">-->
<!--                <a href="javascript:void(0);" (click)="navigateService.navigateToMemberListingPage(profileDetails.member_id, profileDetails.username)"-->
<!--                   class="text-secondary">-->
<!--                  <small>-->
<!--                    &lt;!&ndash;Tajr account statement&ndash;&gt; كشف حساب تاجر-->
<!--                  </small>-->
<!--                </a>-->
<!--              </p>-->
              <!-- 4.My Tajr-->
              <p class="text-warning mt-4 mb-0">
                <!-- My Tajr--> حسابي
              </p>
              <hr class="mt-2">
              <p class="text-secondary mb-0 ">
                <a class="font-weight-bold text-dark" routerLink="/my-profile">{{profileDetails.username}}</a>
              </p>
              <p class="text-dark mb-0" dir="rtl">
                <small>
                  <!--Member id -->معرف العضو : &nbsp;
                </small>
                <small class="font-weight-bold text-dark">{{profileDetails.member_id}}</small>
              </p>
              <p class=" mb-0">
                <small class="text-dark">{{profileDetails.mobile_number}}</small>
              </p>
              <p class="mb-0">
                <a class="text-secondary" routerLink="/change-password">
                  <small>
                    <!--Change my password--> تغيير كلمة المرور الخاصة بي
                  </small>
                </a>
              </p>
              <p class="mt-1 mb-2">
                <a class="text-secondary" routerLink="/my-photos">
                  <small>
                    <!--My photos--> صوري
                  </small>
                </a>
              </p>
              <p class="text-dark mb-0">
                <small>{{profileDetails.first_name}}</small>
                <br>
                <small>{{location}}</small>
              </p>
              <p class="mb-0">
                <a class="text-secondary" routerLink="/my-profile">
                  <small>
                    <!--Change my contact details--> تغيير معلومات التواصل
                  </small>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- blank page -->
      <div class="pt-5 pb-5"></div>
    </div>
    <div class="col-auto text-center bg-light p-0">
      <p class="text-warning py-2 h4">
        <!-- blank space make width for this column -->
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <!-- My Tajr-->حسابي
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </p>
      <p class="mb-0">
        <a href="javascript:void(0);" class="nav-link text-warning alert-secondary py-3 mb-0 h5 figure-img">
          <!--General--> عام
        </a>
      </p>
    </div>
  </div>
</div>
<!-- Screen on mobile screen -->
<div class="container-fluid d-sm-none" *ngIf="profileDetails">
  <div class="row">
    <div class="col text-right bg-light">
      <p class="text-warning py-2 h5">
        <!-- My Tajr-->حسابي
      </p>
      <p class="mb-0">
        <a href="javascript:void(0);" class="nav-link text-warning alert-secondary py-3 mb-0 h5 figure-img">
          <!--General--> عام
        </a>
      </p>
    </div>
  </div>
  <hr class="my-1">
  <div class="row">
    <div class="col">
      <div class="row">
        <div class="col text-right">
          <!-- 1.Selling -->
          <p class="text-warning mb-1">
            <!-- Selling -->بيع
          </p>
          <p class="mb-0">
            <span class="text-secondary">({{memberListingSummary?.selling_listings.doc_count}})</span>&nbsp;
            <!-- selling -->
            <a class="text-secondary" routerLink="/selling">
              <small> الاعلانات المعروضة للبيع</small>
            </a>
          </p>
          <p class="mb-0">
            <span class="text-secondary soldNumber">({{memberListingSummary?.expired_listings.doc_count}})</span>&nbsp;
            <!-- sold -->
            <a class="text-secondary" href="javascript:void(0);" (click)="navigateToSellingPage('/selling', '1')">
              <small> الاعلانات المنتهية</small>
            </a>
          </p>
          <p class="mb-0">
            <span class="text-secondary soldNumber">({{memberListingSummary?.withdrawn_listings.doc_count}})</span>&nbsp;
            <!-- Withdrawn listings -->
            <a class="text-secondary" href="javascript:void(0);" (click)="navigateToSellingPage('/selling', '2')">
              <small> الاعلانات المحذوفة</small>
            </a>
          </p>
          <!-- 2.Buying -->
          <p class="text-warning mt-4 mb-1">
            <!-- Buying -->شراء
          </p>
          <p class="mb-0">
            <span class="text-secondary watchlistNumber">({{memberListingSummary?.watchlist.doc_count}})</span>&nbsp;
            <!-- watch list -->
            <a class="text-secondary" routerLink="/watchlist">
              <small> الاعلانات المراقبة</small>

            </a>
          </p>
          <!-- 3.Tajr account-->
<!--          <p class="text-warning mt-4 mb-2">-->
<!--            &lt;!&ndash; Tajr account&ndash;&gt;رصيد تاجر-->
<!--          </p>-->
<!--          <p class="text-secondary mb-1 d-flex justify-content-end align-items-center figure-img">-->
<!--            <button class="btn btn-primary btn-sm mr-5" type="button">-->
<!--              <small>-->
<!--                &lt;!&ndash; Add credit&ndash;&gt;أشحن رصيدك-->
<!--              </small>-->
<!--            </button>&nbsp;-->
<!--            <small class="font-weight-bold text-dark"> ر.س</small>&nbsp;-->
<!--            <span class="font-weight-bold text-dark myAccountBalance">0</span>-->
<!--          </p>-->
<!--          <p class="text-primary mb-0" dir="rtl">-->
<!--            <a href="javascript:void(0);" (click)="navigateService.navigateToMemberListingPage(profileDetails.member_id, profileDetails.username)"-->
<!--               class="text-secondary">-->
<!--              <small>-->
<!--                &lt;!&ndash;Tajr account statement&ndash;&gt; كشف حساب تاجر-->
<!--              </small>-->
<!--            </a>-->
<!--          </p>-->
          <!-- 4.My Tajr-->
          <p class="text-warning mt-4 mb-1">
            <!-- My Tajr--> حسابي
          </p>
          <p class="text-secondary mb-0 ">
            <a class="font-weight-bold text-dark" routerLink="/my-profile">{{profileDetails.username}}</a>
          </p>
          <p class="text-dark mb-0" dir="rtl">
            <small>
              <!--Member id -->معرف العضو : &nbsp;
            </small>
            <br>
            <small class="font-weight-bold text-dark">{{profileDetails.member_id}}</small>
          </p>
          <p class=" mb-0">
            <small class="text-dark">{{profileDetails.mobile_number}}</small>
          </p>
          <p class="mb-0">
            <a class="text-secondary" routerLink="/change-password">
              <small>
                <!--Change my password--> تغيير كلمة المرور الخاصة بي
              </small>
            </a>
          </p>
          <p class="mt-1 mb-2">
            <a class="text-secondary" routerLink="/my-photos">
              <small>
                <!--My photos--> صوري
              </small>
            </a>
          </p>
          <p class="text-dark mb-0">
            <small>{{profileDetails.first_name}}</small>
            <br>
            <small>{{location}}</small>
          </p>
          <p class="mb-0">
            <a class="text-secondary" routerLink="/my-profile">
              <small>
                <!--Change my contact details--> تغيير معلومات التواصل
              </small>
            </a>
          </p>
        </div>
      </div>
      <!-- blank page -->
      <div class="pt-5 pb-5"></div>
    </div>
  </div>
</div>

<!-- blue line  -->
<div class="bg-primary pt-2"></div>
