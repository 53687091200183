import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AuthguardService} from './services/authguard.service';
import {PreventMemberService} from './services/prevent.member.service';

import {DashboardDefaultComponent} from './views/dashboard-default/dashboard-default.component';
import {DashboardNotfoundpageComponent} from './views/dashboard-notfoundpage/dashboard-notfoundpage.component';
import {DashboardLoginComponent} from './views/membership-area/dashboard-login/dashboard-login.component';
import {DashboardRegisterComponent} from './views/membership-area/dashboard-register/dashboard-register.component';
import {DashboardRegisterConfirmComponent} from './views/membership-area/dashboard-register-confirm/dashboard-register-confirm.component';
import {DashboardRegisterDetailsComponent} from './views/membership-area/dashboard-register-details/dashboard-register-details.component';
import {DashboardTncComponent} from './views/membership-area/dashboard-tnc/dashboard-tnc.component';
import {DashboardCommunityComponent} from './views/membership-area/dashboard-community/dashboard-community.component';
import {DashboardResetPasswordComponent} from './views/membership-area/dashboard-reset-password/dashboard-reset-password.component';
import {DashboardResetPasswordConfirmComponent} from './views/membership-area/dashboard-reset-password-confirm/dashboard-reset-password-confirm.component';
import {DashboardNewPasswordComponent} from './views/membership-area/dashboard-new-password/dashboard-new-password.component';
import {DashboardMyTajrComponent} from './views/membership-area/dashboard-my-tajr/dashboard-my-tajr.component';
import {DashboardWatchlistComponent} from './views/membership-area/dashboard-watchlist/dashboard-watchlist.component';
import {DashboardSellingComponent} from './views/membership-area/dashboard-selling/dashboard-selling.component';
import {DashboardListAnItemComponent} from './views/membership-area/dashboard-list-an-item/dashboard-list-an-item.component';
import {DashboardListAnItemPhotosComponent} from './views/membership-area/dashboard-list-an-item-photos/dashboard-list-an-item-photos.component';
import {DashboardListAnItemDetailsComponent} from './views/membership-area/dashboard-list-an-item-details/dashboard-list-an-item-details.component';
import {DashboardListAnItemConfirmComponent} from './views/membership-area/dashboard-list-an-item-confirm/dashboard-list-an-item-confirm.component';
import {DashboardListAnItemSuccessComponent} from './views/membership-area/dashboard-list-an-item-success/dashboard-list-an-item-success.component';
import {DashboardListingComponent} from './views/dashboard-listing/dashboard-listing.component';
import {DashboardSearchPageComponent} from './views/dashboard-search-page/dashboard-search-page.component'
import {DashboardMemberListingsComponent} from './views/dashboard-member-listings/dashboard-member-listings.component';
import {DashboardFavouritesComponent} from './views/membership-area/dashboard-favourites/dashboard-favourites.component';
import {DashboardProhibitedGoodsComponent} from './views/dashboard-prohibited-goods/dashboard-prohibited-goods.component';
import {DashboardContactComponent} from './views/dashboard-contact/dashboard-contact.component';
import {DashboardSettingsComponent} from './views/dashboard-settings/dashboard-settings.component';
import {DashboardNotificationComponent} from './views/membership-area/dashboard-notification/dashboard-notification.component';
import {DashboardBuyingComponent} from './views/membership-area/dashboard-buying/dashboard-buying.component';
import {DashboardMyProfileComponent} from './views/membership-area/dashboard-my-profile/dashboard-my-profile.component';
import {DashboardMyPhotosComponent} from './views/membership-area/dashboard-my-photos/dashboard-my-photos.component';
import {DashboardEditProfileComponent} from './views/membership-area/dashboard-edit-profile/dashboard-edit-profile.component';
import {DashboardChangePasswordComponent} from './views/membership-area/dashboard-change-password/dashboard-change-password.component';
import {AllCategoriesResolve} from "./server-side-rendering/resolves/all.categories.resolve";
import {CategoriesDetailsResolve} from "./server-side-rendering/resolves/categories.details.resolve";
import {LocalitiesResolve} from "./server-side-rendering/resolves/localities.resolve";
import {DashboardMotorsComponent} from "./views/dashboard-motors/dashboard-motors.component";
import {DashboardRecentlyViewedListingsComponent} from "./views/dashboard-recently-viewed-listings/dashboard-recently-viewed-listings.component";
import {ReportReasonsResolve} from "./server-side-rendering/resolves/report.reasons.resolve";
import {HomeClosingSoonResolve} from "./server-side-rendering/resolves/home.closing.soon.resolve";
import {HomeLatestResolve} from "./server-side-rendering/resolves/home.latest.resolve";
import {HomeQuickCarSearchResolve} from "./server-side-rendering/resolves/home.quick.car.search.resolve";
import { DashboardChatComponent } from './views/dashboard-chat/dashboard-chat.component';


const routes: Routes = [
  {
    path: '',
    component: DashboardDefaultComponent,
    resolve: {
      allCategoriesRs: AllCategoriesResolve,
      categoriesDetailsRs : CategoriesDetailsResolve,
      hpClosingSoonRs : HomeClosingSoonResolve,
      hpLatestRs : HomeLatestResolve,
      hpAllListingsRs : HomeQuickCarSearchResolve,
    }
  },
  {
    path: 'login',
    canActivate: [PreventMemberService],
    runGuardsAndResolvers: 'always',
    component: DashboardLoginComponent
  },
  {
    path: 'term-and-condition',
    canActivate: [PreventMemberService],
    runGuardsAndResolvers: 'always',
    component: DashboardTncComponent
  },
  {
    path: 'register',
    canActivate: [PreventMemberService],
    runGuardsAndResolvers: 'always',
    component: DashboardRegisterComponent
  },
  {
    path: 'register-confirm',
    canActivate: [PreventMemberService],
    runGuardsAndResolvers: 'always',
    component: DashboardRegisterConfirmComponent
  },
  // Step register-details don't has mobileNumber and verificationCode will redirect to first step
  {
    path: 'register-details',
    canActivate: [PreventMemberService],
    runGuardsAndResolvers: 'always',
    component: DashboardRegisterDetailsComponent,
    resolve: {
      localitiesRs: LocalitiesResolve
    }
  },
  {
    path: 'community',
    component: DashboardCommunityComponent
  },
  {
    path: 'reset-password',
    component: DashboardResetPasswordComponent
  },
  {
    path: 'change-password',
    canActivate: [AuthguardService],
    runGuardsAndResolvers: 'always',
    component: DashboardChangePasswordComponent
  },
  // Step reset-password don't has verificationCode will redirect to first step
  {
    path: 'reset-password-confirm',
    component: DashboardResetPasswordConfirmComponent
  },
  {
    path: 'new-password',
    component: DashboardNewPasswordComponent
  },
  {
    path: 'my-tajr',
    canActivate: [AuthguardService],
    runGuardsAndResolvers: 'always',
    component: DashboardMyTajrComponent
  },
  {
    path: 'watchlist',
    canActivate: [AuthguardService],
    runGuardsAndResolvers: 'always',
    component: DashboardWatchlistComponent
  },
  {
    path: 'selling',
    canActivate: [AuthguardService],
    runGuardsAndResolvers: 'always',
    component: DashboardSellingComponent
  },
  {
    path: 'list-an-item',
    canActivate: [AuthguardService],
    runGuardsAndResolvers: 'always',
    component: DashboardListAnItemComponent,
    resolve: {
      allCategoriesRs: AllCategoriesResolve,
      categoriesDetailsRs : CategoriesDetailsResolve
    }
  },
  {
    path: 'list-an-item-photos',
    canActivate: [AuthguardService],
    runGuardsAndResolvers: 'always',
    component: DashboardListAnItemPhotosComponent
  },
  {
    path: 'list-an-item-details',
    canActivate: [AuthguardService],
    runGuardsAndResolvers: 'always',
    component: DashboardListAnItemDetailsComponent,
    resolve: {
      categoriesDetailsRs : CategoriesDetailsResolve
    }
  },
  {
    path: 'list-an-item-confirm',
    canActivate: [AuthguardService],
    runGuardsAndResolvers: 'always',
    component: DashboardListAnItemConfirmComponent,
    resolve: {
      allCategoriesRs: AllCategoriesResolve,
      categoriesDetailsRs : CategoriesDetailsResolve
    }
  },
  {
    path: 'list-an-item-success',
    canActivate: [AuthguardService],
    runGuardsAndResolvers: 'always',
    component: DashboardListAnItemSuccessComponent
  },
  {
    path: 'favourites',
    canActivate: [AuthguardService],
    runGuardsAndResolvers: 'always',
    component: DashboardFavouritesComponent
  },
  {
    path: 'notification',
    canActivate: [AuthguardService],
    runGuardsAndResolvers: 'always',
    component: DashboardNotificationComponent
  },
  {
    path: 'buying',
    canActivate: [AuthguardService],
    runGuardsAndResolvers: 'always',
    component: DashboardBuyingComponent
  },
  {
    path: 'my-profile',
    canActivate: [AuthguardService],
    runGuardsAndResolvers: 'always',
    component: DashboardMyProfileComponent
  },
  {
    path: 'my-photos',
    canActivate: [AuthguardService],
    runGuardsAndResolvers: 'always',
    component: DashboardMyPhotosComponent
  },
  {
    path: 'edit-profile',
    canActivate: [AuthguardService],
    runGuardsAndResolvers: 'always',
    component: DashboardEditProfileComponent,
    resolve: {
      localitiesRs: LocalitiesResolve
    }
  },
  {
    path: 'listings/:listingId/:title',
    component: DashboardListingComponent,
    resolve: {
      allCategoriesRs: AllCategoriesResolve,
      reportReasonsRs: ReportReasonsResolve
    }
  },
  {
    path: 'motors',
    component: DashboardMotorsComponent,
    resolve: {
      allCategoriesRs: AllCategoriesResolve,
      localitiesRs: LocalitiesResolve
    }
  },
  {
    path: 'members/:memberId/:username',
    component: DashboardMemberListingsComponent,
    resolve: {
      allCategoriesRs: AllCategoriesResolve
    }
  },
  {
    path: 'prohibited-goods',
    component: DashboardProhibitedGoodsComponent
  },
  {
    path: 'contact',
    component: DashboardContactComponent
  },
  {
    path: 'settings',
    component: DashboardSettingsComponent
  },
  {
    path: 'notfound',
    component: DashboardNotfoundpageComponent
  },
  {
    path: 'search',
    component: DashboardSearchPageComponent,
    resolve: {
      allCategoriesRs: AllCategoriesResolve,
      localitiesRs: LocalitiesResolve
    }
  },
  {
    path: 'recently-viewed-listings',
    canActivate: [AuthguardService],
    runGuardsAndResolvers: 'always',
    component: DashboardRecentlyViewedListingsComponent,
    resolve: {
      allCategoriesRs: AllCategoriesResolve,
      localitiesRs: LocalitiesResolve
    }
  },
  {
    path: 'chat/new/:id',
    canActivate: [AuthguardService],
    runGuardsAndResolvers: 'always',
    component: DashboardChatComponent
  },
  {
    path: 'chat/:id',
    canActivate: [AuthguardService],
    runGuardsAndResolvers: 'always',
    component: DashboardChatComponent
  },
  {
    path: 'chat',
    canActivate: [AuthguardService],
    runGuardsAndResolvers: 'always',
    component: DashboardChatComponent
  },
  {
    path: '**',
    component: DashboardNotfoundpageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    relativeLinkResolution: 'legacy',
    scrollPositionRestoration: 'top'
})],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
