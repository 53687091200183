<!-- blue line  -->
<div class="bg-primary pt-2"></div>
<!-- Progress Menu -->
<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-lg-10 col-xl-8 offset-lg-1 offset-xl-2 ">
      <div class="row mt-3">
        <div class="col text-right p-0">
          <a href="javascript:void(0);" class="text-decoration-none text-secondary"
             [ngClass]="screenWidth >768?'':'small'" dir="rtl"> 4.
            تأكيد
            معلومات الإعلان</a>
        </div>
        <div class="col text-right p-0">
          <a href="javascript:void(0);" class="text-decoration-none text-secondary"
             [ngClass]="screenWidth >768?'':'small'" dir="rtl"> 3.
            معلومات
            الإعلان</a>
        </div>
        <div class="col text-right p-0">
          <a class="text-decoration-none text-secondary font-weight-bold"
             href="javascript:void(0);"
             [ngClass]="screenWidth >768?'':'small'" dir="rtl"> 2.
            الصور</a>
        </div>
        <div class="col text-right">
          <a class="text-decoration-none text-secondary"
             (click)="navigateToListAnItemPage('/list-an-item')"
             [ngClass]="screenWidth >768?'':'small'"
             dir="rtl">1.
            معلومات السيارة</a>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col">
          <div class="progress h-100">
            <div class="progress-bar bg-light w-50 pt-1" role="progressbar" aria-valuenow="0"
                 aria-valuemin="0" aria-valuemax="100">
            </div>
            <div class="progress-bar bg-warning w-50 pt-1" role="progressbar" aria-valuenow="0"
                 aria-valuemin="0" aria-valuemax="100">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--content-->
<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
      <!-- photos -->
      <div class="row">
        <div class="col mt-5">
          <h4 class="text-right text-warning">الصور</h4>
        </div>
      </div>
      <!-- empty photo -->
      <div class="row m-0" *ngIf="!(files.length > 0)">
        <div class="col">
          <div class="row">
            <div class="col-4 text-right d-none d-sm-block">
              <img class="img-fluid rounded" src="../../../assets/images/icon-images/tajr-icon-000033.png">
            </div>
            <div class="col">
              <div
                class="photosArea  text-secondary text-center  border border-primary rounded align-self-center py-5"
                DraganddropDirective (fileDropped)="onFileDropped($event)">
                <input type="file" #fileDropRef id="fileDropRef" class="w-100 h-100"
                       accept="image/*"
                       (change)="fileBrowseHandler($event.target.files)"/>
                <img src="../../../assets/images/item-images/tajr-item000001.png">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col d-none d-sm-block"></div>
            <div class="col-12 col-sm-8 text-center pt-2">
              <p class="text-primary mb-0">
                <!--Upload 20 photos for free-->ارفع ٢٠ صوره
              </p>
              <a href="javascript:void(0);" class="text-decoration-none text-secondary">
                <!--See our photo policy and guidelines-->شروط وأحكام رفع الصور</a>
              <br>
              <a href="javascript:void(0);" class="text-decoration-none text-secondary p-0"
                 (click)="imageModal.show()">
                <!--Use a photo you've uploaded before-->أو أستخدم صوره مرفوعة من قبل
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- photo is not empty-->
      <div class="row m-0" *ngIf="files.length > 0">
        <div class="col-4 d-none d-sm-block">
          <div class="embed-responsive embed-responsive-4by3 rounded border border-primary ">
            <div
              class="embed-responsive-item d-flex justify-content-center align-items-center bg-light"
              *ngIf="files.length === 0">
              <img class="img-fluid" src="../../../assets/images/icon-images/tajr-icon-000035.png">
              <div class="spinner-border position-absolute" role="status"></div>
            </div>

            <div
              class="embed-responsive-item d-flex justify-content-center align-items-center bg-light"
              *ngIf="files.length > 0">
              <img class="img-fluid rounded tajrImage" [ngStyle]="loadingFileStatus(0) !==true ? opacityImage: '' "
                   src="{{files[0]?.photo_urls}}">
            </div>
          </div>
        </div>
        <div class="col photosArea border border-primary rounded pt-2 pb-2">
          <!-- item photo when upload -->
          <ul class="list-unstyled mb-0 itemImages" cdkDropList (cdkDropListDropped)="drop($event)">
            <!-- list item -->
            <li class="row media border-bottom py-1 itemSelect d-flex align-items-center itemList"
                cdkDrag
                *ngFor="let file of files; let i = index">
              <div class="col-3 col-sm-2 align-self-center">
                <div class="embed-responsive embed-responsive-4by3">
                  <div class="embed-responsive-item d-flex justify-content-center align-items-center ">
                    <img class="img-fluid rounded tajrImage" [ngStyle]="loadingFileStatus(i) !==true ? opacityImage: '' "
                         src="{{file?.photo_urls}}">
                  </div>

                </div>
              </div>
              <div class="col  px-1">
                <div class="w-100 small">
                  {{ file?.file_name }}
                  <app-progress [progress]="file?.progress"></app-progress>
                </div>
              </div>
              <div class="col-auto px-1">
                <div class="d-flex align-items-center">
                  <!-- maim photo -->
                  <a href="javascript:void(0);" class="text-decoration-none"
                     *ngIf="i==0">
                    <!-- main photo -->
                    <small class="text-primary"
                           [ngClass]="loadingFileStatus(i) ===true? 'text-primary': 'text-secondary'">
                      الصورة الرئيسية
                    </small>
                  </a>
                  <!-- sub image icon -->
                  <a href="javascript:void(0);" *ngIf="i > 0 && loadingFileStatus(i) !==true">
                    <i class="material-icons text-secondary">
                      star_outline
                    </i>
                  </a>
                  <a href="javascript:void(0);" *ngIf="i > 0 && loadingFileStatus(i) ===true"
                     (click)="selectMainPhoto(i)">
                    <i class="material-icons text-primary">
                      star_outline
                    </i>
                  </a>
                  <!-- main image icon -->
                  <a href="javascript:void(0);" *ngIf="i === 0">
                    <i
                      [ngClass]="loadingFileStatus(0) ===true? 'material-icons text-primary':'material-icons text-secondary'">
                      star
                    </i>
                  </a>
                  <!-- delete icon -->
                  <a href="javascript:void(0);" (click)="deleteFile(i)">
                    <i class="material-icons text-warning">
                      delete
                    </i>
                  </a>

                </div>
              </div>
            </li>
            <!-- loading photos -->
            <li class="media border-bottom py-1">
              <div class="col p-0">
                <p class="text-primary text-right mb-0">
                  <!-- <b>Tip :</b> Click and drag photos up or down to reorder. -->
                  <b>معلومة: </b> اضغط واسحب الصور فوق أو تحت عشان تغير ترتيب الصور في
                  الإعلان.
                </p>
              </div>
            </li>
          </ul>
          <!-- add photo button -->
          <div class="row  ">
            <div class="col-12 col-sm pt-2" [ngClass]="screenWidth >575?'':'text-right'" DraganddropDirective>
              <input type="file" class="w-100 h-100" #fileDropRef
                     accept="image/*"
                     (change)="fileBrowseHandler($event.target.files)"/>
              <a href="javascript:void(0);" class="text-decoration-none text-secondary w-100">
                <img class="img-fluid pr-1" src="../../../assets/images/icon-images/tajr-icon-000034.png">
                <!--Add more photos-->أضف صور أخرى
              </a>
            </div>
            <div class="col-12 col-sm-auto text-right pt-2">
              <a href="javascript:void(0);" class="text-decoration-none text-secondary"
                 (click)="imageModal.show()">
                <!--Use a photo you've uploaded before-->أو أستخدم صوره مرفوعة من قبل
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- video -->
      <div class="row">
        <div class="col mt-5">
          <h4 class="text-right text-warning">
            <!--Video-->فيديو</h4>
        </div>
      </div>
      <div class="row m-0">
        <div class="col d-none d-sm-block"></div>
        <div class="col-12 col-sm-8 text-secondary text-center border border-primary rounded pt-5 pb-5 ">
          <div class="row pb-3">
            <div class="col ">
              <p class="text-primary mb-0">
                <!--You can add one YouTube video to your listing.-->تقدر تضيف فيديو واحد
                للإعلان
              </p>
            </div>
          </div>
          <!-- video image-->
          <div class="row" *ngIf="videoUrl !== ''">
            <div class="col-12 col-sm-6 offset-sm-3  embed-responsive embed-responsive-4by3">
              <img class="img-fluid embed-responsive-item" [src]="videoUrl">
            </div>
          </div>
          <div class="row pt-3">
            <div class="col d-flex justify-content-between align-items-center">
              <input class="form-control py-0" id="videoUrl" value="" (input)="updateVideoUrl($event)">
            </div>
          </div>
          <div class="row pt-1">
            <div class="col">
              <p class="text-primary mb-0">
                <!-- Please ensure the advertising and annotation features are disabled. -->الرجاء
                التأكد ان الأعلانات الخارجية والنصوص الكتابية غير مفعله في الفيديو
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- Next button -->
      <div class="row m-0 mt-5">
        <div class="col text-right p-0">
          <button [disabled]="isUploading" (click)="onSubmit()" class="btn btn-primary rounded border px-5">
            <!--Next -->استمر
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- blue line  -->
<div class="bg-primary mt-3 pt-2"></div>
<!-- image modal -->
<div bsModal #imageModal="bs-modal" class="modal fade" tabindex="-1"
     role="dialog" aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <!-- menu and close button on image modal-->
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <div class="d-flex justify-content-between align-items-center pt-2">
              <button type="button" class="close float-right" (click)="imageModal.hide()">
                <img class="img-fluid rounded" src="../../../assets/images/icon-images/tajr-icon-000001.png">
              </button>
              <h5 class="modal-title text-warning">ارفع ٢٠ صوره </h5>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="text-right text-primary mb-0" *ngIf="files.length > 0 && imageFilesOnModal.length > 0">
              Your upload image {{files.length}}/ 20
            </p>
          </div>
        </div>
        <hr class="m-0" *ngIf="imageFilesOnModal.length > 0">
      </div>
      <!--image area-->
      <div class="modal-body">
        <div class="container-fluid border-top pt-2" *ngIf="imageFilesOnModal.length == 0">
          <div class="row modalItem" dir="rtl">
            <div class="col-3"></div>
            <div class="col-6 embed-responsive embed-responsive-4by3">
              <img class="embed-responsive-item tajrImage"
                   src="../../../assets/images/icon-images/tajr-icon-000033.png">
            </div>
          </div>
          <div class="row modalItem" dir="rtl">
            <div class="col">
              <p class="text-primary text-center mb-0">
                <!--Upload 20 photos for free-->ارفع ٢٠ صوره
              </p>
              <p class="text-center">
                <a href="javascript:void(0);" class="text-decoration-none text-secondary">
                  <!--See our photo policy and guidelines-->شروط وأحكام رفع الصور</a>
              </p>
            </div>
          </div>
        </div>
        <!-- on xl screen -->
        <cdk-virtual-scroll-viewport itemSize="20" *ngIf="imageFilesOnModal.length > 0"
                                     (scroll)="getMoreImageOnModal()"
                                     class="container-fluid modalItem pre-scrollable w-100 vh-100 modalViewport">
          <div class="row">
            <div *ngFor="let item of imageFilesOnModal ; let i = index;"
                 class="col-6 col-sm-4 col-lg-3">
              <mat-card class="embed-responsive embed-responsive-4by3 shadow-none">
                <input type="checkbox" [ngModel]="item.checkBoxValue === 'true'"/>
                <label class="embed-responsive-item w-100 h-100  p-2 m-2 border-primary"
                       (click)="selectItemOnModal(item)">
                  <img class="img-fluid rounded border border-primary tajrImage"
                       src="{{item.photo_urls}}"
                       alt="{{item.file_name}}">
                </label>
              </mat-card>
            </div>
          </div>
        </cdk-virtual-scroll-viewport>

      </div>
    </div>
  </div>
</div>
