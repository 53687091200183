import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LanguageService} from '../../services/language.service';
import {SearchService} from '../../services/search.service';
import {HttpHelperService} from '../../services/http-helper.service';
import {DatePipe} from '@angular/common';
import {TimeService} from '../../services/time.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TabsetComponent} from 'ngx-bootstrap/tabs';
import {Subscription} from 'rxjs';
import {AccountService} from '../../services/account.service';
import {SearchModel} from '../../modules/models/search.model';
import {NavigateService} from '../../services/navigate.service';
import {ListingService} from "../../services/listing.service";
import {RecentlyViewedListingDetails} from "../../modules/models/recently.viewed.listing.details";
import {ITEM_PER_PAGE, SearchConstant} from "../../shared/global";
import {TitleService} from "../../services/title.service";

@Component({
  selector: 'app-recently-viewed-listings',
  templateUrl: './recently-viewed-listings.component.html',
  styleUrls: ['./recently-viewed-listings.component.css']
})
export class RecentlyViewedListingsComponent implements OnInit, OnDestroy {
  sourceTabLabel = this.languageService.findMessageByLanguage('myTajr');
  recentlyViewedLabel = this.languageService.findMessageByLanguage('recentlyViewed');
  loadMoreMessage = this.languageService.findMessageByLanguage('loadMore');
  loadingMess = this.languageService.findMessageByLanguage('loading');
  deletedMess = this.languageService.findMessageByLanguage('itemHasBeenDeleted');
  expiredMess = this.languageService.findMessageByLanguage('itemHasBeenExpired');

  public currentTabLabel;
  public screenWidth: number;
  public tab: number;
  private subscription: Subscription;
  public recentlyViewedListingDetails: RecentlyViewedListingDetails;
  public totalNumberItem = 0;
  public numberItemOfListingsMess = '';
  public currentNumberItem = 0;
  public loading = false;
  private getListings: SearchModel;
  public getListingFromItem = '0';
  public getListingToItem = ITEM_PER_PAGE;
  // Pagination attribute
  public currentPage = 1;
  public showBoundaryLinks = true;
  public maxSize = 5;

  constructor(
    // get language message from local
    private languageService: LanguageService,
    // service send request to login
    private searchService: SearchService,
    // contain api
    private httpHelper: HttpHelperService,
    private elementRef: ElementRef,
    // service convert date format
    private datePipe: DatePipe,
    // parse time service
    private timeService: TimeService,
    // service use to get value from router
    private activatedroute: ActivatedRoute,
    private accountService: AccountService,
    public navigateService: NavigateService,
    public listingService: ListingService,
    public titleService: TitleService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    // get width of device
    this.screenWidth = window.innerWidth;
    this.currentTabLabel = this.recentlyViewedLabel;

    // get value from url
    this.activatedroute.queryParamMap.subscribe((queryParams) => {
      if (queryParams.has(SearchConstant.PAGE)) {
        this.currentPage = Number(queryParams.get(SearchConstant.PAGE));
      }

      this.tab = 0;
      this.selectTab(this.tab);

      // get recently viewed listings
      this.getRecentlyViewedListings();
    });

  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  @ViewChild('staticTabs') staticTabs: TabsetComponent;

  /**
   * get recently viewed listings
   */
  getRecentlyViewedListings() {
    //  Get listings by search item Api
    this.getListings = new SearchModel();
    this.getListings.from = ((this.currentPage - 1) * this.getListingToItem).toString();
    this.getListings.size = this.getListingToItem.toString();

    // send request to get listing
    this.subscription = this.accountService.getRecentlyViewedListings(this.getListings).subscribe(data => {
      // if listing list available
      if (200 === data.status && 0 < data.body.listing_ids.length) {
        // send request to get listing details
        this.recentlyViewedListingDetails = data.body;
        this.totalNumberItem = Number(this.recentlyViewedListingDetails.total);
        this.currentNumberItem = ((this.currentPage - 1) * this.getListingToItem) + this.recentlyViewedListingDetails.listing_ids.length;
        this.numberItemOfListingsMess = this.languageService.wrapDir('rtl', 'عرض ') + this.languageService.wrapDir('rtl', this.currentNumberItem) + this.languageService.wrapDir('rtl', ' إعلان ');
      }
    });
  }

  /**
   * change tab when user select or redirect
   * @param tabId
   */
  selectTab(tabId: number) {
    if (this.staticTabs && this.staticTabs.tabs[tabId] !== undefined) {
      this.staticTabs.tabs[tabId].active = true;
      this.onSelect(tabId);
    }
  }

  /**
   * change currentTabLabel show on browser
   * @param tabId
   */
  onSelect(tabId: number): void {
    // tab watchlist
    if (tabId === 0) {
      this.currentTabLabel = this.recentlyViewedLabel;
    }
  }

  /**
   * load more item when change pagination
   */
  onChangepaginationSearchPage(event: any) {
    this.currentPage = event.page;
    if ((this.currentPage - 1) * this.getListingToItem == Number(this.getListings.from)) {
      return;
    }
    let urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams[SearchConstant.PAGE] = this.currentPage;

    this.router.navigateByUrl(urlTree);

  }
}
