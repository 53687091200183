<div class="bg-light">
  <div class="container-fluid py-1">
    <div class="row pt-4 mb-1">
      <div class="col">
        <div class="d-flex justify-content-between">
          <!--View all-->
          <a href="javascript:void(0);"
             (click)="navigateToSearchPage(menu.leftMenuLink, category_id)">
            {{leftMenuLabel}}
          </a>
          <a class="nav-link text-warning p-0 h5 mb-0"
             href="javascript:void(0);"
             (click)="navigateToSearchPage(menu.rightMenuLink, category_id)">
            {{rightMenuLabel}}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
