<!-- blue line  -->
<div class="bg-primary pt-2"></div>
<!--content-->
<div class="bg-light">
  <div class="container-fluid">

    <div class="row d-md-none">
      <div class="col text-right p-2 d-flex align-items-center justify-content-end">
          <span class="text-warning small">
         عدل بياناتي
          </span>
        <a class="btn btn-primary WIDTH_150 ml-2"
           routerLink="/list-an-item">
          <span class="material-icons">add</span>
          أضف إعلانك
        </a>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-10 col-md-8 col-lg-6 offset-sm-1 offset-md-2 offset-lg-3 text-right pb-5">
        <form [formGroup]="editProfileForm" (keyup)="errorMessage = ''"  (ngSubmit)="editProfileDetails()">
          <div class="row">
            <div class="col mt-5">
              <h4 class="text-right text-warning">{{editMess}}</h4>
            </div>
          </div>
          <!-- username -->
          <div class="row">
            <div class="col mt-4">
              <label for="username" class="font-weight-bold text-secondary">أسم المستخدم
              </label>
              <input id="username"
                     type="text" disabled
                     [value]="profileDetails.username"
                     class="form-control text-right bg-light">
            </div>
          </div>
          <!-- mobile number -->
          <div class="row">
            <div class="col mt-4">
              <label for="mobileNumber" class="font-weight-bold text-secondary">رقم الجوال
              </label>
              <input id="mobileNumber"
                     type="text" disabled
                     [value]="profileDetails.mobile_number"
                     class="form-control text-right bg-light">
            </div>
          </div>
          <!-- first name -->
          <div class="row">
            <div class="col mt-4">
              <span class="font-weight-bold text-danger"> * </span>
              <label for="firstName" class="font-weight-bold text-secondary">الاسم الأول
              </label>
              <input id="firstName"
                     [ngModel]='profileDetails.first_name'
                     type="text"
                     maxlength="50"
                     class="form-control text-right"
                     formControlName="first_name"
                     autocomplete="on">
            </div>
          </div>
          <!-- error first name -->
          <div class="row ">
            <div class="col position-absolute mt-1">
              <small class="text-danger pt-3"
                     *ngIf="(editProfileForm.controls.first_name.dirty || submitted)  && !editProfileForm.controls.first_name.valid">
            <span *ngIf="editProfileForm.controls.first_name.errors?.required">
              {{requiredFirstnameMessage}}</span>
                <span *ngIf="editProfileForm.controls.first_name.errors.pattern">
              {{regexFirstnameMessage}}</span>
              </small>
            </div>
          </div>
          <!-- last name -->
          <div class="row">
            <div class="col mt-4">
              <span class="font-weight-bold text-danger"> * </span>
              <label for="lastName" class="font-weight-bold text-secondary">اسم العائلة
              </label>
              <input id="lastName"
                     type="text"
                     [ngModel]='profileDetails.last_name'
                     maxlength="50"
                     class="form-control text-right"
                     formControlName="last_name"
                     autocomplete="on">
            </div>
          </div>
          <!-- error last name -->
          <div class="row ">
            <div class="col position-absolute mt-1">
              <small class="text-danger pt-3"
                     *ngIf="(editProfileForm.controls.last_name.dirty || submitted)  && !editProfileForm.controls.last_name.valid">
            <span *ngIf="editProfileForm.controls.last_name.errors?.required">
              {{requiredLastnameMessage}}</span>
                <span *ngIf="editProfileForm.controls.last_name.errors.pattern">
              {{regexLastnameMessage}}</span>
              </small>
            </div>
          </div>
          <!-- location -->
          <div class="row">
            <div class="col mt-4">
              <span class="font-weight-bold text-danger"> * </span>
              <label for="closestDistrict" class="font-weight-bold text-secondary">اقرب محافظة
              </label>
              <select dir="rtl" id="closestDistrict" class="form-control "
                      [ngModel]='selected'
                      formControlName="location"
                      autocomplete="on">
                <option *ngFor="let location of locationList"
                        [value]='location.value'>
                  {{location.localityMess}}
                </option>
              </select>
            </div>
          </div>
          <!-- error edit details response from server -->
          <div class="row ">
            <div class="col position-absolute text-center mt-1">
              <span class="text-danger">{{errorMessage}}</span>
            </div>
          </div>
          <!-- edit button -->
          <div class="row">
            <div class="col mt-5">
              <button type="submit" class="btn btn-primary btn-block" *ngIf="!loading">{{editMess}}</button>
              <button class="btn alert-primary text-white btn-block d-flex align-items-center justify-content-center "
                      *ngIf="loading" disabled>
                {{loadingMess}}
                <span class="spinner-border spinner-border-sm mx-2"></span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- blue line  -->
<div class="bg-primary pt-2"></div>
