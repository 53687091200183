export const environment = {
	production: true,
	baseUrl: 'https://api.tajr.sa',
	adminUrl: '',
	environmentName: 'DEV',
	captchaKey: '',
	enableDebug: false ,  // <-- Set [true] use to debug purposes only
	expiryDate: 30,
	version: '0.0.2'
};

export const firebase = {
	apiKey: "AIzaSyAlYhW27b6yPoLBFi1yZo8sO4f7jzNw4oU",
	authDomain: "tajr-fcm.firebaseapp.com",
	projectId: "tajr-fcm",
	storageBucket: "tajr-fcm.appspot.com",
	messagingSenderId: "855600153351",
	appId: "1:855600153351:web:dcab79fc0e5223fb71fba2",
	measurementId: "G-8Y8VZJJ20D"
}