import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountService} from '../../services/account.service';
import {HttpHelperService} from '../../services/http-helper.service';
import {LanguageService} from '../../services/language.service';
import {ClosestDistrict} from '../../modules/models/closestDistrict';
import {UserRegisterDetails} from '../../modules/models/user.register.details';
import {Subscription} from 'rxjs';
import {LOCALITIES_RESOURCE, SMOOTH, TAJR_PERSONAL_DETAILS} from '../../shared/global';
import {Locality} from '../../modules/models/locality';
import {SessionStorageService} from "../../server-side-rendering/storages/session.storage.service";
import {LocalStorageService} from "../../server-side-rendering/storages/local.storage.service";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-register-details',
  templateUrl: './register-details.component.html',
  styleUrls: ['./register-details.component.css']
})
export class RegisterDetailsComponent implements OnInit {
  // message show when valid input value
  requirePasswordMessage = this.languageService.findMessageByLanguage('requirePassword');
  regexPasswordMessage = this.languageService.findMessageByLanguage('regexPassword');
  requireConfirmPasswordMessage = this.languageService.findMessageByLanguage('requireConfirmPassword');
  regexConfirmPasswordMessage = this.languageService.findMessageByLanguage('regexConfirmPassword');
  requiredUsernameMessage = this.languageService.findMessageByLanguage('requiredUsername');
  regexUsernameMessage = this.languageService.findMessageByLanguage('regexdUsername');
  requiredFirstnameMessage = this.languageService.findMessageByLanguage('requiredFirstname');
  regexFirstnameMessage = this.languageService.findMessageByLanguage('regexFirstname');
  requiredLastnameMessage = this.languageService.findMessageByLanguage('requiredLastname');
  regexLastnameMessage = this.languageService.findMessageByLanguage('regexLastname');
  someThingWentWrongMessage = this.languageService.findMessageByLanguage('someThingWentWrong');
  placeholderText = this.languageService.findMessageByLanguage('shouldBe6OrMoreCharacters');
  loadingMess = this.languageService.findMessageByLanguage('loading');
  createYourAccountMess = this.languageService.findMessageByLanguage('createYourAccount');
  // verify code input regex
  passworsRegexPattern = /^.{6,128}$/;
  confirmPassworsRegexPattern = /^[*]*/;
  usernameRegexPattern = /^.{1,15}$/;
  firstNameRegexPattern = /^.{1,50}$/;
  lastNameRegexPattern = /^.{1,50}$/;

  // form builder to manage login modal form
  registerDetailsForm: FormGroup;
  // attribute to show loading button
  loading = false;
  // attribute to show resend code status button
  resendCodeStatus = false;
  // error message response from server
  errorMessage: string;
  // mobileNumber received from url
  mobileNumber: string;
  // verificationCode received from url
  verificationCode: string;
  // Observable request when get location details
  private subscription: Subscription;
  private subscriptionLocalities: Subscription;
  // variable show closest district list
  public closestDistrictList = '';
  // locationList contain list of localities
  public locationList: Array<ClosestDistrict> = [];
  // user register details send to server
  public userRegisterDetails: UserRegisterDetails;
  // tab localities default value
  selected: string;
  // retrieve button submitted or not
  submitted = false;
  public LocalityCatalogue: Array<Locality> = [];

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    // service use to get value from router
    private activatedRoute: ActivatedRoute,
    // manage form input
    private formBuilder: FormBuilder,
    // service send request to login
    private accountService: AccountService,
    // contain api
    private httpHelper: HttpHelperService,
    // get language message from local
    private languageService: LanguageService,
    // go to redirect to register-confirm page
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private localStorageService: LocalStorageService
  ) {
    // Form validation
    this.registerDetailsForm = this.formBuilder.group({
        // input password: default value empty, require has value.
        password: ['', Validators.compose([
          Validators.required,
          Validators.pattern(this.passworsRegexPattern)
        ])],
        // input confirm password: default value empty, require has value.
        confirmPassword: ['', Validators.compose([
          Validators.required,
          Validators.pattern(this.confirmPassworsRegexPattern)
        ])],
        // input username: default value empty, require has value.
        username: ['', Validators.compose([
          Validators.required,
          Validators.pattern(this.usernameRegexPattern)
        ])],
        // input first_name: default value empty, require has value.
        first_name: ['', Validators.compose([
          Validators.required,
          Validators.pattern(this.firstNameRegexPattern)
        ])],
        // input last_name: default value empty, require has value.
        last_name: ['', Validators.compose([
          Validators.required,
          Validators.pattern(this.lastNameRegexPattern)
        ])]
        ,  // input location: default value empty, require has value.
        location: ['', Validators.compose([])]
      },
      // check password and confirm password matching or not
      {validator: this.checkIfMatchingPasswords('password', 'confirmPassword')}
    );
  }

  /**
   * check password and confirm password matching or not
   * @param passwordKey -- password
   * @param passwordConfirmationKey -- confirm password
   */
  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      // get password and confirm password value
      let passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
      // if password is empty retuen error "required"
      if (passwordConfirmationInput.value == '') {
        return passwordConfirmationInput.setErrors({required: true});
      } else if (passwordInput.value !== passwordConfirmationInput.value) {
        // if password and confirm password is not matching return error "notEquivalent"
        return passwordConfirmationInput.setErrors({notEquivalent: true});
      } else {
        // other case set error null
        return passwordConfirmationInput.setErrors(null);
      }
    };
  }

  ngOnInit() {
    // get value from url
    this.activatedRoute.queryParamMap.subscribe((queryParams) => {
      if (queryParams.has('mobileNumber') && queryParams.has('verificationCode')) {
        this.mobileNumber = queryParams.get('mobileNumber');
        this.verificationCode = queryParams.get('verificationCode');
      } else {
        this.router.navigate(['/register']);
      }
    });

    // Localities - Retrieve all localities.
    this.LocalityCatalogue = this.activatedRoute.snapshot.data[LOCALITIES_RESOURCE];
    // loop and get localities information
    this.closestDistrictList = '';
    // this.LocalityCatalogue.forEach(locality => {
    // set value for location list
    this.LocalityCatalogue.forEach(locality => {
      locality.districts.forEach(district => {
        // import locality detail.
        const location = new ClosestDistrict();
        location.localityId = locality.locality_id;
        location.name = locality.name;
        location.districts = district;
        location.localityMess = this.languageService.messageFromServer(locality.name) + '-' + this.languageService.messageFromServer(district.name);
        location.value = locality.locality_id + '-' + district.district_id;
        // If user already update details, will be user location id.Unless it will be the first item.
        const userLocalityDefaultId = '1';
        const userDistrictIdDefaultId = '1';
        if (userLocalityDefaultId == locality.locality_id && userDistrictIdDefaultId == district.district_id) {
          this.selected = locality.locality_id + '-' + district.district_id;
        }
        // push locality detail to location list
        this.locationList.push(location);
      });
    });
  }

  /**
   * Send request to Update mobile number status and create a member.
   * If update success will send request to get user login information from server and goto home page.
   */
  registerDetails() {
    this.submitted = true;
    // reset previous error message
    this.errorMessage = '';
    // If registerDetailsForm input is valid
    if (this.registerDetailsForm.valid) {
      // change loading status to show loading when sending request
      this.loading = true;
      // import register detail information, item position follow constructor of UserRegisterDetails
      this.userRegisterDetails = new UserRegisterDetails(
        this.mobileNumber,
        this.verificationCode,
        this.registerDetailsForm.value.password,
        this.registerDetailsForm.value.username,
        this.registerDetailsForm.value.first_name,
        this.registerDetailsForm.value.last_name,
        this.registerDetailsForm.value.location.split('-', 2)[0],
        this.registerDetailsForm.value.location.split('-', 2)[1]
      );
      // Send request to Update mobile number status and create a member.
      this.accountService.registerDetails(this.userRegisterDetails).subscribe(detailsValidation => {
        // if user register details success
        if (detailsValidation.status === 200) {
          // If update success will send request to get user login information from server
          this.subscription = this.accountService.getUserDetail().subscribe(currentUserDetails => {
            // if user has information response
            if (200 == currentUserDetails.status && currentUserDetails.body) {
              // save value for cookie storage
              this.sessionStorageService.set(TAJR_PERSONAL_DETAILS, JSON.stringify(currentUserDetails.body));
              // register details success goto home page
              this.router.navigate(['/']);
              // close loading status on button
              this.loading = false;
            }
          });
          // scroll to top to see error response from serve
          if (isPlatformBrowser(this.platformId)) {
            window.scroll({top: 0, behavior: SMOOTH});
          }
          // close loading status on button
          this.loading = false;
        }
      }, err => {
        // User login fail, close loading status on button
        this.loading = false;
        // get error message response from server
        if (err && err.error) {
          // show error
          this.errorMessage = this.languageService.messageFromServer(err.error.message);
        } else {
          // service error message
          this.errorMessage = this.someThingWentWrongMessage;
        }
      });
    }
  }
}
