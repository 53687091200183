import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {HttpHelperService} from 'src/app/services/http-helper.service';
import {AccountService} from '../../services/account.service';
import {LanguageService} from '../../services/language.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.css']
})
export class NewPasswordComponent implements OnInit {
  // message show when valid input value
  requirePasswordMessage = this.languageService.findMessageByLanguage('requirePassword');
  regexPasswordMessage = this.languageService.findMessageByLanguage('regexPassword');
  requireConfirmPasswordMessage = this.languageService.findMessageByLanguage('requireConfirmPassword');
  regexConfirmPasswordMessage = this.languageService.findMessageByLanguage('regexConfirmPassword');
  placeholderText = this.languageService.findMessageByLanguage('shouldBe6OrMoreCharacters');
  someThingWentWrongMessage = this.languageService.findMessageByLanguage('someThingWentWrong');
  loadingMess = this.languageService.findMessageByLanguage('loading');
  saveChangesMess = this.languageService.findMessageByLanguage('saveChanges');
  // verify code input regex
  passworsRegexPattern = /^.{6,128}$/;
  confirmPassworsRegexPattern = /^[*]*/;
  // form builder to manage login modal form
  newPasswordForm: FormGroup;
  // attribute to show loading button
  loading = false;
  // error message response from server
  errorMessage: string;
  // mobileNumber received from url
  mobileNumber: string;
  // verificationCode received from url
  verificationCode: string;
  // retrieve button submitted or not
  submitted = false;

  constructor(
    // service use to get value from router
    private activatedroute: ActivatedRoute,
    // manage form input
    private formBuilder: FormBuilder,
    // service send request to login
    private accountService: AccountService,
    // contain api
    private httpHelper: HttpHelperService,
    // get language message from local
    private languageService: LanguageService,
    // go to redirect to register-confirm page
    private router: Router,
    // show toast notification
    private toastr: ToastrService
  ) {
    // Form validation
    this.newPasswordForm = this.formBuilder.group({
        // input password: default value empty, require has value.
        password: ['', Validators.compose([
          Validators.required,
          Validators.pattern(this.passworsRegexPattern)
        ])],
        // input confirm password: default value empty, require has value.
        confirmPassword: ['', Validators.compose([
          Validators.required,
          Validators.pattern(this.confirmPassworsRegexPattern)
        ])],
      },
      // check password and confirm password matching or not
      {validator: this.checkIfMatchingPasswords('password', 'confirmPassword')}
    );
  }

  ngOnInit() {
    // get mobileNumber and verificationCode value from url
    this.activatedroute.queryParamMap.subscribe((queryParams) => {
      if (queryParams.has('mobileNumber') && queryParams.has('verificationCode')) {
        this.mobileNumber = queryParams.get('mobileNumber');
        this.verificationCode = queryParams.get('verificationCode');
      } else {
        // this.router.navigate(['/reset-password']);
      }
    });
  }

  /**
   * check password and confirm password matching or not
   * @param passwordKey -- password
   * @param passwordConfirmationKey -- confirm password
   */
  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      // get password and confirm password value
      let passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
      // if password is empty retuen error "required"
      if (passwordConfirmationInput.value == '') {
        return passwordConfirmationInput.setErrors({required: true});
      } else if (passwordInput.value !== passwordConfirmationInput.value) {
        // if password and confirm password is not matching return error "notEquivalent"
        return passwordConfirmationInput.setErrors({notEquivalent: true});
      } else {
        // other case set error null
        return passwordConfirmationInput.setErrors(null);
      }
    };
  }

  newPassword() {
    this.submitted = true;
    // reset previous error message
    this.errorMessage = '';
    // If newPasswordForm input is valid
    if (this.newPasswordForm.valid) {
      // change loading status to show loading when sending request
      this.loading = true;
      // Send request to Update mobile number status and create a member.
      this.accountService.newPassword(this.mobileNumber, this.verificationCode, this.newPasswordForm.value.password).subscribe(newPasswordValidation => {
        // if user register details success
        if (newPasswordValidation.status === 200) {
          // change new password success goto login
          this.router.navigate(['/login']);
          // close loading status on button
          this.loading = false;
        }
      }, err => {
        // User login fail, close loading status on button
        this.loading = false;
        // get error message response from server
        if (err && err.error) {
          // show error
          this.errorMessage = this.languageService.messageFromServer(err.error.message);
        } else {
          // service error message
          this.errorMessage = this.someThingWentWrongMessage;
        }
      });
    }
  }
}
