import {Component, ElementRef, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {FormBuilder} from '@angular/forms';
import {LanguageService} from '../../services/language.service';
import {ProductService} from '../../services/product.service';
import {DatePipe, DOCUMENT} from '@angular/common';
import {TimeService} from '../../services/time.service';
import {
  FIRST_ITEM,
  ItemAttributes, ListingStatus,
  NOT_FOUND_PAGE, REPORT_REASONS_RESOURCE, Social, TAJR_ALL_CATEGORIES_RESOURCE,
  TAJR_PERSONAL_DETAILS, YOUTUBE_FILE_TYPE, YOUTUBE_URL,
  MAX_CATEGORY_LEVEL
} from '../../shared/global';
import {AllMotorsCategories} from '../../modules/models/all.motors.categories';
import {CategoryDetails} from '../../modules/models/category.details';
import {DomSanitizer} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';
import {MatDialog} from '@angular/material/dialog';
import {ListingItemsCarouselModalComponent} from '../modals/listing-items-carousel-modal/listing-items-carousel-modal.component';
import {ListingService} from '../../services/listing.service';
import {Util} from '../../utils/utils';
import {ConfirmDeleteListingModalComponent} from '../modals/confirm-delete-listing-modal/confirm-delete-listing-modal.component';
import {AccountService} from '../../services/account.service';
import {NotificationServices} from '../../services/notification.services';
import {ProfileDetails} from '../../modules/models/profile.details';
import {LoginModalComponent} from '../modals/login-modal/login-modal.component';
import {ListingDetails} from '../../modules/models/listingDetails';
import {TajrAccount} from '../../modules/models/tajr.account';
import {TitleService} from '../../services/title.service';
import {NavigateService} from '../../services/navigate.service';
import {SessionStorageService} from '../../server-side-rendering/storages/session.storage.service';
import {LocalStorageService} from '../../server-side-rendering/storages/local.storage.service';
import {CategoryService} from "../../services/category.service";
import {ListingReportModalComponent} from '../modals/listing-report-modal/listing-report-modal.component';
import {ReportReasons} from '../../modules/models/report.reasons';

@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.css'],
  providers: [DatePipe]
})
export class ListingComponent implements OnInit, OnDestroy {
  watchListMessage = this.languageService.findMessageByLanguage('watchListMessage');
  deletedMess = this.languageService.findMessageByLanguage('itemHasBeenDeleted');
  expiredMess = this.languageService.findMessageByLanguage('itemHasBeenExpired');
  itemAddedToWatchlistMess = this.languageService.findMessageByLanguage('itemAddedToWatchlist');

  public listingId = '';
  public listingTitle = '';
  public categoryIdSlitStr = '';
  public submitted = false;
  // width of screen size on browser
  public screenWidth: number;
  public activeSlideIndex = 0;
  public itemsCarouselIndicatorsPerSlide = 6;
  public errorMessage = '';
  // attribute to show loading button
  public loading = false;
  public isAddedToWatchList = false;
  public tajrAllCategories;
  public photoList = [];
  public emptyPhotoSpaceList = [];
  public videoList = [];
  public userWatchList: Array<string> = [];
  public allMotorsCategories: AllMotorsCategories;
  public categoryDetailsById: CategoryDetails;
  //  subscription to observable request get member's listings
  public listingDetailsRequest: Subscription;
  public listingDetails: ListingDetails;
  public categoryDetailsByIDSubscription: Subscription;
  public watchListSubscription: Subscription;
  public startTouchPosition: number;
  public hignlightEndDate = false;
  public userDetails: ProfileDetails;
  public tajrAccount: TajrAccount;
  public navigation = true;
  public slidesPerView = 6;
  public spaceBetween = 10;
  public mainCarouselListItem;
  public reportReasons: Array<ReportReasons>;
  public socialTitle = '';
  public testUrl = '';
  public testUrl2 = '';
  private originUrl = '';
  public twitterUrl = '';
  public whatsappUrl = '';

  constructor(
    // service use to get value from router
    private activatedroute: ActivatedRoute,
    // get language message from local
    private languageService: LanguageService,
    // service send request to login
    private productService: ProductService,
    private elementRef: ElementRef,
    // Router service use to get value from url
    private route: ActivatedRoute,
    private router: Router,
    // service convert date format
    private datePipe: DatePipe,
    // parse time service
    private timeService: TimeService,
    // manage form input
    private formBuilder: FormBuilder,
    // service use to sanitize the youtube URL
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    public listingService: ListingService,
    private categoryService: CategoryService,
    public accountService: AccountService,
    private notify: NotificationServices,
    private titleService: TitleService,
    public navigateService: NavigateService,
    private sessionStorageService: SessionStorageService,
    private localStorageService: LocalStorageService,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  ngOnInit() {
    // get width of device
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.itemsCarouselIndicatorsPerSlide = 4;
    }
    this.originUrl = this.document.location.origin + this.router.url;
    // get value from url
    this.activatedroute.paramMap.subscribe((params) => {
      if (!params.has(ItemAttributes.LISTING_ID) || !params.has(ItemAttributes.TITLE)) {
        // when get listing detaile has error will be redirect to not found page
        this.router.navigate([NOT_FOUND_PAGE]);
      }

      this.listingId = params.get(ItemAttributes.LISTING_ID);
      this.listingTitle = this.languageService.wrapDir('rtl', params.get(ItemAttributes.TITLE) ? params.get(ItemAttributes.TITLE).replace(/-/g,' ') : '');
      this.socialTitle = this.listingTitle ? this.listingTitle : this.titleService.defaultTitle;
      this.socialTitle = this.socialTitle + ' \n' + this.originUrl + '?share=twt&ref=web';
      this.twitterUrl = Social.TWITTER_SHARE_LINK.replace('{text}', encodeURIComponent(this.socialTitle))
      this.twitterUrl = this.titleService.generateArabicLable(this.twitterUrl)
      
      this.socialTitle = this.listingTitle ? this.listingTitle : this.titleService.defaultTitle;
      this.socialTitle = this.socialTitle + ' \n' + this.originUrl + '?share=wht&ref=web';
      this.whatsappUrl = Social.WHATSAPP_SHARE_LINK.replace('{text}', encodeURIComponent(this.socialTitle))
      this.whatsappUrl = this.titleService.generateArabicLable(this.whatsappUrl)
    });

    // receive value from data transfer Api
    this.tajrAllCategories = this.activatedRoute.snapshot.data[TAJR_ALL_CATEGORIES_RESOURCE];
    this.allMotorsCategories = this.tajrAllCategories[FIRST_ITEM].subcategories[FIRST_ITEM];
    this.titleService.setCategories(this.tajrAllCategories);

    // report reasons
    this.reportReasons = this.activatedRoute.snapshot.data[REPORT_REASONS_RESOURCE];

    // send to get listing details by id
    this.listingDetailsRequest = this.productService.getListingDetails(this.listingId).subscribe(data => {
      if (200 === data.status && data.body && Util.isNotNullOrEmpty(data.body.listing_id)) {
        this.listingDetails = data.body;
        this.titleService.setListingItemPageSeoTab(this.listingDetails);
        // check item exits in user watchlist this.listingDetails
        if (Util.isNotNullOrEmpty(this.listingDetails) && Util.isNotNullOrEmpty(this.listingDetails.watchlist_id)) {
          this.isAddedToWatchList = true;
        }
        this.categoryIdSlitStr = this.listingDetails.category_id.split('-')[2];
        // check local storage available all Motors Categories or not
        if (this.categoryIdSlitStr) {
          // send request to get listing
          this.categoryDetailsByIDSubscription = this.productService.getCategoryDetailsByID(this.categoryIdSlitStr).subscribe(details => {
            // if user has information response
            if (details) {
              // check local storage available Categories details or not
              this.categoryDetailsById = details;
            }
          });
        }
        // show photo and video
        this.showPhotosAndVideo();
      }
    }, err => {
      // when get listing detaile has error will be redirect to not found page
      this.router.navigate([NOT_FOUND_PAGE]);
    });

  }

  ngOnDestroy(): void {
    if (this.listingDetailsRequest) {
      this.listingDetailsRequest.unsubscribe();
    }
  }

  /**
   * check listing status: active, expired, withdraw
   */
  checkListingStatus(): string {
    let status = ListingStatus.ACTIVE;
    if (Util.isNullOrEmpty(this.listingDetails)) {
      return status;
    }
    if (Util.isNotNullOrEmpty(this.listingDetails.dd)) {
      status = ListingStatus.DELETED;
    } else if (Util.isNotNullOrEmpty(this.listingDetails.dates) && Util.isNotNullOrEmpty(this.listingDetails.dates.find(date => date.date_attribute_id === 'endDate'))) {
      const current = Date.now();
      const endDate = Date.parse(this.listingDetails.dates.find(item => item.date_attribute_id === 'endDate').value[0]);
      const difference = this.timeService.convertMS(endDate - current);

      if (difference.day >= 0) {
        status = ListingStatus.ACTIVE;
      }
      if (difference.day < 0) {
        status = ListingStatus.EXPIRED;
      }
    }

    return status;
  }

  /**
   * use HostListener to  updated on resize:
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  getScreenSize(event) {
    this.screenWidth = window.innerWidth;
  }

  /**
   * even on carousel item
   * @param swiper swiper event
   */
  showNavigation(swiper) {
    if (swiper.isEnd) {
      this.navigation = false;
    } else {
      this.stopVideos();
      this.navigation = true;
    }
  }

  /**
   * return show navigation attribute
   */
  getNavigation(): boolean {
    return this.navigation;
  }

  /**
   * return slide number item show on slide
   */
  getSlidesPerView(): number {
    let result = this.slidesPerView;
    if (0 == this.videoList.length) {
      result++;
    }

    if (this.screenWidth < 768) {
      result--;
    }

    if (this.screenWidth < 575) {
      result--;
    }
    return result;
  }

  /**
   * even on carousel item when init swiper
   * @param swiper swiper event
   */
  onSwiper(swiper) {
    this.mainCarouselListItem = swiper;
    this.activeSlideIndex = swiper.realIndex;
    this.showNavigation(swiper);
  }

  /**
   * even on carousel item when change slide
   * @param swiper swiper event
   */
  onSlideChange(swiper) {
    this.activeSlideIndex = swiper.realIndex;
    this.showNavigation(swiper);
  }


  /**
   * show attribute label follow language response from server
   * @param attributesId attributesId ex: model,modelDetail...
   * @param dataType dataType of attributes in strings,numbers,bool, dates
   */
  showAttributesLable(attributesId: string, dataType: string) {
    let attributesLable = '';
    let attribute;
    // list dataType follow design document and api response
    const listDataType = ['strings', 'dates', 'bool', 'numbers'];
    // return if don't has value
    if (!this.listingDetails || !this.listingDetails[dataType] || !listDataType.includes(dataType)) {
      return;
    }

    switch (dataType) {
      case 'strings':
        attribute = this.listingDetails.strings?.find(item => item.string_attribute_id === attributesId);
        if (Util.isNotNullOrEmpty(attribute) && Util.isNotNullOrEmpty(attribute.display_name)) {
          attributesLable = this.languageService.messageFromServer(attribute.display_name);
        }
        break;
      case 'dates':
        attribute = this.listingDetails.dates?.find(item => item.date_attribute_id === attributesId);
        if (Util.isNotNullOrEmpty(attribute) && Util.isNotNullOrEmpty(attribute.display_name)) {
          attributesLable = this.languageService.messageFromServer(attribute.display_name);
        }
        break;
      case 'bool':
        attribute = this.listingDetails.bool?.find(item => item.bool_attribute_id === attributesId);
        if (Util.isNotNullOrEmpty(attribute) && Util.isNotNullOrEmpty(attribute.display_name)) {
          attributesLable = this.languageService.messageFromServer(attribute.display_name);
        }
        break;
      case 'numbers':
        attribute = this.listingDetails.numbers?.find(item => item.number_attribute_id === attributesId);
        if (Util.isNotNullOrEmpty(attribute) && Util.isNotNullOrEmpty(attribute.display_name)) {
          attributesLable = this.languageService.messageFromServer(attribute.display_name);
        }
        break;
      default:
        break;
    }
    if (undefined === attributesLable) {
      attributesLable = '';
    }
    return attributesLable;
  }

  /**
   * show attribute value follow language response from server
   * @param attributesId attributesId ex: model,modelDetail...
   * @param dataType dataType of attributes in strings,numbers,bool, dates
   */
  showAttributesValue(attributesId: string, dataType: string): string {
    let attributesValue = '';
    let attribute;
    // list dataType follow design document and api response
    const listDataType = ['strings', 'dates', 'bool', 'numbers'];
    // return if don't has value
    if (!this.listingDetails || !this.listingDetails[dataType] || !listDataType.includes(dataType)) {
      return;
    }

    switch (dataType) {
      case 'strings':
        attribute = this.listingDetails.strings.find(item => item.string_attribute_id === attributesId);
        if (Util.isNotNullOrEmpty(attribute)) {
          if (Util.isNullOrEmpty(attribute.value) && Util.isNotNullOrEmpty(attribute.option_display)) {
            attributesValue = this.languageService.messageFromServer(attribute.option_display);
          } else {
            attributesValue = attribute.value[0];
          }
        }
        break;
      case 'dates':
        attribute = this.listingDetails.dates.find(item => item.date_attribute_id === attributesId);
        if (Util.isNotNullOrEmpty(attribute)) {
          attributesValue = attribute.value[0];
          if (0 < attributesValue.length) {
            attributesValue = attributesValue; // this.datePipe.transform(attributesValue, 'yyyy-MM-dd');
          } else {
            attributesValue = '';
          }
        }
        break;
      case 'bool':
        attribute = this.listingDetails.bool.find(item => item.bool_attribute_id === attributesId);
        if (Util.isNotNullOrEmpty(attribute)) {
          attributesValue = attribute.value;
          if ('true' === attributesValue) {
            attributesValue = '✓';
          } else {
            attributesValue = 'x';
          }
        }
        break;
      case 'numbers':
        attribute = this.listingDetails.numbers.find(item => item.number_attribute_id === attributesId);
        if (Util.isNotNullOrEmpty(attribute)) {
          if (ItemAttributes.ASKING_PRICE === attributesId && 0 < attributesValue.length) {
            attributesValue = this.languageService.wrapDir('rtl',
              new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }).format(parseFloat(attributesValue)));
          } else if (attribute.value && 0 < attribute.value.length) {
            attributesValue = attribute.value[0];
          } else if (attribute.option_display) {
            attributesValue = this.languageService.messageFromServer(attribute.option_display);
          } else {
            attributesValue = '';
          }
        }
        break;
      default:
        break;
    }

    if (undefined === attributesValue) {
      attributesValue = '';
    }
    return attributesValue;
  }

  /**
   * show categoriesBreadcrumb on browser
   */
  showCategoriesBreadcrumb(): Array<string> {
    const categoriesBreadcrumb = [];
    if (Util.isNotNullOrEmpty(this.tajrAllCategories) && this.listingDetails) {
      const categories = this.categoryService.initCategoriesFromData(this.tajrAllCategories, MAX_CATEGORY_LEVEL, this.listingDetails.category_id)
      for (let i = 0; i < MAX_CATEGORY_LEVEL; i++) {
        let breadCrumbText = i ? ' > ' : '';
        categoriesBreadcrumb.push({
          router: '/',
          label: this.languageService.messageFromServer(categories.getCategoryBylevel(i+1).name)
        })
      }
    }
    return categoriesBreadcrumb;
  }

  /**
   * display create item localtion
   */
  showLocation() {
    let locality = '';
    let district = '';
    let location: string;
    if (this.listingDetails && this.listingDetails.member && this.listingDetails.member.locality.name && this.listingDetails.member.district.name) {
      district = this.languageService.messageFromServer(this.listingDetails.member.district.name);
      locality = this.languageService.messageFromServer(this.listingDetails.member.locality.name);
    }

    location = district + ' , ' + locality;
    return location;
  }

  /**
   * endDate of item
   */
  showStartDateText(): string {
    // startDate string from server response
    const startDateStr = this.showAttributesValue('startDate', 'dates');
    // return if don't has value
    if (startDateStr && 0 === startDateStr.length) {
      return;
    }
    // startDate text show on template
    let startDateText: string;
    // parse startDate
    const startDate = Date.parse(startDateStr);
    //  Date.now() returns the number of milliseconds elapsed since January 1, 1970 00:00:00 UTC
    const current = Date.now();
    // the time zone difference, in minutes, from current locale (host system settings) to UTC
    const difference = this.timeService.convertMS(current - startDate);

    switch (difference.day) {
      case 0:
        this.hignlightEndDate = true;
        startDateText = this.languageService.wrapDir('rtl', 'الإعلان نزل اليوم');
        break;
      case 1:
        this.hignlightEndDate = true;
        startDateText = this.languageService.wrapDir('rtl', 'الإعلان نزل أمس');
        break;
      case 2:
        startDateText = this.languageService.wrapDir('rtl', 'الإعلان نزل قبل يومين');
        break;
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
        startDateText = this.languageService.wrapDir('rtl', 'الإعلان نزل قبل ') + this.languageService.wrapDir('rtl', difference.day) + this.languageService.wrapDir('rtl', ' أيام');
        break;
      default:
        startDateText = this.languageService.wrapDir('rtl', 'الإعلان نزل قبل ') + this.languageService.wrapDir('rtl', difference.day) + this.languageService.wrapDir('rtl', ' يوم');
        break;
    }
    return startDateText;
  }

  showDateCreate(): string {
    // dateCreate from server response
    const dateCreateStr = this.listingDetails.member.member_since;
    // return if don't has value
    if (dateCreateStr && 0 === dateCreateStr.length) {
      return;
    }
    // endDate text show on template
    let dateCreateText: string;
    // let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }; // all localised stuff
    const memberSinceOptions = {
      year: 'numeric',
      month: 'long'
    }; // we only need year and month
    const memberSince = new Date(dateCreateStr);
    memberSince.setUTCHours(memberSince.getUTCHours());
    dateCreateText = memberSince.toLocaleDateString('ar-EG', memberSinceOptions);
    return dateCreateText;
  }

  /**
   * display Vehicle Details attributes
   */
  vehicleDetailsList() {
    let list = [];
    for (let i = 0; i < this.listingDetails.strings?.length; i++) {
      if ('Vehicle Details' === this.listingDetails.strings[i].group_name && this.showAttributesValue(this.listingDetails.strings[i].string_attribute_id, 'strings') !== '' && 'description' !== this.listingDetails.strings[i].string_attribute_id) {
        let item = {};
        item['attributeIdLabel'] = this.languageService.messageFromServer(this.listingDetails.strings[i].display_name);
        item['attributeIdValue'] = this.showAttributesValue(this.listingDetails.strings[i].string_attribute_id, 'strings');
        list.push(item);
      }
    }

    for (let i = 0; i < this.listingDetails.numbers?.length; i++) {
      if ('Vehicle Details' === this.listingDetails.numbers[i].group_name) {
        let item = {};
        item['attributeIdLabel'] = this.languageService.messageFromServer(this.listingDetails.numbers[i].display_name);
        item['attributeIdValue'] = this.showAttributesValue(this.listingDetails.numbers[i].number_attribute_id, 'numbers');
        list.push(item);
      }
    }

    for (let i = 0; i < this.listingDetails.bool?.length; i++) {
      if ('Vehicle Details' === this.listingDetails.bool[i].group_name) {
        let item = {};
        item['attributeIdLabel'] = this.languageService.messageFromServer(this.listingDetails.bool[i].display_name);
        item['attributeIdValue'] = this.showAttributesValue(this.listingDetails.bool[i].bool_attribute_id, 'bool');
        list.push(item);
      }
    }

    for (let i = 0; i < this.listingDetails.dates?.length; i++) {
      if ('Vehicle Details' === this.listingDetails.dates[i].group_name) {
        let item = {};
        item['attributeIdLabel'] = this.languageService.messageFromServer(this.listingDetails.dates[i].display_name);
        item['attributeIdValue'] = this.showAttributesValue(this.listingDetails.dates[i].date_attribute_id, 'dates');
        list.push(item);
      }
    }

    return list;
  }

  /**
   * display Vehicle Features attributes
   */
  vehicleFeaturesList() {
    let list = [];
    if (this.listingDetails && this.listingDetails.bool && 0 < this.listingDetails.bool.length) {
      for (let i = 0; i < this.listingDetails.bool.length; i++) {
        if ('Vehicle Features' === this.listingDetails.bool[i].group_name && this.showAttributesValue(this.listingDetails.bool[i].bool_attribute_id, 'bool') === '✓') {
          let item = {};
          item['attributeIdLabel'] = this.listingDetails.bool[i].bool_attribute_id;
          item['attributeIdValue'] = this.showAttributesLable(this.listingDetails.bool[i].bool_attribute_id, 'bool');
          list.push(item);
        }
      }
    }
    return list;
  }

  /**
   * display photos and video
   */
  showPhotosAndVideo() {
    this.photoList = [];
    this.videoList = [];
    this.emptyPhotoSpaceList = [];
    // number carousel will be added in carousel indicator per slide
    let numberCarouselWillBeAddedInCarouselIndicatorPerSlide = 0;
    // get photos
    if (this.listingDetails && this.listingDetails.photos) {
      Object.keys(this.listingDetails.photos).forEach(key => {
        let item = {};
        let title = this.titleService.generateArabicLable(this.showAttributesValue('title', 'strings'));
        item['id'] = this.listingDetails.photos[key];
        item['photoListing'] = environment.baseUrl + '/v1/photos/' + this.listingDetails.photos[key] + '/sizes/800x600/' + title;
        item['photoCarousel'] = environment.baseUrl + '/v1/photos/' + this.listingDetails.photos[key] + '/sizes/800x600/' + title;
        item['photoIndicator'] = environment.baseUrl + '/v1/photos/' + this.listingDetails.photos[key] + '/sizes/160x120/' + title;
        item['imageTitle'] = title;
        this.photoList.push(item);
      });
    }

    // get video
    if (this.listingDetails && this.listingDetails.embedded_content_options) {
      for (let i = 0; i < this.listingDetails.embedded_content_options.length; i++) {
        if (this.listingDetails.embedded_content_options[i].id) {
          let item = {};
          item['id'] = this.listingDetails.embedded_content_options[i].id;
          item['thumbnail'] = YOUTUBE_URL + this.listingDetails.embedded_content_options[i].value[i] + YOUTUBE_FILE_TYPE;
          let videoUrl = 'https://www.youtube.com/embed/' + this.listingDetails.embedded_content_options[i].value[i];
          item['videoUrl'] = this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);
          this.videoList.push(item);
        }
      }
    }

    // count space in photo area
    if (0 < this.photoList.length) {
      numberCarouselWillBeAddedInCarouselIndicatorPerSlide = this.itemsCarouselIndicatorsPerSlide - (this.photoList.length % this.itemsCarouselIndicatorsPerSlide);
    }
    // add empty carousel in emptyPhotoSpaceList
    if (0 < this.photoList.length && 0 < numberCarouselWillBeAddedInCarouselIndicatorPerSlide && this.itemsCarouselIndicatorsPerSlide > numberCarouselWillBeAddedInCarouselIndicatorPerSlide) {
      for (let i = 0; i < numberCarouselWillBeAddedInCarouselIndicatorPerSlide; i++) {
        this.emptyPhotoSpaceList.push(i);
      }
    }
  }

  /**
   * Move slide in main carousel  in main make it show slide in sub carousel
   * @param index index of slide want to show
   */
  moveSlideOnMainCarouselToShowPhotos(index: number) {
    this.stopVideos();
    // show photos carousel indicator
    this.mainCarouselListItem.slideTo(index);
  }

  /**
   * Move slide in main carousel to last slide
   * @param index index of slide want to show
   */
  moveToLastSlide() {
    this.mainCarouselListItem.slideTo(this.mainCarouselListItem.slides.length);
  }

  /**
   * stop video when user move slide from video to photo on carousel
   */
  stopVideos() {
    const videos = document.querySelectorAll('iframe, video');
    if (videos) {
      Array.prototype.forEach.call(videos, function(video) {
        if (video.tagName.toLowerCase() === 'video') {
          video.pause();
        } else {
          const src = video.src;
          video.src = src;
        }
      });
    }
  }

  /**
   *  open carousel item modal
   * @param template - modal
   */
  openDialog() {
    const dialogRef = this.dialog.open(ListingItemsCarouselModalComponent, {
      maxWidth: '100%',
      data: {photolist: this.photoList, index: this.activeSlideIndex},
      panelClass: 'carouselModalDialog'
    });
  }

  /**
   *  open confirm delete item modal
   * @param template - modal
   */
  openConfirmDialog() {
    const confirmDialogRef = this.dialog.open(ConfirmDeleteListingModalComponent, {
      maxWidth: '100%',
      data: {listingId: this.listingId},
      panelClass: 'confirmDeleteModalDialog'
    });
  }

  /**
   *  open login model when user not login status and want to add item to watchlist
   * @param template - modal
   */
  openLoginDialog() {
    const loginRef = this.dialog.open(LoginModalComponent, {
      maxWidth: '100%',
      panelClass: 'loginModalDialog'
    });
  }

  /**
   *  open report model
   * @param template - modal
   */
  openReportDialog() {
    const reportRef = this.dialog.open(ListingReportModalComponent, {
      data: {reportReasons: this.reportReasons, listingDetails: this.listingDetails},
      maxWidth: '100%',
      panelClass: 'loginModalDialog'
    });
  }

  /**
   * Show number of watchlist message on listing page
   */
  showWatchListMessage(): string {
    let mess = '';
    if (Util.isNotNullOrEmpty(this.listingDetails) && Util.isNotNullOrEmpty(this.listingDetails.watchers)) {
      if (this.listingDetails.watchers !== '0') {
        mess = this.watchListMessage.replace('{numberOfWatchers}', this.listingDetails.watchers);
      }
    }

    return mess;
  }

  /**
   * check user login status
   */
  checkUserLoginStatus(): boolean {
    let loginStatus = false;
    if (this.sessionStorageService.get(TAJR_PERSONAL_DETAILS)) {
      this.userDetails = JSON.parse(this.sessionStorageService.get(TAJR_PERSONAL_DETAILS));
      // compare user login id with user create item id
      if (this.userDetails.member_id === this.listingDetails.member.member_id) {
        loginStatus = true;
      }
    }
    return loginStatus;
  }

  /**
   * Send request to add this item to user watchlist
   */
  addToWatchList() {
    // if user not in login status will redirect to login page
    if (!this.sessionStorageService.get(TAJR_PERSONAL_DETAILS)) {
      // open login model
      this.openLoginDialog();
      return;
    }

    if (!this.isAddedToWatchList) {
      // addToWatchlist;
      this.watchListSubscription = this.accountService.addToWatchlist(this.listingId).subscribe(data => {
        // if user has information response
        if (200 === data.status && data.body && data.body.watchlist_id) {
          this.listingDetails.watchlist_id = data.body.watchlist_id;
          // show notification to user
          this.notify.showInfoNotificationMessage(this.itemAddedToWatchlistMess);
          this.userWatchList.push(this.listingDetails.watchlist_id);
          this.isAddedToWatchList = true;
        }
      }, error => {
        this.notify.showWarnmingNotificationMessage('Cannot add this item to you watchlist.');
      });

    } else {
      this.notify.showWarnmingNotificationMessage('This item already in your watchlist.');
    }

  }

  /**
   * Send request to removw this item to user watchlist
   */
  removeToWatchList() {
    if (this.sessionStorageService.get(TAJR_PERSONAL_DETAILS)) {
      // remove item on watchlist;
      this.watchListSubscription = this.accountService.removeToWatchList(this.listingDetails.watchlist_id).subscribe(data => {
        // if user has information response
        if (200 === data.status && data.body) {
          // show notification to user
          this.notify.showInfoNotificationMessage('This item has been removed from your watchlist.');
          const index: number = this.userWatchList.indexOf(this.listingDetails.watchlist_id);
          this.userWatchList.splice(index, 1);
          this.isAddedToWatchList = false;
        }
      }, error => {
        this.notify.showWarnmingNotificationMessage('Cannot remove this item from you watchlist.');
      });

    } else {
      this.notify.showWarnmingNotificationMessage('This item is not in your watchlist.');
    }
  }

  /**
   * Open Report modal if user in login status
   */
  openReportListingModal() {
    // if user not in login status will redirect to login page
    if (!this.sessionStorageService.get(TAJR_PERSONAL_DETAILS)) {
      // open login model
      this.openLoginDialog();
      return;
    }
    this.openReportDialog();
  }

  /**
   * split category to show category level
   * @param index category level
   */
  splitCatergoty(level: number): string {
    let result = '';
    const str = this.listingDetails.category_id.split('-');
    switch (level) {
      case 0:
        result = str[0];
        break;
      case 1:
        result = str[0] + '-' + str[1];
        break;
      case 2:
        result = this.listingDetails.category_id;
        break;
      default:
        break;
    }
    return result;
  }

  checkDisplayContactButton() {
    const isLoggedIn = this.userDetails;
    const hasPhoneNumber = !!this.showAttributesValue("showMobileNumber", "strings");
    if (isLoggedIn) return this.userDetails.member_id != this.listingDetails.member.member_id;;
    return !hasPhoneNumber;
  }

  handleClickContactButton() {
    const isLoggedIn = this.userDetails;
    if (!isLoggedIn) return this.openLoginDialog();
    this.router.navigate(['/chat/new', this.listingDetails.member.member_id])
  }
}
