import {Injectable} from '@angular/core';
import {HttpHelperService} from './http-helper.service';
import {ApiReferenceService} from '../shared/api-reference.service';
import {Observable} from 'rxjs';
import {SearchModel} from '../modules/models/search.model';
import {Util} from '../utils/utils';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  public searchDetails: SearchModel;

  constructor(
    // sent request service helper
    private http: HttpHelperService,
    // contain api
    private apiReference: ApiReferenceService,
  ) {
  }

  /**
   * search all item follow the query string use type on search bar
   * @param query search string
   */
  searchItemByQuery(query: string) {
    if (Util.isNullOrEmpty(query)) {
      return;
    }
    this.searchDetails = new SearchModel();
    this.searchDetails.sort_by = 'latest';
    this.searchDetails.from = '0';
    this.searchDetails.size = '20';
    this.searchDetails.query = query;
    this.searchGeneralListing(this.searchDetails);
  }

  /**
   * Search listing item
   */
  searchGeneralListing(search: any): Observable<any> {
    return this.http.searchListing(this.apiReference.search, search).pipe(response => {
      return response;
    });
  }

  /**
   * Get member listing item
   */
  searchMemberListings(search: any): Observable<any> {
    return this.http.searchListing(this.apiReference.searchMember, search).pipe(response => {
      return response;
    });
  }
}
