import {Component, ElementRef, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {DatePipe} from '@angular/common';
import {Subscription} from 'rxjs';

import {HttpHelperService} from '../../services/http-helper.service';
import {TimeService} from '../../services/time.service';
import {LanguageService} from '../../services/language.service';
import {Router} from '@angular/router';
import {RECENTLY_VIEWD_LISTING_PAGE, TAJR_PERSONAL_DETAILS} from '../../shared/global';
import {SearchModel} from '../../modules/models/search.model';
import {Hits} from '../../modules/models/hits';
import {Util} from '../../utils/utils';
import {NavigateService} from '../../services/navigate.service';
import SwiperCore, {EffectFade, Virtual} from 'swiper/core';
import {Navigation, Pagination, Scrollbar, A11y} from 'swiper/core';
import {AccountService} from "../../services/account.service";
import {SessionStorageService} from "../../server-side-rendering/storages/session.storage.service";
import {ProfileDetails} from "../../modules/models/profile.details";
import {DefaultMenuTypeA} from "../../modules/models/default.menu.type.a";
import {RecentlyViewedListingDetails} from "../../modules/models/recently.viewed.listing.details";
import {TitleService} from "../../services/title.service";
import {ListingService} from "../../services/listing.service";

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Virtual, EffectFade]);

@Component({
  selector: 'app-default-listing-recently-viewed',
  templateUrl: './default-listing-recently-viewed.component.html',
  styleUrls: ['./default-listing-recently-viewed.component.css']
})
export class DefaultListingRecentlyViewedComponent implements OnInit, OnDestroy {
  recentlyViewedMess = this.languageService.findMessageByLanguage('recentlyViewed');

  // model OOP
  private getListings: SearchModel;
  // Observables use to receive response
  private subscription: Subscription;
  // width of screen size on browser
  public screenWidth: number;
  public recentlyViewedListingDetails: RecentlyViewedListingDetails;
  public getListingFromItem = '0';
  public getListingToItem = '10';
  public slidesPerView = 5;
  public spaceBetween = 10;
  public userDetails: ProfileDetails;
  // format menu and label
  public menu: DefaultMenuTypeA;
  public leftMenuLabel: string;
  public rightMenuLabel: string;
  // list of menu
  public listOfLenu = [
    {
      index: '0',
      leftMenuLabel: {
        arabic: 'عرض الكل',
        english: 'View all'
      },
      leftMenuLink: 'latest',
      rightMenuLabel: {
        arabic: 'شوهدت مؤخراً',
        english: 'Recently Viewed'
      },
      rightMenuLink: 'latest',
      routerLink: RECENTLY_VIEWD_LISTING_PAGE
    },
  ];

  constructor(
    private elementRef: ElementRef,
    // service convert date format
    private datePipe: DatePipe,
    // service send request
    private accountService: AccountService,
    // contain api
    private httpHelper: HttpHelperService,
    // parse time service
    private timeService: TimeService,
    // language and sorts arabic message
    private languageService: LanguageService,
    public navigateService: NavigateService,
    private sessionStorageService: SessionStorageService,
    private router: Router,
    public titleService: TitleService,
    public listingService: ListingService,
  ) {
  }

  ngOnInit() {
    // get width of device
    this.screenWidth = window.innerWidth;

    //  receive and show value from list of menu
    this.menu = this.listOfLenu[0];
    this.leftMenuLabel = this.languageService.messageFromServer(this.menu.leftMenuLabel);
    this.rightMenuLabel = this.languageService.messageFromServer(this.menu.rightMenuLabel);

    //  Get listings by search item Api
    if (this.checkUserLoginStatus()) {
      this.getRecentlyViewedListings();
    }

    this.responsiveCarousel();
  }

  ngOnDestroy(): void {
    // unsubscribe if observable with get latest listing request available
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  /**
   * get recently viewed listings
   */
  getRecentlyViewedListings() {
    //  Get listings by search item Api
    // this.getListings = new GetListings();
    this.getListings = new SearchModel();
    this.getListings.from = this.getListingFromItem;
    this.getListings.size = this.getListingToItem;

    // send request to get listing
    this.subscription = this.accountService.getRecentlyViewedListings(this.getListings).subscribe(data => {
      // if listing list available
      if (200 === data.status && 0 < data.body.listing_ids.length) {
        this.recentlyViewedListingDetails = data.body;
      }
    });
  }

  /**
   * check user login status
   */
  checkUserLoginStatus(): boolean {
    let loginStatus = false;
    if (this.sessionStorageService.get(TAJR_PERSONAL_DETAILS)) {
      this.userDetails = JSON.parse(this.sessionStorageService.get(TAJR_PERSONAL_DETAILS));
      // compare user login id with user create item id
      if (this.userDetails.member_id) {
        loginStatus = true;
      }
    }
    if (!loginStatus) {
      this.recentlyViewedListingDetails = new RecentlyViewedListingDetails();
    }
    return loginStatus;
  }

  /**
   * use HostListener to  updated on resize:
   * @param event tab when selected
   */
  @HostListener('window:resize', ['$event'])
  getScreenSize(event) {
    this.screenWidth = window.innerWidth;
    this.responsiveCarousel();
  }

  /**
   * Set item per slide follow screen size
   * On desktop screen will disable touch
   */
  responsiveCarousel() {
    if (this.screenWidth <= 575) {
      this.slidesPerView = 2.1;
      this.spaceBetween = 0;
    } else if (this.screenWidth <= 768) {
      this.slidesPerView = 3.2;
      this.spaceBetween = 8;
    } else if (this.screenWidth <= 991) {
      this.slidesPerView = 4.2;
      this.spaceBetween = 8;
    } else {
      this.slidesPerView = 5;
      this.spaceBetween = 8;
    }
  }

  /**
   * endDate text show on list
   */
  endDateText(endDate: string): string {
    let endDateText = '';
    let endTime;
    // parse endDate
    endTime = Date.parse(endDate);
    //  Date.now() returns the number of milliseconds elapsed since January 1, 1970 00:00:00 UTC
    const current = Date.now();
    // the time zone difference, in minutes, from current locale (host system settings) to UTC
    const difference = this.timeService.convertMS(endTime - current);

    if (difference.day < 1) {
      // hours
      if (difference.hour > 0) {
        if (difference.hour === 1) {
          endDateText = this.languageService.wrapDir('rtl', 'الاعلان ينتهي بعد ساعه');
        } else {

          if (difference.hour === 2) {
            endDateText = this.languageService.wrapDir('rtl', 'الاعلان ينتهي بعد ساعتين');
          }
          if (difference.hour >= 3 && difference.hour < 11) {
            endDateText = this.languageService.wrapDir('rtl', 'الإعلان ينتهي بعد ') + this.languageService.wrapDir('rtl', difference.hour) + this.languageService.wrapDir('rtl', ' ساعات');
          }

          if (difference.hour > 10) {
            endDateText = this.languageService.wrapDir('rtl', 'الإعلان ينتهي بعد ') + this.languageService.wrapDir('rtl', difference.hour) + this.languageService.wrapDir('rtl', ' ساعه');
          }
        }
      } else {
        // minutes
        if (difference.minute > 0) {

          if (difference.minute === 1) {
            endDateText = this.languageService.wrapDir('rtl', 'الاعلان ينتهي بعد دقيقة');
          } else {

            if (difference.minute === 2) {
              endDateText = this.languageService.wrapDir('rtl', 'الاعلان ينتهي بعد دقيقتين');
            }
            if (difference.minute >= 3 && difference.minute < 11) {
              endDateText = this.languageService.wrapDir('rtl', 'الإعلان ينتهي بعد ') + this.languageService.wrapDir('rtl', difference.minute) + this.languageService.wrapDir('rtl', ' دقائق');
            }

            if (difference.minute > 10) {
              endDateText = this.languageService.wrapDir('rtl', 'الإعلان ينتهي بعد ') + this.languageService.wrapDir('rtl', difference.minute) + this.languageService.wrapDir('rtl', ' دقيقة');
            }
          }
        } else {
          endDateText = this.languageService.wrapDir('rtl', 'الإعلان انتهى');
        }
      }
    } else if (difference.day === 1) {
      endDateText = this.languageService.wrapDir('rtl', 'الاعلان ينتهي بعد يوم');
    } else {

      if (difference.day === 2) {
        endDateText = this.languageService.wrapDir('rtl', 'الإعلان ينتهي بعد يومين');
      }
      if (difference.day >= 3 && difference.day < 11) {
        endDateText = this.languageService.wrapDir('rtl', 'الإعلان ينتهي بعد ') + this.languageService.wrapDir('rtl', difference.day) + this.languageService.wrapDir('rtl', ' أيام');
      }

      if (difference.day > 10) {
        endDateText = this.languageService.wrapDir('rtl', 'الإعلان ينتهي بعد ') + this.languageService.wrapDir('rtl', difference.day) + this.languageService.wrapDir('rtl', ' يوم');
      }
    }
    return endDateText;
  }

  /**
   * Price message show on list
   */
  composedPrice(listingDetails: Hits): string {
    let orNearOffer: string;
    let orNearOfferText: string;
    let askingPrice: string;
    let askingPriceText: string;
    let composedPrice = '';
    if (Util.isNullOrEmpty(listingDetails)) {
      return composedPrice;
    }
    if (Util.isNotNullOrEmpty(listingDetails.orNearOffer)) {
      orNearOffer = listingDetails.orNearOffer;
      orNearOfferText = this.languageService.findMessageByLanguage('orNearOffer');
    }

    askingPriceText = this.languageService.findMessageByLanguage('askingPrice');

    if (Util.isNotNullOrEmpty(listingDetails.askingPrice)) {
      askingPrice = listingDetails.askingPrice;
    }

    if (orNearOffer === 'true') {
      composedPrice = this.languageService.wrapDir('rtl', askingPrice) + this.languageService.wrapDir('rtl', ' ر.س ') + this.languageService.wrapDir('rtl', orNearOfferText);
    } else {
      composedPrice = this.languageService.wrapDir('rtl', askingPriceText) + this.languageService.wrapDir('rtl', ' ') + this.languageService.wrapDir('rtl', askingPrice) + this.languageService.wrapDir('rtl', ' ر.س ');
    }
    return composedPrice;
  }

  /**
   * navigate to recently viewd page
   *
   */
  navigateToRecentlyViewPage() {
    this.router.navigate([RECENTLY_VIEWD_LISTING_PAGE]);
  }

}
