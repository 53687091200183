import {makeStateKey, TransferState} from '@angular/platform-browser';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {ProductService} from '../../services/product.service';
import {HP_LATEST, LATEST} from '../../shared/global';
import {first, tap} from 'rxjs/operators';
import {SearchListingDetails} from '../../modules/models/search.listing.details';
import {SearchModel} from '../../modules/models/search.model';

@Injectable()
export class HomeLatestResolve implements Resolve<SearchListingDetails> {
  private getListings: SearchModel;
  public getListingFromItem = '0';
  public getListingToItem = '8';

  constructor(
    private productService: ProductService,
    private transferState: TransferState) {
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<SearchListingDetails> {

    const DATA = makeStateKey<SearchListingDetails>(HP_LATEST);
    this.getListings = new SearchModel();
    this.getListings.sort_by = LATEST;
    this.getListings.from = this.getListingFromItem;
    this.getListings.size = this.getListingToItem;
    return this.productService.getExistListingsItem(this.getListings)
      .pipe(
        first(),
        tap(data => {
          if (data) {
            this.transferState.set(DATA, data);
          }
        })
      );
  }
}
