<!-- blue line  -->
<div class="bg-primary pt-2"></div>
<!--content-->
<div class="bg-light">
  <div class="container-fluid">

    <div class="row d-md-none">
      <div class="col text-right py-2">
        <a class="btn btn-primary d-flex align-items-center justify-content-center WIDTH_150 ml-auto"
           routerLink="/list-an-item">
          <span class="material-icons">add</span>
          أضف إعلانك
        </a>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-10 col-md-8 col-lg-6 offset-sm-1 offset-md-2 offset-lg-3 text-right pb-5">
        <form [formGroup]="changePasswordForm"  (keyup)="errorMessage = ''"  (ngSubmit)="changePassword()">
          <div class="row">
            <div class="col mt-5">
              <h4 class="text-right text-warning">تغيير كلمة المرور</h4>
            </div>
          </div>
          <!-- old password -->
          <div class="row">
            <div class="col mt-3">
              <span class="font-weight-bold text-danger"> * </span>
              <label for="oldPassword" class="font-weight-bold text-secondary">كلمة المرور القديمة</label>
              <input id="oldPassword" type="password" maxlength="128" class="form-control text-right"
                     formControlName="oldPassword"
                     autocomplete="on"
                     [placeholder]="placeholderText">
            </div>
          </div>
          <!-- error old password -->
          <div class="row ">
            <div class="col position-absolute mt-1">
              <small class="text-danger pt-3"
                     *ngIf="(changePasswordForm.controls.oldPassword.dirty || submitted)  && !changePasswordForm.controls.oldPassword.valid">
            <span *ngIf="changePasswordForm.controls.oldPassword.errors?.required">
              {{requirePasswordMessage}}</span>
                <span *ngIf="changePasswordForm.controls.oldPassword.errors.pattern">
              {{regexPasswordMessage}}</span>
              </small>
            </div>
          </div>
          <!-- password -->
          <div class="row">
            <div class="col mt-3">
              <span class="font-weight-bold text-danger"> * </span>
              <label for="password" class="font-weight-bold text-secondary">كلمة المرور الجديدة</label>
              <input id="password" type="password" maxlength="128" class="form-control text-right"
                     formControlName="password"
                     autocomplete="on"
                     [placeholder]="placeholderText">
            </div>
          </div>
          <!-- error password -->
          <div class="row ">
            <div class="col position-absolute mt-1">
              <small class="text-danger pt-3"
                     *ngIf="(changePasswordForm.controls.password.dirty || submitted)  && !changePasswordForm.controls.password.valid">
            <span *ngIf="changePasswordForm.controls.password.errors?.required">
              {{requirePasswordMessage}}</span>
                <span *ngIf="changePasswordForm.controls.password.errors.pattern">
              {{regexPasswordMessage}}</span>
              </small>
            </div>
          </div>
          <!-- confirm password -->
          <div class="row">
            <div class="col mt-4">
              <span class="font-weight-bold text-danger"> * </span>
              <label for="confirmPassword" class="font-weight-bold text-secondary">أعد كتابة كلمة المرور الجديدة
              </label>
              <input id="confirmPassword"
                     type="password" maxlength="128"
                     class="form-control text-right"
                     formControlName="confirmPassword"
                     autocomplete="on"
                     [placeholder]="placeholderText">
            </div>
          </div>
          <!-- error confirm password -->
          <div class="row ">
            <div class="col position-absolute mt-1">
              <small class="text-danger pt-3"
                     *ngIf="(changePasswordForm.controls.confirmPassword.dirty ||  submitted)  &&
                    !changePasswordForm.controls.confirmPassword.valid &&
                     changePasswordForm.controls.password.valid">
                         <span *ngIf="changePasswordForm.controls.confirmPassword.errors?.required">
              {{requireConfirmPasswordMessage}}</span>
                <span *ngIf="changePasswordForm.controls.confirmPassword.errors.notEquivalent">
              {{regexConfirmPasswordMessage}}</span>
              </small>
            </div>
          </div>
          <!-- error change password response from server -->
          <div class="row ">
            <div class="col position-absolute text-center mt-1">
              <span class="text-danger">{{errorMessage}}</span>
            </div>
          </div>
          <!-- register button -->
          <div class="row">
            <div class="col mt-5">
              <button type="submit" class="btn btn-primary btn-block" *ngIf="!loading">{{updatePassword}}</button>
              <button class="btn alert-primary text-white btn-block d-flex align-items-center justify-content-center "
                      *ngIf="loading" disabled>
                {{loadingMess}}
                <span class="spinner-border spinner-border-sm mx-2"></span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- blue line  -->
<div class="bg-primary pt-2"></div>
