<!-- blue line  -->
<div class="bg-primary pt-2"></div>

<!-- content -->
<div class="bg-light">
  <div class="container-fluid pb-5">

    <div class="row">
      <div class="col pt-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-light p-0 pt-1 category" dir="rtl">

            <a routerLink="/my-tajr" class="text-secondary">
              <small>
                <!--My Tajer -->حسابي
              </small>
            </a>&gt;
            <a class="text-secondary">
              <small>&nbsp;
                <!--My photos--> صوري
              </small>
            </a>
          </ol>
        </nav>
      </div>
    </div>
    <div class="row">
      <div class="col pt-1">
        <h4 class="text-warning text-right">
          <!--My photos--> صوري
        </h4>
      </div>
    </div>
    <!--delete-select all -unselect all-->
    <div class="row" dir="rtl">
      <div class="col-12 col-sm-auto bg-light text-right py-2">
        <button type="button" class="btn btn-primary"
                *ngIf="!deletingItem"
                (click)="deleteAllSelectedPhoto()">
          <!--Delete selected-->حذف
        </button>
        <button class="btn btn-primary"
                [ngClass]="screenWidth > 575? '':'btn-sm'"
                type="button" disabled *ngIf="deletingItem">
          <span class="spinner-border spinner-border-sm"></span>
          {{loadingMess}}
        </button>
        <small class="text-secondary px-1"></small>
        <a href="javascript:void(0);" role="button"
           class="text-secondary text-decoration-none border-0"
           [ngClass]="screenWidth > 575? '':'small'"
           (click)="selectAllPhotos()">
          <!-- Select all-->حدد الكل
        </a>
        <small class="text-secondary px-2">|</small>
        <a href="javascript:void(0);" role="button"
           class="text-secondary text-decoration-none border-0"
           [ngClass]="screenWidth > 575? '':'small'"
           (click)="unSelectAllPhotos()">
          <!--  Unselect all-->إلغاء تحديد الكل
        </a>
      </div>
      <div class="col-12 col-sm py-2 d-flex align-items-center"
           [ngClass]="screenWidth > 575? 'justify-content-end bg-light':''">
        <p class="text-primary mb-0 numberItemOfListings">
          ‫عرض ‬‫
          {{photoList.length}}
          ‬‫ صور
        </p>
      </div>
    </div>
    <!--list photos when empty-->
    <div *ngIf="loadPageFinished && 0 === photoList.length">
      <br/>
      <br/>
      <br/>
      <div class="row">
        <div
          class="col-sm-8 col-md-6 col-lg-4 embed-responsive offset-sm-2 offset-md-3 offset-lg-4 embed-responsive-4by3">
          <img class="embed-responsive-item tajrImage"
               src="../../../assets/images/icon-images/tajr-icon-000033.png">
        </div>
      </div>

      <br/>

      <div class="row">
        <div class="col">
          <p class="text-primary text-center mb-0">
            <!--Upload 20 photos for free-->ارفع ٢٠ صوره
          </p>
          <p class="text-center">
            <a href="javascript:void(0);" class="text-decoration-none text-secondary">
              <!--See our photo policy and guidelines-->شروط وأحكام رفع الصور</a>
          </p>
        </div>
      </div>
      <br/>
      <br/>
      <br/>
      <br/>
    </div>
    <!--list photos when has item-->
    <div *ngIf="photoList.length > 0">
      <!--photos-->
      <div class="row  m-0" dir="rtl">

        <div class="col-6 col-sm-4 col-md-3 pt-3"
             *ngFor="let item of photoList ;">
          <div class="row carouselStyle">
            <div class="col-auto px-1 d-none d-sm-block">
              <label class="pb-5">
                <input type="checkbox" [ngModel]="item.checkBoxValue === 'true'"
                       (click)="selectPhoto(item.photo_id)">
              </label>
            </div>
            <div class="col">
              <div class="row">
                <div class="col"></div>
                <div class="col-6 embed-responsive embed-responsive-4by3">
                  <img class="embed-responsive-item tajrImage"
                       [ngStyle]="item.checkBoxValue === 'true' ? opacityImage: '' "
                       (click)="selectPhoto(item.photo_id)"
                       src="{{item.photo_urls}}"
                       alt="{{item.file_name}}">
                </div>
                <div class="col"></div>
              </div>
              <div class="row mt-2">
                <div class="col text-center">
                  <p class="mb-0 d-sm-none">
                    <label class="mb-0">
                      <input type="checkbox" [ngModel]="item.checkBoxValue === 'true'"
                             (click)="selectPhoto(item.photo_id)">
                    </label>
                  </p>
                  <p class="text-center figure-img mb-0"
                     (click)="selectPhoto(item.photo_id)">
                    <small
                      class="text-primary text-break file_name"> {{item.file_name}}
                    </small>
                  </p>
                  <p class="text-center mb-0">
                    <a href="javascript:void(0)"
                       class="text-decoration-none text-secondary"
                       (click)="deletePhoto(item.photo_id)"> احذف الصورة </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <!--load more button-->
      <div class="row">
        <div class="col text-center"
             *ngIf="pageState !== null">
          <a type="button" class="badge text-decoration-none text-secondary dropdown-toggle"
             (click)="getPhotosByIdMember()"
             [ngClass]="screenWidth>575?'':'btn-block'"
             *ngIf="!loading">
            {{loadMoreMessage}}
          </a>
          <a class="btn border-0 text-secondary"
             type="button" disabled *ngIf="loading">
            {{loadingMess}}
            <span class="spinner-border spinner-border-sm"></span>
          </a>
        </div>
      </div>
      <br/>
      <div class="row">
        <div class="col">
          <p class="text-right mb-0 mt-3">
            <a href="javascript:void(0);" class="text-secondary text-decoration-none">
              <!--  See our photo policy and guidelines-->انظر الى سياسة و شروط وأحكام رفع الصور
            </a>
          </p>
        </div>
      </div>
      <br/>
    </div>
  </div>
</div>

<!--the spinner when page load not finished-->
<div class="d-flex justify-content-center min-vh-100 py-5 my-5" *ngIf="!loadPageFinished">
  <mat-spinner class="mt-5"></mat-spinner>
</div>

<!-- blue line  -->
<div class="bg-primary pt-2"></div>
