import {Component, DoCheck, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountService} from '../../services/account.service';
import {HttpHelperService} from '../../services/http-helper.service';
import {LanguageService} from '../../services/language.service';
import {SMOOTH, TAJR_PERSONAL_DETAILS} from '../../shared/global';
import {ProfileDetails} from '../../modules/models/profile.details';
import {UserChangePassword} from '../../modules/models/user.change.password';
import {NotificationServices} from '../../services/notification.services';
import {SessionStorageService} from "../../server-side-rendering/storages/session.storage.service";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  // message show when valid input value
  requirePasswordMessage = this.languageService.findMessageByLanguage('requirePassword');
  regexPasswordMessage = this.languageService.findMessageByLanguage('regexPassword');
  requireConfirmPasswordMessage = this.languageService.findMessageByLanguage('requireConfirmPassword');
  regexConfirmPasswordMessage = this.languageService.findMessageByLanguage('regexConfirmPassword');
  someThingWentWrongMessage = this.languageService.findMessageByLanguage('someThingWentWrong');
  placeholderText = this.languageService.findMessageByLanguage('shouldBe6OrMoreCharacters');
  loadingMess = this.languageService.findMessageByLanguage('loading');
  updatePassword = this.languageService.findMessageByLanguage('updatePassword');
  yourPasswordHasBeenChangedSuccessfullyMess = this.languageService.findMessageByLanguage('yourPasswordHasBeenChangedSuccessfully');

  // verify code input regex
  passworsRegexPattern = /^.{6,128}$/;
  confirmPassworsRegexPattern = /^[*]*/;

  // form builder to manage login modal form
  changePasswordForm: FormGroup;
  // attribute to show loading button
  loading = false;
  // error message response from server
  errorMessage: string;
  // mobileNumber received from url
  mobileNumber: string;
  // verificationCode received from url
  verificationCode: string;
  // tab localities default value
  selected: string;
  // retrieve button submitted or not
  submitted = false;
  // width of screen size on browser
  public screenWidth: number;
  // user details
  public profileDetails: ProfileDetails;
  public userChangePassword: UserChangePassword;

  constructor(
    // service use to get value from router
    private activatedroute: ActivatedRoute,
    // manage form input
    private formBuilder: FormBuilder,
    // service send request to login
    private accountService: AccountService,
    // contain api
    private httpHelper: HttpHelperService,
    // get language message from local
    private languageService: LanguageService,
    // go to redirect to register-confirm page
    private router: Router,
    // show notify sercice
    private notify: NotificationServices,
    private sessionStorageService: SessionStorageService,
  ) {
    // Form validation
    this.changePasswordForm = this.formBuilder.group({
        // input password: default value empty, require has value.
        oldPassword: ['', Validators.compose([
          Validators.required,
          Validators.pattern(this.passworsRegexPattern)
        ])],
        // input password: default value empty, require has value.
        password: ['', Validators.compose([
          Validators.required,
          Validators.pattern(this.passworsRegexPattern)
        ])],
        // input confirm password: default value empty, require has value.
        confirmPassword: ['', Validators.compose([
          Validators.required,
          Validators.pattern(this.confirmPassworsRegexPattern)
        ])]
      },
      // check password and confirm password matching or not
      {validator: this.checkIfMatchingPasswords('password', 'confirmPassword')}
    );
  }

  ngOnInit() {
    // get width of device
    this.screenWidth = window.innerWidth;
    // get user information
    if (this.sessionStorageService.get(TAJR_PERSONAL_DETAILS)) {
      this.profileDetails = JSON.parse(this.sessionStorageService.get(TAJR_PERSONAL_DETAILS));
    }
  }

  /**
   * check password and confirm password matching or not
   * @param passwordKey -- password
   * @param passwordConfirmationKey -- confirm password
   */
  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      // get password and confirm password value
      let passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
      // if password is empty retuen error "required"
      if (passwordConfirmationInput.value == '') {
        return passwordConfirmationInput.setErrors({required: true});
      } else if (passwordInput.value !== passwordConfirmationInput.value) {
        // if password and confirm password is not matching return error "notEquivalent"
        return passwordConfirmationInput.setErrors({notEquivalent: true});
      } else {
        // other case set error null
        return passwordConfirmationInput.setErrors(null);
      }
    };
  }

  /**
   * senf request to change password
   */
  changePassword() {
    this.submitted = true;
    // reset previous error message
    this.errorMessage = '';
    // If changePasswordForm input is valid
    if (this.changePasswordForm.valid) {
      // change loading status to show loading when sending request
      this.loading = true;
      // import edit detail information, item position follow constructor of userEditDetails
      this.userChangePassword = new UserChangePassword(
        this.changePasswordForm.value.oldPassword,
        this.changePasswordForm.value.password
      );
      // Send request to Update mobile number status and create a member.
      this.accountService.changePassword(this.userChangePassword).subscribe(data => {
        // if user edit details success
        if (data.status === 200) {
          // send request to logout after change password success
          // remove information from cookie storage
          this.sessionStorageService.clear();
          // send request to logout
          this.notify.showInfoNotificationMessage(this.yourPasswordHasBeenChangedSuccessfullyMess);
          // edit details success goto home page
          this.router.navigate(['/login']);
          // close loading status on button
          this.loading = false;
        }
      }, err => {
        // User login fail, close loading status on button
        this.loading = false;
        // get error message response from server
        if (err && err.error) {
          // show error
          this.errorMessage = this.languageService.messageFromServer(err.error.message);
        } else {
          // service error message
          this.errorMessage = this.someThingWentWrongMessage;
        }
      });

    }
  }
}
