import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {ChatService} from '../../services/chat.service';
import {AccountService} from '../../services/account.service';
import {Subscription} from 'rxjs';
import { AuthService } from '../../services/auth.service';


@Component({
	selector: 'app-chat-list',
	templateUrl: './chat-list.component.html',
	styleUrls: ['./chat-list.component.css']
})
export class ChatListComponent implements OnInit {
	public tabs = [ 'buying', 'selling' ];
	public activeTab: string = 'buying';
	public conversations = [];
	public activeConversation = {};
	public getAllConversationsRequest: Subscription;
	public getUserPublicDetailsRequest: Subscription;
	public searchString = "";
	@Output('clickConversation') clickConversation = new EventEmitter();
	@Output('updateQuery') updateQuery = new EventEmitter();
	@Input('newConversationSellerID') public newConversationSellerID;
	@Input('conversationID') public conversationID;

	constructor(
		private chatService: ChatService,
		private accountService: AccountService,
		private authService: AuthService
		) { }

	ngOnInit(): void {
		if (this.conversationID){
			this.findConversation();
		} 
		else {
			this.getConversationList();
		}
		this.listen();
	}

	ngOnChanges() {
	}

	listen() {
		navigator.serviceWorker.addEventListener('message', (event) => {
			if (event.data && event.data.data) {
				this.updateConversation(event.data.data);
			}
		});;
	}

	findConversation() {
		this.chatService.getConversationList({
			type: 'buying'
		}).subscribe(buyingRes => {
			if (200 === buyingRes.status && buyingRes.body) {
				if (buyingRes.body.find(conversation => conversation.conversation_id === this.conversationID)) {
					this.conversations = buyingRes.body;
				}
				else {
					this.chatService.getConversationList({
						type: 'selling'
					}).subscribe(sellingRes => {
						if (200 === sellingRes.status && sellingRes.body) {
							if (sellingRes.body.find(conversation => conversation.conversation_id === this.conversationID)) {
								this.activeTab = 'selling';
								this.conversations = sellingRes.body;
							}
							else {
								this.conversations = buyingRes.body;
								this.conversationID = null;
							}
						}
					}, err => {
					});
				}
			}
		}, err => {
		});
	}

	getConversationList() {
		this.getAllConversationsRequest = this.chatService.getConversationList({
			type: this.activeTab
		}).subscribe(data => {
			if (200 === data.status && data.body) {
				this.conversations = data.body;
				if (this.newConversationSellerID) {
					this.handleRedirectToConversation();
				}
				else if(this.conversationID) {
					this.handleClickConversation('')
				}
			}
		}, err => {
		});
	}

	changeTab(e) {
		if (e.target.id == this.activeTab) return;
		this.activeTab = e.target.id;
		this.conversations = [];
		this.getConversationList();
	}

	handleClickConversation(conversationID) {
		this.activeConversation = conversationID;
		this.clickConversation.emit(conversationID)
	}

	handleRedirectToConversation() {
		const existingConversationWithSeller = this.conversations.find(c => c.seller_id == this.newConversationSellerID);
		
		if (existingConversationWithSeller) {
			this.updateQuery.emit(existingConversationWithSeller.conversation_id)
		}
		else if (this.activeTab == 'buying'){
			this.getUserPublicDetailsRequest = this.accountService.getUserPublicDetails(this.newConversationSellerID).subscribe(data => {
				if (200 === data.status && data.body) {
					const newConversation = {
						seller_id: this.newConversationSellerID,
						seller_username: data.body.username,
						conversation_id: 'new'
					}
					this.conversations = this.conversations.concat(newConversation)
					this.conversationID = 'new'
				}
			});
		}
	}
	getConversationListName(conversation) {
		if (this.activeTab === 'buying') return conversation.seller_username;
			return  conversation.buyer_username;
	}

	getConversationShortDate(conversation) {
		if (!conversation.du) return 'Now';
		let chatDateOptions = {
			month: 'long',
			day: 'numeric'
		}; 
		let chatDate = new Date(conversation.du);
		chatDate.setUTCHours(chatDate.getUTCHours());
		return chatDate.toLocaleDateString('ar-EG', chatDateOptions);
	}

	searchConversation(e) {
		const textSearch = e.target.value;
		setTimeout(() => {
			this.searchString = textSearch;
			this.handleClickConversation(null)
		}, 300)
	}

	checkSeenConversation(conversation) {
		if (conversation.conversation_id == 'new') return true;
		const myID = this.authService.getMemberId();
		const myLastSeen = this.activeTab === 'buying' ? conversation.buyer_last_seen : conversation.seller_last_seen;
		const isLastSender = conversation.last_message_sender_id == myID;
		return isLastSender || new Date(conversation.last_message_dc) <= new Date(myLastSeen);
	}

	updateConversation(message) {
		this.conversations = this.conversations.map(conversation => {
			if (conversation.conversation_id == message.conversation_id) {
				return Object.assign({}, conversation, {
					last_message: message.text,
					last_message_dc: message.dc,
					last_message_sender_id: message.sender_id,
					du: message.dc
				})
			}
			return conversation;
		}).sort((a, b) => {
			const aDC = new Date(a.last_message_dc).getTime();
			const bDC = new Date(b.last_message_dc).getTime();
			return bDC - aDC;
		})
	}

	updateNewConversation(message) {
		this.conversations = this.conversations.map(conversation => {
			if (conversation.conversation_id == 'new') {
				return Object.assign({}, conversation, {
					last_message: message.text,
					last_message_dc: message.dc,
					last_message_sender_id: message.sender_id,
					du: message.dc,
					conversation_id: message.conversation_id
				})
			}
			return conversation;
		}).sort((a, b) => {
			const aDC = new Date(a.last_message_dc).getTime();
			const bDC = new Date(b.last_message_dc).getTime();
			return bDC - aDC;
		})
	}

	updateSeenConversation(seenRes) {
		this.conversations = this.conversations.map(conversation => {
			if (conversation.conversation_id == seenRes.conversation_id) {
				if (this.activeTab == 'selling') {
					conversation.seller_last_seen = seenRes.seller_last_seen;
				}
				else {
					conversation.buyer_last_seen = seenRes.buyer_last_seen;
				}
			}
			return conversation;
		})
	}
}
