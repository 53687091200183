import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {LanguageService} from '../../services/language.service';
import {SearchService} from '../../services/search.service';
import {HttpHelperService} from '../../services/http-helper.service';
import {DatePipe} from '@angular/common';
import {TimeService} from '../../services/time.service';
import {ActivatedRoute} from '@angular/router';
import {TabsetComponent} from 'ngx-bootstrap/tabs';

@Component({
  selector: 'app-favourites',
  templateUrl: './favourites.component.html',
  styleUrls: ['./favourites.component.css']
})
export class FavouritesComponent implements OnInit {
  sourceTabLabel = this.languageService.findMessageByLanguage('myTajr');
  loadMoreMessage = this.languageService.findMessageByLanguage('loadMore');
  loadingMess = this.languageService.findMessageByLanguage('loading');
  // width of screen size on browser
  public screenWidth: number;
  // attribute to show loading button
  loading = false;
  // select button default value
  public selected = 'latest';
  public tab: number;

  constructor(
    // get language message from local
    private languageService: LanguageService,
    // service send request to login
    private searchService: SearchService,
    // contain api
    private httpHelper: HttpHelperService,
    private elementRef: ElementRef,
    // service convert date format
    private datePipe: DatePipe,
    // parse time service
    private timeService: TimeService,
    // service use to get value from router
    private activatedroute: ActivatedRoute,
  ) { }

  ngOnInit() {
    // get width of device
    this.screenWidth = window.innerWidth;
  }

  /**
   * view tabs
   */
  @ViewChild('staticTabs') staticTabs: TabsetComponent;

  /**
   * use HostListener to  updated on resize:
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  getScreenSize(event) {
    this.screenWidth = window.innerWidth;
  }

  /**
   * change tab when user select or redirect
   * @param tabId
   */
  selectTab(tabId: number) {
    if (this.staticTabs && this.staticTabs.tabs[tabId] !== undefined) {
      this.staticTabs.tabs[tabId].active = true;
    }
  }

}
