import {Component, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {Subscription} from 'rxjs';
import {LanguageService} from '../../services/language.service';
import {ProductService} from '../../services/product.service';
import {HttpHelperService} from '../../services/http-helper.service';
import {DatePipe, isPlatformBrowser} from '@angular/common';
import {TimeService} from '../../services/time.service';

import {
  ACTION, AttributeConstant, CATEGORIES_DETAILS_RESOURCE, CREATE, EDIT, ID,
  ItemAttributes, LIST_AN_ITEM, LIST_AN_ITEM_CONFIRM, MOBILE_NUMBER_REGEX, SELL_SIMILIAR,
  SMOOTH, TAJR_LIST_AN_ITEM, TAJR_LIST_AN_ITEM_DETAILS, TAJR_LIST_AN_ITEM_FEES,
  TAJR_LIST_AN_ITEM_PHOTOS, TAJR_PERSONAL_DETAILS, VALUE,
  ListingAttributes,
  LIST_AN_ITEM_ERROR_TYPE,
} from '../../shared/global';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CategoryDetails} from '../../modules/models/category.details';
import {Options} from '../../modules/models/options';
import {ListAnItemModules} from '../../modules/models/list.an.item.modules';
import {ActivatedRoute, Router} from '@angular/router';
import {Util} from '../../utils/utils';
import {SessionStorageService} from "../../server-side-rendering/storages/session.storage.service";
import {ListAnItemService} from "../../services/list.an.item.service";
import {CategoryService} from "../../services/category.service";

class Listing {
  category_id = '';
  attributes: any;
  duration = '';
  photos: Photo [];
  embedded_content_options: Attribute;
}

class Attribute {
  id = '';
  value = '';
}

class Photo {
  position = '';
  photo_id = '';
}

@Component({
  selector: 'app-list-an-item-details',
  templateUrl: './list-an-item-details.component.html',
  styleUrls: ['./list-an-item-details.component.css']
})

export class ListAnItemDetailsComponent implements OnInit {
  // message show when valid modal input value
  someThingWentWrongMessage = this.languageService.findMessageByLanguage('someThingWentWrong');
  loadingMess = this.languageService.findMessageByLanguage('loading');
  continueMess = this.languageService.findMessageByLanguage('continue');

  //  subscription to observable request get member's listings
  public feesForNewListing: Subscription;
  public categoryDetailData: CategoryDetails;
  public listAnItemModules: ListAnItemModules;
  // create all categories dynamic form
  public activeCategoriesForm: FormGroup;
  public submitted = false;
  // width of screen size on browser
  public screenWidth: number;
  public errorMessage = '';
  // variable containt item information from step 1,step2,step3
  public tajrListAnItem;
  public tajrListAnItemPhotos;
  public tajrListAnItemDetails;
  // attribute to show loading button
  loading = false;
  public action = CREATE;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    // get language message from local
    private languageService: LanguageService,
    // service send request to login
    private productService: ProductService,
    // contain api
    private httpHelper: HttpHelperService,
    private elementRef: ElementRef,
    // Router service use to get value from url
    private route: ActivatedRoute,
    private router: Router,
    // service convert date format
    private datePipe: DatePipe,
    // parse time service
    private timeService: TimeService,
    // manage form input
    private formBuilder: FormBuilder,
    private sessionStorageService: SessionStorageService,
    private activatedRoute: ActivatedRoute,
    private listAnItemService: ListAnItemService,
    private categoryService: CategoryService,
  ) {
  }

  ngOnInit() {
    // get screen of device
    this.screenWidth = window.innerWidth;

    // get value from url, get action with listing item, default action is create new item
    this.route.queryParamMap.subscribe((queryParams) => {
      if (queryParams.has(ACTION)) {
        this.action = queryParams.get(ACTION);
      }
    });

    // Check in local first step list an item result must be available
    if (this.listAnItemService.checkIfEmptySessionData([TAJR_LIST_AN_ITEM, TAJR_LIST_AN_ITEM_PHOTOS])) {
      this.router.navigate([LIST_AN_ITEM]);
    } else {
      // item information from step 1
      this.tajrListAnItem = this.listAnItemService.tajrListAnItem || {};
      // item information from step 2
      this.tajrListAnItemPhotos = this.listAnItemService.tajrListAnItemPhotos || {};
    }
    this.listAnItemModules = new ListAnItemModules();
    const tajrListAnItemDetails = this.listAnItemService.tajrListAnItemDetails;
    if (tajrListAnItemDetails) this.listAnItemModules = tajrListAnItemDetails;

    // set value to variable "categoryDetailData" show on browser;
    this.categoryDetailData = this.activatedRoute.snapshot.data[CATEGORIES_DETAILS_RESOURCE];
    //if already save information in session will show on browser

    // count duration
    switch (this.action) {
      case EDIT:
        this.listAnItemModules.duration = this.listAnItemModules.duration;
        break;
      default:
        if (this.listAnItemModules.duration === '') {
          this.listAnItemModules.duration = this.categoryDetailData.allowed_durations[this.categoryDetailData.allowed_durations.length - 1];
        }
        break;
    }

    const durationControl = {
      duration: ['']
    }
    const categoryIDLv2 = this.categoryService.getActiveIDList(this.listAnItemService.getActiveIDFromSS())[1];
    const formOptionsFromData = this.listAnItemService.getFormOptionsFromData(
      this.categoryDetailData, ListingAttributes.LIST_AN_ITEM_DETAIL[categoryIDLv2], this.listAnItemModules, durationControl);
    this.activeCategoriesForm = this.formBuilder.group(formOptionsFromData);
  }


  getDurationsList(): string[] {
    let result = [];
    switch (this.action) {
      case EDIT:
        result = this.listAnItemModules.durations;
        break;
      default:
        result = this.categoryDetailData.durations;
        break;
    }
    return result;
  }

  isIncludeCategory(catId) {
    return this.categoryDetailData.attributes.find(atrr => atrr.id === catId);
  }

  /**
   * use HostListener to  updated on resize:
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  getScreenSize(event) {
    this.screenWidth = window.innerWidth;
  }

  /**
   *  show attribute label follow language response from server
   */
  showAttributesLable(attributesId: string) {
    let attributesLable = '';
    // return if don't has value
    if (Util.isNullOrEmpty(this.categoryDetailData) || Util.isNullOrEmpty(this.categoryDetailData.attributes)) {
      return;
    }

    const attribute = this.categoryDetailData.attributes.find(item => item.id === attributesId);

    // select label want to show from response
    if (Util.isNotNullOrEmpty(attribute) && Util.isNotNullOrEmpty(attribute.display_name)) {
      attributesLable = this.languageService.messageFromServer(attribute.display_name);
    }
    return attributesLable;
  }

  /**
   * Get maxlength value from server response to put on input tab in browser
   * @param attributeId
   */
  validatorsMaxLength(attributeId: string) {
    if (Util.isNullOrEmpty(this.categoryDetailData) || Util.isNullOrEmpty(this.categoryDetailData.attributes)) {
      return;
    }

    const attribute = this.categoryDetailData.attributes.find(item => item.id === attributeId);

    if (Util.isNotNullOrEmpty(attribute) && Util.isNotNullOrEmpty(attribute.max_length)) {
      return Number(attribute.max_length);
    }
  }

  /**
   * Get min value from server response to put on input tab in browser
   * @param attributeId
   */
  validatorsLower(attributeId: string): number {
    if (Util.isNullOrEmpty(this.categoryDetailData) || Util.isNullOrEmpty(this.categoryDetailData.attributes)) {
      return;
    }

    const attribute = this.categoryDetailData.attributes.find(item => item.id === attributeId);

    if (Util.isNotNullOrEmpty(attribute) && Util.isNotNullOrEmpty(attribute.lower)) {
      return Number(attribute.lower);
    }
  }

  /**
   * Get max value from server response to put on input tab in browser
   * @param attributeId
   */
  validatorsUpper(attributeId: string) {

    if (Util.isNullOrEmpty(this.categoryDetailData) || Util.isNullOrEmpty(this.categoryDetailData.attributes)) {
      return;
    }

    const attribute = this.categoryDetailData.attributes.find(item => item.id === attributeId);

    if (Util.isNotNullOrEmpty(attribute) && Util.isNotNullOrEmpty(attribute.upper)) {
      return Number(attribute.upper);
    }
  }

  /**
   *  show option value want to show from response
   */
  showSelectTabOptionItems(attributesId: string): Array<Options> {
    let optionItems: Array<Options> = [];
    // return if don't has value
    if (Util.isNullOrEmpty(this.categoryDetailData) || Util.isNullOrEmpty(this.categoryDetailData.attributes)) {
      return;
    }

    const attribute = this.categoryDetailData.attributes.find(item => item.id === attributesId);

    // select option value want to show from response
    if (Util.isNotNullOrEmpty(attribute) && Util.isNotNullOrEmpty(attribute.options)) {
      optionItems = attribute.options;
    }
    return optionItems;
  }
  getErrorMessage(key, type) {
    const errorType = LIST_AN_ITEM_ERROR_TYPE[type];
    const attributeToShow = this.categoryDetailData?.attributes?.find(attr => attr.id == key);
    if (!attributeToShow) return '';
    return this.languageService.messageFromServer(attributeToShow.error[errorType])
  }

  /**
   * check form valid and save value to session storage
   */
  onSubmit() {
    // value to show error
    this.submitted = true;
    if (this.activeCategoriesForm.valid) {
      // form object to save in session
      const formObj = {};
      Object.keys(this.activeCategoriesForm.controls).forEach(key => {
        const formControl = this.activeCategoriesForm.controls[key];
        if (Util.isNotNullOrEmpty(formControl) && Util.isNotNullOrEmpty(formControl.value)) {
          // Convert all activeCategoriesForm input value to string and push in formObj
          formObj[key] = formControl.value.toString();
        } else {
          formObj[key] = '';
        }
      });
      // add listingType for manual because at the moment this step use for listingType : classified
      formObj[ItemAttributes.LISTING_TYPE] = 'classified';
      // add onRoadCostsIncluded value follow format string "true/false"
      if (this.isIncludeCategory('onRoadCostsIncluded') && Util.isNullOrEmpty(this.activeCategoriesForm.controls[ItemAttributes.ON_ROAD_COSTS_INCLUDED].value)) {
        formObj[ItemAttributes.ON_ROAD_COSTS_INCLUDED] = AttributeConstant.FALSE;
      }
      // save item information in local
      this.sessionStorageService.set(TAJR_LIST_AN_ITEM_DETAILS, JSON.stringify(formObj));
      // redirect to list-an-item-confirm page
      this.router.navigate([LIST_AN_ITEM_CONFIRM], {queryParams: {action: this.action}});
      // send request to get fees
      // this.sendRequestToGetFees();
    } else {
      // scroll to the top browser
      this.scrollToError();
    }
  }

  /**
   *  scroll to input has invalid value follow class "ng-invalid" was added by angular form
   */
  scrollToError(): void {
    // get the first input invalid, it is the second element has class ".ng-invalid" in angular form
    const firstElementWithError = document.querySelectorAll('.ng-invalid')[1];
    // scroll to that element if it exit
    if (firstElementWithError) {
      firstElementWithError.scrollIntoView({behavior: SMOOTH, block: 'center'});
    }
  }

  /**
   *listings - Fees for a new listing.
   * collect item details from step 1
   * tajrListAnItem => already get it from ngOnit()
   * collect item details from step 2
   * tajrListAnItemPhotos  => already get it from ngOnit()
   */
  sendRequestToGetFees() {
    // change loading status to show loading when sending request
    this.loading = true;
    // collect item information in step 3
    if (this.sessionStorageService.get(TAJR_LIST_AN_ITEM_DETAILS)) {
      this.tajrListAnItemDetails = JSON.parse(this.sessionStorageService.get(TAJR_LIST_AN_ITEM_DETAILS));
    }

    const listing = new Listing();
    // convert categories id format "0001-0100-0200" to "0200" use to send request follow api design doc
    listing.category_id = this.tajrListAnItem.categoryId.split('-')[2];
    listing.photos = this.tajrListAnItemPhotos.photos;
    listing.duration = this.tajrListAnItemDetails.duration;
    listing.embedded_content_options = this.tajrListAnItemPhotos.embedded_content_options;
    // add all item attribute from step 1 and step 3 follow design doc
    listing.attributes = [];
    // list attribute don't put in dataToSend.attributes follow design document. ex photos put in dataToSend.photosot in dataToSend.attributes[photo]
    const arrayAttributeIdAvoid = ['category_id', 'duration', 'photos', 'embedded_content_options', 'title'];
    const constAttribute = Object.assign(this.tajrListAnItem, this.tajrListAnItemDetails);
    for (let key in constAttribute) {
      if (!arrayAttributeIdAvoid.includes(key) && Util.isNotNullOrEmpty(this.categoryDetailData.attributes.find(attr => attr.id === key)) && Util.isNotNullOrEmpty(constAttribute[key])) {
        let item = {};
        item[ID] = key;
        item[VALUE] = constAttribute[key];
        // push item in listing
        listing.attributes.push(item);
      }
    }

    switch (this.action) {
      case EDIT:
        // send request to get fees
        this.feesForNewListing = this.productService.getFeesForEditListing(this.tajrListAnItem.listingId, listing).subscribe(data => {
          // if user has information response
          if (200 === data.status) {
            // save item information in local
            this.sessionStorageService.set(TAJR_LIST_AN_ITEM_FEES, JSON.stringify(data.body));
            // set 0.5s will go to step 4 because save finish TAJR_LIST_AN_ITEM_FEES in local want 0.3s
            setTimeout(() => {
              // go to list-an-item-confirm page with action edit
              this.router.navigate([LIST_AN_ITEM_CONFIRM], {queryParams: {action: EDIT}});
            }, 500);
          }
          // reset submitted
          this.submitted = false;
        }, err => {
          // User login fail, close loading status on button
          this.loading = false;
          // get error message response from server
          if (err && err.error) {
            // show error
            this.errorMessage = this.languageService.messageFromServer(err.error.message);

          } else {
            // service error message
            this.errorMessage = this.someThingWentWrongMessage;
          }
          // scroll to top to see error response from serve
          if (isPlatformBrowser(this.platformId)) {
            window.scroll({top: 0, behavior: SMOOTH});
          }
          // reset submitted
          this.submitted = false;
        });
        break;
      default:
        // send request to get fees
        this.feesForNewListing = this.productService.getFeesForNewListing(listing).subscribe(data => {
          // if user has information response
          if (200 === data.status) {
            // save item information in local
            this.sessionStorageService.set(TAJR_LIST_AN_ITEM_FEES, JSON.stringify(data.body));
            // set 0.5s will go to step 4 because save finish TAJR_LIST_AN_ITEM_FEES in local want 0.3s
            setTimeout(() => {
              switch (this.action) {
                case SELL_SIMILIAR:
                  // go to list-an-item-confirm page  with action sellSimilar
                  this.router.navigate([LIST_AN_ITEM_CONFIRM], {queryParams: {action: SELL_SIMILIAR}});
                  break;
                default:
                  // go to list-an-item-confirm page with action create
                  this.router.navigate([LIST_AN_ITEM_CONFIRM], {queryParams: {action: CREATE}});
                  break;
              }

            }, 500);

          }
          // reset submitted
          this.submitted = false;
        }, err => {
          // User login fail, close loading status on button
          this.loading = false;
          // get error message response from server
          if (err && err.error) {
            // show error
            this.errorMessage = this.languageService.messageFromServer(err.error.message);

          } else {
            // service error message
            this.errorMessage = this.someThingWentWrongMessage;
          }
          // scroll to top to see error response from serve
          if (isPlatformBrowser(this.platformId)) {
            window.scroll({top: 0, behavior: SMOOTH});
          }
          // reset submitted
          this.submitted = false;
        });
        break;
    }
  }

  /**
   * navigate to listing-an-item page
   * @param listing
   */
  navigateToListAnItemPage(url: string) {
    this.router.navigate([url], {queryParams: {action: this.action}});
  }
}


