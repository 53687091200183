<!-- content-->
<div class="bg-light">
  <div class="container-fluid" *ngIf="listingDetails">

    <!-- breadcrumb -->
    <div class="row">
      <div class="col pt-3">
        <nav aria-label="breadcrumb ">
          <ol class="breadcrumb bg-light p-0 pt-1 category" dir="rtl" *ngIf="0 < showCategoriesBreadcrumb().length">
            <li class="breadcrumb-item pl-0" *ngFor="let item of showCategoriesBreadcrumb();let last = last;let index=index">
              <a [routerLink]="['/search']" [queryParams]="{ categoryId: splitCatergoty(index)  }"
                 *ngIf="!last"> {{item.label}}
              </a>
              <a href="javascript:void(0);" [routerLink]="['/search']"
                 [queryParams]="{ categoryId: splitCatergoty(index)  }" *ngIf="last" class="text-decoration-none">
                {{item.label}}
              </a>
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <!--  seller option-->
    <div class="row py-3 sellerOptions mb-3 border" dir="rtl" *ngIf="checkUserLoginStatus() === true">
      <div class="col pt-3">
        <p class="font-weight-bold text-warning text-right mb-0 h5">
          <!--Seller's options -->خيارات البائع‎</p>
        <div class="dropdown-divider"></div>
        <div class="row">
          <div class="col">
            <div class="d-flex justify-content-between align-content-center">
              <p class="text-secondary text-right mb-1 startDate-displayName">
                {{showAttributesLable("startDate", "dates")}}
              </p>
              <p class="text-secondary text-right mb-1 startDate">
                {{showStartDateText()}}
              </p>
            </div>
            <div class="d-flex justify-content-between align-content-center">
              <p class="text-secondary text-right mb-1 askingPrice-displayName">
                {{showAttributesLable("askingPrice", "numbers")}}
              </p>
              <p class="text-secondary text-right mb-1 askingPrice">
                {{showAttributesValue("askingPrice", "numbers")}}  ر.س
              </p>
            </div>
            <div class="d-flex justify-content-between align-content-center">
              <p class="text-secondary text-right mb-1 orNearOffer-displayName">
                {{showAttributesLable("orNearOffer", "bool")}}
              </p>
              <p class="text-secondary text-right mb-1 orNearOffer">
                {{showAttributesValue("orNearOffer", "bool")}}
              </p>
            </div>

          </div>
        </div>
      </div>
      <div class="col-12 col-md-3 align-self-center">
        <br>
        <button class="btn btn-primary btn-block"
                (click)="listingService.editListingService(listingId, categoryDetailsById, 'edit')"
                *ngIf="checkListingStatus() == 'active'">
          <!--Edit -->عدل بيانات الإعلان
        </button>
        <button class="btn btn-primary btn-block"
                (click)="listingService.editListingService(listingId, categoryDetailsById,'sellSimilar')">
          <!-- Sell similar item-->اضف اعلان مماثل‎
        </button>

        <button class="btn btn-primary btn-block" (click)="openConfirmDialog()"
                *ngIf="checkListingStatus() != 'deleted'">
          <!-- Withdraw-->امسح الإعلان
        </button>
        <br>
      </div>
    </div>
    <!--title and subtitle on md,lg,xl screen-->
    <div class="row">
      <div class="col pl-0">
        <h3 class="font-weight-bold text-secondary text-right mb-3 title" dir="rtl">
          {{showAttributesValue("title", "strings")}}
          ‬</h3>
        <p class="text-secondary text-right mb-1 subtitle" dir="rtl">
          {{showAttributesValue("subtitle", "strings")}}
        </p>
        <p class="text-right" dir="ltr" *ngIf="listingDetails && showWatchListMessage() != ''">
          <small class="badge border badge-light text-warning">
            <!-- people added this to their Watchlist 34-->
            {{showWatchListMessage()}}
          </small>
        </p>
      </div>
    </div>
    <!--carousel-->
    <div class="row">
      <div class="col col-md-auto col-lg-4 d-none d-lg-block">
        <div class="row">
          <div class="col py-3">
            <form>
              <div class="row">
                <div class="col-12 col-md-auto" [ngClass]="screenWidth >768?'':'text-right'">
                  <!-- address -->
                  <p class="mb-0">
                    <img class="img-fluid pr-1" width="16"
                         src="../../../assets/images/icon-images/tajr-icon-000040.png">
                    <small class="text-secondary location">{{showLocation()}}</small>
                  </p>
                  <!-- listing time -->
                  <p class="text-secondary mb-0">
                    <small class="startDate small" [ngClass]="hignlightEndDate === true? 'text-danger':''">
                      {{showStartDateText()}}
                    </small>
                  </p>
                  <!--item status-->
                  <p class="mb-0" *ngIf="checkListingStatus() === 'deleted' ">
                    <small class="badge badge-danger">
                      {{deletedMess}}
                    </small>
                  </p>
                  <p class="mb-0" *ngIf="checkListingStatus() === 'expired' ">
                    <small class="badge badge-primary">
                      {{expiredMess}}
                    </small>
                  </p>
                </div>
              </div>
              <div class="dropdown-divider"></div>
              <!-- Asking Price -->
              <div class="row">
                <div class="col">
                  <div class="d-flex align-items-center pt-3"
                       [ngClass]="{
                       'justify-content-between':screenWidth > 768,
                        'justify-content-center':screenWidth <= 768
                       }">
                    <div>
                      <p class="text-dark mb-0 askingPrice"
                         [ngClass]="{
                       'h4':screenWidth > 991,
                       'h5':screenWidth > 768 && screenWidth <= 991
                       }" dir="rtl">
                        {{showAttributesValue("askingPrice", "numbers")}}  ر.س
                      </p>
                      <!-- Or Near Offer -->
                      <p class="text-dark mb-0 orNearOffer"
                         [ngClass]="{
                        'text-right':screenWidth <= 768
                       }">
                        {{showAttributesLable("orNearOffer", "bool")}}
                      </p>
                    </div>
                    <p class="text-dark mb-0 h5 d-md-none">&nbsp; : &nbsp;</p>
                    <p class="font-weight-bold text-dark mb-0  askingPrice-displayName">
                      {{showAttributesLable("askingPrice", "numbers")}}
                    </p>
                  </div>
                </div>
              </div>
              <div class="dropdown-divider"></div>
              <!-- On road costs -->
              <div class="row">
                <div class="col">
                  <div class="d-flex justify-content-between align-items-center">
                    <p class="text-dark mb-0 h5 onRoadCostsIncluded">
                      {{showAttributesValue("onRoadCostsIncluded", "bool")}}
                    </p>
                    <p class="text-secondary small mb-0 onRoadCostsIncluded">
                      {{showAttributesLable("onRoadCostsIncluded", "bool")}}
                    </p>
                  </div>
                </div>
              </div>
              <div class="dropdown-divider" *ngIf="checkUserLoginStatus() !== true"></div>
              <!-- add watch list button -->
              <div class="row" *ngIf="checkUserLoginStatus() !== true">
                <div class="col">

                  <!-- show remove watchlist button if item already in watchlist -->
                  <button type="button" (click)="removeToWatchList()" *ngIf="isAddedToWatchList == true"
                          class="btn btn-primary btn-block d-flex align-items-center justify-content-center">
                    <img class="img-fluid" src="../../../assets/images/icon-images/tajr-icon-000041.png">
                    <!-- Added to watchlist--> تمت إضافة الإعلان إلى قائمة المراقبة
                  </button>

                  <!-- show add watchlist button if item already not in watchlist -->
                  <button type="button" (click)="addToWatchList()" *ngIf="isAddedToWatchList == false"
                          class="btn btn-primary btn-block d-flex align-items-center justify-content-center">
                    <span class="material-icons">grade</span>
                    <!-- Add to watchlist--> أضف الاعلان الى قائمة المراقبه
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-12 col-md col-lg-8">
        <swiper
          [navigation]="getNavigation()"
          (swiper)="onSwiper($event)"
          (slideChange)="onSlideChange($event)"
          *ngIf="0 < photoList.length || 0 < videoList.length"
          class="bg-white mainCarouselListItem">
          <ng-template swiperSlide *ngFor="let item of photoList; let index=index">
            <mat-card class="rounded-lg bg-white p-0">
              <mat-card-header class="embed-responsive embed-responsive-4by3">
                <img class="img-fluid embed-responsive-item tajrImage" [src]="item.photoListing"
                     [alt]="item.imageTitle"
                     (click)="openDialog()">
              </mat-card-header>
            </mat-card>
          </ng-template>

          <!--show video-->
          <ng-template swiperSlide class="videoSlide" *ngFor="let item of videoList">
            <div class="embed-responsive embed-responsive-4by3">
              <iframe class="embed-responsive-item"
                      [src]="item.videoUrl" frameborder="0" allowfullscreen>
              </iframe>
            </div>
          </ng-template>

        </swiper>

        <!-- Carousel indicator with picture-->
        <div class="row m-0 mt-4">
          <div class="col p-0">
            <div class="row m-0">
              <div class="align-self-center "
                   [ngClass]="0 < videoList.length ? 'col-10 px-1 bg-white':'col'">
                <swiper
                  [navigation]="true"
                  [slidesPerView]="getSlidesPerView()"
                  [spaceBetween]="spaceBetween"
                  *ngIf="0 < photoList.length"
                  class="col px-3 py-1 subCarouselListItem"
                  [ngClass]="screenWidth <= 575 ? 'px-1':''">
                  <ng-template swiperSlide *ngFor="let item of photoList; let i = index">
                    <mat-card class="rounded-lg p-0 embed-responsive embed-responsive-4by3">
                      <mat-card-header class="embed-responsive-item">
                        <img class="img-fluid embed-responsive-item tajrImage" [src]="item.photoCarousel" [alt]="item.imageTitle"
                             (click)="moveSlideOnMainCarouselToShowPhotos(i)">
                      </mat-card-header>
                    </mat-card>
                  </ng-template>
                </swiper>

              </div>
              <!-- show video -->
              <div class="col-2 p-0 align-self-center"
                   [ngClass]="screenWidth <= 575 ? 'px-1':''"
                   *ngIf="0 < videoList.length">
                <div class="embed-responsive embed-responsive-4by3 rounded-lg">
                  <a type="button" href="javascript:void(0);" (click)="moveToLastSlide()">
                    <img class="img-fluid embed-responsive-item tajrImage carouselStyle"
                         [src]="videoList[0].thumbnail">
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--title and subtitle on xs,sm screen-->
        <div class="row d-lg-none" dir="rtl">
          <div class="col col-sm-8 col-md-6 offset-sm-4 offset-md-6 mt-2" [ngClass]="screenWidth >991?'':'text-right'">
            <!-- address -->
            <p class="mb-0">
              <img class="img-fluid pr-1" width="16" src="../../../assets/images/icon-images/tajr-icon-000040.png">
              <small class="text-secondary location">{{showLocation()}}</small>
            </p>
            <!-- listing time -->
            <p class="text-secondary mb-0">
              <small>
                <small class="startDate" [ngClass]="hignlightEndDate === true? 'text-danger':''">
                  {{showStartDateText()}}
                </small>
              </small>
            </p>
            <p class="mb-0" *ngIf="checkListingStatus() === 'deleted' ">
              <small class="badge badge-danger">
                {{deletedMess}}
              </small>
            </p>
            <p class="mb-0" *ngIf="checkListingStatus() === 'expired' ">
              <small class="badge badge-primary">
                {{expiredMess}}
              </small>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col col-sm-8 col-md-6 offset-sm-4 offset-md-6 d-lg-none">
            <div class="row">
              <div class="col py-3">
                <form>
                  <!-- Asking Price -->
                  <div class="row">
                    <div class="col">
                      <div class="d-flex align-items-center pt-3"
                           [ngClass]="{
                       'justify-content-between':screenWidth > 768,
                        'justify-content-center':screenWidth <= 768
                       }">
                        <div>
                          <p class="text-dark mb-0 askingPrice"
                             [ngClass]="{
                       'h4':screenWidth > 991,
                       'h5':screenWidth <= 768
                       }" dir="rtl">
                            {{showAttributesValue("askingPrice", "numbers")}}  ر.س
                          </p>
                          <!-- Or Near Offer -->
                          <p class="text-dark mb-0 orNearOffer"
                             [ngClass]="{
                        'text-right':screenWidth <= 768
                       }">
                            {{showAttributesLable("orNearOffer", "bool")}}
                          </p>
                        </div>
                        <p class="text-dark mb-0 h5 d-md-none">&nbsp; : &nbsp;</p>
                        <p class="font-weight-bold text-dark mb-0  askingPrice-displayName">
                          {{showAttributesLable("askingPrice", "numbers")}}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="dropdown-divider"></div>
                  <!-- On road costs -->
                  <div class="row">
                    <div class="col">
                      <div class="d-flex justify-content-between align-items-center">
                        <p class="text-dark mb-0 h5 onRoadCostsIncluded">
                          {{showAttributesValue("onRoadCostsIncluded", "bool")}}
                        </p>
                        <p class="text-secondary small mb-0 onRoadCostsIncluded">
                          {{showAttributesLable("onRoadCostsIncluded", "bool")}}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="dropdown-divider" *ngIf="checkUserLoginStatus() !== true"></div>
                  <!-- add watch list button -->
                  <div class="row" *ngIf="checkUserLoginStatus() !== true">
                    <div class="col">
                      <!-- show remove watchlist button if item already in watchlist -->
                      <button type="button" (click)="removeToWatchList()" *ngIf="isAddedToWatchList == true"
                              class="btn btn-primary btn-block d-flex align-items-center justify-content-center">
                        <img class="img-fluid" src="../../../assets/images/icon-images/tajr-icon-000041.png">
                        <!-- Added to watchlist--> تمت إضافة الإعلان إلى قائمة المراقبة
                      </button>

                      <!-- show add watchlist button if item already not in watchlist -->
                      <button type="button" (click)="addToWatchList()" *ngIf="isAddedToWatchList == false"
                              class="btn btn-primary btn-block d-flex align-items-center justify-content-center">
                        <span class="material-icons">grade</span>
                        <!-- Add to watchlist--> أضف الاعلان الى قائمة المراقبه
                      </button>

                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- Key details - Description form Questions & Answers-->
        <div class="row">
          <div class="col">
            <!-- Key details -->
            <div class="row" *ngIf="0 < vehicleDetailsList ().length">
              <div class="col pt-3">
                <p class="font-weight-bold text-warning text-right mb-1 pt-2">
                  <!-- Key details-->التفاصيل الرئيسية</p>
              </div>
            </div>
            <div class="dropdown-divider mb-0" *ngIf="0 < vehicleDetailsList ().length"></div>
            <div class="pb-2">
              <div class="row attributes m-0" dir="rtl" *ngFor="let item of vehicleDetailsList (); let last = last">

                <div class="col-12 col-sm-4 col-md-3 col-3 pt-1">
                  <p class="text-secondary font-weight-bold text-right mb-1">{{item.attributeIdLabel}}</p>
                </div>
                <div class="col-12 col-sm-8 col-md-9 col-3"
                     [ngClass]="screenWidth <= 575 ? '': 'pt-1' ">
                  <p class="text-secondary text-right mb-1">{{item.attributeIdValue}}</p>
                </div>

                <div class="col-12 d-sm-none" *ngIf="!last">
                  <div class="dropdown-divider m-0"></div>
                </div>
              </div>
            </div>

            <!-- Features -->
            <div class="row" *ngIf="0 < vehicleFeaturesList().length">
              <div class="col">
                <p class="font-weight-bold text-warning text-right mb-1 pt-2">
                  <!--Features -->مواصفات السيارة
                </p>
                <div class="dropdown-divider mb-3"></div>
              </div>
            </div>
            <div class="row vehicleFeaturesItems py-2 m-0" dir="rtl" *ngIf="0 < vehicleFeaturesList().length">
              <div class="col-12 col-sm-4 col-md-3" *ngFor="let item of vehicleFeaturesList()">
                <p class="text-secondary text-right mb-1">
                  {{item.attributeIdValue}}
                </p>
              </div>
            </div>

            <!-- Description -->
            <div class="row" *ngIf="showAttributesValue('description', 'strings')">
              <div class="col">
                <p class="font-weight-bold text-warning text-right mb-1 pt-2">
                  <!--Description -->  التفاصيل
                </p>
                <div class="dropdown-divider mb-3"></div>
              </div>
            </div>
            <div class="row" *ngIf="showAttributesValue('description', 'strings')">
              <div class="col">
              <textarea cdkTextareaAutosize
                        class="text-secondary bg-light text-right border-0 text-description overflow-hidden" readonly
                        dir="rtl">{{showAttributesValue('description', 'strings')}}
              </textarea>
              </div>
            </div>

            <!-- form Questions & Answers -->
            <div class="row" *ngIf="false">
              <div class="col pt-3">
                <p class="font-weight-bold text-warning text-right mb-1 pt-2">
                  <!--Questions & Answers -->الأسئلة والأجوبة</p>
                <div class="dropdown-divider mb-3"></div>
              </div>
            </div>
            <form class="bg-light px-3 pb-3 pt-2" *ngIf="false">
              <!-- question1 -->
              <div class="form-group mb-0">
                <div class="row pt-4" dir="rtl">
                  <div class="col-auto">
                    <h6 class="font-weight-bold rounded-circle bg-primary text-white p-1 mb-0">س
                    </h6>
                  </div>
                  <div class="col text-right">
                    <div class="row">
                      <div class="col">
                        <p class="font-weight-bold text-dark text-break mb-0">
                          <!-- Hi any interior, engine bay etc photos? Is it push start? Cheers-->مرحبا
                          عندك
                          صور للداخليه؟
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col d-flex justify-content-start align-items-center">
                        <p class="text-dark mb-0">
                          <a href="javascript:void(0);" class="nav-link text-secondary p-0  ">
                            <small class="memberAskQuestion">memberAskQuestion</small>
                          </a>
                        </p>
                        &nbsp;<p class="text-dark mb-0">
                        <small class="text-primary timeToPutQuestion">
                          <!-- 11:56 am, Wed, 21 Nov-->2019-11-21 الأربعاء
                        </small>
                      </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Answers1 -->
              <div class="form-group mb-0">
                <div class="row pb-1" dir="rtl">
                  <div class="col-auto">
                    <h6 class="font-weight-bold rounded-circle bg-light text-dark p-1 mb-0">
                      &nbsp;ج&nbsp;
                    </h6>
                  </div>
                  <div class="col text-right pt-1 pb-1">
                    <div class="row">
                      <div class="col">
                        <p class="text-dark text-right text-break mb-0" dir="rtl">
                                                <span class="answerQuestions"> I will take some tomorrow as I'm out
                                                    tonight.
                                                    Its key start.
                                                </span>
                          <small class="text-primary timeToAnswer" dir="rtl">
                            <!-- Wednesday, 21 November 2018-->2019-11-21 الأربعاء
                          </small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- question2 -->
              <div class="form-group mb-0">
                <div class="row pt-4" dir="rtl">
                  <div class="col-auto">
                    <h6 class="font-weight-bold rounded-circle bg-primary text-white p-1 mb-0">س
                    </h6>
                  </div>
                  <div class="col text-right">
                    <div class="row">
                      <div class="col">
                        <p class="font-weight-bold text-dark text-break mb-0">
                          Who do you use for insurance on something as unique as this ?
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col d-flex justify-content-start align-items-center">
                        <p class="text-dark mb-0">
                          <a href="javascript:void(0);" class="nav-link text-secondary p-0  ">
                            <small class="memberAskQuestion">memberAskQuestion</small>
                          </a>
                        </p>
                        &nbsp;<p class="text-dark mb-0">
                        <small class="text-primary timeToPutQuestion">
                          <!-- 11:56 am, Wed, 21 Nov-->2019-11-21 الأربعاء
                        </small>
                      </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Answers2 -->
              <div class="form-group mb-0">
                <div class="row pb-1" dir="rtl">
                  <div class="col-auto">
                    <h6 class="font-weight-bold rounded-circle bg-light text-dark p-1 mb-0">
                      &nbsp;ج&nbsp;
                    </h6>
                  </div>
                  <div class="col text-right pt-1 pb-1">
                    <div class="row">
                      <div class="col">
                        <p class="text-dark text-right text-break mb-0" dir="rtl">
                                                <span class="answerQuestions">
                                                    We just use Tower Insurance, we have since we purchased in 2004.
                                                    Actual car engine isn't modified. Bodykit is after-market. Air
                                                    filter is after-market and exhaust is after-market
                                                </span>
                          <small class="text-primary timeToAnswer" dir="rtl">
                            <!-- Wednesday, 21 November 2018-->2019-11-21 الأربعاء
                          </small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- asked a question button -->
              <div class="row pt-3">
                <div class="col">
                  <div class="collapse show" id="collapseExample">
                    <div class="row">
                      <div class="col">
                        <!-- text input -->
                        <textarea class="form-control text-secondary text-right" rows="3"></textarea>
                        <small class="form-text text-muted float-left">
                          500 characters remaining
                        </small>
                      </div>
                      <div class="col-auto">
                        <h6 class="font-weight-bold rounded-circle bg-primary text-white p-1 mb-0">س
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row pt-3">
                <div class="col text-right">
                  <button type="button" class="btn btn-primary" data-toggle="collapse" href="#collapseExample"
                          role="button" aria-expanded="true" aria-controls="collapseExample">
                    <!--Ask a Question -->اطرح سؤال
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--blank space-->
<div class="bg-light p-5 w-100 d-none d-sm-block position-absolute" *ngIf="listingDetails">
  <div class="row ">
    <div class="col pb-3">
      <div class="p-5"></div>
      <div class="p-5"></div>
    </div>
  </div>
</div>
<!--contact seller for bottom of page-->
<div class="container-fluid bg-light" *ngIf="listingDetails">
  <div class="row pt-3">
    <div class="col col-sm-5 col-lg-6 d-none d-sm-block"></div>
    <div class="col">
      <form class="bg-white border rounded p-3 w-100"
            *ngIf="listingDetails">
        <!-- seller name -->
        <div class="row">
          <div class="col">
            <p class="font-weight-bold text-right mb-1">
              <a
                (click)="navigateService.navigateToMemberListingPage(listingDetails.member.member_id, listingDetails.member.username)"
                class="nav-link text-secondary p-0 sellerName">
                {{listingDetails.member.username}}
              </a>
            </p>
          </div>
        </div>
        <!-- Member since -->
        <div class="row">
          <!-- Seller's other listings -->
          <div class="col">
            <p class="text-secondary mb-0">
              <a
                (click)="navigateService.navigateToMemberListingPage(listingDetails.member.member_id, listingDetails.member.username)"
                class="nav-link text-secondary p-0 goToMemberListings">
                <small>
                  <img class="img-fluid" src="../../../assets/images/icon-images/tajr-icon-000017.png">
                  <!-- Seller's other listings-->اعلانات البائع الأخرى
                </small>
              </a>
            </p>
          </div>
          <div class="col-auto">
            <p class="text-secondary text-right mb-0">
              <small>
                <!-- Member since-->عضو منذ
              </small>
            </p>
            <p class="text-dark text-right mb-2">
              <small class="date_created">{{showDateCreate()}}</small>
            </p>
          </div>
        </div>
        <!-- Location -->
        <div class="row">
          <div class="col">
            <p class="text-secondary text-right mb-0">
              <small>
                <!--Location -->موقع البائع
              </small>
            </p>
            <p class="text-dark text-right mb-2">
              <small class="location">{{showLocation()}}</small>
            </p>
          </div>
        </div>
        <!-- showMobileNumber -->
        <div class="row showMobileNumberArea">
          <div class="col">
            <p class="text-secondary text-right mb-0">
              <small class="showMobileNumber-displayName">{{showAttributesLable("showMobileNumber", "strings")}}</small>
            </p>
            <p class="text-dark text-right mb-2">
              <small class="showMobileNumber">  {{showAttributesValue("showMobileNumber", "strings")}}</small>
            </p>
          </div>
        </div>
        <div type="button" *ngIf="checkDisplayContactButton()" (click)="handleClickContactButton()" class="btn btn-primary btn-block d-flex align-items-center justify-content-center">تواصل مع البائع</div>
      </form>
    </div>
  </div>
</div>
<!--Report this listing and social icon-->
<div class="bg-light">
  <div class="container-fluid" *ngIf="listingDetails">
    <div class="row pt-3 mb-1">
      <div class="col text-right mb-2"
           *ngIf="checkUserLoginStatus() != true">
        <a href="javascript:void(0);" class="text-secondary" (click)="openReportListingModal()">
          <!--Report this listing -->الإبلاغ عن هذا الاعلان</a>
      </div>
    </div>
    <div class="row pb-3">
      <div class="col text-right">
        <a href="https://www.snapchat.com/" class="d-none" target="_blank">
          <i class="fa fa-snapchat FONT_SIZE_28 text-secondary" aria-hidden="true"></i>
        </a>
        <a href="https://www.instagram.com/" class="ml-2 d-none" target="_blank">
          <i class="fa fa-instagram FONT_SIZE_28 text-secondary" aria-hidden="true"></i>
        </a>
        <a [href]="twitterUrl" data-lang="ar" class="ml-2" target="_blank">
          <i class="fa fa-twitter FONT_SIZE_28 text-secondary" aria-hidden="true"></i>
        </a>
        <a [href]="whatsappUrl" data-lang="ar"class="ml-2" target="_blank">
          <i class="fa fa-whatsapp FONT_SIZE_28 text-secondary" aria-hidden="true"></i>
        </a>
      </div>
    </div>
  </div>
</div>
<!--the spinner when page load not finished-->
<div class="d-flex justify-content-center min-vh-100 py-5 my-5" *ngIf="!listingDetails">
  <mat-spinner class="mt-5"></mat-spinner>
</div>
<!-- blue line  -->
<div class="bg-primary pt-2"></div>
