<div class="bg-light">
  <div class="container-fluid d-none d-md-block py-2">
    <div class="row">
      <!-- input search and Categories button-->
      <div class="col">
        <form>
          <div class="form-group row mb-0">
            <div class="col-auto">
              <!-- [search] button -->
              <button type="submit" class="btn btn-primary text-white rounded px-5"
                      (click)="navigateToSearchPage()">
                <!-- Search-->أبحث
              </button>
            </div>
            <div class="col">
              <input type="text" dir="rtl"
                     class="form-control text-right rounded-pill py-0 mx-1 border"
                     placeholder="أبحث"
                     [(ngModel)]="keySearch"
                     (input)="searchItem()"
                     [formControl]="myFormControl"
                     [matAutocomplete]="auto">
              <mat-autocomplete #auto="matAutocomplete" class="py-2 w-100">
<!--                <mat-option class="h-auto"-->
<!--                            *ngFor="let option of searchOption">-->
<!--                  <h6 class="font-weight-bold mb-2"-->
<!--                      (click)="navigateToSearchPageWithListing(option.category, option.label )" >-->
<!--                    {{option.label}}-->
<!--                    <br/>-->
<!--                    <small>{{option.categoryBreadcrumb}}</small>-->
<!--                  </h6>-->

<!--                </mat-option>-->
              </mat-autocomplete>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
