import {AfterViewInit, Component, HostListener, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {ProfileDetails} from '../../modules/models/profile.details';
import {Subscription} from 'rxjs';
import {LanguageService} from '../../services/language.service';
import {AccountService} from '../../services/account.service';
import {ImageModel} from '../../modules/models/image.model';
import {NotificationServices} from '../../services/notification.services';
import {isPlatformBrowser} from "@angular/common";
import {SMOOTH} from "../../shared/global";

@Component({
  selector: 'app-my-photos',
  templateUrl: './my-photos.component.html',
  styleUrls: ['./my-photos.component.css']
})
export class MyPhotosComponent implements OnInit, AfterViewInit {
  loadMoreMessage = this.languageService.findMessageByLanguage('loadMore');
  loadingMess = this.languageService.findMessageByLanguage('loading');
  someThingWentWrongMessage = this.languageService.findMessageByLanguage('someThingWentWrong');

  // user details receive from server
  public profileDetails: ProfileDetails;
  // user details save from cookie
  public userDetails: ProfileDetails;
  //  subscription to observable request get member's listings
  private subscription: Subscription;
  private subscriptionPhotosMetaData: Subscription;
  private deletePhotoSubscription: Subscription;
  // width of screen size on browser
  public screenWidth: number;
  // attribute to show loading button
  public loading = false;
  public deletingItem = false;
  public loadPageFinished = false;
  public pageState = '';
  public errorMessage = '';
  public photoList: Array<ImageModel> = [];
  // style of carousel image
  opacityImage = {
    opacity: '0.5'
  };

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    // get language message from local
    private languageService: LanguageService,
    private accountService: AccountService,
    private notify: NotificationServices,
  ) {
  }

  ngOnInit() {
    // get width of device
    this.screenWidth = window.innerWidth;
    //  get all photo br member
    this.getPhotosByIdMember();
  }

  ngAfterViewInit() {
    this.loadPageFinished = true;
  }

  /**
   * use HostListener to  updated on resize:
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  getScreenSize(event) {
    this.screenWidth = window.innerWidth;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const currentPosition = (document.documentElement.scrollTop || document.body.scrollTop);
    const maxPosition = document.documentElement.scrollHeight;
    if ((currentPosition / maxPosition > 0.1)) {
      this.holdOnScrollDown(currentPosition);
      this.getPhotosByIdMember();
    }
  }

  /**
   * Photos - Get all photo IDs by member.
   * These images will on on image modal
   */
  getPhotosByIdMember() {
    if (this.pageState !== undefined && this.pageState !== null && this.loading === false) {
      this.loading = true;
      this.subscription = this.accountService.getAllPhotosIdByMember(this.pageState).subscribe(data => {
        // if data available
        if (200 === data.status && 0 < data.body.photo_ids.length) {
          for (let i = 0; i < data.body.photo_ids.length; i++) {
            const image = new ImageModel();
            image.photo_id = data.body.photo_ids[i];
            image.checkBoxValue = 'false';
            this.subscriptionPhotosMetaData = this.accountService.getPhotosMetaData(data.body.photo_ids[i]).subscribe(imageData => {
              if (200 === imageData.status) {
                image.file_name = imageData.body.file_name;
                image.photo_urls = imageData.body.photo_urls['160x120'];
                this.photoList.push(image);
              }
              // if last request has response will allow other request
              if (i === data.body.photo_ids.length - 1) {
                this.loading = false;
              }
            });
          }
          // set pageState value use to send request later
          if (undefined !== data.body.page_state && null !== data.body.page_state) {
            this.pageState = data.body.page_state;
          } else {
            this.pageState = null;
          }
        }
      });
    }
  }

  /**
   * select photo checkbox by click on image
   * @param photoId photos id
   */
  selectPhoto(photoId: string) {
    // if length of image file lower 20 and this maodel image is not added in files[]
    if (undefined !== photoId) {
      const photo = this.photoList.find(item => item.photo_id === photoId);
      // change checkbox value
      if (photo.checkBoxValue === 'true') {
        photo.checkBoxValue = 'false';
      } else {
        photo.checkBoxValue = 'true';
      }
    }
  }

  /**
   * select all photos
   */
  selectAllPhotos() {
    this.photoList.forEach(photo => {
      photo.checkBoxValue = 'true';
    });
  }

  /**
   * un select all photos
   */
  unSelectAllPhotos() {
    this.photoList.forEach(photo => {
      photo.checkBoxValue = 'false';
    });
  }

  /**
   * send request to delete user photo save in serve
   * @param photoId  photoId will be deleted
   */
  deletePhoto(photoId: string) {
    if (undefined !== photoId && undefined !== this.photoList.find(photo => photo.photo_id === photoId)) {
      this.deletePhotoSubscription = this.accountService.deletePhotoByMember(photoId).subscribe(data => {
        if (200 === data.status) {
          // remove photo in photoList
          const index = this.photoList.findIndex(photo => photo.photo_id === photoId);
          this.photoList.splice(index, 1);
          // show delete button
          this.deletingItem = false;
          // auto load more image if photolist item lower 10
          if (0 !== this.photoList.length && 10 > this.photoList.length) {
            this.getPhotosByIdMember();
          }
        }

      }, err => {
        // get error message response from server
        if (err && err.error) {
          // show error
          this.errorMessage = this.languageService.messageFromServer(err.error.message);
          this.notify.showInfoNotificationMessage(this.errorMessage);
        } else {
          // service error message
          this.errorMessage = this.someThingWentWrongMessage;
          this.notify.showInfoNotificationMessage(this.errorMessage);
        }
        // scroll to top to see error response from serve
        if (isPlatformBrowser(this.platformId)) {
          window.scroll({top: 0, behavior: SMOOTH});
        }
        // reset loading buton
        this.deletingItem = false;
      });
    }
  }

  /**
   * send request to delete all photo user selected in browser
   */
  deleteAllSelectedPhoto() {
    this.photoList.forEach(photo => {
      if ('true' === photo.checkBoxValue) {
        this.deletingItem = true;
        this.deletePhoto(photo.photo_id);
      }
    });
  }

  /**
   * Hold On Scroll Down Position
   *
   * @param currentPosition The position will append element on screen.
   */
  holdOnScrollDown(currentPosition) {
    window.scrollTo({top: currentPosition, behavior: 'smooth'});
  }
}
