<!-- blue line  -->
<div class="bg-primary pt-2"></div>
<!--content-->
<div class="bg-light">
  <div class="container-fluid pb-5" *ngIf="profileDetails">

    <!-- user avatar -->
    <div class="row">
      <div class="col text-center">
        <br>
        <br class="d-none d-md-block">
        <a routerLink="/edit-profile" class="embed-responsive embed-responsive-1by1 profile rounded rounded-circle
       d-flex justify-content-between align-items-center mx-auto text-decoration-none">
          <img class="img-fluid embed-responsive-item"
               width="128px"
               src="../../../assets/images/logo-images/tajr-round-logo.png">
          <div class="overlay w-100 text-white font-weight-bold">Edit Profile</div>
        </a>
        <p class="font-weight-bold text-dark text-center mt-1">{{profileDetails.username}}</p>
      </div>
    </div>
    <div class="row">
      <div class="col col-sm-10 offset-sm-1 col-md-8 offset-sm-2 text-right">
        <button class="btn btn-primary" routerLink="/edit-profile" >
          <!-- Edit My Profile-->
          عدل بياناتي
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col col-sm-10 offset-sm-1 col-md-8 offset-sm-2">
        <div class="table-responsive">
          <table class="table table-borderless">
            <tbody>
            <tr class="border-bottom">
              <td>{{profileDetails.member_id}}</td>
              <td class="text-right">
                <!--Member number-->
                معرف العضو
              </td>
            </tr>
            <tr class="border-bottom">
              <td>{{profileDetails.mobile_number}}</td>
              <td class="text-right">
                <!--mobile number-->
                رقم الجوال
              </td>
            </tr>
            <tr class="border-bottom">
              <td>{{profileDetails.first_name}}</td>
              <td class="text-right">
                <!--First Name-->
                الاسم
              </td>
            </tr>
            <tr class="border-bottom">
              <td>{{profileDetails.last_name}}</td>
              <td class="text-right">
                <!--Last Name-->
                اسم العائلة
              </td>
            </tr>
            <tr class="border-bottom">
              <td>{{showLocation()}}</td>
              <td class="text-right">
                <!--Location -->
                موقع البائع
              </td>
            </tr>
            <tr class="border-bottom">
              <td>{{showDateCreate()}}</td>
              <td class="text-right">
                <!-- Member since-->
                عضو منذ
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col col-sm-10 offset-sm-1 col-md-8 offset-sm-2 text-right">
        <button class="btn btn-primary" (click)="navigateService.navigateToMemberListingPage(profileDetails.member_id, profileDetails.username)" >
          <!-- View member public page-->
          عرض صفحتي العامة
        </button>
      </div>
    </div>
<!--    <div class="row">-->
<!--      <div class="col col-md-10 offset-sm-1">-->
<!--        <div class="row" dir="rtl">-->
<!--          <div class="col-12 col-sm text-right">-->
<!--            <p class="font-weight-bold pt-3">&lt;!&ndash;My account balance &ndash;&gt;رصيدي</p>-->
<!--            <h3>0.00 <span> ر.س</span></h3>-->
<!--            <small class="text-secondary">&lt;!&ndash; Tajr account&ndash;&gt;رصيد تاجر</small>-->
<!--          </div>-->
<!--          <div class="col-12 col-sm align-self-center"-->
<!--               [ngClass]="screenWidth > 575? '':'text-right'">-->
<!--            <a href="javascript:void(0);" role="button" class="btn btn-primary">-->
<!--              أشحن رصيدك-->
<!--            </a>-->
<!--          </div>-->
<!--        </div>-->
<!--        <br>-->
<!--        <br>-->
<!--        <br>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</div>
<!-- blue line  -->
<div class="bg-primary pt-2"></div>
