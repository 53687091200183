import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prohibited-goods',
  templateUrl: './prohibited-goods.component.html',
  styleUrls: ['./prohibited-goods.component.css']
})
export class ProhibitedGoodsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
