import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ACTION, CREATE, EDIT, ItemAttributes, LIST_AN_ITEM, NOT_FOUND_PAGE, SMOOTH} from '../../shared/global';
import {Subscription} from 'rxjs';
import {ProductService} from '../../services/product.service';
import {ListingDetails} from '../../modules/models/listingDetails';
import {Util} from '../../utils/utils';
import {LanguageService} from '../../services/language.service';
import {DatePipe} from '@angular/common';
import {NavigateService} from '../../services/navigate.service';

@Component({
  selector: 'app-list-an-item-success',
  templateUrl: './list-an-item-success.component.html',
  styleUrls: ['./list-an-item-success.component.css']
})
export class ListAnItemSuccessComponent implements OnInit {
  yourClassifiedHasStartedMess = this.languageService.findMessageByLanguage('yourClassifiedHasStarted');
  editListingSuccessfulMess = this.languageService.findMessageByLanguage('editListingSuccessful');

  public listingId = '';
  public listingTitle = '';
  public listingDetailsRequest: Subscription;
  public listingDetails: ListingDetails;
  public listingResultMess: string;
  public action = CREATE;

  constructor(
    // service send request to login
    private productService: ProductService,
    // service use to get value from router
    private activatedroute: ActivatedRoute,
    private router: Router,
    // get language message from local
    private languageService: LanguageService,
    // service convert date format
    private datePipe: DatePipe,
    public navigateService: NavigateService,
  ) {
  }

  ngOnInit() {
    // init value
    this.listingResultMess = this.yourClassifiedHasStartedMess;

    // get value from url
    this.activatedroute.queryParamMap.subscribe((queryParams) => {

      if (!queryParams.has(ItemAttributes.LISTING_ID)) {
        this.router.navigate([LIST_AN_ITEM]);
      }

      this.listingId = queryParams.get(ItemAttributes.LISTING_ID);

      if (queryParams.has(ACTION)) {
        this.action = queryParams.get(ACTION);
      }

      if (this.action == EDIT) {
        this.listingResultMess = this.editListingSuccessfulMess;
      }
    });

    // send to get listing details by id
    this.listingDetailsRequest = this.productService.getListingDetails(this.listingId).subscribe(data => {
      if (200 == data.status && data.body) {
        this.listingDetails = data.body;
      }
    }, err => {
      this.router.navigate([NOT_FOUND_PAGE]);
    });
  }

  /**
   * show attribute value follow language response from server
   * @param attributesId attributesId ex: model,modelDetail...
   * @param dataType dataType of attributes in strings,numbers,bool, dates
   */
  showAttributesValue(attributesId: string, dataType: string): string {
    let attributesValue = '';
    let attribute;
    // list dataType follow design document and api response
    const listDataType = ['strings', 'dates', 'bool', 'numbers'];
    // return if don't has value
    if (!this.listingDetails || !this.listingDetails[dataType] || !listDataType.includes(dataType)) {
      return;
    }

    switch (dataType) {
      case 'strings':
        attribute = this.listingDetails.strings.find(item => item.string_attribute_id === attributesId);
        if (Util.isNotNullOrEmpty(attribute)) {
          if (Util.isNullOrEmpty(attribute.value) && Util.isNotNullOrEmpty(attribute.option_display)) {
            attributesValue = this.languageService.messageFromServer(attribute.option_display);
          } else {
            attributesValue = attribute.value[0];
          }
        }
        break;
      case 'dates':
        attribute = this.listingDetails.dates.find(item => item.date_attribute_id === attributesId);
        if (Util.isNotNullOrEmpty(attribute)) {
          attributesValue = attribute.value[0];
          if (0 < attributesValue.length) {
            attributesValue = this.datePipe.transform(attributesValue, 'yyyy-MM-dd');
          } else {
            attributesValue = '';
          }
        }
        break;
      case 'bool':
        attribute = this.listingDetails.bool.find(item => item.bool_attribute_id === attributesId);
        if (Util.isNotNullOrEmpty(attribute)) {
          attributesValue = attribute.value;
          if ('true' === attributesValue) {
            attributesValue = '✓';
          } else {
            attributesValue = 'x';
          }
        }
        break;
      case 'numbers':
        attribute = this.listingDetails.numbers.find(item => item.number_attribute_id === attributesId);
        if (Util.isNotNullOrEmpty(attribute)) {
          attributesValue = attribute.value[0];
          if (ItemAttributes.ASKING_PRICE === attributesId && 0 < attributesValue.length) {
            attributesValue = this.languageService.wrapDir('rtl',
              new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }).format(parseFloat(attributesValue)));
          } else {
            attributesValue = '';
          }
        }
        break;
      default:
        break;
    }

    if (undefined === attributesValue) {
      attributesValue = '';
    }
    return attributesValue;
  }

}
