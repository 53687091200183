<!-- blue line  -->
<div class="bg-primary pt-2"></div>

<!-- content -->
<div class="bg-light">
  <div class="container-fluid">

    <div class="row">
      <div class="col pt-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-light p-0 pt-1 category" dir="rtl">
            <a routerLink="/my-tajr" class="text-secondary">
              <small>
                <!--My Tajr -->
                {{sourceTabLabel}}
              </small>
            </a>&gt;
            <a class="text-secondary">
              <small>&nbsp;
                {{currentTabLabel}}
              </small>
            </a>
          </ol>
        </nav>
      </div>
    </div>

    <!--tabs-->
    <div class="row">
      <div class="col">

        <tabset #staticTabs dir="rtl" class="navTabs">
          <!-- watchlist tab-->
          <tab (selectTab)="onSelect(0)">
            <ng-template tabHeading>
              <b class="text-secondary font-weight-normal"
                 [ngClass]="screenWidth > 575? '':'small'">
                <!--watchlist-->
                الاعلانات المراقبة
              </b>
            </ng-template>
            <!--watchlist tab content-->
            <!--watchlist item when empty-->
            <div class="row" *ngIf="totalNumberItem == 0">
              <div class="col text-center">
                <br>
                <br>
                <br>

                <img class="img-fluid pb-3 pt-4"
                     [width]="screenWidth > 575? '128':'64'"
                     src="../../../assets/images/icon-images/tajr-icon-000018.png">
                <h4 class="font-weight-normal pb-2">{{yourWatchListIsEmptyMess}}</h4>
                <p class="text-secondary">
                  {{keepTrackOfListingsYouLikeMess}}
                </p>

                <br>
                <br>
                <br>

              </div>
            </div>
            <!--number of listing-->
            <div class="row" *ngIf="totalNumberItem > 0 && watchlistListingDetails != null">
              <div class="col">
                <p class="text-primary text-right mb-0 mt-2 px-2">
                  <small>{{numberItemOfListingsMess}}</small>
                </p>
              </div>
            </div>
            <!--watchlist item when has item-->
            <div *ngIf="totalNumberItem > 0">
              <div>
                <div class="row pt-2">
                  <div class="col col-lg-10 offset-lg-2">
                    <ul class="list-unstyled" *ngIf="watchlistListingDetails != null" dir="ltr">
                      <a class="media border rounded-lg carouselStyle position-relative bg-white row m-0 mb-3"
                          *ngFor="let slide of watchlistListingDetails.hits; index as index"
                         href="javascript:void(0);"
                          (click)="navigateService.navigateListingPageUrl(slide.listing.listing_id, slide.listing.title)">
                        <mat-card
                          class="align-self-end text-left p-2 shadow-none d-none d-sm-block mb-1 ml-1 position-absolute"
                          dir="rtl">
                          <mat-card-title class="font-weight-bold text-dark text-break mb-0 h6" dir="rtl">
                            ‬ {{listingService.composedPrice(slide.listing)}}
                          </mat-card-title>
                        </mat-card>
                        <div class="media-body col-8 col-md-9 p-2" dir="rtl">
                          <mat-card class="text-right shadow-none p-0">

                            <mat-card-subtitle class="small mb-0"
                                               *ngIf="listingService.checkListingStatus(slide.listing) == 'deleted' ">
                            <span class="badge badge-danger px-2 py-1">
                                   {{deletedMess}}
                            </span>
                            </mat-card-subtitle>
                            <mat-card-subtitle class="small mb-0"
                                               *ngIf="listingService.checkListingStatus(slide.listing) == 'expired' ">
                            <span class="badge badge-primary px-2 py-1">
                                   {{expiredMess}}
                            </span>
                            </mat-card-subtitle>
                            <mat-card-content class="text-dark mb-2 cardGalleryLineBreak" dir="rtl"
                                              [ngClass]="screenWidth <= 575 ? 'small' : ''">
                              {{slide.listing.locality_arabic}}| {{ listingService.endDateText(slide.listing.endDate) }}
                            </mat-card-content>

                            <mat-card-title class="font-weight-bold text-dark cardGalleryLineBreak"
                                            [ngClass]="screenWidth <= 575 ? 'FONT_SIZE_14' : 'h6'">
                              {{slide.listing.title}}
                            </mat-card-title>
                            <mat-card-subtitle class="text-dark text-break align-self-end  cardGalleryLineBreak"
                                               *ngIf="slide.listing.subtitle != null">
                              {{slide.listing.subtitle}}
                            </mat-card-subtitle>
                            <mat-card-title class="font-weight-bold text-dark text-break text-left d-sm-none"
                                            [ngClass]="screenWidth <= 575 ? 'FONT_SIZE_14' : 'h6'">
                              ‬ {{listingService.composedPrice(slide.listing)}}
                            </mat-card-title>
                          </mat-card>
                        </div>
                        <mat-card
                          class="col-4 col-sm-4 col-md-3 embed-responsive embed-responsive-16by9 shadow-none align-self-center"
                          dir="rtl">
                          <img class="img-fluid embed-responsive-item w-100 h-100 tajrImage"
                               [src]="listingService.showPhoto(slide.listing.photo_1, slide.listing.title)"
                               [alt]="slide.listing.photo_1 != null ? titleService.generateArabicLable(slide.listing.title): titleService.generateArabicLable(titleService.defaultTitle)">
                        </mat-card>
                        <!--watchlist icon-->
                        <img class="img-fluid p-1 position-absolute tajrCardIcon"
                             (click)="$event.stopPropagation(); removeToWatchList(slide.watchlist.watchlist_id, slide.watchlist.listing_id)"
                             *ngIf="slide?.watchlist.watchlist_id"
                             src="../../../assets/images/icon-images/tajr-icon-000041.png">
                        <span
                          (click)="$event.stopPropagation(); addToWatchList(slide.watchlist.watchlist_id, slide.watchlist.listing_id)"
                          *ngIf="!slide?.watchlist.watchlist_id"
                          class="material-icons p-1 position-absolute tajrCardIcon">grade</span>
                      </a>
                    </ul>
                  </div>
                </div>

                <!-- pagination  -->
                <div class="row" dir="ltr" *ngIf="loading == false && totalNumberItem > getWatchlistSize">
                  <div class="col-2 d-none d-lg-block"></div>
                  <div class="col" dir="rtl">
                    <pagination class="d-flex justify-content-center"
                                [boundaryLinks]="showBoundaryLinks"
                                [totalItems]="totalNumberItem"
                                (pageChanged)="onChangepaginationWatchlistPage($event)"
                                [maxSize]="maxSize"
                                [itemsPerPage]="getWatchlistSize"
                                [(ngModel)]="currentPage"
                                previousText="&lsaquo;"
                                nextText="&rsaquo;"
                                firstText="&laquo;"
                                lastText="&raquo;">
                    </pagination>
                  </div>
                </div>

              </div>
            </div>
          </tab>
        </tabset>

      </div>
    </div>

  </div>
</div>
<!-- blue line  -->
<div class="bg-primary pt-2"></div>
