import {Component, ElementRef, HostListener, OnDestroy, OnInit, Input} from '@angular/core';
import {SearchModel} from "../../modules/models/search.model";
import {Subscription} from "rxjs";
import {SearchListingDetails} from "../../modules/models/search.listing.details";
import {DatePipe} from "@angular/common";
import {ProductService} from "../../services/product.service";
import {HttpHelperService} from "../../services/http-helper.service";
import {TimeService} from "../../services/time.service";
import {LanguageService} from "../../services/language.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NavigateService} from "../../services/navigate.service";
import {TAJR_ALL_CATEGORIES_RESOURCE} from "../../shared/global";
import {Hits} from "../../modules/models/hits";
import {Util} from "../../utils/utils";
import {ListingService} from "../../services/listing.service";

@Component({
  selector: 'app-default-random-listing-results',
  templateUrl: './default-random-listing-results.component.html',
  styleUrls: ['./default-random-listing-results.component.css']
})
export class DefaultRandomListingResultsComponent implements OnInit, OnDestroy {
  loadMoreMessage = this.languageService.findMessageByLanguage('loadMore');
  loadingMess = this.languageService.findMessageByLanguage('loading');
  // model OOP
  private getListings: SearchModel;
  // Observables use to receive response
  private subscription: Subscription;
  // width of screen size on browser
  public screenWidth: number;
  public searchListing: SearchListingDetails;
  public getListingFromItem = '0';
  public getListingToItem = '10';
  public tajrAllCategories;
  public totalNumberItem: number;
  public currentNumberItem: number;
  public isLoading = false;
  @Input('category_id') category_id: string = '';

  constructor(
    private elementRef: ElementRef,
    // service convert date format
    private datePipe: DatePipe,
    // service send request
    private productService: ProductService,
    // contain api
    private httpHelper: HttpHelperService,
    // parse time service
    private timeService: TimeService,
    // language and sorts arabic message
    private languageService: LanguageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigateService: NavigateService,
    public listingService: ListingService,
  ) {
  }

  ngOnInit(): void {
    // get width of device
    this.screenWidth = window.innerWidth;
    // receive value from data transfer Api
    this.tajrAllCategories = this.activatedRoute.snapshot.data[TAJR_ALL_CATEGORIES_RESOURCE];

    //  Get listings by search item Api
    // this.getListings = new GetListings();
    this.getListings = new SearchModel();
    this.getListings.from = this.getListingFromItem;
    this.getListings.size = this.getListingToItem;
    if (this.category_id) {
      this.getListings.category_id = this.category_id
    }

    // send request to get listing
    this.subscription = this.productService.getListingsItem(this.getListings).subscribe(data => {
      // if listing list available
      if (200 === data.status && 0 < data.body.listing_ids.length) {
        this.searchListing = data.body;
        this.totalNumberItem = Number(this.searchListing.total);
        this.currentNumberItem = this.searchListing.listing_ids.length;
      }
    });
  }

  ngOnDestroy(): void {
    // unsubscribe if observable with get latest listing request available
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }


  /**
   * use HostListener to  updated on resize:
   * @param event tab when selected
   */
  @HostListener('window:resize', ['$event'])
  getScreenSize(event) {
    this.screenWidth = window.innerWidth;
  }

  /**
   * endDate text show on list
   */
  endDateText(endDate: string): string {
    let endDateText = '';
    let endTime;
    // parse endDate
    endTime = Date.parse(endDate);
    //  Date.now() returns the number of milliseconds elapsed since January 1, 1970 00:00:00 UTC
    let current = Date.now();
    // the time zone difference, in minutes, from current locale (host system settings) to UTC
    let difference = this.timeService.convertMS(endTime - current);

    if (difference.day < 1) {
      // hours
      if (difference.hour > 0) {
        if (difference.hour === 1) {
          endDateText = this.languageService.wrapDir('rtl', 'الاعلان ينتهي بعد ساعه');
        } else {

          if (difference.hour === 2) {
            endDateText = this.languageService.wrapDir('rtl', 'الاعلان ينتهي بعد ساعتين');
          }
          if (difference.hour >= 3 && difference.hour < 11) {
            endDateText = this.languageService.wrapDir('rtl', 'الإعلان ينتهي بعد ') + this.languageService.wrapDir('rtl', difference.hour) + this.languageService.wrapDir('rtl', ' ساعات');
          }

          if (difference.hour > 10) {
            endDateText = this.languageService.wrapDir('rtl', 'الإعلان ينتهي بعد ') + this.languageService.wrapDir('rtl', difference.hour) + this.languageService.wrapDir('rtl', ' ساعه');
          }
        }
      } else {
        // minutes
        if (difference.minute > 0) {

          if (difference.minute === 1) {
            endDateText = this.languageService.wrapDir('rtl', 'الاعلان ينتهي بعد دقيقة');
          } else {

            if (difference.minute === 2) {
              endDateText = this.languageService.wrapDir('rtl', 'الاعلان ينتهي بعد دقيقتين');
            }
            if (difference.minute >= 3 && difference.minute < 11) {
              endDateText = this.languageService.wrapDir('rtl', 'الإعلان ينتهي بعد ') + this.languageService.wrapDir('rtl', difference.minute) + this.languageService.wrapDir('rtl', ' دقائق');
            }

            if (difference.minute > 10) {
              endDateText = this.languageService.wrapDir('rtl', 'الإعلان ينتهي بعد ') + this.languageService.wrapDir('rtl', difference.minute) + this.languageService.wrapDir('rtl', ' دقيقة');
            }
          }
        } else {
          endDateText = this.languageService.wrapDir('rtl', 'الإعلان انتهى');
        }
      }
    } else if (difference.day === 1) {
      endDateText = this.languageService.wrapDir('rtl', 'الاعلان ينتهي بعد يوم');
    } else {

      if (difference.day === 2) {
        endDateText = this.languageService.wrapDir('rtl', 'الإعلان ينتهي بعد يومين');
      }
      if (difference.day >= 3 && difference.day < 11) {
        endDateText = this.languageService.wrapDir('rtl', 'الإعلان ينتهي بعد ') + this.languageService.wrapDir('rtl', difference.day) + this.languageService.wrapDir('rtl', ' أيام');
      }

      if (difference.day > 10) {
        endDateText = this.languageService.wrapDir('rtl', 'الإعلان ينتهي بعد ') + this.languageService.wrapDir('rtl', difference.day) + this.languageService.wrapDir('rtl', ' يوم');
      }
    }
    return endDateText;
  }

  /**
   * Price message show on list
   */
  composedPrice(listingDetails: Hits): string {
    let orNearOffer: string;
    let orNearOfferText: string;
    let askingPrice: string;
    let askingPriceText: string;
    let composedPrice = '';
    if (Util.isNullOrEmpty(listingDetails)) {
      return composedPrice;
    }
    if (Util.isNotNullOrEmpty(listingDetails.orNearOffer)) {
      orNearOffer = listingDetails.orNearOffer;
      orNearOfferText = this.languageService.findMessageByLanguage('orNearOffer');
    }

    askingPriceText = this.languageService.findMessageByLanguage('askingPrice');

    if (Util.isNotNullOrEmpty(listingDetails.askingPrice)) {
      askingPrice = listingDetails.askingPrice;
    }

    if (orNearOffer === 'true') {
      composedPrice = this.languageService.wrapDir('rtl', askingPrice) + this.languageService.wrapDir('rtl', ' ر.س ') + this.languageService.wrapDir('rtl', orNearOfferText);
    } else {
      composedPrice = this.languageService.wrapDir('rtl', askingPriceText) + this.languageService.wrapDir('rtl', ' ') + this.languageService.wrapDir('rtl', askingPrice) + this.languageService.wrapDir('rtl', ' ر.س ');
    }
    return composedPrice;
  }

  /**
   * If user has item doesn't show end will show this button
   */
  showLoadMoreButton() {
    if (this.currentNumberItem < this.totalNumberItem) {
      return true;
    }
    return false;
  }

  /**
   * Load more item when load more button was click
   */
  loadMoreItem() {
    this.isLoading = true;
    this.getListings.from = (this.getListingFromItem + this.currentNumberItem).toString();
    // send request to get listing
    this.subscription = this.productService.getListingsItem(this.getListings).subscribe(data => {
      // if user has information response
      if (200 == data.status && data.body) {
        this.isLoading = false;
        let newSearchListing = new SearchListingDetails();
        newSearchListing = data.body;
        this.searchListing = this.mergeListingResult(this.searchListing, newSearchListing);
        this.currentNumberItem = this.searchListing.listing_ids.length;
      }
    });
  }

  /**
   * merge source object and target objet
   *
   * @param source source result
   * @param target target result
   */
  mergeListingResult(source: SearchListingDetails, target: SearchListingDetails): SearchListingDetails {
    for (let i = 0; i < target.listing_ids.length; i++) {
      let targetId = target.listing_ids[i];
      let sourceId = source.listing_ids.find(id => id == targetId);
      if (Util.isNullOrEmpty(sourceId)) {
        source.listing_ids.push(targetId);
        source.hits.push(target.hits[i]);
        source.from = target.from;
      }
    }
    return source;
  }
}
