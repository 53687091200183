import {Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {LanguageService} from '../../services/language.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {AccountService} from '../../services/account.service';
import {ImageModel} from '../../modules/models/image.model';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {ToastrService} from 'ngx-toastr';
import {ApiReferenceService} from '../../shared/api-reference.service';
import {HttpClient, HttpEventType} from '@angular/common/http';
import {
  ACTION,
  AttributeConstant,
  BOTTOM, CREATE, EDIT, ItemAttributes, LIST_AN_ITEM, LIST_AN_ITEM_DETAILS, LIST_AN_ITEM_PHOTOS, SELL_SIMILIAR, SMOOTH,
  TAJR_LIST_AN_ITEM,
  TAJR_LIST_AN_ITEM_PHOTOS, VALUE,
  YOUTUBE,
  YOUTUBE_FILE_TYPE, YOUTUBE_REGEX_URL,
  YOUTUBE_URL
} from '../../shared/global';
import {Util} from '../../utils/utils';
import {NotificationServices} from '../../services/notification.services';
import {ListAnItemService} from "../../services/list.an.item.service";


class ListAnItemPhotosAndVideo {
  photos: Photo [];
  embedded_content_options: EmbeddedContentOptions [];
}

class EmbeddedContentOptions {
  id = '';
  value = '';
}

class Photo {
  position = '';
  photo_id = '';
}

@Component({
  selector: 'app-list-an-item-photos',
  templateUrl: './list-an-item-photos.component.html',
  styleUrls: ['./list-an-item-photos.component.css']
})

export class ListAnItemPhotosComponent implements OnInit, OnDestroy {
  regexYoutubeLinkMessage = this.languageService.findMessageByLanguage('regexYoutubeLink');
  imageDuplicateMessage = this.languageService.findMessageByLanguage('imageDuplicateMess');
  imageLimitSizeMess = this.languageService.findMessageByLanguage('imageLimitSize12MBMessage');
  imageMinDimensionErrorMessage = this.languageService.findMessageByLanguage('imageMinDimensionError');
  imageMaxDimensionErrorMessage = this.languageService.findMessageByLanguage('imageMaxDimensionError');
  requiredImageLimit20PicMessage = this.languageService.findMessageByLanguage('requiredImageLimit20Pic');
  requiredImageFileTypeMessage = this.languageService.findMessageByLanguage('requiredImageFileType');
  imageFileNameTooLargeMessage = this.languageService.findMessageByLanguage('imageFileNameTooLarge');
  // style of carousel image
  opacityImage = {
    opacity: '0.5'
  };
  //  subscription to observable request get member's listings
  public subscription: Subscription;
  public subscriptionImageOnModal: Subscription;
  public subscriptionImageOnPhotosList: Subscription;
  public submitted = false;
  public screenWidth: number;
  public files: any[] = [];
  public imageFilesOnModal: Array<ImageModel> = [];
  public pageState = '';
  public sendingRequestOnModalStatus = false;
  public videoUrl = '';
  public videoId = '';
  public action = CREATE;
  public isUploading = false;

  /**
   * photosAndVideo contain list of photos and video format same request when create listing design in design doc.
   */
  public photosAndVideo: ListAnItemPhotosAndVideo;
  @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;

  constructor(
    // get language message from local
    private languageService: LanguageService,
    private accountService: AccountService,
    // Router service use to get value from url
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private http: HttpClient,
    private apiReference: ApiReferenceService,
    private notify: NotificationServices,
    private listAnItemService: ListAnItemService,
  ) {
  }

  ngOnInit() {
    // get screen of device
    this.screenWidth = window.innerWidth;

    // Check in local first step list an item result must be available
    if (!this.listAnItemService.tajrListAnItem) {
      this.listAnItemService.removeSessionData([TAJR_LIST_AN_ITEM_PHOTOS]);
      this.router.navigate([LIST_AN_ITEM]);
    }

    // Check in local has save list an item pho to or not
    const tajrListAnItemPhotos = this.listAnItemService.tajrListAnItemPhotos;
    if (tajrListAnItemPhotos) {
      this.photosAndVideo = tajrListAnItemPhotos;
      // loop and send request tp get photo meta data
      for (const photo of  this.photosAndVideo.photos) {
        if (Util.isNotNullOrEmpty(photo.photo_id)) {
          const image = new ImageModel();
          image.photo_id = photo.photo_id;
          image.checkBoxValue = AttributeConstant.TRUE;
          image.source = ItemAttributes.MODAL_FILE_SOURCE;
          image.progress = 100;
          this.files.push(image);
          this.subscriptionImageOnPhotosList = this.accountService.getPhotosMetaData(photo.photo_id).subscribe(imageData => {
            if (200 === imageData.status) {
              const imageFileList = this.files.find(item => item.photo_id === imageData.body.photo_id);
              imageFileList.file_name = imageData.body.file_name;
              imageFileList.photo_urls = imageData.body.photo_urls[ItemAttributes.PHOTO_508X380];
            }
          });
        }
      }
      // check video
      if (Util.isNotNullOrEmpty(this.photosAndVideo.embedded_content_options) && Util.isNotNullOrEmpty(this.photosAndVideo.embedded_content_options[0].value.length)) {
        this.videoId = this.photosAndVideo.embedded_content_options[0].value;
        this.videoUrl = YOUTUBE_URL + this.videoId + YOUTUBE_FILE_TYPE;
      }
    }

    /**
     * Photos - Get all photo IDs by member.
     * These images will on on image modal when load page
     */
    this.subscription = this.accountService.getAllPhotosIdByMember(this.pageState).subscribe(data => {

      // if data available
      if (200 === data.status && 0 < data.body.photo_ids.length) {
        for (const photoId of  data.body.photo_ids) {
          const image = new ImageModel();
          image.photo_id = photoId;
          image.checkBoxValue = AttributeConstant.FALSE;
          image.source = ItemAttributes.MODAL_FILE_SOURCE;
          image.progress = 100;
          this.imageFilesOnModal.push(image);
          this.subscriptionImageOnModal = this.accountService.getPhotosMetaData(photoId).subscribe(imageData => {
            if (200 === imageData.status) {
              const imageFileOnModal = this.imageFilesOnModal.find(item => item.photo_id === imageData.body.photo_id);
              imageFileOnModal.file_name = imageData.body.file_name;
              imageFileOnModal.photo_urls = imageData.body.photo_urls[ItemAttributes.PHOTO_508X380];
            }
          });
        }

        // set pageState value
        if (Util.isNotNullOrEmpty(data.body.page_state)) {
          this.pageState = data.body.page_state;
        } else {
          this.pageState = '';
        }
      }
    });

    // get value from url, get action with listing item, default action is create new item
    this.route.queryParamMap.subscribe((queryParams) => {
      if (queryParams.has(ACTION)) {
        this.action = queryParams.get(ACTION);
      }
    });
  }

  /**
   * use HostListener to  updated on resize:
   * @param event event when change width of device
   */
  @HostListener('window:resize', ['$event'])
  getScreenSize(event) {
    this.screenWidth = window.innerWidth;
  }

  /**
   * destroy subscription when load page
   */
  ngOnDestroy(): void {
    // unsubscribe if observable with get latest listing request available
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscriptionImageOnModal) {
      this.subscriptionImageOnModal.unsubscribe();
    }
  }

  /**
   * Send request to get more member image show on modal
   */
  getMoreImageOnModal() {
    // if scroll to bottom of viewport
    if (this.viewPort.measureScrollOffset(BOTTOM) <= 0) {
      this.getPhotosByIdMember();
    }
  }

  /**
   * when user click image on modal, that image will be add in ite list an item photo
   * @param element image information
   */
  selectItemOnModal(element: any) {
    // if length of image file lower 20 and this maodel image is not added in files[]
    if (20 > this.files.length && element.checkBoxValue === AttributeConstant.FALSE) {
      // check image exits in files list will show image exit message
      if (Util.isNotNullOrEmpty(this.files.find(item => item.photo_id === element.photo_id))) {
        this.notify.showInfoNotificationMessage(this.imageDuplicateMessage);
      } else {
        // append value to files[]
        this.files.push(element);
        // change checkBoxValue in imageFilesOnModal[]
        element.checkBoxValue = AttributeConstant.TRUE;
      }

    } else if (element.checkBoxValue === AttributeConstant.TRUE) {
      // change checkBoxValue in imageFilesOnModal[]
      element.checkBoxValue = AttributeConstant.FALSE;
      // remove item in files[]
      const index = this.files.findIndex(item => item.photo_id === element.photo_id);
      this.deleteFile(index);
    } else {
      // show image limit mess
      this.notify.showInfoNotificationMessage(this.requiredImageLimit20PicMessage);
    }
  }

  /**
   * Photos - Get all photo IDs by member.
   * These images will on on image modal
   */
  getPhotosByIdMember() {
    if (Util.isNotNullOrEmpty(this.pageState) && this.sendingRequestOnModalStatus === false) {
      this.sendingRequestOnModalStatus = true;
      this.subscription = this.accountService.getAllPhotosIdByMember(this.pageState).subscribe(data => {
        // if data available
        if (200 === data.status && 0 < data.body.photo_ids.length) {
          for (const photoId of  data.body.photo_ids) {
            const image = new ImageModel();
            image.photo_id = photoId;
            image.checkBoxValue = AttributeConstant.FALSE;
            image.source = ItemAttributes.MODAL_FILE_SOURCE;
            image.progress = 100;
            this.imageFilesOnModal.push(image);
            this.subscriptionImageOnModal = this.accountService.getPhotosMetaData(photoId).subscribe(imageData => {
              if (200 === imageData.status) {
                const listDetails = this.imageFilesOnModal.find(item => item.photo_id === imageData.body.photo_id);
                listDetails.file_name = imageData.body.file_name;
                listDetails.photo_urls = imageData.body.photo_urls[ItemAttributes.PHOTO_508X380];
              }
              // if last request has response will allow other request
              if (photoId === data.body.photo_ids[data.body.photo_ids.length - 1]) {
                this.sendingRequestOnModalStatus = false;
              }
            });
          }

          // set pageState value use to send request later
          if (Util.isNotNullOrEmpty(data.body.page_state)) {
            this.pageState = data.body.page_state;
          } else {
            this.pageState = '';
          }

        }

      });
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    if (Util.isNotNullOrEmpty(this.files) && this.files[event.previousIndex].progress === 100 && this.files[event.currentIndex].progress === 100) {
      moveItemInArray(this.files, event.previousIndex, event.currentIndex);
    }
  }

  /**
   * loading file status use to show progress bar on browser
   * @param index position of file in files []
   */
  loadingFileStatus(index: number) {
    if (Util.isNotNullOrEmpty(this.files[index]) && this.files[index].progress === 100) {
      return true;
    }
    return false;
  }

  /**
   *  move a file to the first position in files []
   * @param index previous position of file in files []
   */
  selectMainPhoto(index: number) {
    moveItemInArray(this.files, index, 0);
  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    // check image source from modal list
    const photo = this.imageFilesOnModal.find(item => item.photo_id === this.files[index].photo_id);
    if (Util.isNotNullOrEmpty(photo) && Util.isNotNullOrEmpty(photo.photo_id)) {
      const listDetails = this.imageFilesOnModal.find(item => item.photo_id === this.files[index].photo_id && item.source === ItemAttributes.MODAL_FILE_SOURCE);
      // change attribute to release it
      listDetails.checkBoxValue = AttributeConstant.FALSE;
    }
    // remove image in file list
    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process from 0% tp 100%
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (Util.isNullOrEmpty(this.files[index]) || Util.isNullOrEmpty(this.files[index].progress) || index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress >= 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          }
        }, 200);
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      const reader = new FileReader();
      reader.readAsDataURL(item);
      reader.onload = () => {
        // fileStyle,photoData,fileName will send to server
        const fileStyle = reader.result.toString().substring('data:image/'.length, reader.result.toString().indexOf(';base64')).toLowerCase();
        const photoData = reader.result.toString().split(',')[1]; // In this case "/9j/...."
        const fileName = item.name;
        // image url will show on browser
        const photoUrls = reader.result.toString();
        // array image type supported
        const arrayImageTypeSupported = ['jpg', 'jpeg', 'png', 'gif'];
        // 1.check image available in image file list
        if (Util.isNotNullOrEmpty(this.files.find(item => item.file_name === fileName))) {
          this.notify.showInfoNotificationMessage(this.imageDuplicateMessage);
          return;
        }
        // 2.check file name max length
        if (255 < fileName.length) {
          this.notify.showInfoNotificationMessage(this.imageFileNameTooLargeMessage);
          return;
        }
        // 3.check image file size
        if (12000000  < item.size) {
          this.notify.showInfoNotificationMessage(this.imageLimitSizeMess);
          return;
        }

        // 4.check image number limit 20 images
        if (20 <= this.files.length) {
          // show image limit mess
          this.notify.showInfoNotificationMessage(this.requiredImageLimit20PicMessage);
          return;
        } else
        // 5. Check image file type after encoder is one of ("jpg","jpeg","png","gif")
        if (!arrayImageTypeSupported.includes(fileStyle)) {
          this.notify.showInfoNotificationMessage(this.requiredImageFileTypeMessage);
          return;
        }
        // 6. check image dimension
        const img = new Image();
        img.src = photoUrls;
        img.onload = () => {
          const width = img.width;
          const height = img.height;
          // 7. min width 500 pixel and min height 500 pixel
          if (500 > width || 500 > height) {
            // show image min dimension error
            this.notify.showInfoNotificationMessage(this.imageMinDimensionErrorMessage);
            return;
          } else
          // 8. max width 9000 pixel and max height 9000 pixel
          if (9000 < width || 9000 < height) {
            // show image max dimension error
            this.notify.showInfoNotificationMessage(this.imageMaxDimensionErrorMessage);
            return;
          } else {
            // add file image to image list
            item.source = ItemAttributes.USER_FILE_SOURCE;
            item.file_name = fileName;
            item.photo_urls = photoUrls;
            item.photo_id = '';
            item.checkBoxValue = AttributeConstant.TRUE;
            item.progress = 0;
            // push to file list
            this.files.push(item);
            // send request to upload image
            const photoPostData = {
              file_name: fileName,
              file_type: fileStyle,
              photo_data: photoData
            };
            // this.uploadPhoto(photoPostData);
            this.http.post(this.apiReference.uploadPhoto, photoPostData, {
              withCredentials: true,
              reportProgress: true,
              observe: 'events'
            }).subscribe(events => {
              if (events.type === HttpEventType.UploadProgress) {
                const uploadProgress = Math.round(events.loaded / events.total * 100);
                item.progress = uploadProgress;
              }

              if (events.type === HttpEventType.Response) {
                // if data available
                if (200 === events.status && Util.isNotNullOrEmpty(events.body)) {
                  // fine that element and change it detail by details response from serve
                  const listDetails = this.files.find(file => file.file_name === fileName && file.source === ItemAttributes.USER_FILE_SOURCE);
                  listDetails.photo_id = events.body[ItemAttributes.PHOTO_ID];
                  listDetails.file_name = events.body[ItemAttributes.FILE_NAME];
                  listDetails.photo_urls = events.body[ItemAttributes.PHOTO_URLS][ItemAttributes.PHOTO_508X380];
                  listDetails.checkBoxValue = AttributeConstant.TRUE;
                  listDetails.source = ItemAttributes.MODAL_FILE_SOURCE;
                  listDetails.progress = 100;
                  this.isUploading = false;
                }
              }
            }, err => {
              // show error
              const errorMessage = this.languageService.messageFromServer(err.error.message);
              this.notify.showInfoNotificationMessage(errorMessage);
              const listDetails = this.files.find(file => file.file_name === fileName && file.source === ItemAttributes.USER_FILE_SOURCE);
              const index = this.files.findIndex(file => file.file_name === fileName && file.source === ItemAttributes.USER_FILE_SOURCE);
              // end progress bar show in browser
              listDetails.progress = 100;
              // delete that item
              this.deleteFile(index);
            });
            this.isUploading = true;
          }
        };

      };
      // when parse file error show notification
      reader.onerror = () => {
        this.notify.showInfoNotificationMessage(this.requiredImageFileTypeMessage);
        return;
      };
    }
    this.uploadFilesSimulator(0);
  }

  /**
   * uer update video for their listing
   */
  updateVideoUrl(event: Event) {
    const value = event.target[VALUE];
    // regex to extract youtube video id
    const regex = new RegExp(YOUTUBE_REGEX_URL);
    if (null != regex.exec(value) && 1 < regex.exec(value).length) {
      this.videoId = regex.exec(value)[1];
      this.videoUrl = YOUTUBE_URL + this.videoId + YOUTUBE_FILE_TYPE;
    } else {
      this.videoId = '';
      this.videoUrl = '';
      this.notify.showInfoNotificationMessage(this.regexYoutubeLinkMessage);
    }

  }

  /**
   * submit button send request
   */
  onSubmit() {
    let position = 1;
    this.photosAndVideo = new ListAnItemPhotosAndVideo();
    this.photosAndVideo.photos = [];
    // push image to photosList
    for (const photo of  this.files) {
      // check in files list, if image source is not from modal will send request
      if (Util.isNotNullOrEmpty(photo.photo_id)) {
        const photoItem = new Photo();
        photoItem.position = position.toString();
        photoItem.photo_id = photo.photo_id;
        position++;
        this.photosAndVideo.photos.push(photoItem);
      }
    }
    if (Util.isNotNullOrEmpty(this.videoId)) {
      this.photosAndVideo.embedded_content_options = [];
      let video = new EmbeddedContentOptions();
      video.id = YOUTUBE;
      video.value = this.videoId;
      this.photosAndVideo.embedded_content_options.push(video);
    }

    // save embeddedContentOptionList in local
    this.listAnItemService.setDataToStorage(TAJR_LIST_AN_ITEM_PHOTOS, this.photosAndVideo);

    switch (this.action) {
      case EDIT:
        // go to list-an-item-details page with action edit
        this.router.navigate([LIST_AN_ITEM_DETAILS], {queryParams: {action: EDIT}});
        break;
      case SELL_SIMILIAR:
        // go to list-an-item-details page  with action sellSimilar
        this.router.navigate([LIST_AN_ITEM_DETAILS], {queryParams: {action: SELL_SIMILIAR}});
        break;
      default:
        // go to list-an-item-details page with action create
        this.router.navigate([LIST_AN_ITEM_DETAILS], {queryParams: {action: CREATE}});
        break;
    }
  }

  /**
   * navigate to listing-an-item page
   * @param url router url
   */
  navigateToListAnItemPage(url: string) {
    this.router.navigate([url], {queryParams: {action: this.action}});
  }
}
