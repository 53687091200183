<div class="bg-light">
  <div class="container-fluid" *ngIf="searchListing && searchListing.hits &&  searchListing.hits.length > 0">
    <div class="row m-0 cardGalleryCarousel">
      <swiper
        [slidesPerView]="1"
        [allowTouchMove]="false"
        *ngFor="let slide of searchListing.hits; index as index"
        class="col-6 col-sm-4 col-md-3 col-lg-2 col-customs-lg pb-3 px-1">
        <ng-template swiperSlide
                     class="card border rounded rounded-lg h-auto carouselStyle">
          <a [routerLink]="navigateService.generateListingPageUrl(slide.listing_id, slide.title)"
          class="text-decoration-none randomListing">
            <!-- card header -->
            <div
              class="card-header embed-responsive embed-responsive-4by3 bg-white rounded-top
              rounded-lg border-bottom-0 position-relative">
              <img [src]="listingService.showPhoto(slide.photo_1, slide.title)"
                   class="card-img-top img-fluid embed-responsive-item tajrImage">
              <!-- card icon -->
              <img class="img-fluid p-1 position-absolute tajrCardIcon"
                   *ngIf="slide?.watchlist_id"
                   src="../../../assets/images/icon-images/tajr-icon-000041.png">
            </div>
            <!-- card content -->
            <div class="card-body p-2 mb-4">

              <div class="font-weight-bold">
                <div class="d-flex justify-content-between font-weight-bold text-dark card-title-group" dir="rtl">
                  <small class="small text-nowrap ml-3">{{slide.locality_arabic}}</small>
                  <small class="small cardGalleryLineBreak card-date">{{ endDateText(slide.endDate) }}</small>
                </div>

                <p class="text-dark font-weight-bold text-right FONT_SIZE_14 mb-2" dir="rtl">
                  {{slide.title}}
                </p>

                <p class="font-weight-normal text-right small mb-0"
                   *ngIf="slide.subtitle != null" dir="rtl">
                  {{slide.subtitle}}
                </p>
              </div>
            </div>
            <div class="card-footer border border-top-0 rounded-bottom rounded-lg p-0 bg-white px-2 fixed-bottom">
              <p class="font-weight-bold text-right small mb-2 cardGalleryLineBreak" dir="rtl">{{composedPrice(slide)}}</p>
            </div>
          </a>
        </ng-template>
      </swiper>
    </div>
    <!--  load more button  -->
    <div class="row" dir="ltr">
      <div class="col text-center"
           *ngIf="showLoadMoreButton()">
        <button type="button" class="btn border-0 btn-primary"
                (click)="loadMoreItem()"
                *ngIf="!isLoading">
          {{loadMoreMessage}}
        </button>
        <!-- the spinner when loading more item -->
        <button class="btn border-0 btn-block text-primary d-flex align-items-center justify-content-center"
                type="button" disabled *ngIf="isLoading">
          {{loadingMess}}
          <span class="spinner-border spinner-border-sm mx-2"></span>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <!--the spinner when carousel load not finished-->
        <div class="d-flex justify-content-center align-items-center py-5 my-3"
             *ngIf="!searchListing || !searchListing.hits ||  searchListing.hits.length == 0">
          <mat-spinner></mat-spinner>
        </div>
      </div>
    </div>
  </div>
</div>
