import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LanguageService} from '../../services/language.service';
import {SearchService} from '../../services/search.service';
import {HttpHelperService} from '../../services/http-helper.service';
import {DatePipe} from '@angular/common';
import {TimeService} from '../../services/time.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TabsetComponent} from 'ngx-bootstrap/tabs';
import {Subscription} from 'rxjs';
import {Util} from '../../utils/utils';
import {AccountService} from '../../services/account.service';
import {ProductService} from '../../services/product.service';
import {SearchModel} from '../../modules/models/search.model';
import {NotificationServices} from '../../services/notification.services';
import {NavigateService} from '../../services/navigate.service';
import {ListingService} from '../../services/listing.service';
import {WatchlistListingDetails} from '../../modules/models/watchlist.listing.details';
import {ITEM_PER_PAGE, SearchConstant} from '../../shared/global';
import {TitleService} from '../../services/title.service';

@Component({
  selector: 'app-watchlist',
  templateUrl: './watchlist.component.html',
  styleUrls: ['./watchlist.component.css']
})
export class WatchlistComponent implements OnInit, OnDestroy {

  constructor(
    // get language message from local
    private languageService: LanguageService,
    // service send request to login
    private searchService: SearchService,
    // contain api
    private httpHelper: HttpHelperService,
    private elementRef: ElementRef,
    // service convert date format
    private datePipe: DatePipe,
    // parse time service
    private timeService: TimeService,
    // service use to get value from router
    private activatedroute: ActivatedRoute,
    // member service
    private accountService: AccountService,
    private productService: ProductService,
    private router: Router,
    private notify: NotificationServices,
    public navigateService: NavigateService,
    public listingService: ListingService,
    public titleService: TitleService,
  ) {
  }
  sourceTabLabel = this.languageService.findMessageByLanguage('myTajr');
  watchlistTabLabel = this.languageService.findMessageByLanguage('watchlist');
  loadMoreMessage = this.languageService.findMessageByLanguage('loadMore');
  loadingMess = this.languageService.findMessageByLanguage('loading');
  deletedMess = this.languageService.findMessageByLanguage('itemHasBeenDeleted');
  expiredMess = this.languageService.findMessageByLanguage('itemHasBeenExpired');
  itemAddedToWatchlistdMess = this.languageService.findMessageByLanguage('itemAddedToWatchlist');
  removedSuccessdMess = this.languageService.findMessageByLanguage('itemRemovedSuccessFromWatchlist');
  yourWatchListIsEmptyMess = this.languageService.findMessageByLanguage('yourWatchListIsEmpty');
  keepTrackOfListingsYouLikeMess = this.languageService.findMessageByLanguage('keepTrackOfListingsYouLikeMess');

  public currentTabLabel = this.watchlistTabLabel;
  public screenWidth: number;
  public tab: number;
  private subscription: Subscription;
  public watchlistListingDetails: WatchlistListingDetails;
  public totalNumberItem = 0;
  public getWatchlist: SearchModel;
  public getWatchlistFromItem = 0;
  public getWatchlistSize = ITEM_PER_PAGE;
  public numberItemOfListingsMess = '';
  public currentNumberItem = 0;
  public loading = false;
  public watchListSubscription: Subscription;
  // Pagination attribute
  public totalItems: number;
  public currentPage = 1;
  public showBoundaryLinks = true;
  public maxSize = 5;

  @ViewChild('staticTabs') staticTabs: TabsetComponent;

  ngOnInit() {
    // get width of device
    this.screenWidth = window.innerWidth;
    // get value from url
    this.activatedroute.queryParamMap.subscribe((queryParams) => {
      if (queryParams.has(SearchConstant.PAGE)) {
        this.currentPage = Number(queryParams.get(SearchConstant.PAGE));
      }

      if (queryParams.has(SearchConstant.TAB)) {
        this.tab = Number(queryParams.get(SearchConstant.TAB));
        // show tab 0: selling tab, tab 1 : unsold tab
        this.selectTab(this.tab);
      } else {
        this.tab = 0;
        this.selectTab(this.tab);
      }

      // get member watchlist
      this.getWatchlist = new SearchModel();
      this.getWatchlist.from = ((this.currentPage - 1) * this.getWatchlistSize).toString();
      this.getWatchlist.size = this.getWatchlistSize.toString();
      this.subscription = this.accountService.getMemberWatchlist(this.getWatchlist).subscribe(data => {
        // if listing list available
        if (200 == data.status && Util.isNotNullOrEmpty(data.body)) {
          // send request to get listing details
          this.watchlistListingDetails = data.body;
          this.totalNumberItem = Number(this.watchlistListingDetails.total);
          this.currentNumberItem = ((this.currentPage - 1) * this.getWatchlistSize) + this.watchlistListingDetails.listing_ids.length;
          this.numberItemOfListingsMess = this.languageService.wrapDir('rtl', 'عرض ') + this.languageService.wrapDir('rtl', this.currentNumberItem) + this.languageService.wrapDir('rtl', ' إعلان ');
        }
      });

    });

  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  /**
   * change tab when user select or redirect
   * @param tabId
   */
  selectTab(tabId: number) {
    if (this.staticTabs && this.staticTabs.tabs[tabId] !== undefined) {
      this.staticTabs.tabs[tabId].active = true;
      this.onSelect(tabId);
    }
  }

  /**
   * change currentTabLabel show on browser
   * @param tabId
   */
  onSelect(tabId: number): void {
    // tab watchlist
    if (tabId === 0) {
      this.currentTabLabel = this.watchlistTabLabel;
    }
  }

  onChangepaginationWatchlistPage(event: any) {
    this.currentPage = event.page;
    if ((this.currentPage - 1) * this.getWatchlistSize == Number(this.getWatchlist.from)) {
      return;
    }
    const urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams[SearchConstant.PAGE] = this.currentPage;
    urlTree.queryParams[SearchConstant.TAB] = this.tab;

    this.router.navigateByUrl(urlTree);
  }

  /**
   * Send request to removw this item to user watchlist
   */
  removeToWatchList(watchListId: string, listingId: string) {
    if (Util.isNullOrEmpty(watchListId) || Util.isNullOrEmpty(listingId)) {
      return;
    }

    const watchlistItemWantToRemove = this.watchlistListingDetails.hits.find(item => item.watchlist.listing_id === listingId && item.watchlist.watchlist_id === watchListId);
    if (Util.isNullOrEmpty(watchlistItemWantToRemove)) {
      return;
    }
    this.watchListSubscription = this.accountService.removeToWatchList(watchListId).subscribe(data => {
      // if user has information response
      if (200 === data.status && data.body) {
        watchlistItemWantToRemove.watchlist.watchlist_id = null;
        this.currentNumberItem = --this.currentNumberItem;
        this.numberItemOfListingsMess = this.languageService.wrapDir('rtl', 'عرض ') + this.languageService.wrapDir('rtl', this.currentNumberItem) + this.languageService.wrapDir('rtl', ' إعلان ');
        // show notification to user
        this.notify.showInfoNotificationMessage(this.removedSuccessdMess);
      }
    }, error => {
      const message = this.languageService.messageFromServer(error.error.message);
      this.notify.showWarnmingNotificationMessage(message);
    });

    return;
  }

  /**
   * Send request to add this item to user watchlist
   */
  addToWatchList(watchListId: string, listingId: string) {
    if (Util.isNullOrEmpty(listingId) || Util.isNotNullOrEmpty(watchListId)) {
      return;
    }

    const watchlistItemWantToRemove = this.watchlistListingDetails.hits.find(item => item.watchlist.listing_id === listingId && item.watchlist.watchlist_id === null);
    if (Util.isNullOrEmpty(watchlistItemWantToRemove)) {
      return;
    }
    // addToWatchlist;
    this.watchListSubscription = this.accountService.addToWatchlist(listingId).subscribe(data => {
      // if user has information response
      if (200 === data.status && data.body && data.body.watchlist_id) {
        watchlistItemWantToRemove.watchlist.watchlist_id = data.body.watchlist_id;
        this.currentNumberItem = ++this.currentNumberItem;
        this.numberItemOfListingsMess = this.languageService.wrapDir('rtl', 'عرض ') + this.languageService.wrapDir('rtl', this.currentNumberItem) + this.languageService.wrapDir('rtl', ' إعلان ');
        // show notification to user
        this.notify.showInfoNotificationMessage(this.itemAddedToWatchlistdMess);
      }
    }, error => {
      const message = this.languageService.messageFromServer(error.error.message);
      this.notify.showWarnmingNotificationMessage(message);
    });
  }
}
