<!-- blue line  -->
<div class="bg-primary pt-2"></div>
<!--content-->
<div class="bg-light">
  <div class="container-fluid">

    <!-- profile setting -->
    <div class="row">
      <div class="col text-right">
        <h2 class="font-weight-bold text-warning pt-4 mt-3 mb-0 pb-4">Profile settings</h2>
        <h5 class="pb-3 mb-0">General</h5>
        <p class="pb-2">You are currently set as <b>not in trade.</b></p>
        <p class="pb-2">If you're selling items in trade, the law requires that you disclose this to members
          when you're selling items on Tajer. If you don't,
          the Commerce Commission can fine you. As a seller, you need to adhere to legal obligations when
          selling to consumers.<br>
          As a general rule, if you make, buy or obtain goods with the intention of selling them it's
          likely you're in trade.
          However, if you're listing items on Tajer that you initially bought or acquired for your own
          personal use,
          then you may not be in trade. If you’re doing both, we'd recommend you operate two accounts, one
          for personal use and one for business.</p>
        <p class="pb-2">To work out whether you are 'in trade', or for more information, check out the <a
          href> help page.</a></p>
        <button type="button" class="btn btn-lg btn-primary">
          <h5 class="mb-1"> Change my in trade status</h5>
        </button>
        <h5 class="pt-5 mt-4 pb-3">Search</h5>
        <!-- Display recent searches -->
        <div class="form-group form-check pb-4">
          <label for="customCheck1">Display recent searches</label>
          <input type="checkbox" id="customCheck1">
        </div>
        <button type="button" class="btn btn-lg btn-primary">
          <h5 class="mb-1"> Clear search history</h5>
        </button>
        <h6 class="pt-5 mt-4 pb-3">Privacy options</h6>
        <p class="text-secondary">We care about your privacy and we are committed to being transparent with
          how your personal information is
          used and disclosed. In our <a href> privacy policy</a>, we commit to giving you the ability
          to change your privacy options. More information is
          available on our ‘<a href>Info about your privacy options</a>’ page.</p>
        <p class="text-secondary mt-4 pb-2">Changes to your privacy options may take up to 24 hours to take
          effect.</p>
      </div>
    </div>
    <div class="row">
      <div class="col text-right bg-white border p-4">
        <p class="text-secondary mb-4">When opted in, Tajer can use and disclose your information:</p>
        <p class="text-secondary">To show you relevant advertising
          <img class="img-fluid" width="6"
               src="../../../assets/images/icon-images/tajr-icon-000049.png">
        </p>
        <p class="text-secondary">To trusted third parties using Tajer tailored products
          <img
            class="img-fluid" width="6" src="../../../assets/images/icon-images/tajr-icon-000049.png">
        </p>
        <p class="text-secondary">To show you relevant advertising
          <img class="img-fluid" width="6"
               src="../../../assets/images/icon-images/tajr-icon-000049.png">
        </p>

        <p class="text-secondary">When opted out, advertising may be less relevant and some functionality of
          tailored products or Tajer
          affiliated services may be reduced.
        </p>
        <!-- Opt in -->
        <div class="form-group form-check pt-3 pb-3">
          <label class="form-check-label" for="customCheck-optin">
            <h6> Opt in </h6>
          </label>
          <input type="checkbox" id="customCheck-optin">
        </div>

      </div>
    </div>
    <br/>
    <br/>
    <br/>
  </div>
</div>
<!-- blue line  -->
<div class="bg-primary pt-2"></div>
