import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {ProductService} from 'src/app/services/product.service';
import {Locality} from 'src/app/modules/models/locality';
import {TimeService} from 'src/app/services/time.service';
import {LanguageService} from 'src/app/services/language.service';
import {CarouselItem} from 'src/app/modules/models/carouselItem';
import {Subscription} from 'rxjs';
import {
  CATEGORY_LEVEL_1, EMPTY_VALUE, ITEM_PER_PAGE,
  LATEST,
  LOCALITIES_RESOURCE, MAX_CATEGORY_LEVEL,
  NUMBER_REGEX,
  SearchConstant, TAJR_ALL_CATEGORIES_RESOURCE
} from '../../shared/global';
import {District} from '../../modules/models/district';
import {Util} from '../../utils/utils';
import {SearchListingDetails} from '../../modules/models/search.listing.details';
import {SearchModel} from '../../modules/models/search.model';
import {TitleService} from '../../services/title.service';
import {NavigateService} from '../../services/navigate.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ListingService} from '../../services/listing.service';
import {CategoryLevel3Buckets} from '../../modules/models/CategoryLevel3.buckets';
import {CategoryLevel1} from '../../modules/models/CategoryLevel1';
import {CategoryLevel2} from '../../modules/models/CategoryLevel2';

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.css']
})
export class SearchPageComponent implements OnInit, OnDestroy {
  loadMoreMessage = this.languageService.findMessageByLanguage('loadMore');
  loadingMess = this.languageService.findMessageByLanguage('loading');
  deletedMess = this.languageService.findMessageByLanguage('itemHasBeenDeleted');
  expiredMess = this.languageService.findMessageByLanguage('itemHasBeenExpired');
  searchResultsMess = this.languageService.findMessageByLanguage('searchResultsFor');
  narrowYourSearchMess = this.languageService.findMessageByLanguage('narrowYourSearchFor');
  inMess = this.languageService.findMessageByLanguage('in');

  // width of screen size on browser
  public screenWidth: number;
  public locality: Locality;
  public isLoading = false;
  public numberItemOfListings = '';
  // option of search, follow search Api. Example: latest, closing_soon, price_low_to_high and price_high_to_low.
  public selectedOption = '';
  public id = '';
  public tajrAllCategories;
  public carouselList: Array<CarouselItem> = [];
  public getListings: SearchModel;
  public LocalityCatalogue: Array<Locality> = [];
  public searchListing: SearchListingDetails;
  // select item number show on carousel
  public getListingFromItem = 0;
  public getListingToItem = ITEM_PER_PAGE;
  public totalNumberItem = 0;
  public currentNumberItem = 0;
  private subscription: Subscription;
  // Search URL Parameters
  public query: string;
  public queryLable: string;
  public from: string;
  public size: string;
  public categoryId: string;
  public localityId: string;
  public districtId: string;
  public memberId: string;
  public filterOptions = [
    {id: '', name: 'الجميع'},
    {id: 'latest', name: 'الاعلانات الأخيرة'},
    {id: 'closing_soon', name: 'تنتهي قريبا'},
    {id: 'price_low_to_high', name: 'السعر من الأقل للاعلى'},
    {id: 'price_high_to_low', name: 'السعر من الاعلى للأقل'}
  ];
  // select button default value
  public categoryLabel: string;
  // Pagination attribute
  public currentPage = 1;
  public showBoundaryLinks = true;
  public maxSize = 5;

  constructor(
    // service send request
    private productService: ProductService,
    private router: Router,
    // parse time service
    private timeService: TimeService,
    // service use to get value from router
    private activatedRoute: ActivatedRoute,
    // language and sorts arabic message
    private languageService: LanguageService,
    public titleService: TitleService,
    public navigateService: NavigateService,
    public listingService: ListingService,
  ) {
  }

  ngOnInit() {
    // get width of device
    this.screenWidth = window.innerWidth;
    // receive value from data transfer Api
    this.tajrAllCategories = this.activatedRoute.snapshot.data[TAJR_ALL_CATEGORIES_RESOURCE];
    this.titleService.setCategories(this.tajrAllCategories);
    this.activatedRoute.queryParamMap.subscribe((queryParams) => {
      let value;
      // set search param for default
      this.localityId = '';
      this.districtId = '';
      this.categoryId = '';
      if (queryParams.has(SearchConstant.QUERY)) {
        this.query = queryParams.get(SearchConstant.QUERY);
        this.titleService.setSearchPageTitleAndMetaTab(this.query);
        this.queryLable = this.titleService.generateArabicLable(this.query);
      }
      if (queryParams.has(SearchConstant.FROM)) {
        value = queryParams.get(SearchConstant.FROM);
        if (NUMBER_REGEX.test(value)) {
          this.from = value;
        }
      }
      if (queryParams.has(SearchConstant.SORT_BY)) {
        value = queryParams.get(SearchConstant.SORT_BY);
        if (Util.isNotNullOrEmpty(this.filterOptions.find(item => item.id === value))) {
          this.selectedOption = value;
        }
      }
      if (queryParams.has(SearchConstant.CATEGORY_ID)) {
        this.categoryId = queryParams.get(SearchConstant.CATEGORY_ID);
      }
      if (queryParams.has(SearchConstant.LOCALITY_ID)) {
        this.localityId = queryParams.get(SearchConstant.LOCALITY_ID);
      }
      if (queryParams.has(SearchConstant.DISTRICT_ID)) {
        this.districtId = queryParams.get(SearchConstant.DISTRICT_ID);
      }
      if (queryParams.has(SearchConstant.MEMBER_ID)) {
        this.memberId = queryParams.get(SearchConstant.MEMBER_ID);
      }
      if (queryParams.has(SearchConstant.PAGE)) {
        this.currentPage = Number(queryParams.get(SearchConstant.PAGE));
      }

      this.from = ((this.currentPage - 1) * this.getListingToItem).toString();
      // get listing
      this.getListings = new SearchModel(
        this.query,
        this.selectedOption,
        this.from,
        this.getListingToItem.toString(),
        this.categoryId,
        this.localityId,
        this.districtId,
        this.memberId, null);

      this.getSearchListing();

    });

    // get category label
    this.categoryLabel = this.getCategoryLabelByLevel(this.categoryId, CATEGORY_LEVEL_1);

    // get all localties
    this.LocalityCatalogue = this.activatedRoute.snapshot.data[LOCALITIES_RESOURCE];
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  /**
   * send request to get item by search api follow user-selected filter.
   * example: All_listings,latest,closing_soon,price_low_to_high, price_high_to_low
   *
   */
  onChangeSelectedFilter(optionId = null) {
    // clear the listing first
    this.carouselList = [];
    this.isLoading = true;
    this.currentPage = 1;
    this.from = ((this.currentPage - 1) * this.getListingToItem).toString();
    if (optionId != null) {
      this.selectedOption = optionId;
    }
    this.getListings = new SearchModel(
      this.query,
      this.selectedOption,
      this.from,
      this.getListingToItem.toString(),
      this.categoryId,
      this.localityId,
      this.districtId,
      null, null);
    this.navigateInSearchPage(this.getListings)
  }

  /**
   * send request to get item by search api.
   * follow by user-selected filter and localty_id
   *
   */
  filterByLocalities(localityIdSelect = null) {
    // clear the current listing
    this.carouselList = [];
    this.isLoading = true;
    this.districtId = '';
    this.currentPage = 1;
    this.from = ((this.currentPage - 1) * this.getListingToItem).toString();
    this.selectedOption = LATEST;
    if (localityIdSelect != null) {
      this.localityId = localityIdSelect;
    }
    // get listing
    this.getListings = new SearchModel(
      this.query,
      this.selectedOption,
      this.from,
      this.getListingToItem.toString(),
      this.categoryId,
      this.localityId,
      this.districtId,
      null, null);
    this.navigateInSearchPage(this.getListings)
  }

  /**
   * send request to get item by search api.
   * follow by user-selected filter, localty_id and district_id
   *
   */
  filterByLocalitiesById(districtIdSelect = null) {
    if (districtIdSelect != null) {
      this.districtId = districtIdSelect;
    }
    if (Util.isNullOrEmpty(this.districtId)) {
      this.filterByLocalities();
    }
    this.carouselList = [];
    this.isLoading = true;
    this.currentPage = 1;
    this.from = ((this.currentPage - 1) * this.getListingToItem).toString();
    this.selectedOption = LATEST;
    // get listing
    this.getListings = new SearchModel(
      this.query,
      this.selectedOption,
      this.from,
      this.getListingToItem.toString(),
      this.categoryId,
      this.localityId,
      this.districtId,
      null, null);

    this.navigateInSearchPage(this.getListings)
  }

  /**
   * generated all district data show on district select option
   */
  generatedDistrictData(): Array<District> {
    let districtList: Array<District> = [];
    // return if don't has value
    if (Util.isNullOrEmpty(this.LocalityCatalogue)) {
      return districtList;
    }

    const districtData = this.LocalityCatalogue.find(item => item.locality_id == this.localityId);
    // select option value want to show from response
    if (Util.isNotNullOrEmpty(districtData) && Util.isNotNullOrEmpty(districtData.districts)) {
      districtList = districtData.districts;
    }

    return districtList;
  }

  /**
   * load more item when change pagination
   */
  onChangepaginationSearchPage(event: any) {
    this.currentPage = event.page;
    if ((this.currentPage - 1) * this.getListingToItem == Number(this.searchListing.from)) {
      return;
    }
    const urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams[SearchConstant.PAGE] = this.currentPage;

    this.router.navigateByUrl(urlTree);
  }

  /**
   * use HostListener to  updated on resize:
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  getScreenSize(event) {
    this.screenWidth = window.innerWidth;
  }

  /**
   * show CategoryLevel3
   */
  showCategoryLevel3(categoryLevel1Index: number, categoryLevel2Index: number): CategoryLevel3Buckets[] {
    let category: CategoryLevel3Buckets[];

    try {

      category = this.searchListing.aggregations.CategoryLevel1.buckets[categoryLevel1Index].CategoryLevel2.buckets[categoryLevel2Index].CategoryLevel3.buckets;

    } catch (e) {

    }

    return category;
  }

  /**
   * show CategoryLevel2
   */
  showCategoryLevel2(categoryLevel1Index: number) {
    let category: CategoryLevel2;

    try {

      category = this.searchListing.aggregations.CategoryLevel1.buckets[categoryLevel1Index].CategoryLevel2;

    } catch (e) {

    }

    return category;
  }

  /**
   * show CategoryLevel1
   */
  showCategoryLevel1() {
    let category: CategoryLevel1;

    try {

      category = this.searchListing.aggregations.CategoryLevel1;

    } catch (e) {

    }

    return category;
  }

  /**
   * get category label from level
   *
   * @param categoryId The category Id
   * @param level The level
   */
  getCategoryLabelByLevel(categoryId: string, level: number) {
    let result;
    let catergory;
    let categoryLevel;
    let categoryvalue;
    if (Util.isNullOrEmpty(categoryId)) {
      return;
    }

    switch (level) {
      case 0:
        categoryLevel = categoryId.split('-');
        categoryvalue = categoryLevel[0];
        catergory = this.tajrAllCategories.find(item => item.id == categoryvalue);
        if (Util.isNotNullOrEmpty(catergory)) {
          result = this.languageService.messageFromServer(catergory.name);
        }
        break;
      case 1:
        categoryLevel = categoryId.split('-');
        categoryvalue = categoryLevel[0] + '-' + categoryLevel[1];
        catergory = this.tajrAllCategories[level - 1].subcategories.find(item => item.id == categoryvalue);
        if (Util.isNotNullOrEmpty(catergory)) {
          result = this.languageService.messageFromServer(catergory.name);
        }
        break;
      case 2:
      default:
        /* TODO:  When Api has more data */
        break;
    }

    return result;
  }

  searchByCategoryLevel3(categoryId: string) {
    if (Util.isNullOrEmpty(categoryId)) {
      return;
    }
    this.categoryId = categoryId;
    this.currentPage = 1;
    this.from = ((this.currentPage - 1) * this.getListingToItem).toString();
    // get listing
    this.getListings = new SearchModel(
      this.query,
      this.selectedOption,
      this.from,
      this.getListingToItem.toString(),
      this.categoryId,
      this.localityId,
      this.districtId,
      null, null);

    this.navigateInSearchPage(this.getListings)
  }

  getSearchListing() {
    this.subscription = this.productService.getListingsItem(this.getListings).subscribe(data => {
      // if listing list available
      if (200 == data.status && Util.isNotNullOrEmpty(data.body)) {
        this.searchListing = data.body;
        this.totalNumberItem = Number(this.searchListing.total);
        this.currentNumberItem = (this.currentPage - 1) * this.getListingToItem + this.searchListing.listing_ids.length;
        // check item is in watchlist or not
        this.titleService.removeDataStructure();
        this.titleService.createDataStructure();
        this.isLoading = false;
        if (this.searchListing.hits.length == 0) {
          this.titleService.setDefaultDataStructure();
        }
        /* Update SEO tab */
        this.searchListing.hits.forEach(hit => {
          this.titleService.setHomePageDataStructure(hit);
        });
      }
    });
  }

  /**
   * Detect this category is leaf or not
   *
   * @param categoryId The category Id
   */
  isLeafCategory(categoryId: string): boolean {
    let result = false;
    if (Util.isNullOrEmpty(categoryId)) {
      return result;
    }
    const categoryLevel = categoryId.split('-');
    if (categoryLevel.length == MAX_CATEGORY_LEVEL) {
      result = true;
    }
    return result;
  }

  showCategoryMessage(): string {
    let result = '';
    // Narrow your search for {query} in {categoryLabel}
    if (this.query && this.categoryLabel) {
      result = '        <p class="mb-2">\n' +
        this.narrowYourSearchMess +
        '          <span class="text-dark">\n' +
        this.queryLable +
        '        </span>\n' +
        this.inMess +
        '          <span class="text-dark h5">\n' +
        this.categoryLabel +
        '        </span>\n' +
        '        </p>';
    } else if (this.categoryLabel) {
      // Narrow your search for in {categoryLabel}'
      result = '        <p class="mb-2">\n' +
        this.narrowYourSearchMess +
        '          <span class="text-dark">\n' +
        this.categoryLabel +
        '        </span>\n' +
        '        </p>';
    }

    return result;
  }

  navigateInSearchPage(getListings :  SearchModel) {
    const urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams[SearchConstant.QUERY] = getListings.query ?  getListings.query : EMPTY_VALUE;
    urlTree.queryParams[SearchConstant.FROM] = getListings.from ? getListings.from: EMPTY_VALUE;
    urlTree.queryParams[SearchConstant.SORT_BY] = getListings.sort_by ? getListings.sort_by: EMPTY_VALUE;
    urlTree.queryParams[SearchConstant.CATEGORY_ID] = getListings.category_id ? getListings.category_id: EMPTY_VALUE
    urlTree.queryParams[SearchConstant.LOCALITY_ID] = getListings.locality_id ? getListings.locality_id: EMPTY_VALUE;
    urlTree.queryParams[SearchConstant.DISTRICT_ID] = getListings.district_id ? getListings.district_id: EMPTY_VALUE;
    urlTree.queryParams[SearchConstant.MEMBER_ID] = getListings.member_id ? getListings.member_id : EMPTY_VALUE;
    urlTree.queryParams[SearchConstant.PAGE] = this.currentPage ? this.currentPage: EMPTY_VALUE;
    this.router.navigateByUrl(urlTree);
  }
}

