import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Util} from '../../../utils/utils';
import {ListingService} from '../../../services/listing.service';
import {LanguageService} from "../../../services/language.service";

@Component({
  selector: 'app-confirm-delete-listing-modal',
  templateUrl: './confirm-delete-listing-modal.component.html',
  styleUrls: ['./confirm-delete-listing-modal.component.css']
})
export class ConfirmDeleteListingModalComponent implements OnInit {
  confirmMess = this.languageService.findMessageByLanguage('confirm');
  doYouWantToDeleteThisListingMess = this.languageService.findMessageByLanguage('doYouWantToDeleteThisListing');
  yesMess = this.languageService.findMessageByLanguage('yes');
  noMess = this.languageService.findMessageByLanguage('no');

  constructor(
    public dialogRef: MatDialogRef<ConfirmDeleteListingModalComponent>,
    public listingService: ListingService,
    public languageService: LanguageService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    if (Util.isNotNullOrEmpty(this.data) && Util.isNotNullOrEmpty(this.data.listingId)) {
      this.listingService.withdrawListing(this.data.listingId);
    }
    // close confirm modal
    setTimeout(() => {
      this.closeModal();
    }, 500);
  }
}
