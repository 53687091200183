import {Injectable} from '@angular/core';
import {Router, NavigationStart} from '@angular/router';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomizerService {

  constructor(private router: Router) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        if (event.url.indexOf('applayout-') === -1) {
          if (event.url.indexOf('sessions') !== -1) {
            return;
          }
        }
      });
  }

  /**
   * sorts out right ot left string
   * @param isRTL -- true is arabic language
   */
  toggleDir(isRTL) {
    let html = document.getElementsByTagName('html')[0];
    if (!html) {
      return;
    }
    if (isRTL) {
      html.setAttribute('dir', 'rtl');
    } else {
      html.setAttribute('dir', 'ltr');
    }
  }
}
