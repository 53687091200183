import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {ProfileDetails} from '../../modules/models/profile.details';
import {Subscription} from 'rxjs';
import {SearchListingDetails} from '../../modules/models/search.listing.details';
import {LanguageService} from '../../services/language.service';
import {SearchService} from '../../services/search.service';
import {HttpHelperService} from '../../services/http-helper.service';
import {DatePipe} from '@angular/common';
import {TimeService} from '../../services/time.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TabsetComponent} from 'ngx-bootstrap/tabs';
import {AccountService} from '../../services/account.service';
import {SearchModel} from '../../modules/models/search.model';
import {NavigateService} from '../../services/navigate.service';
import {ITEM_PER_PAGE, ItemAttributes, NOT_FOUND_PAGE, SearchConstant} from '../../shared/global';
import {ListingService} from '../../services/listing.service';
import {TitleService} from '../../services/title.service';

@Component({
  selector: 'app-member-listings',
  templateUrl: './member-listings.component.html',
  styleUrls: ['./member-listings.component.css']
})
export class MemberListingsComponent implements OnInit {
  loadMoreMessage = this.languageService.findMessageByLanguage('loadMore');
  loadingMess = this.languageService.findMessageByLanguage('loading');
  deletedMess = this.languageService.findMessageByLanguage('itemHasBeenDeleted');
  expiredMess = this.languageService.findMessageByLanguage('itemHasBeenExpired');

  // user details save from cookie
  public userDetails: ProfileDetails;
  //  subscription to observable request get member's listings
  private subscription: Subscription;
  // member listing
  private getListings: SearchModel;
  public sellingListingDetails: SearchListingDetails;
  // select item number show on carousel
  public getListingFromItem = 0;
  public getListingToItem = ITEM_PER_PAGE;
  public totalNumberItem = 0;
  public currentNumberItem = 0;
  // width of screen size on browser
  public screenWidth: number;
  public memberId: string;
  public username: string;
  // attribute to show loading button
  loading = false;
  // select button default value
  public selected = 'latest';
  numberItemOfListingsMess = '';
  // Pagination attribute
  public totalItems: number;
  public currentPage = 1;
  public showBoundaryLinks = true;
  public maxSize = 5;

  constructor(
    // get language message from local
    private languageService: LanguageService,
    // service send request to login
    private searchService: SearchService,
    private accountService: AccountService,
    // contain api
    private httpHelper: HttpHelperService,
    private elementRef: ElementRef,
    // service convert date format
    private datePipe: DatePipe,
    // parse time service
    private timeService: TimeService,
    // service use to get value from router
    private activatedroute: ActivatedRoute,
    public navigateService: NavigateService,
    private router: Router,
    public listingService: ListingService,
    public titleService: TitleService,
  ) {
  }

  @ViewChild('staticTabs') staticTabs: TabsetComponent;

  ngOnInit() {
    // get width of device
    this.screenWidth = window.innerWidth;
    this.getListings = new SearchModel();
    // get value from url
    this.activatedroute.queryParamMap.subscribe((queryParams) => {
      if (queryParams.has(SearchConstant.PAGE)) {
        this.currentPage = Number(queryParams.get(SearchConstant.PAGE));
      }

      this.activatedroute.paramMap.subscribe((params) => {
        if (!params.has(ItemAttributes.MEMBER_ID) || !params.has(ItemAttributes.USERNAME)) {
          // when get listing detaile has error will be redirect to not found page
          this.router.navigate([NOT_FOUND_PAGE]);
        }
        this.memberId = params.get(ItemAttributes.MEMBER_ID);
        this.username = params.get(ItemAttributes.USERNAME);
        // get member public details
        this.getMemberPublicDetails(this.memberId);
        // get member listing
        this.getMemberPrivateListing();
      });
    });
  }

  /**
   * use HostListener to  updated on resize:
   * @param event resize event
   */
  @HostListener('window:resize', ['$event'])
  getScreenSize(event) {
    this.screenWidth = window.innerWidth;
  }

  /**
   * Load more item when change pagination
   */
  onChangepaginationMemberPage(event: any) {
    this.currentPage = event.page;
    if ((this.currentPage - 1) * this.getListingToItem === Number(this.sellingListingDetails.from)) {
      return;
    }
    const urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams[SearchConstant.PAGE] = this.currentPage;
    this.router.navigateByUrl(urlTree);
  }

  /**
   * display member localtion
   */
  showLocation() {
    let locality = '';
    let district = '';
    let location = '';
    if (this.userDetails && this.userDetails.locality && this.userDetails.district) {
      district = this.languageService.messageFromServer(this.userDetails.district.name);
      locality = this.languageService.messageFromServer(this.userDetails.locality.name);
    }

    location = district + ' , ' + locality;
    return location;
  }

  /**
   * Get listings. This function will call when loading page and when on change select button
   */
  getMemberPrivateListing(selectedVal = null) {
    // import getListings detail parameters with "filter_by" is "latest" to get listing.
    if (selectedVal) {
      this.selected = selectedVal;
    }
    this.getListings.sort_by = this.selected;
    this.getListings.from = ((this.currentPage - 1) * this.getListingToItem).toString();
    this.getListings.size = this.getListingToItem.toString();
    this.getListings.member_id = this.memberId;

    // send request to get listing
    this.subscription = this.searchService.searchGeneralListing(this.getListings).subscribe(data => {
      // if user has information response
      if (200 === data.status && data.body) {
        // set value for sellingListingDetails
        this.sellingListingDetails = data.body;
        this.totalNumberItem = Number(this.sellingListingDetails.total);
        this.currentNumberItem = (this.currentPage - 1) * this.getListingToItem + this.sellingListingDetails.listing_ids.length;
        this.numberItemOfListingsMess = this.languageService.wrapDir('rtl', 'عرض ') +
          this.languageService.wrapDir('rtl', this.currentNumberItem) +
          this.languageService.wrapDir('rtl', ' إعلان ');
      }
    });
  }

  /**
   * Get member public data
   */
  getMemberPublicDetails(memberId: string) {
    this.subscription = this.accountService.getUserPublicDetails(memberId).subscribe(data => {
      // if user has information response
      if (200 === data.status && data.body) {
        this.userDetails = data.body;
      }
    });
  }

  /**
   * show member register date
   */
  showMemberRegisterDate(): string {
    // dateCreate from server response
    const registerDateStr = this.userDetails.member_since;
    // return if don't has value
    if (registerDateStr && 0 === registerDateStr.length) {
      return;
    }
    // endDate text show on template
    let registerDateText = '';
    // let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }; // all localised stuff
    const memberSinceOptions = {
      year: 'numeric',
      month: 'long'
    }; // we only need year and month
    const memberSince = new Date(registerDateStr);
    memberSince.setUTCHours(memberSince.getUTCHours());
    registerDateText = memberSince.toLocaleDateString('ar-EG', memberSinceOptions);
    return registerDateText;
  }

}
