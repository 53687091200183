<!-- blue line  -->
<div class="bg-primary pt-2"></div>
<!--content-->
<div class="bg-light">
  <div class="container-fluid">

    <div class="row">
      <div class="col-12 col-sm-10 col-md-8 col-lg-6 offset-sm-1 offset-md-2 offset-lg-3 text-right pb-5">
        <form [formGroup]="verifyResetPasswordForm" (ngSubmit)="verifyResetPasswordForm.valid && verifyResetPassword()">
          <!-- title -->
          <div class="row">
            <div class="col mt-5">
              <h4 class="text-warning">التحقق من هويتك</h4>
            </div>
          </div>
          <div class="row">
            <div class="col mt-4">
              <p class="text-secondary" dir="rtl">
                <span class="font-weight-bold text-dark pl-1 pr-1">{{mobileNumberMess}}</span>
                للحفاظ على حماية حسابك، نحتاج إلى التحقق من هويتك. تم إرسال رمز التحقق إلى رقم الجوال
              </p>
            </div>
          </div>
          <!-- verification code -->
          <div class="row">
            <div class="col mt-3">
              <label class="font-weight-bold text-secondary" for="verificationCode-lg">
                رمز التحقق
              </label>
              <input class="form-control text-right" id="verificationCode-lg" formControlName="verification_code"
                     autocomplete="on">
            </div>
          </div>
          <!-- error verification code -->
          <div class="row ">
            <div class="col position-absolute mt-1">
              <small class="text-danger pt-3"
                     *ngIf="verifyResetPasswordForm.controls.verification_code.dirty && !verifyResetPasswordForm.controls.verification_code.valid">
            <span *ngIf="verifyResetPasswordForm.controls.verification_code.errors?.required">
              {{requireVerifyCodeMessage}}</span>
                <span *ngIf="verifyResetPasswordForm.controls.verification_code.errors.pattern">
              {{regexVerifyCodeMessage}}</span>
              </small>
              <small class="text-danger pt-3">
                <span>{{errorMessage}}</span>
              </small>
            </div>
          </div>
          <!-- enter code button -->
          <div class="row">
            <div class="col mt-5">
              <button type="submit" class="btn btn-primary btn-block" *ngIf="!loading">{{continueMess}}</button>
              <button class="btn alert-primary text-white btn-block d-flex align-items-center justify-content-center "
                      *ngIf="loading" disabled>
                {{loadingMess}}
                <span class="spinner-border spinner-border-sm mx-2"></span>
              </button>
            </div>
          </div>
          <!-- link resend code -->
          <div class="row" *ngIf="!(timeService.timeLeft > 0)">
            <div class="col mt-4 ">
              <a href="javascript:void(0);" (click)="resendVerifyResetPassword()" *ngIf="!resendCodeStatus"
                 class="text-decoration-none">
                {{resendCodeMess}} </a>
              <a href="javascript:void(0);" *ngIf="resendCodeStatus"
                 class="text-decoration-none">
                {{loadingMess}}
                <span class="spinner-border spinner-border-sm float-right"></span>
              </a>
            </div>
          </div>
          <!-- link count down timer -->
          <div class="row" *ngIf="timeService.timeLeft > 0">
            <div class="col mt-4 ">
              <a href="javascript:void(0);" class="text-decoration-none text-secondary">
                {{waitToResendOtpMess.replace('{timer}', commonHelperService.numberToArabic(timeService.timeLeft))}}
              </a>
            </div>
          </div>
          <!-- error resend code -->
          <div class="row ">
            <div class="col position-absolute mt-1">
              <small class="text-danger">{{errorResendCodeMessage}}</small>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- blue line  -->
<div class="bg-primary pt-2"></div>
