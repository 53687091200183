import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-listing',
  templateUrl: './dashboard-listing.component.html',
  styleUrls: ['./dashboard-listing.component.css']
})
export class DashboardListingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
