import {ChangeDetectionStrategy, Component, HostListener, Inject, OnInit} from '@angular/core';
import {ProfileDetails} from "../../../modules/models/profile.details";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {LanguageService} from "../../../services/language.service";
import {NotificationServices} from "../../../services/notification.services";
import {FixedSizeVirtualScrollStrategy, VIRTUAL_SCROLL_STRATEGY} from "@angular/cdk/scrolling";
import {ReportReasons} from "../../../modules/models/report.reasons";
import {Util} from "../../../utils/utils";
import {ListingDetails} from "../../../modules/models/listingDetails";
import {ReportService} from "../../../services/report.service";
import {Reports} from "../reports";

export class CustomVirtualScrollStrategy extends FixedSizeVirtualScrollStrategy {
  constructor() {
    super(50, 250, 500);
  }
}

@Component({
  selector: 'app-listing-report-modal',
  templateUrl: './listing-report-modal.component.html',
  styleUrls: ['./listing-report-modal.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{provide: VIRTUAL_SCROLL_STRATEGY, useClass: CustomVirtualScrollStrategy}]
})
export class ListingReportModalComponent implements OnInit {
  // message show when valid modal input value
  requireMobileNumberMessage = this.languageService.findMessageByLanguage('requireMobileNumber');
  regexMobileNumberMessage = this.languageService.findMessageByLanguage('regexMobileNumber');
  someThingWentWrongMessage = this.languageService.findMessageByLanguage('someThingWentWrong');
  loadingMess = this.languageService.findMessageByLanguage('loading');
  confirmMess = this.languageService.findMessageByLanguage('confirm');
  reportHasBeenSentSuccessMess = this.languageService.findMessageByLanguage('reportHasBeenSentSuccess');

  // attribute to show loading button
  public loading = false;
  // error message response from server
  public errorMessage: string;
  // user details receive from server
  public profileDetails: ProfileDetails;
  public reportReasons: Array<ReportReasons>;
  public listingType: string;
  public selectedSubjectId: string;
  public listingDetails: ListingDetails;
  public screenWidth: number;

  constructor(
    public dialogRef: MatDialogRef<ListingReportModalComponent>,
    public languageService: LanguageService,
    private notify: NotificationServices,
    private reportService: ReportService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit(): void {
    // get width of device
    this.screenWidth = window.innerWidth;

    if (Util.isNotNullOrEmpty(this.data) && Util.isNotNullOrEmpty(this.data.listingDetails)) {
      this.listingDetails = this.data.listingDetails;
      const attribute = this.listingDetails.strings.find(item => item.string_attribute_id === "listingType");
      this.listingType = attribute.option_id;
      this.reportReasons = this.data.reportReasons.filter(item =>
        item.supported_listing_types.includes(this.listingType)
      );
    }
  }

  /**
   * use HostListener to  updated on resize:
   * @param event event hold when responsive
   */
  @HostListener('window:resize', ['$event'])
  getScreenSize(event) {
    this.screenWidth = window.innerWidth;
  }

  /**
   * close model
   */
  closeModal(): void {
    this.dialogRef.close();
  }

  selectReport(subjectId: string) {
    this.selectedSubjectId = subjectId;
  }

  sendReport() {
    if (Util.isNullOrEmpty(this.selectedSubjectId)) {
      return;
    }
    let report = new Reports(this.listingDetails.listing_id, this.selectedSubjectId);
    this.reportService.sendReport(report).subscribe(data => {
      // if user has information response
      if (200 === data.status) {
        this.closeModal();
        this.notify.showInfoNotificationMessage(this.reportHasBeenSentSuccessMess);
      }
    }, err => {
      // User login fail, close loading status on button
      this.loading = false;
      // get error message response from server
      if (err && err.error) {
        // show error
        this.errorMessage = this.languageService.messageFromServer(err.error.message);
      } else {
        // service error message
        this.errorMessage = this.someThingWentWrongMessage;
      }
      this.closeModal();
      this.notify.showInfoNotificationMessage(this.errorMessage);
    });
  }
}
