import {makeStateKey, TransferState} from '@angular/platform-browser';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {ProductService} from '../../services/product.service';
import {HP_ALL_LISTING} from '../../shared/global';
import {first, tap} from "rxjs/operators";
import {SearchListingDetails} from "../../modules/models/search.listing.details";

@Injectable()
export class HomeQuickCarSearchResolve implements Resolve<SearchListingDetails> {
  constructor(
    private productService: ProductService,
    private transferState: TransferState) {
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<SearchListingDetails> {

    const DATA = makeStateKey<SearchListingDetails>(HP_ALL_LISTING);
    /**
     * Get all listings, without passing any parameters
     */
    return this.productService.getExistListingsItem(null)
      .pipe(
        first(),
        tap(data => {
          if (data) {
            this.transferState.set(DATA, data);
          }
        })
      );
  }
}
