<!-- blue line  -->
<div class="bg-primary pt-2"></div>
<!--content-->
<div class="container-fluid">

  <div class="row">
    <div class="col mt-5">
      <h4 class="text-warning text-right">السلع والإعلانات الممنوعة</h4>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <p class="text-primary text-right" dir="rtl">
        هذا الموقع للاختبار والمنتجات ليست حقيقية
      </p>
    </div>
  </div>
  <br/>
  <br/>
  <br/>
  <br/>
</div>

<!-- blue line  -->
<div class="bg-primary mt-3 pt-2"></div>
