import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpHelperService} from 'src/app/services/http-helper.service';
import {ApiReferenceService} from 'src/app/shared/api-reference.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  constructor(
    // sent request service helper
    private http: HttpHelperService,
    // contain api
    private apiReference: ApiReferenceService,
  ) {
  }

  /**
   * Get all report
   */
  getAllReportReasons() {
    return this.http.getAllReportReasons(this.apiReference.getReportReasons);
  }

  /**
   * create report
   * @param data data
   */
  sendReport(data: any): Observable<any> {
    return this.http.createReport(this.apiReference.createReportReasons, data).pipe(response => {
      return response;
    });
  }
}
