import {Injectable} from '@angular/core';

import {
  TAJR_LIST_AN_ITEM,
  FIRST_ITEM,
  TAJR_LIST_AN_ITEM_PHOTOS,
  TAJR_LIST_AN_ITEM_FEES,
  TAJR_LIST_AN_ITEM_DETAILS,
  TAJR_CATEGORIES_DETAILS,
  MAX_CATEGORY_LEVEL,
  NOT_EMPTY_REGEX
} from '../shared/global';
import {Util} from '../utils/utils';
import {SessionStorageService} from "../server-side-rendering/storages/session.storage.service";
import {CategoryService} from "./category.service";
import {Validators} from '@angular/forms';


@Injectable({
  providedIn: 'root'
})
export class ListAnItemService {
  scope = [TAJR_LIST_AN_ITEM, TAJR_LIST_AN_ITEM_PHOTOS, TAJR_LIST_AN_ITEM_DETAILS, TAJR_LIST_AN_ITEM_FEES];

  public _tajrListAnItem;
  public _tajrListAnItemPhotos;
  public _tajrListAnItemDetails;
  public _tajrListAnItemFees;

  constructor(
    private sessionStorageService: SessionStorageService,
    private categoryService: CategoryService,
    ) {
    
  }

  get tajrListAnItem() {
    return this.getDataFromStorage(TAJR_LIST_AN_ITEM);
  }

  get tajrListAnItemCategoryID() {
    return this.getDataFromStorage(TAJR_LIST_AN_ITEM)?.categoryId;
  }

  get tajrListAnItemPhotos() {
    return this.getDataFromStorage(TAJR_LIST_AN_ITEM_PHOTOS);
  }

  get tajrListAnItemDetails() {
    return this.getDataFromStorage(TAJR_LIST_AN_ITEM_DETAILS);
  }

  get tajrListAnItemFees() {
    return this.getDataFromStorage(TAJR_LIST_AN_ITEM_FEES);
  }

  set tajrListAnItem(value) {
    this.setDataToStorage(TAJR_LIST_AN_ITEM, value)
  }

  set tajrListAnItemPhotos(value) {
    this.setDataToStorage(TAJR_LIST_AN_ITEM_PHOTOS, value)
  }

  set tajrListAnItemDetails(value) {
    this.setDataToStorage(TAJR_LIST_AN_ITEM_DETAILS, value)
  }

  set tajrListAnItemFees(value) {
    this.setDataToStorage(TAJR_LIST_AN_ITEM_FEES, value)
  }

  public getActiveIDFromSS() {
    return this.getDataFromStorage(TAJR_LIST_AN_ITEM)?.categoryId;
  }

  public removeSessionData(list = []) {
    list.forEach(key => this.sessionStorageService.remove(key))
  }

  public checkIfEmptySessionData(list = []) {
    return list.reduce((final, key) => !this.getDataFromStorage(key) && final, true)
  }

  public clearAllListAnItemSessionData() {
    this.removeSessionData(this.scope);
  }

  getDataFromStorage(key) {
    if (!key) return null;
    if (!this.sessionStorageService.get(key)) return null;
    return JSON.parse(this.sessionStorageService.get(key))
  }

  setDataToStorage(key, value) {
    this.sessionStorageService.set(key, JSON.stringify({
        ...value,
    }));
  }

  public getFormOptionsFromData(categoryDetailData, attrList, existingModule, extraControl) {
    const excludeAtrrs = []
    return categoryDetailData.attributes.reduce((full, attr) => {
      const validateRules = [];
      let defaultValue;
      defaultValue = existingModule[attr.id] || attr.default_value || '';
      if (attr.type == 'bool') {
        defaultValue = Util.isNotNullOrEmpty(existingModule[attr.id]) ? existingModule[attr.id] : attr.default_value === 'true';
      }
      if (attr.is_required_for_selling === 'true') {
        validateRules.push(Validators.required);
        validateRules.push(Validators.pattern(NOT_EMPTY_REGEX));
      }
      switch (attr.type) {
        case 'string': 
          if (Number(attr.max_length)) validateRules.push(Validators.maxLength(Number(attr.max_length)));
        break;
        case 'number':
          if (Number(attr.lower)) validateRules.push(Validators.min(Number(attr.lower)));
          if (Number(attr.lower)) validateRules.push(Validators.max(Number(attr.upper)));
        break;
      }
      return attrList?.indexOf(attr.id) > -1 ? {
        ...full,
        [attr.id]: [
          defaultValue,
          Validators.compose(validateRules)
        ]
      } : full;
    }, { ...extraControl });
  }

}
