import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-register-details',
  templateUrl: './dashboard-register-details.component.html',
  styleUrls: ['./dashboard-register-details.component.css']
})
export class DashboardRegisterDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
