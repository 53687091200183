<div class="bg-light">
  <div class=" container-fluid">
    <div class="row pt-3 pb-3 position-relative d-flex justify-content-end align-items-center">
      <!-- login menu -->
      <div class="col text-right position-absolute text-primary" dir="rtl" *ngIf="checkUserLoginStatus()">
        <a href="javascript:void(0);" (click)="signOut()" class="text-secondary">
          <small>تسجيل الخروج</small>
        </a> &nbsp;
        <!--Hi -->هلا &nbsp;
        <a class="chat-icon" routerLink="/chat" title="Chat"><i class="fa fa-comments"></i></a>&nbsp;
        <span class="text-primary">{{userDetails.first_name}}</span>
      </div>
      <!--logout menu  -->
      <div class="col text-right position-absolute" *ngIf="!checkUserLoginStatus()">
        <a href="javascript:void(0);" (click)="openLoginDialog()">
          <small>تسجيل
            الدخول
          </small>
        </a>
        <a class="text-secondary mr-1" routerLink="/term-and-condition">
          <small> سـجّـل الأن</small>
        </a>
      </div>
    </div>
    <!-- desktop size -->
    <div class="row d-none d-md-block">
      <div class="col pt-1 pb-1">
        <div class="d-flex justify-content-between align-items-center pt-2 pb-2">
          <div class="d-flex justify-content-start align-items-center">
            <div>
              <!-- my tajr -->
              <a href="javascript:void(0);" class="text-dark d-flex align-items-center"
                 data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                 (click)="isCloseMyAccountMenuBtn  = !isCloseMyAccountMenuBtn; getMemberListingSummary()">
                حسابي
              </a>
              <!-- dropdown menu -->
              <div class="dropdown-menu dropdown-menu-left  w-auto pt-0 ml-3"
                   (click)="isCloseMyAccountMenuBtn  = !isCloseMyAccountMenuBtn"
                   [collapse]="isCloseMyAccountMenuBtn">
                <!-- user not login - use d-none to change -->
                <div class="container-fluid" *ngIf="!checkUserLoginStatus()">
                  <div class="row border-bottom bg-light align-self-center pt-2 pb-2">
                    <div class="col text-right">
                      <a routerLink="/login" class="font-weight-bold text-secondary">
                        <img src="../../../assets/images/icon-images/tajr-icon-000002.png">
                        الدخول إلى الحساب
                      </a>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-12 col-md-auto text-right">
                      <p class="text-warning mb-1 d-flex justify-content-end">
                        <!-- Buying -->شراء
                      </p>
                      <p class="text-secondary mb-0 d-flex justify-content-end">
                        <!-- watch list -->
                        <a class="nav-link text-secondary p-0" routerLink="/login">
                          <small> الاعلانات المراقبة</small>
                        </a>
                      </p>
                    </div>
                    <div class="col-12 col-md-auto text-right">
                      <p class="text-warning mb-1 d-flex justify-content-end">
                        <!-- Selling -->بيع
                      </p>
                      <p class="text-secondary mb-0 d-flex justify-content-end">
                        <!-- selling -->
                        <a class="nav-link text-secondary p-0" routerLink="/login">
                          <small> الاعلانات المعروضة للبيع</small>
                        </a>
                      </p>
                      <p class="text-secondary mb-0 d-flex justify-content-end">
                        <!-- sold -->
                        <a class="nav-link text-secondary p-0" routerLink="/login">
                          <small> الاعلانات المنتهية</small>
                        </a>
                      </p>
                      <p class="text-secondary mb-0 d-flex justify-content-end">
                        <a class="nav-link text-secondary p-0" routerLink="/login">
                          <small> الاعلانات المحذوفة</small>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <!-- member login - use d-none to change -->
                <div class="container-fluid" *ngIf="checkUserLoginStatus()">
                  <div class="row border-bottom bg-light align-self-center pt-2 pb-2">
                    <div class="col text-right">
                      <a routerLink="/my-tajr" class="font-weight-bold text-secondary">
                        <img src="../../../assets/images/icon-images/tajr-icon-000002.png">
                        الدخول إلى الحساب
                      </a>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-12 col-md-auto text-right">
                      <p class="text-warning mb-1 d-flex justify-content-end">
                        <!-- Buying -->شراء
                      </p>
                      <p class="text-secondary mb-0 d-flex justify-content-end">
                        <span>({{memberListingSummary?.watchlist.doc_count}})</span>&nbsp;
                        <!-- watch list -->
                        <a class="nav-link text-secondary p-0" routerLink="/watchlist">
                          <small> الاعلانات المراقبة</small>
                        </a>
                      </p>
                    </div>
                    <div class="col-12 col-md-auto text-right">
                      <p class="text-warning mb-1 d-flex justify-content-end">
                        <!-- Selling -->بيع
                      </p>
                      <p class="text-secondary mb-0 d-flex justify-content-end">
                        <span>({{memberListingSummary?.selling_listings.doc_count}})</span>&nbsp;
                        <!-- selling -->
                        <a class="nav-link text-secondary p-0" routerLink="/selling">
                          <small> الاعلانات المعروضة للبيع</small>
                        </a>
                      </p>
                      <p class="text-secondary mb-0 d-flex justify-content-end">
                        <span>({{memberListingSummary?.expired_listings.doc_count}})</span>&nbsp;
                        <!-- sold -->
                        <a class="nav-link text-secondary p-0" href="javascript:void(0);"
                           (click)="navigateToSellingPage('/selling', '1')">
                          <small> الاعلانات المنتهية</small>
                        </a>
                      </p>
                      <p class="text-secondary mb-0 d-flex justify-content-end">
                        <span>({{memberListingSummary?.withdrawn_listings.doc_count}})</span>&nbsp;
                        <a class="nav-link text-secondary p-0" href="javascript:void(0);"
                           (click)="navigateToSellingPage('/selling', '2')">
                          <small> الاعلانات المحذوفة</small>
                        </a>
                      </p>
                    </div>
                    <div class="col-12 col-md-auto text-right">
                      <p class="text-warning mb-1 d-flex justify-content-end">
                        <!--Account -->حسابي
                      </p>
                      <p class="text-secondary mb-0">
                        <a class="nav-link text-secondary p-0" routerLink="/my-profile">
                          {{userDetails.username}}
                        </a>
                      </p>
<!--                      <p class="text-secondary mb-0">&nbsp;</p>-->
<!--                      <p class="text-secondary mb-1">-->
<!--                        <a class="nav-link font-weight-bold text-secondary p-0" routerLink="/my-tajr">-->
<!--                          &lt;!&ndash; Tajer account&ndash;&gt;رصيد تاجر-->
<!--                        </a>-->
<!--                      </p>-->
<!--                      <p class="text-secondary mb-0 d-flex justify-content-end">-->
<!--                        <a class="nav-link text-secondary p-0" href="javascript:void(0);">-->
<!--                          <small>-->
<!--                            &lt;!&ndash; Add credit&ndash;&gt;أشحن رصيدك-->
<!--                          </small>-->
<!--                        </a>&nbsp;-->
<!--                        <small class="text-secondary"> ر.س</small>-->
<!--                        <span class="text-secondary"> 0</span>-->
<!--                      </p>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <a class="text-dark ml-3" routerLink="/watchlist">
              <!-- Watchlist-->قائمة المراقبة</a>
            <a class="ml-3 btn btn-primary btn-sm d-flex align-items-center justify-content-center"
               routerLink="/list-an-item">
              <span class="material-icons">add</span>
              <!-- List an item-->أضف إعلانك
            </a>
            <span class="ml-3 text-warning btn-sm d-flex align-items-center justify-content-center">
              الاعلان والبيع مجاناً... بدون عموله
            </span>
          </div>
          <a routerLink="/"><img width="55" src="../../../assets/images/logo-images/tajr-logo.png"></a>
        </div>
      </div>
    </div>
    <!-- mobile size -->
    <div class="d-md-none">
      <div class="row">
        <div class="col pl-0">
          <nav class="navbar navbar-expand-md navbar-light pl-0  pr-2  w-100" dir="rtl">
            <form class="w-100 row" dir="rtl">
              <button class="navbar-toggler border-0 col-auto p-0" type="button"
                      (click)="isCloseTajrLogoBtn = !isCloseTajrLogoBtn"
                      (click)="isCloseMyAccountMenuBtn = true">
                <span class="navbar-toggler-icon"></span>
              </button>
              <a routerLink="/" class="col-auto align-self-center px-2" (click)="keySearch = ''">
                <img width="36" src="../../../assets/images/logo-images/tajr-round-logo.png">
              </a>
              <mat-form-field class="col p-0">
                <mat-label></mat-label>
                <input type="text" dir="rtl"
                       class="text-right py-0 pl-4"
                       #searchInput
                       matInput
                       placeholder=""
                       [(ngModel)]="keySearch"
                       (input)="searchItem()"
                       (keyup.enter)="navigateToSearchPage(); "
                       (keydown.enter)="navigateToSearchPage(); "
                       [formControl]="myFormControl"
                       [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" class="py-2 w-100">
                  <!--                <mat-option class="h-auto"-->
                  <!--                            *ngFor="let option of searchOption"-->
                  <!--                            [value]="option.label">-->
                  <!--                  <h6 class="font-weight-bold mb-2"-->
                  <!--                      (click)="navigateToSearchPageWithListing(option.category, option.label)">-->
                  <!--                    {{option.label}}-->
                  <!--                    <br/>-->
                  <!--                    <small>{{option.categoryBreadcrumb}}</small>-->
                  <!--                  </h6>-->

                  <!--                </mat-option>-->
                </mat-autocomplete>
              </mat-form-field>
              <img id="searchIconBtn" [routerLink]="['/search']" [queryParams]="{query: keySearch}"
                   src="../../../assets/images/icon-images/tajr-icon-000003.png">

            </form>

          </nav>

          <div class="collapse navbar-collapse"
               [collapse]="isCloseTajrLogoBtn"
               (click)="isCloseTajrLogoBtn  = !isCloseTajrLogoBtn">
            <ul class="navbar-nav pt-2 pb-4 text-right">
              <!-- watchlist -->
              <li class="nav-item mt-4 "><a class="text-secondary" routerLink="/watchlist">قائمة
                المراقبة</a>
              </li>
              <li class="nav-item mt-4">
                <!-- my tajr -->
                <a href="javascript:void(0);" class="text-secondary" data-toggle="dropdown"
                   aria-haspopup="true" aria-expanded="false"
                   (click)="isCloseMyAccountMenuBtn  = !isCloseMyAccountMenuBtn; getMemberListingSummary()"
                   (click)="isCloseTajrLogoBtn  = true">
                  حسابي
                </a>
                <!-- dropdown menu -->
                <div class="dropdown-menu dropdown-menu-left dropdown-menu-xl-right w-auto pt-0 mt-3"
                     aria-labelledby="navbarDropdownMenuLink" aria-haspopup="true" aria-expanded="false"
                     (click)="isCloseMyAccountMenuBtn  = !isCloseMyAccountMenuBtn"
                     [collapse]="isCloseMyAccountMenuBtn">
                  <!-- user not login - use d-none to change -->
                  <div class="container-fluid" *ngIf="!checkUserLoginStatus()">
                    <div class="row border-bottom bg-light align-self-center pt-2 pb-2">
                      <div class="col text-right">
                        <a routerLink="/login" class="font-weight-bold text-secondary">
                          <img src="../../../assets/images/icon-images/tajr-icon-000002.png">
                          الدخول إلى الحساب
                        </a>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-12 col-md-auto text-right">
                        <p class="text-warning mb-1 d-flex justify-content-end">
                          <!-- Buying -->شراء
                        </p>
                        <p class="text-secondary mb-0 d-flex justify-content-end">
                          <!-- watch list -->
                          <a class="nav-link text-secondary p-0" routerLink="/login">
                            <small> الاعلانات المراقبة</small>
                          </a>
                        </p>
                      </div>
                      <div class="col-12 col-md-auto text-right">
                        <p class="text-warning mb-1 d-flex justify-content-end">
                          <!-- Selling -->بيع
                        </p>
                        <p class="text-secondary mb-0 d-flex justify-content-end">
                          <!-- selling -->
                          <a class="nav-link text-secondary p-0" routerLink="/login">
                            <small> الاعلانات المعروضة للبيع</small>
                          </a>
                        </p>
                        <p class="text-secondary mb-0 d-flex justify-content-end">
                          <!-- sold -->
                          <a class="nav-link text-secondary p-0" routerLink="/login">
                            <small> الاعلانات المنتهية</small>
                          </a>
                        </p>
                        <p class="text-secondary mb-0 d-flex justify-content-end">
                          <a class="nav-link text-secondary p-0" routerLink="/login">
                            <small> الاعلانات المحذوفة</small>
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <!-- member login - use d-none to change -->
                  <div class="container-fluid" *ngIf="checkUserLoginStatus()">
                    <div class="row border-bottom bg-light align-self-center pt-2 pb-2">
                      <div class="col text-right">
                        <a routerLink="/my-tajr" class="font-weight-bold text-secondary">
                          <img src="../../../assets/images/icon-images/tajr-icon-000002.png">
                          الدخول إلى الحساب
                        </a>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-12 col-md-auto text-right">
                        <p class="text-warning mb-1 d-flex justify-content-end">
                          <!-- Buying -->شراء
                        </p>
                        <p class="text-secondary mb-0 d-flex justify-content-end">
                          <span>({{memberListingSummary?.watchlist.doc_count}})</span>&nbsp;
                          <!-- watch list -->
                          <a class="nav-link text-secondary p-0" routerLink="/watchlist">
                            <small> الاعلانات المراقبة</small>
                          </a>
                        </p>
                      </div>
                      <div class="col-12 col-md-auto text-right">
                        <p class="text-warning mb-1 d-flex justify-content-end">
                          <!-- Selling -->بيع
                        </p>
                        <p class="text-secondary mb-0 d-flex justify-content-end">
                          <span>({{memberListingSummary?.selling_listings.doc_count}})</span>&nbsp;
                          <!-- selling -->
                          <a class="nav-link text-secondary p-0" routerLink="/selling">
                            <small> الاعلانات المعروضة للبيع</small>
                          </a>
                        </p>
                        <p class="text-secondary mb-0 d-flex justify-content-end">
                          <span>({{memberListingSummary?.expired_listings.doc_count}})</span>&nbsp;
                          <a class="nav-link text-secondary p-0" href="javascript:void(0);"
                             (click)="navigateToSellingPage('/selling', '1')">
                            <small> الاعلانات المنتهية</small>
                          </a>
                        </p>
                        <p class="text-secondary mb-0 d-flex justify-content-end">
                          <span>({{memberListingSummary?.withdrawn_listings?.doc_count}})</span>&nbsp;
                          <!-- sold -->
                          <a class="nav-link text-secondary p-0" href="javascript:void(0);"
                             (click)="navigateToSellingPage('/selling', '2')">
                            <small> الاعلانات المحذوفة</small>
                          </a>
                        </p>
                      </div>
                      <div class="col-12 col-md-auto text-right">
                        <p class="text-warning mb-1 d-flex justify-content-end">
                          <!--Account -->حسابي
                        </p>
                        <p class="text-secondary mb-0">
                          <a class="nav-link text-secondary p-0"
                             routerLink="/my-profile">
                            {{userDetails.username}}
                          </a>
                        </p>
<!--                        <p class="text-secondary mb-1">-->
<!--                          <a class="nav-link font-weight-bold text-secondary p-0"-->
<!--                             routerLink="/my-tajr">-->
<!--                            &lt;!&ndash; Tajr account&ndash;&gt;رصيد تاجر-->
<!--                          </a>-->
<!--                        </p>-->
<!--                        <p class="text-secondary mb-0 d-flex justify-content-end">-->
<!--                          <a class="nav-link text-secondary p-0" href="javascript:void(0);">-->
<!--                            <small>-->
<!--                              &lt;!&ndash; Add credit&ndash;&gt;أشحن رصيدك-->
<!--                            </small>-->
<!--                          </a>&nbsp;-->
<!--                          <small class="text-secondary"> ر.س</small>-->
<!--                          <span class="text-secondary"> 0</span>-->
<!--                        </p>-->
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="showAddListAnItemButton()">
        <div class="col text-right p-2 d-flex align-items-center justify-content-end">
          <span class="text-warning small">
          الاعلان والبيع مجاناً... بدون عموله
          </span>
          <a class="btn btn-primary WIDTH_150 ml-2"
             routerLink="/list-an-item">
            <span class="material-icons">add</span>
            أضف إعلانك
          </a>
        </div>
      </div>

    </div>
  </div>
</div>

