<div class="bg-light">
  <div class="container-fluid">
    <!-- query search string -->
    <div class="row" *ngIf="query">
      <div class="col text-right pt-3">
        <p class="mb-2" dir="rtl">{{searchResultsMess}}
          <span class="text-dark">
            "
            {{queryLable}}
            "
        </span>
        </p>
      </div>
    </div>
    <!-- filter-->
    <div class="row d-sm-none" dir="rtl">
      <div class="col bg-light px-2 pt-2 pb-0 ">
        <form>
          <div class="form-row align-items-center" [ngClass]="screenWidth > 575?'mb-2':''">
            <div class="col-12 col-sm d-flex justify-content-start align-items-center">
              <swiper
                slidesPerView='auto'
                [spaceBetween]="8"
                class="bg-light py-1 w-100">
                <ng-template swiperSlide *ngFor="let locality of LocalityCatalogue; index as index"
                             class="card-header p-0 w-auto border-0 MARGIN_LEFT_8">
                  <button (click)="filterByLocalities('')"
                          *ngIf="index === 0"
                          type="button"
                          [ngClass]="!localityId? 'selected' : ''"
                          class="btn btn-xs rounded-pill ml-1 scrollBtn">
                    أختر
                  </button>
                  <button (click)="filterByLocalities(locality.locality_id)" type="button"
                          [ngClass]="localityId == locality.locality_id? 'selected' : ''"
                          class="btn btn-xs rounded-pill scrollBtn">
                    {{locality.name.arabic}}
                  </button>
                </ng-template>
              </swiper>

            </div>
            <div class="col-12">
              <hr class="m-0" />
            </div>
            <div class="col-12 col-sm-auto d-flex justify-content-start align-items-center"
                 *ngIf="generatedDistrictData().length > 0">
              <swiper
                slidesPerView='auto'
                [spaceBetween]="8"
                class="bg-light py-1 w-100">
                <ng-template swiperSlide *ngFor="let district of generatedDistrictData(); index as index"
                             class="card-header p-0 w-auto border-0 MARGIN_LEFT_8">
                  <!-- first button-->
                  <button
                    (click)="filterByLocalitiesById('')"
                          type="button"
                          *ngIf="index == 0"
                    [ngClass]="districtId == ''? 'selected' : ''"
                          class="btn btn-xs rounded-pill ml-1 scrollBtn">
                    أختر
                  </button>

                  <button (click)="filterByLocalitiesById(district.district_id)" type="button"
                          class="btn btn-xs rounded-pill scrollBtn">
                    {{district.name.arabic}}
                  </button>
                </ng-template>
              </swiper>
            </div>

            <div class="col-12" *ngIf="generatedDistrictData().length > 0">
              <hr class="m-0"/>
            </div>

          </div>
        </form>

      </div>
    </div>

    <div class="row d-none d-sm-block" dir="rtl">
      <div class="col bg-light px-2 pt-2 pb-0 ">
        <form>
          <div class="form-row align-items-center" [ngClass]="screenWidth > 575?'mb-2':''">
            <div class="col-12 col-sm-auto d-flex justify-content-start align-items-center pt-1">
              <p class="text-primary mb-0" [ngClass]="screenWidth > 575?'':'text-right w-25'">
                المنطقة:
              </p>
              <select name="localites"
                      [(ngModel)]="localityId"
                      (ngModelChange)="filterByLocalities()"
                      class="form-control-sm py-0"
                      [ngClass]="screenWidth > 575?'mx-3':'form-control'" dir="rtl">
                <option value="">أختر</option>
                <option *ngFor="let locality of LocalityCatalogue"
                        value="{{locality.locality_id}}">{{locality.name.arabic}}</option>
              </select>
            </div>

            <div class="col-12 col-sm-auto d-flex justify-content-start align-items-center pt-1">
              <p class="text-primary mb-0" [ngClass]="screenWidth > 575?'':'text-right w-25'">
                المدينة:
              </p>
              <select name="districtId"
                      [(ngModel)]="districtId"
                      (ngModelChange)="filterByLocalitiesById()"
                      [disabled]="localityId === '' "
                      class=" form-control-sm py-0"
                      [ngClass]="screenWidth > 575?'mx-3':'form-control'"
                      dir="rtl">
                <option value="">أختر</option>
                <option
                  *ngFor="let district of generatedDistrictData()"
                  value="{{district.district_id}}">{{district.name.arabic}}</option>
              </select>
            </div>
          </div>
        </form>

      </div>
    </div>

    <!-- narrow Your Search Mess -->
    <div class="row" dir="rtl" *ngIf="!isLeafCategory(categoryId) && searchListing && searchListing.hits.length > 0 && screenWidth > 575">
      <div class="col text-right" [innerHTML]="showCategoryMessage()">
      </div>
    </div>

    <!-- search aggregations details -->
    <div class="row px-2 py-0" *ngIf="!isLeafCategory(categoryId) && showCategoryLevel3(0,0) && screenWidth <= 575">
      <div class="col-12 col-sm px-0" dir="rtl">
        <swiper
          slidesPerView='auto'
          [spaceBetween]="8"
          class="bg-light py-1 w-100">
          <ng-template swiperSlide *ngFor="let category of showCategoryLevel3(0,0); index as index"
                       class="card-header p-0 w-auto border-0 MARGIN_LEFT_8">
            <button (click)="searchByCategoryLevel3(category.id)" type="button"
                    class="btn btn-xs rounded-pill ml-1 scrollBtn">
              {{category?.name.arabic}}
                <span class="badge rounded-circle badgeBtn">{{category?.doc_count}}</span>

            </button>
          </ng-template>
        </swiper>
      </div>
      <div class="col-12 d-sm-none" *ngIf="screenWidth <=575 && LocalityCatalogue.length > 0">
        <hr class="m-0"/>
      </div>
    </div>

    <div class="row py-2" dir="rtl" *ngIf="!isLeafCategory(categoryId) && showCategoryLevel3(0,0) && screenWidth > 575">
      <div class="col-6 col-sm-4 col-md-3 col-lg-2 offset-lg-2 text-right small"
           *ngFor="let category of showCategoryLevel3(0,0); index as index">
        <a href="javascript:void(0);"
           class="text-secondary"
           (click)="searchByCategoryLevel3(category.id)">
          {{category.name.arabic}}
        </a><span>({{category.doc_count}})</span>
      </div>
    </div>
    <!-- sort -->
    <div class="row" dir="rtl" *ngIf="totalNumberItem > 0 && screenWidth <= 575">
      <div class="col bg-light px-2 ">
        <swiper
          slidesPerView='auto'
          [spaceBetween]="8"
          class="bg-light py-1 w-100">
          <ng-template swiperSlide *ngFor="let option of filterOptions; index as index"
                       class="card-header p-0 w-auto border-0 MARGIN_LEFT_8">
            <button (click)="onChangeSelectedFilter(option.id)" type="button"
                    [ngClass]="option.id === selectedOption? 'selected' : ''"
                    class="btn btn-xs rounded-pill scrollBtn">
              {{option.name}}
            </button>
          </ng-template>
        </swiper>
      </div>
    </div>

    <div class="row" dir="rtl" *ngIf="totalNumberItem > 0 && screenWidth > 575">
      <div class="col bg-light p-2 pt-0 pb-2 ">
        <form>
          <div class="form-row align-items-center mb-2">
            <div class="col-12 col-sm-auto d-flex justify-content-start align-items-center pt-1">
              <p class="text-primary mb-0" [ngClass]="screenWidth > 575?'':'text-right w-25'">
                <!--filter --> تصفيه:
              </p>
              <select name="filter"
                      [(ngModel)]='selectedOption'
                      (ngModelChange)="onChangeSelectedFilter()"
                      class="form-control-sm py-0"
                      [ngClass]="screenWidth > 575?'mx-3':'form-control'" dir="rtl">
                <option
                  *ngFor="let option of filterOptions"
                  value="{{option.id}}">{{option.name}}
                </option>
              </select>
            </div>
          </div>
        </form>

      </div>
    </div>


    <div class="container-fluid">
      <!--search listing result has item-->
      <div *ngIf="searchListing && searchListing.hits.length > 0 ">
        <div class="row pt-2">
          <div class="col col-lg-10 offset-lg-2 px-0">
            <ul class="list-unstyled" *ngIf="searchListing != null" dir="ltr">
              <a class="media border rounded-lg carouselStyle position-relative bg-white row m-0 mb-3"
                 *ngFor="let slide of searchListing.hits; index as index"
                 [routerLink]="navigateService.generateListingPageUrl(slide.listing_id, slide.title)">
                <mat-card class="align-self-end text-left p-2 shadow-none d-none d-sm-block mb-1 ml-1 position-absolute"
                          dir="rtl">
                  <mat-card-title class="font-weight-bold text-dark text-break mb-0 h6" dir="rtl">
                    ‬ {{listingService.composedPrice(slide)}}
                  </mat-card-title>
                </mat-card>
                <div class="media-body col-8 col-md-9 p-2" dir="rtl">
                  <mat-card class="text-right shadow-none p-0">

                    <mat-card-subtitle class="small mb-0"
                                       *ngIf="listingService.checkListingStatus(slide) == 'deleted' ">
                            <span class="badge badge-danger px-2 py-1">
                                   {{deletedMess}}
                            </span>
                    </mat-card-subtitle>
                    <mat-card-subtitle class="small mb-0"
                                       *ngIf="listingService.checkListingStatus(slide) == 'expired' ">
                            <span class="badge badge-primary px-2 py-1">
                                   {{expiredMess}}
                            </span>
                    </mat-card-subtitle>
                    <mat-card-content class="text-dark mb-2 cardGalleryLineBreak" dir="rtl"
                                      [ngClass]="screenWidth <= 575 ? 'small' : ''">
                      {{slide.locality_arabic}} | {{ listingService.endDateText(slide.endDate) }}
                    </mat-card-content>

                    <mat-card-title class="font-weight-bold text-dark cardGalleryLineBreak"
                                    [ngClass]="screenWidth <= 575 ? 'FONT_SIZE_14' : 'h6'">
                      {{slide.title}}
                    </mat-card-title>
                    <mat-card-subtitle class="text-dark text-break align-self-end  cardGalleryLineBreak"
                                       *ngIf="slide.subtitle != null">
                      {{slide.subtitle}}
                    </mat-card-subtitle>
                    <mat-card-title class="font-weight-bold text-dark text-break text-left d-sm-none"
                                    [ngClass]="screenWidth <= 575 ? 'FONT_SIZE_14' : 'h6'">
                      ‬ {{listingService.composedPrice(slide)}}
                    </mat-card-title>
                  </mat-card>
                </div>
                <mat-card
                  class="col-4 col-sm-4 col-md-3 embed-responsive embed-responsive-16by9 shadow-none align-self-center"
                  dir="rtl">
                  <img class="img-fluid embed-responsive-item w-100 h-100 tajrImage"
                       [src]="listingService.showPhoto(slide.photo_1, slide.title)"
                       [alt]="slide.photo_1 != null ? titleService.generateArabicLable(slide.title): titleService.generateArabicLable(titleService.defaultTitle)">
                </mat-card>
                <!-- card icon -->
                <img class="img-fluid p-1 position-absolute tajrCardIcon"
                     *ngIf="slide?.watchlist_id"
                     src="../../../assets/images/icon-images/tajr-icon-000041.png">
              </a>
            </ul>
          </div>
        </div>

        <!-- pagination  -->
        <div class="row" dir="ltr" *ngIf="isLoading == false && totalNumberItem > getListingToItem">
          <div class="col-2 d-none d-lg-block"></div>
          <div class="col" dir="rtl">
            <pagination class="d-flex justify-content-center"
                        [boundaryLinks]="showBoundaryLinks"
                        [totalItems]="totalNumberItem"
                        (pageChanged)="onChangepaginationSearchPage($event)"
                        [maxSize]="maxSize"
                        [itemsPerPage]="getListingToItem"
                        [(ngModel)]="currentPage"
                        previousText="&lsaquo;"
                        nextText="&rsaquo;"
                        firstText="&laquo;"
                        lastText="&raquo;">
            </pagination>
          </div>
        </div>

      </div>

      <!--search listing result has no item-->
      <div *ngIf="searchListing && searchListing.hits.length == 0 ">
        <div class="row pt-2">
          <div class="col position-relative">
            <br>
            <br>
            <h2 class="font-weight-bold text-center text-dark pt-2">...عفوا</h2>
            <br>
            <h4 class="font-weight-bold text-center text-secondary pt-2">!لا توجد سيارات بالإختيارات التي حُددت</h4>
            <br>
            <br>
            <br>
          </div>
        </div>
      </div>
      <!--search listing is loading-->
      <div class="d-flex justify-content-center min-vh-100 py-5 mt-5" *ngIf="!searchListing">
        <mat-spinner></mat-spinner>
      </div>

    </div>
  </div>
</div>


