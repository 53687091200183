<div class="bg-light"
     *ngIf="checkUserLoginStatus() && recentlyViewedListingDetails && recentlyViewedListingDetails.hits &&  recentlyViewedListingDetails.hits.length > 0">
  <div class="container-fluid">
    <div class="row pt-3 mb-1">
      <div class="col">
        <div class="d-flex justify-content-between">
          <!--View all-->
          <a href="javascript:void(0);"
             (click)="navigateToRecentlyViewPage()">
            {{leftMenuLabel}}
          </a>
          <a class="nav-link text-warning p-0 h5 mb-0"
             href="javascript:void(0);"
             (click)="navigateToRecentlyViewPage()">
            {{rightMenuLabel}}
          </a>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col" dir="rtl">
        <!-- item slide -->
        <swiper
          [slidesPerView]="slidesPerView"
          [spaceBetween]="spaceBetween">
          <ng-template swiperSlide *ngFor="let slide of recentlyViewedListingDetails.hits; index as index"
                       class="card border rounded rounded-lg h-auto carouselStyle CARD_MAX_WIDTH MARGIN_LEFT_8">
            <a [routerLink]="navigateService.generateListingPageUrl(slide.listing.listing_id, slide.listing.title)"
               class="text-decoration-none">
              <!-- card header -->
              <div
                class="card-header embed-responsive embed-responsive-4by3 bg-white rounded-top
              rounded-lg border-bottom-0 position-relative">
                <img [src]="listingService.showPhoto(slide.listing.photo_1, slide.listing.title)"
                     [alt]="slide.listing.photo_1 != null ? titleService.generateArabicLable(slide.listing.title): titleService.generateArabicLable(titleService.defaultTitle)"
                     class="card-img-top img-fluid embed-responsive-item tajrImage">
                <!-- card icon -->
                <img class="img-fluid p-1 position-absolute tajrCardIcon"
                     *ngIf="slide?.listing.watchlist_id"
                     src="../../../assets/images/icon-images/tajr-icon-000041.png">
              </div>
              <!-- card content -->
              <div class="card-body p-2">

                <div class="font-weight-bold">
                  <div class="d-flex justify-content-between font-weight-bold text-dark card-title-group">
                    <small class="small text-nowrap ml-3">{{slide.listing.locality_arabic}}</small>
                    <small class="small cardGalleryLineBreak card-date">{{ endDateText(slide.listing.endDate) }}</small>
                  </div>

                  <p class="text-dark font-weight-bold text-right FONT_SIZE_14 mb-2">
                    {{slide.listing.title}}
                  </p>

                  <p class="font-weight-normal text-secondary text-right small mb-4">
                    {{slide.listing.subtitle}}
                  </p>
                </div>
              </div>
              <div class="card-footer border-top-0 rounded-bottom rounded-lg p-0 bg-white px-2 position-absolute fixed-bottom">
                <p class="font-weight-bold text-right small mb-2 cardGalleryLineBreak" dir="rtl">{{composedPrice(slide.listing)}}</p>
              </div>
            </a>
          </ng-template>

        </swiper>

      </div>
    </div>

    <div class="row">
      <div class="col">
        <!--the spinner when carousel load not finished-->
        <div class="d-flex justify-content-center align-items-center py-5 my-3"
             *ngIf="!recentlyViewedListingDetails || !recentlyViewedListingDetails.hits ||  recentlyViewedListingDetails.hits.length == 0">
          <mat-spinner></mat-spinner>
        </div>
      </div>
    </div>
  </div>
</div>
