import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {LanguageService} from '../../services/language.service';
import {ActivatedRoute} from '@angular/router';
import {TabsetComponent} from 'ngx-bootstrap/tabs';

@Component({
  selector: 'app-buying',
  templateUrl: './buying.component.html',
  styleUrls: ['./buying.component.css']
})
export class BuyingComponent implements OnInit {
  sourceTabLabel = this.languageService.findMessageByLanguage('myTajr');
  buyingTabLabel = this.languageService.findMessageByLanguage('buying');
  public currentTabLabel = this.buyingTabLabel;
  public screenWidth: number;
  public tab: number;

  constructor(
    // get language message from local
    private languageService: LanguageService,
    // service use to get value from router
    private activatedroute: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    // get width of device
    this.screenWidth = window.innerWidth;
    // get value from url
    this.activatedroute.queryParamMap.subscribe((queryParams) => {
      if (queryParams.has('tab')) {
        this.tab = Number(queryParams.get('tab'));
        // show tab 0: selling tab, tab 1 : unsold tab
        this.selectTab(this.tab);
      } else {
        this.tab = 0;
        this.selectTab(this.tab);
      }
    });
  }

  /**
   * use HostListener to  updated on resize:
   * @param event event hold when responsive
   */
  @HostListener('window:resize', ['$event'])
  getScreenSize(event) {
    this.screenWidth = window.innerWidth;
  }

  @ViewChild('staticTabs') staticTabs: TabsetComponent;

  /**
   * change tab when user select or redirect
   * @param tabId tab when active
   */
  selectTab(tabId: number) {
    if (this.staticTabs && this.staticTabs.tabs[tabId] !== undefined) {
      this.staticTabs.tabs[tabId].active = true;
      this.onSelect(tabId);
    }
  }

  /**
   * change currentTabLabel show on browser
   * @param tabId tab when active
   */
  onSelect(tabId: number): void {
    // tab selling
    if (tabId === 0) {
      this.currentTabLabel = this.buyingTabLabel;
    }
  }
}
