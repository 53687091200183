import {makeStateKey, TransferState} from '@angular/platform-browser';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {ProductService} from '../../services/product.service';
import {TAJR_ALL_LOCALITIES} from '../../shared/global';
import {first, tap} from "rxjs/operators";
import {Locality} from "../../modules/models/locality";

@Injectable()
export class LocalitiesResolve implements Resolve<Array<Locality>> {
  constructor(
    private productService: ProductService,
    private transferState: TransferState) {
  }

  /**
   *
   * @param route acctivated route snapshot
   * @param state router state
   */
  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<Array<Locality>> {

    const LOCALITIES = makeStateKey<Array<Locality>>(TAJR_ALL_LOCALITIES);
    if (this.transferState.hasKey(LOCALITIES)) {
      const course = this.transferState.get(LOCALITIES, null);
      return of(course);
    } else {
      return this.productService.getLocalities()
        .pipe(
          first(),
          tap(data => {
            this.transferState.set(LOCALITIES, data);
          })
        );
    }
  }
}
