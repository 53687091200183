import {Component, OnInit} from '@angular/core';
import {ProfileDetails} from '../../modules/models/profile.details';
import {LanguageService} from '../../services/language.service';
import {ProductService} from '../../services/product.service';
import {HttpHelperService} from '../../services/http-helper.service';

import {TAJR_PERSONAL_DETAILS} from '../../shared/global';
import {SearchService} from '../../services/search.service';
import {Router} from '@angular/router';
import {NavigateService} from '../../services/navigate.service';
import {SessionStorageService} from '../../server-side-rendering/storages/session.storage.service';
import {AccountService} from '../../services/account.service';
import {MemberListingSummary} from '../../modules/models/member.listing.summary';
import {NotificationServices} from '../../services/notification.services';

class SearchModel {
  sort_by: string;
  from: string;
  size: string;
  member_id: string;
  filter_by: string;
}

@Component({
  selector: 'app-my-tajr',
  templateUrl: './my-tajr.component.html',
  styleUrls: ['./my-tajr.component.css']
})
export class MyTajrComponent implements OnInit {
  someThingWentWrongMessage = this.languageService.findMessageByLanguage('someThingWentWrong');
  // user details receive from server
  public profileDetails: ProfileDetails;
  // user details save from cookie
  public userDetails: ProfileDetails;
  public location: string;
  public errorMessage: string;
  public memberListingSummary: MemberListingSummary;

  constructor(
    // get language message from local
    private languageService: LanguageService,
    // service send request to login
    private productService: ProductService,
    // contain api
    private httpHelper: HttpHelperService,
    // service send request to login
    private searchService: SearchService,
    private router: Router,
    public navigateService: NavigateService,
    private sessionStorageService: SessionStorageService,
    private accountService: AccountService,
    private notify: NotificationServices,
  ) {
  }

  ngOnInit(): void {
    // if cookie contain user details
    if (this.sessionStorageService.get(TAJR_PERSONAL_DETAILS)) {
      this.userDetails = JSON.parse(this.sessionStorageService.get(TAJR_PERSONAL_DETAILS));
      // get user details
      this.profileDetails = this.userDetails;
      this.location = this.showLocation();
    }
    this.getMemberListingSummary();
  }

  /**
   * display member localtion
   */
  showLocation() {
    let locality = '';
    let district = '';
    let location = '';
    if (this.profileDetails && this.profileDetails.locality && this.profileDetails.district) {
      district = this.languageService.messageFromServer(this.profileDetails.district.name);
      locality = this.languageService.messageFromServer(this.profileDetails.locality.name);
    }

    location = district + ' , ' + locality;
    return location;
  }

  /**
   * navigate to listing  page
   * @param listing
   */
  navigateToSellingPage(url: string, tabNum: string) {
    this.router.navigate([url], {queryParams: {tab: tabNum}});
  }

  getMemberListingSummary() {
    this.accountService.getMemberListingSummary().subscribe(data => {
      // if user has information response
      if (200 === data.status && data.body) {
        this.memberListingSummary = data.body;
      }
    }, err => {
      // get error message response from server
      if (err && err.error) {
        // show error
        this.errorMessage = this.languageService.messageFromServer(err.error.message);
      } else {
        // service error message
        this.errorMessage = this.someThingWentWrongMessage;
      }
      this.notify.showWarnmingNotificationMessage(this.errorMessage)
    });
  }
}
