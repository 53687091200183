import {Component, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {Subscription} from 'rxjs';
import {Categories} from '../../modules/models/categories';
import {CategoryDetails} from '../../modules/models/category.details';
import {ListAnItemModules} from '../../modules/models/list.an.item.modules';
import {LanguageService} from '../../services/language.service';
import {ProductService} from '../../services/product.service';
import {HttpHelperService} from '../../services/http-helper.service';
import {ActivatedRoute, Router} from '@angular/router';
import {isPlatformBrowser} from '@angular/common';
import {
  ACTION, CREATE, EDIT,
  CATEGORIES_DETAILS_RESOURCE,
  FIRST_ITEM,
  MAX_CATEGORY_LEVEL,
  ID,
  LIST_AN_ITEM, 
  LIST_AN_ITEM_SUCCESS, 
  SELL_SIMILIAR,
  SMOOTH, 
  TAJR_ALL_CATEGORIES_RESOURCE, 
  TAJR_CATEGORIES_DETAILS,
  TAJR_LIST_AN_ITEM,
  TAJR_LIST_AN_ITEM_DETAILS,
  TAJR_LIST_AN_ITEM_FEES,
  TAJR_LIST_AN_ITEM_PHOTOS, 
  VALUE, 
  YOUTUBE_FILE_TYPE, YOUTUBE_URL
} from '../../shared/global';
import {Language} from '../../modules/models/language';
import {environment} from '../../../environments/environment';
import {Util} from '../../utils/utils';
import {ListAnItemService} from "../../services/list.an.item.service";
import {CategoryService} from "../../services/category.service";

/**
 * class to manage listing details will dens to server to create new listing
 */
class Listing {
  category_id = '';
  attributes: any;
  duration = '';
  photos: Photo [];
  embedded_content_options: Attribute;
}

class Attribute {
  id = '';
  value = '';
}

class Photo {
  position = '';
  photo_id = '';
}

/**
 * Class to manage Tajr fees already save in step 3
 */
class TajrListAnItemFees {
  total_cost = '';
  insufficient_balance = '';
  fees: Fees [];
}

class Fees {
  id = '';
  display_name: Language;
  credit = '';
  debit = '';
  purchased = '';
}

@Component({
  selector: 'app-list-an-item-confirm',
  templateUrl: './list-an-item-confirm.component.html',
  styleUrls: ['./list-an-item-confirm.component.css']
})
export class ListAnItemConfirmComponent implements OnInit {
  someThingWentWrongMessage = this.languageService.findMessageByLanguage('someThingWentWrong');
  loadingMess = this.languageService.findMessageByLanguage('loading');
  emptyPhotoMess = this.languageService.findMessageByLanguage('emptyPhotoMess');
  emptyVideoMess = this.languageService.findMessageByLanguage('emptyVideoMess');
  startTheNewListingBtnLabel = this.languageService.findMessageByLanguage('startTheNewListingBtn');
  updateListingBtnLabel = this.languageService.findMessageByLanguage('updateListingBtn');

  //  subscription to observable request get member's listings
  public createNewListing: Subscription;
  public categoryDetailData: CategoryDetails;
  public listAnItemModules: ListAnItemModules;
  public tajrListAnItemFees: TajrListAnItemFees;
  public submitted = false;
  // width of screen size on browser
  public screenWidth: number;
  public errorMessage = '';
  // variable containt item information from step 1,step2,step3
  public tajrListAnItem;
  public tajrListAnItemPhotos;
  public tajrListAnItemDetails;
  public tajrAllCarCategories;
  // Attribute send to server
  public listing: Listing;
  // attribute to show loading button
  loading = false;
  public categoryLv2Label = '';
  public categoryLv3Label = '';
  public categoriesBreadcrumb = '';
  public endDate = '';
  public videoUrl = '';
  public notEmptyPhotoMess = '';
  public photosList = [];
  public action = CREATE;
  public confirmBtnLabel = this.startTheNewListingBtnLabel;
  public categories: Categories;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    // get language message from local
    private languageService: LanguageService,
    // service send request to login
    private productService: ProductService,
    // contain api
    private httpHelper: HttpHelperService,
    private elementRef: ElementRef,
    // Router service use to get value from url
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private listAnItemService: ListAnItemService,
    private categoryService: CategoryService,
  ) {
  }

  ngOnInit() {
    // get screen of device
    this.screenWidth = window.innerWidth;

    // get value from url, get action with listing item, default action is create new item
    this.activatedRoute.queryParamMap.subscribe((queryParams) => {
      if (queryParams.has(ACTION)) {
        this.action = queryParams.get(ACTION);
      }
    });

    // update value follow action
    switch (this.action) {
      case EDIT:
        this.confirmBtnLabel = this.updateListingBtnLabel;
        break;
      default:
        break;
    }

    this.listAnItemModules = new ListAnItemModules();
    this.tajrListAnItemFees = new TajrListAnItemFees();
    // Check in local first step list an item result must be available
     const isPreExistSessionData = !this.listAnItemService.tajrListAnItem
    || !this.listAnItemService.tajrListAnItemPhotos
    || !this.listAnItemService.tajrListAnItemDetails;
    if (isPreExistSessionData) {
      this.listAnItemService.clearAllListAnItemSessionData();
      this.router.navigate([LIST_AN_ITEM]);
    } else {
      // item information from step 1
      this.tajrListAnItem = this.listAnItemService.tajrListAnItem;
      // item information from step 2
      this.tajrListAnItemPhotos = this.listAnItemService.tajrListAnItemPhotos;
      // item information from step 3
      this.tajrListAnItemDetails = this.listAnItemService.tajrListAnItemDetails;
      // get item fee from step 3
      this.tajrListAnItemFees = this.listAnItemService.tajrListAnItemFees;
    }

    // get all catergories from step 1
    const tajrCategoriesData = this.activatedRoute.snapshot.data[TAJR_ALL_CATEGORIES_RESOURCE];
    const prexistIds = this.listAnItemService.getActiveIDFromSS();
    this.categories = this.categoryService.initCategoriesFromData(tajrCategoriesData, MAX_CATEGORY_LEVEL, prexistIds)
    this.categoryDetailData = this.activatedRoute.snapshot.data[CATEGORIES_DETAILS_RESOURCE];
    this.listAnItemModules = Object.assign(this.tajrListAnItem, this.tajrListAnItemDetails);
    
    //Label
    this.categoryLv2Label = this.languageService.messageFromServer(this.categories.getCategoryBylevel(2).name);
    this.categoryLv3Label = this.languageService.messageFromServer(this.categories.getCategoryBylevel(3).name);
    // show end date follow duration
    if (this.listAnItemModules && Util.isNotNullOrEmpty(this.listAnItemModules.duration)) {
      this.endDate = this.listAnItemModules.duration;
    }

    this.categoriesBreadcrumb = this.languageService.messageFromServer(this.categories.getCategoryBylevel(1).name) + ' > ' +
      this.languageService.messageFromServer(this.categories.getCategoryBylevel(2).name) + ' > ' +
      this.languageService.messageFromServer(this.categories.getCategoryBylevel(3).name);
    // show attribute display value
    for (let attr in this.listAnItemModules) {
      if (Util.isNotNullOrEmpty(this.showAttributesDisplayValue(attr))) {
        this.listAnItemModules[attr] = this.showAttributesDisplayValue(attr);
      }
    }

    // check video empty or not
    if (this.tajrListAnItemPhotos.embedded_content_options && Util.isNotNullOrEmpty(this.tajrListAnItemPhotos.embedded_content_options[0].value)) {
      this.videoUrl = YOUTUBE_URL + this.tajrListAnItemPhotos.embedded_content_options[0].value + YOUTUBE_FILE_TYPE;
    }
    // check photos empty or not
    if (this.tajrListAnItemPhotos.photos && 0 < this.tajrListAnItemPhotos.photos.length) {
      // manage max number photos show on browser
      let limitPhotosShowOnPhotosList = this.tajrListAnItemPhotos.photos.length;
      if (limitPhotosShowOnPhotosList > 3) {
        limitPhotosShowOnPhotosList = 3;
      }
      // add note for photo list show on browser
      if (3 >= this.tajrListAnItemPhotos.photos.length) {
        this.notEmptyPhotoMess = 'أضفت ' + this.tajrListAnItemPhotos.photos.length + ' صور، تم عرض اول ٣ فقط';
      } else {
        this.notEmptyPhotoMess = 'أضفت ' + this.tajrListAnItemPhotos.photos.length + ' صوره، تم عرض اول ٣ فقط';
      }
      // loop from response data and repair for each listing id a position available
      for (let x = 0; x < limitPhotosShowOnPhotosList; x++) {
        this.photosList[x] = environment.baseUrl + '/v1/photos/' + this.tajrListAnItemPhotos.photos[x].photo_id + '/sizes/508x380';
      }
    }
  }

  /**
   * use HostListener to  updated on resize:
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  getScreenSize(event) {
    this.screenWidth = window.innerWidth;
  }

  /**
   *  show attribute label follow language response from server
   */
  showAttributesLable(attributesId: string) {
    let attributesLable = '';
    // return if don't has value
    if (Util.isNullOrEmpty(this.categoryDetailData) || Util.isNullOrEmpty(this.categoryDetailData.attributes)) {
      return;
    }

    const attribute = this.categoryDetailData.attributes.find(item => item.id === attributesId);

    // select label want to show from response
    if (Util.isNotNullOrEmpty(attribute) && Util.isNotNullOrEmpty(attribute.display_name)) {
      attributesLable = this.languageService.messageFromServer(attribute.display_name);
    }
    return attributesLable;
  }

  /**
   *  show attribute value follow language response from server "https://api.tajr.sa/v1/category-details/***".
   *  If attributesId contains option will show value in option display.
   *  If attributesId has type is bool will show "✓" or "x" follow true false.
   *  other attributesId has empty value will show "x".
   */
  showAttributesDisplayValue(attributesId: string) {
    let attributesDisplayValue = '';
    // return if don't has value
    if (Util.isNullOrEmpty(this.categoryDetailData) || Util.isNullOrEmpty(this.categoryDetailData.attributes)) {
      return;
    }
    const attribute = this.categoryDetailData.attributes.find(item => item.id === attributesId);
    // If attributesId contains option will show value in option display.
    if (Util.isNotNullOrEmpty(attribute)) {
      if (Util.isNotNullOrEmpty(attribute.options)) {
        const optionArrayContaintAttributeIdDisplayValue = attribute.options;
        const option = optionArrayContaintAttributeIdDisplayValue.find(item => item.value === this.listAnItemModules[attributesId]);
        if (Util.isNotNullOrEmpty(option)) {
          attributesDisplayValue = this.languageService.messageFromServer(option.display);
        } else {
          // set "x" for option user don't choose in step 1.
          attributesDisplayValue = 'x';
        }

      } else if ('bool' === attribute.type) {
        //  If attributesId has type is bool will show "✓" or "x" follow true false.
        if ('true' === this.listAnItemModules[attributesId]) {
          attributesDisplayValue = '✓';
        } else {
          attributesDisplayValue = 'x';
        }
      } else if (Util.isNotNullOrEmpty(this.listAnItemModules[attributesId])) {
        attributesDisplayValue = this.listAnItemModules[attributesId];
      } else {
        // other attributesId has empty value will show "x".
        attributesDisplayValue = 'x';
      }
    }

    return attributesDisplayValue;
  }

  getDurationDisplay(duration) {
    if (this.categoryDetailData.durations) {
      const durationDetail = this.categoryDetailData.durations.find(d => d.number_of_days == duration);
      if (durationDetail) return durationDetail.display_name.arabic;
    }
    return `&#8235;بعد &#8236;&#8235;${duration}&#8236;&#8235; ايام&#8236;`
  }

  isIncludeCategory(catId) {
    return this.categoryDetailData.attributes.find(atrr => atrr.id === catId);
  }

  /**
   *listings -Create a new listing.
   * request body will same same get listing fees
   */
  onSubmit() {
    // change loading status to show loading when sending request
    this.loading = true;
    const listAnItem = this.listAnItemService.tajrListAnItem;
    // item information from step 2
    const listAnItemPhotos = this.listAnItemService.tajrListAnItemPhotos;
    // item information from step 3
    const listAnItemDetails = this.listAnItemService.tajrListAnItemDetails;
    // collect item attribute from step 1 and step 3
    const constAttribute = Object.assign(listAnItem, listAnItemDetails);
    // collect all data and format follow design doc to send request
    let dataToSend;
    dataToSend = {
      category_id: listAnItem.categoryId.split('-')[2],
      attributes: []
    };
    if (Util.isNotNullOrEmpty(listAnItemDetails.duration)) {
      dataToSend.duration = listAnItemDetails.duration;
    }
    if (Util.isNotNullOrEmpty(listAnItemPhotos.photos) && 0 < listAnItemPhotos.photos.length) {
      dataToSend.photos = listAnItemPhotos.photos;
    }
    if (undefined !== listAnItemPhotos.embedded_content_options && '' !== listAnItemPhotos.embedded_content_options.value) {
      dataToSend.embedded_content_options = [];
      dataToSend.embedded_content_options[0] = listAnItemPhotos.embedded_content_options[0];
    }
    // list attribute don't put in dataToSend.attributes follow design document. ex photos put in dataToSend.photosot in dataToSend.attributes[photo]
    const arrayAttributeIdAvoid = ['category_id', 'duration', 'photos', 'embedded_content_options', 'listingId', 'allowed_durations', 'startDate', 'dateCreated'];
    for (let key in constAttribute) {
      const rawAttr = this.categoryDetailData.attributes.find(attr => attr.id === key);
      if (!arrayAttributeIdAvoid.includes(key) 
        && Util.isNotNullOrEmpty(rawAttr) 
        && Util.isNotNullOrEmpty(constAttribute[key])
        && rawAttr.is_automatically_created == 'false') {
        let item = {};
        item[ID] = key;
        item[VALUE] = constAttribute[key];
        // push item in listing
        dataToSend.attributes.push(item);
      }
    }

    switch (this.action) {
      case EDIT:
        // send request to get fees
        this.createNewListing = this.productService.editListing(dataToSend, listAnItem.listingId).subscribe(data => {
          if (200 === data.status && data.body) {
            // remove item data save in local before redirect to other page
            this.listAnItemService.clearAllListAnItemSessionData();
            // redirect to list-an-item-success page
            this.router.navigate([LIST_AN_ITEM_SUCCESS], {queryParams: {listingId: listAnItem.listingId, action: this.action}});
          }
          // reset loading buton
          this.loading = false;
          // reset submitted
          this.submitted = false;
        }, err => {
          // User login fail, close loading status on button
          this.loading = false;
          // get error message response from server
          if (err && err.error && err.error.message) {
            // show error
            this.errorMessage = this.languageService.messageFromServer(err.error.message);
          } else {
            // service error message
            this.errorMessage = this.someThingWentWrongMessage;
          }
          // scroll to top to see error response from serve
          if (isPlatformBrowser(this.platformId)) {
            window.scroll({top: 0, behavior: SMOOTH});
          }
          // reset loading buton
          this.loading = false;
          // reset submitted
          this.submitted = false;
        });
        break;
      default:
        // send request to get fees
        this.createNewListing = this.productService.createNewListing(dataToSend).subscribe(data => {
          if (200 === data.status && data.body && Util.isNotNullOrEmpty(data.body.listing_id)) {
            // remove item data save in local before redirect to other page
            this.listAnItemService.clearAllListAnItemSessionData();
            // redirect to list-an-item-success page
            this.router.navigate([LIST_AN_ITEM_SUCCESS], {queryParams: {listingId: data.body.listing_id, action: this.action}});
          }
          // reset loading buton
          this.loading = false;
          // reset submitted
          this.submitted = false;
        }, err => {
          // User login fail, close loading status on button
          this.loading = false;
          // get error message response from server
          if (err && err.error) {
            // show error
            this.errorMessage = this.languageService.messageFromServer(err.error.message);
          } else {
            // service error message
            this.errorMessage = this.someThingWentWrongMessage;
          }
          // scroll to top to see error response from serve
          if (isPlatformBrowser(this.platformId)) {
            window.scroll({top: 0, behavior: SMOOTH});
          }
          // reset loading buton
          this.loading = false;
          // reset submitted
          this.submitted = false;
        });
        break;
    }
  }

  /**
   * navigate to listing-an-item page
   * @param listing
   */
  navigateToListAnItemPage(url: string) {
    this.router.navigate([url], {queryParams: {action: this.action}});
  }
}
