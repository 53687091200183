import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ViewChildren, ViewChild, QueryList, ElementRef } from '@angular/core';
import {ChatService} from '../../services/chat.service';
import { AuthService } from '../../services/auth.service';
import {AccountService} from '../../services/account.service';
import {Subscription} from 'rxjs';

@Component({
	selector: 'app-chat-detail',
	templateUrl: './chat-detail.component.html',
	styleUrls: ['./chat-detail.component.css']
})
export class ChatDetailComponent implements OnInit, OnChanges {
	@ViewChildren('messagesEle') messagesEle: QueryList<any>;
	@ViewChild('scollContainer') scollContainer: ElementRef;
	@Input('conversationID') public conversationID;
	@Input('newConversationSellerID') public newConversationSellerID;
	@Input('tab') public tab;
	@Output('changeNewConverURL') changeNewConverURL = new EventEmitter();
	@Output('updateChatList') updateChatList = new EventEmitter();
	@Output('updateChatListSeen') updateChatListSeen = new EventEmitter();
	public conversation;
	public memberID = '';
	public targetName = '';
	public text = '';
	public messages = [];
	public getConversationMessagesRequest: Subscription;
	public sendMessageRequest: Subscription;
	public updateLastSeenRequest: Subscription;
	public getUserPublicDetailsRequest: Subscription;
	constructor(
		private chatService: ChatService,
		private authService: AuthService,
		private accountService: AccountService
		) { }

	ngOnInit(): void {
		this.memberID = this.authService.getMemberId();
		this.listen();
		
	}

	ngAfterViewInit() {
		this.scrollToBottom();
		this.messagesEle.changes.subscribe(this.scrollToBottom);
	}

	ngOnChanges() {
		if (!!this.conversationID) {
			this.conversation = Object.assign({}, this.conversation, {
				conversation_id: this.conversationID
			})
			this.getConversationMessages();
			this.updateLastSeen();
		}
		else if (this.newConversationSellerID) {
			this.getUserPublicDetailsRequest = this.accountService.getUserPublicDetails(this.newConversationSellerID).subscribe(data => {
				if (200 === data.status && data.body) {
					this.conversation = {
						conversation_id: null,
						buyer_id: this.memberID,
						seller_id: this.newConversationSellerID,
						conversation_name: `${data.body.first_name} ${data.body.last_name}`
					}
				}
			});
		}
		else this.resetConversation();
		this.text = '';
	}

	listen() {
		navigator.serviceWorker.addEventListener('message', (event) => {
			if (this.conversation && event.data && event.data.data && event.data.data.conversation_id == this.conversation.conversation_id) {

				this.updateMessages(event.data)
			}
		});;
	}

	getConversationMessages() {
		this.getConversationMessagesRequest = this.chatService.getConversationMessages({
			conversation_id: this.conversation.conversation_id
		}).subscribe(data => {
			if (200 === data.status && data.body && data.body.length) {
				this.messages = data.body.reverse();
				const message = this.messages[0];
				let conversation_name = message.sender_username;
				if (this.memberID == this.messages[0].sender_id) {
					conversation_name = message.receiver_username;
				}
				this.conversation.conversation_name = conversation_name;
				this.conversation.buyer_id = message.buyer_id;
				this.conversation.seller_id = message.seller_id;
			}
		}, err => {
			this.resetConversation()
		});
	}

	updateLastSeen() {
		this.updateLastSeenRequest = this.chatService.updateLastSeen(this.conversation.conversation_id).subscribe(data => {
			if (200 === data.status && data.body) {
				this.updateChatListSeen.emit(data.body);
			}
		}, err => {
		});
	}

	getConversationShortDate(message) {
		let chatDateOptions = {
			month: 'long',
			day: 'numeric',
			hour: '2-digit', 
			minute: '2-digit',
			hour12: true
		}; 
		let chatDate = new Date(message.dc);
		chatDate.setUTCHours(chatDate.getUTCHours());
		return chatDate.toLocaleDateString('ar-EG', chatDateOptions);
	}

	getChatName(message) {
		if (this.memberID = message.receiver_id) {
			return message.sender_username;
		}
		return message.receiver_username;
	}

	updateMessages({ data }){
		if (!data || this.messages.find(ms => ms.message_id === data.message_id)) return;
		this.messages = this.messages.concat(data);
		this.text = '';
		this.updateChatList.emit(data);
		if (!this.conversation.conversation_id) {
			this.conversation.conversation_id = data.conversation_id;
			this.changeNewConverURL.emit(data)
		}
	}

	sendMessage() {
		this.sendMessageRequest = this.chatService.sendMessage({
			conversation_id: this.conversation.conversation_id,
			buyer_id: this.conversation.buyer_id,
			seller_id: this.conversation.seller_id,
			text: this.text
		}).subscribe(data => {
			if (200 === data.status && data.body) {
				this.updateMessages({ data: data.body })
			}
		}, err => {
		});
	}

	scrollToBottom = () => {
		try {
			this.scollContainer.nativeElement.scrollTop = this.scollContainer.nativeElement.scrollHeight;
		} catch (err) {}
	}

	checkSeenCondition(message) {
		if (message.receiver_id == this.memberID) return false;
		const createDate = new Date(message.dc).getTime();
		let lastSeen = new Date(this.conversation.buyer_last_seen).getTime();
		if (this.memberID == message.buyer_id) {
			lastSeen = new Date(this.conversation.seller_last_seen).getTime();
		}
		return lastSeen >= createDate;
	}

	resetConversation() {
		this.conversation = null;
		this.messages = [];
		this.targetName = null;
	}
}
