<app-default-search-board></app-default-search-board>
<app-default-listing-recently-viewed></app-default-listing-recently-viewed>
<app-default-listing-menu [index]=0></app-default-listing-menu>
<app-default-listing-gallery [filterBy]="closingSoon"></app-default-listing-gallery>
<app-default-listing-menu [index]=1></app-default-listing-menu>
<app-default-listing-gallery [filterBy]="latest"></app-default-listing-gallery>
<app-default-listing-menu [index]=2 category_id="0001-0100"></app-default-listing-menu>
<app-default-random-listing-results category_id="0001-0100"></app-default-random-listing-results>
<app-default-listing-menu [index]=3 category_id="0001-0877"></app-default-listing-menu>
<app-default-random-listing-results category_id="0001-0877"></app-default-random-listing-results>
