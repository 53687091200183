import {makeStateKey, TransferState} from '@angular/platform-browser';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {TAJR_REPORT_RESONS} from '../../shared/global';
import {first, tap} from "rxjs/operators";
import {ReportReasons} from "../../modules/models/report.reasons";
import {ReportService} from "../../services/report.service";

@Injectable()
export class ReportReasonsResolve implements Resolve<Array<ReportReasons>> {
  constructor(
    private reportService: ReportService,
    private transferState: TransferState) {
  }

  /**
   *
   * @param route activated route snapshot
   * @param state router state
   */
  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<Array<ReportReasons>> {

    const REPORT_REASONS = makeStateKey<Array<ReportReasons>>(TAJR_REPORT_RESONS);
    if (this.transferState.hasKey(REPORT_REASONS)) {
      const result = this.transferState.get(REPORT_REASONS, null);
      return of(result);
    } else {
      return this.reportService.getAllReportReasons()
        .pipe(
          first(),
          tap(data => {
            this.transferState.set(REPORT_REASONS, data);
          })
        );
    }
  }
}
