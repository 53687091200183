import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {ProfileDetails} from '../../modules/models/profile.details';
import {LanguageService} from '../../services/language.service';
import {SearchService} from '../../services/search.service';
import {HttpHelperService} from '../../services/http-helper.service';
import {TabsetComponent} from 'ngx-bootstrap/tabs';
import {SMOOTH, TAJR_PERSONAL_DETAILS} from '../../shared/global';
import {AccountService} from '../../services/account.service';
import {SessionStorageService} from '../../server-side-rendering/storages/session.storage.service';
import {NavigateService} from '../../services/navigate.service';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit {
  // user details receive from server
  public profileDetails: ProfileDetails;
  // user details save from cookie
  public userDetails: ProfileDetails;
  // width of screen size on browser
  public screenWidth: number;
  // attribute to show loading button
  loading = false;
  // select button default value
  public selected = 'latest';
  public tab: number;

  constructor(
    // get language message from local
    private languageService: LanguageService,
    // service send request to login
    private searchService: SearchService,
    // contain api
    private httpHelper: HttpHelperService,
    // service send request to login
    private accountService: AccountService,
    private sessionStorageService: SessionStorageService,
    public navigateService: NavigateService,
  ) {
  }

  @ViewChild('staticTabs') staticTabs: TabsetComponent;

  ngOnInit() {
    // get width of device
    this.screenWidth = window.innerWidth;
    // check user profile data saved in local
    if (this.sessionStorageService.get(TAJR_PERSONAL_DETAILS)) { // try to get user details from cookie storage and show value
      this.userDetails = JSON.parse(this.sessionStorageService.get(TAJR_PERSONAL_DETAILS));
      // if user has information response
      if (this.userDetails !== undefined) {
        // get user details
        this.profileDetails = this.userDetails;
      }
    } else {
      this.accountService.getUserDetail().subscribe(currentUserDetails => {
        // if user has information response
        if (currentUserDetails.status === 200 && currentUserDetails.body) {
          // get user details
          this.profileDetails = this.userDetails;
        }
      });
    }
  }

  /**
   * use HostListener to  updated on resize:
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  getScreenSize(event) {
    this.screenWidth = window.innerWidth;
  }

  /**
   * display member localtion
   */
  showLocation() {
    let locality = '';
    let district = '';
    let location = '';
    if (this.profileDetails && this.profileDetails.locality && this.profileDetails.district) {
      district = this.languageService.messageFromServer(this.profileDetails.district.name);
      locality = this.languageService.messageFromServer(this.profileDetails.locality.name);
    }

    location = district + ' , ' + locality;
    return location;
  }

  showDateCreate(): string {
    // dateCreate from server response
    let dateCreateStr = this.profileDetails.member_since;
    // return if don't has value
    if (dateCreateStr && 0 === dateCreateStr.length) {
      return;
    }
    // endDate text show on template
    let dateCreateText: string;
    let memberSinceOptions = {
      year: 'numeric',
      month: 'long'
    }; // we only need year and month
    let memberSince = new Date(dateCreateStr);
    memberSince.setUTCHours(memberSince.getUTCHours());
    dateCreateText = memberSince.toLocaleDateString('ar-EG', memberSinceOptions);
    return dateCreateText;
  }
}
