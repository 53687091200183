<div class="bg-light" *ngIf="showContent">
  <div class="container-fluid py-4 ">
    <div class="row mr-0">
      <div class="col text-right p-0">
<!--         watch list -->
<!--        <a routerLink="/watchlist" class="text-secondary">قائمة المراقبة</a>-->
      </div>
      <div class="col text-right p-0">
<!--        <a routerLink="/list-an-item" class="text-secondary">أضف إعلانك</a>-->
      </div>
      <div class="col text-right p-0">
        <!-- comunity -->
<!--        <a routerLink="/community" class="text-secondary">المجتمع </a>-->
      </div>
      <div class="col text-right p-0">
        <a routerLink="/"><img class="img-fluid" width="55" src="../../../assets/images/logo-images/tajr-logo.png"></a>
      </div>
    </div>
    <hr class="mt-4 mb-4">
    <!-- footer Middle Content -->
    <div class="row d-flex justify-content-around">
      <div class="col-6 col-md-4 text-right">
<!--        <p class="mb-0"><a href="javascript:void(0);" class="text-primary d-none">السوق</a></p>-->
<!--        <p class="mb-0">-->
<!--          &lt;!&ndash; latest deals &ndash;&gt;<a href="javascript:void(0);" class="text-secondary"><small>اخر العروض</small></a></p>-->
<!--        <p class="mb-0">-->
<!--          &lt;!&ndash; stores &ndash;&gt;<a href="javascript:void(0);" class="text-secondary"><small>المحلات</small></a></p>-->
<!--        <p class="mb-0">-->
<!--          &lt;!&ndash; closing soon &ndash;&gt;<a href="javascript:void(0);" class="text-secondary"><small>الاعلانات الي بتنتهي-->
<!--          قريبا</small></a>-->
<!--        </p>-->
<!--        <p class="mb-2">-->
<!--          &lt;!&ndash; $1 reserve auction &ndash;&gt; <a href="javascript:void(0);" class="text-secondary"><small>مزاد ابو-->
<!--          ريال</small></a></p>-->

<!--        <p class="mb-0"><a href="javascript:void(0);" class="text-secondary">العقار</a></p>-->
<!--        <p class="mb-0">-->
<!--          &lt;!&ndash; residential &ndash;&gt;<a href="javascript:void(0);" class="text-secondary"><small>سكني</small></a></p>-->
<!--        <p class="mb-0">-->
<!--          &lt;!&ndash; businesses for sale &ndash;&gt;<a href="javascript:void(0);" class="text-secondary"><small>شركات-->
<!--          للبيع</small></a>-->
<!--        </p>-->
<!--        <p class="mb-0">-->
<!--          &lt;!&ndash; commercial &ndash;&gt;<a href="javascript:void(0);" class="text-secondary"><small>تجاري</small></a></p>-->

      </div>
      <div class="col-6 col-md-4 text-right">
<!--        <p class="mb-0"><a href="javascript:void(0);" class="text-success">الوظائف</a></p>-->
<!--        <p class="mb-0">-->
<!--          &lt;!&ndash; browse categories &ndash;&gt;<a href="javascript:void(0);" class="text-secondary"><small>تصفح جميع-->
<!--          الاقسام</small></a>-->
<!--        </p>-->
<!--        <p class="mb-0">-->
<!--          &lt;!&ndash; salary guide &ndash;&gt;<a href="javascript:void(0);" class="text-secondary"><small>دليل الرواتب</small></a></p>-->
<!--        <p class="mb-0">-->
<!--          &lt;!&ndash; job hunting guide &ndash;&gt;<a href="javascript:void(0);" class="text-secondary"><small>دليل البحث عن-->
<!--          وظيفة</small></a>-->
<!--        </p>-->
<!--        <p class="mb-2">-->
<!--          &lt;!&ndash; advertising guide &ndash;&gt;<a href="javascript:void(0);" class="text-secondary"><small>دليل الإعلان</small></a>-->
<!--        </p>-->

<!--        <p class="mb-0"><a href="javascript:void(0);" class="text-secondary">الخدمات </a></p>-->
<!--        <p class="mb-0">-->
<!--          &lt;!&ndash; domestic services &ndash;&gt;<a href="javascript:void(0);" class="text-secondary"><small>الخدمات-->
<!--          المنزلية</small></a></p>-->
<!--        <p class="mb-0">-->
<!--          &lt;!&ndash; events entertainment &ndash;&gt;<a href="javascript:void(0);" class="text-secondary"><small>المناسبات</small></a>-->
<!--        </p>-->
<!--        <p class="mb-0">-->
<!--          &lt;!&ndash; health wellbeing &ndash;&gt;<a href="javascript:void(0);" class="text-secondary"><small>الخدمات الغذائيه-->
<!--          والصحيه</small></a>-->
<!--        </p>-->
<!--        <p class="mb-0">-->
<!--          &lt;!&ndash; trades &ndash;&gt;<a href="javascript:void(0);" class="text-secondary"><small>تجارية</small></a>-->
<!--        </p>-->
      </div>
      <div class="col-12 col-md-4 text-right">
        <p class="mb-0"><a routerLink="/motors" class="text-warning">السيارات</a></p>
        <p class="mb-0">
          <!-- cars --> <a routerLink="/motors" class="text-secondary"><small>سيارات</small></a></p>
        <p class="mb-0">
          <!-- tnc --> <a routerLink="/term-and-condition" class="text-secondary"><small>اتفاقية الاستخدام</small></a></p>
<!--        <p class="mb-0">-->
<!--          &lt;!&ndash; motorbikes &ndash;&gt; <a href="javascript:void(0);" class="text-secondary"><small>دبابات</small></a></p>-->
<!--        <p class="mb-0">-->
<!--          &lt;!&ndash; boats marine &ndash;&gt;<a href="javascript:void(0);" class="text-secondary"><small>المركبات البحرية</small></a>-->
<!--        </p>-->
<!--        <p class="mb-2">-->
<!--          &lt;!&ndash; other vehicles &ndash;&gt;<a href="javascript:void(0);" class="text-secondary"><small>المركبات الأخرى</small></a>-->
<!--        </p>-->

<!--        <p class="mb-0">-->
<!--          &lt;!&ndash; Community &ndash;&gt;<a href="javascript:void(0);" class="text-primary">المجتمع</a></p>-->
        <p class="mb-0">
          <!-- contact us -->
          <a href="mailto:tajr.dev@gmail.com" class="text-secondary">
            <small> تواصل معنا</small>
          </a>
        </p>
        <p class="mb-0 pt-1">
          <!-- https://www.instagram.com/tajr.sa/ -->
          <a href="https://www.instagram.com/tajr.sa/"  class="text-secondary" target="_blank">
            <i class="fa fa-instagram FONT_SIZE_28 text-secondary" aria-hidden="true"></i>
          </a>
          <!-- https://twitter.com/tajrsa-->
          <a href="https://twitter.com/tajrsa" class="text-secondary ml-2" target="_blank">
            <i class="fa fa-twitter FONT_SIZE_28 text-secondary" aria-hidden="true"></i>
          </a>
        </p>
<!--        <p class="mb-0">-->
<!--          &lt;!&ndash; announcements &ndash;&gt;<a href="javascript:void(0);" class="text-secondary"><small>المركز الإعلامي</small></a>-->
<!--        </p>-->
<!--        <p class="mb-0">-->
<!--          &lt;!&ndash; trust safety &ndash;&gt;<a href="javascript:void(0);" class="text-secondary"><small>الثقة والسلامة</small></a>-->
<!--        </p>-->
<!--        <p class="mb-0">-->
<!--          &lt;!&ndash; seller information &ndash;&gt;<a href="javascript:void(0);" class="text-secondary"><small>معلومات-->
<!--          للبائعين</small></a>-->
<!--        </p>-->
<!--        <p class="mb-0">-->
<!--          &lt;!&ndash; help &ndash;&gt;<a href="javascript:void(0);" class="text-secondary"><small>مساعدة</small></a></p>-->

      </div>
    </div>
    <!-- language -->
    <div class="row" dir="rtl" *ngIf="false">
      <div class="col text-right">
        <p class="font-weight-bold text-secondary mb-0">اللغة</p>
        <select dir="rtl" class="text-secondary py-0 px-4" disabled>
          <option value="arabic">العربية</option>
          <option value="english">English</option>
        </select>
      </div>
    </div>
  </div>
</div>
