import { Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {ProfileDetails} from '../../modules/models/profile.details';
import {
  AR,
  EN,
  HOME_PAGE,
  SEARCH_PAGE, SearchConstant,
  TAJR_PERSONAL_DETAILS
} from '../../shared/global';
import {FormBuilder, FormControl} from '@angular/forms';
import {BsModalService} from 'ngx-bootstrap/modal';
import {AccountService} from '../../services/account.service';
import {HttpHelperService} from '../../services/http-helper.service';
import {LanguageService} from '../../services/language.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {Util} from '../../utils/utils';
import {SearchModel} from '../../modules/models/search.model';
import {SearchListingDetails} from '../../modules/models/search.listing.details';
import {Subscription} from 'rxjs';
import {SearchService} from '../../services/search.service';
import {NotificationServices} from '../../services/notification.services';
import {LoginModalComponent} from '../modals/login-modal/login-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {SessionStorageService} from '../../server-side-rendering/storages/session.storage.service';
import {MemberListingSummary} from '../../modules/models/member.listing.summary';
import {ChatService} from '../../services/chat.service';

class SearchOption {
  label = '';
  category = '';
  categoryBreadcrumb = '';
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  // message show when valid modal input value
  requireMobileNumberMessage = this.languageService.findMessageByLanguage('requireMobileNumber');
  regexMobileNumberMessage = this.languageService.findMessageByLanguage('regexMobileNumber');
  someThingWentWrongMessage = this.languageService.findMessageByLanguage('someThingWentWrong');
  youHaveLoggedOutSuccessfullyMess = this.languageService.findMessageByLanguage('youHaveLoggedOutSuccessfully');

  // user details
  public userDetails: ProfileDetails;
  public keySearch: string;
  public getListings: SearchModel;
  private subscription: Subscription;
  public searchListingDetails: SearchListingDetails;
  public searchOption: SearchOption[] = [];
  public myFormControl = new FormControl();
  // default value to hide navbar button
  // my-Tajr button board on responsive screen
  public isCloseTajrLogoBtn = true;
  // my-Tajr button board
  public isCloseMyAccountMenuBtn = true;
  public currentUrl: string;
  public memberListingSummary: MemberListingSummary;
  public errorMessage: string;
  public showAddListAnItemButtonPageList = [
    '/',
    '/search',
  ];

  constructor(
    // manage form input
    private formBuilder: FormBuilder,
    // service send request to login
    private accountService: AccountService,
    // contain api
    private httpHelper: HttpHelperService,
    // manage show and hide
    private modalService: BsModalService,
    // get language message from local
    private languageService: LanguageService,
    // AuthService use to check user login data sane in cookie
    private  authService: AuthService,
    // service send request to login
    private searchService: SearchService,
    private chatService: ChatService,
    private notify: NotificationServices,
    private elementRef: ElementRef,
    public dialog: MatDialog,
    private sessionStorageService: SessionStorageService,
    private router: Router,
    // service use to get value from router
    private activatedRoute: ActivatedRoute,
  ) {
  }

  @ViewChild('searchInput') searchInput;

  ngOnInit() {
    this.keySearch = '';
    this.activatedRoute.queryParamMap.subscribe((queryParams) => {
      if (queryParams.has(SearchConstant.QUERY)) {
        this.keySearch = queryParams.get(SearchConstant.QUERY);
      }
    });

    this.checkUserLoginStatus();

    this.router.events.subscribe( (url) => {
      if (url instanceof NavigationEnd) {
        this.currentUrl = url.urlAfterRedirects.split('?')[0];
      }
    });
  }

  searchItem() {
    this.getListings = new SearchModel();
    this.getListings.sort_by = 'latest';
    this.getListings.from = '0';
    this.getListings.size = '5';
    this.getListings.query = this.keySearch;

    // send request to get listing
    this.subscription = this.searchService.searchGeneralListing(this.getListings).subscribe(data => {
      // if user has information response
      if (200 === data.status && data.body) {
        this.searchListingDetails = data.body;
        this.showSearchOptions();
      } else {
        this.searchListingDetails = new SearchListingDetails();
      }
    }, err => {
      this.searchListingDetails = new SearchListingDetails();
    });
  }

  showSearchOptions() {
    if (Util.isNotNullOrEmpty(this.keySearch) && Util.isNotNullOrEmpty(this.searchListingDetails) && Util.isNotNullOrEmpty(this.searchListingDetails.hits)) {
      this.searchOption = [];
      this.searchListingDetails.hits.forEach(item => {
        if (this.languageService.language === AR) {
          const option = new SearchOption();
          option.category = item.full_category_id;
          option.label = item.category_arabic_level_3;
          option.categoryBreadcrumb = item.category_arabic_level_1 + ' > ' + item.category_arabic_level_2 + ' > ' + item.category_arabic_level_3;
          this.searchOption.push(option);
        }

        if (this.languageService.language === EN) {
          const option = new SearchOption();
          option.category = item.full_category_id;
          option.label = item.category_english_level_3;
          option.categoryBreadcrumb = item.category_english_level_1 + ' > ' + item.category_english_level_2 + ' > ' + item.category_english_level_3;
          this.searchOption.push(option);
        }

      });
    } else {
      this.searchOption = [];
    }
  }

  /**
   *  open login model when user not login status and want to add item to watchlist
   * @param template - modal
   */
  openLoginDialog() {
    const loginRef = this.dialog.open(LoginModalComponent, {
      maxWidth: '100%',
      panelClass: 'loginModalDialog'
    });
  }

  /**
   * navigate to search  page
   * @param querySearch query to search
   */
  navigateToSearchPage() {
    this.router.navigate([SEARCH_PAGE],
      {
        queryParams: {
          query: this.keySearch
        }
      })
    this.searchInput.nativeElement.blur();
  }

  /**
   * navigate to search  page
   * @param listingId listingId
   */
  navigateToSearchPageWithListing(category: string, label: string) {
    if (Util.isNotNullOrEmpty(category)) {
      this.router.navigateByUrl(HOME_PAGE, {skipLocationChange: true}).then(() =>
        this.router.navigate([SEARCH_PAGE],
          {
            queryParams: {
              query: label,
              categoryId: category
            }
          }));
    }
  }

  /**
   * navigate to selling  page
   * @param listing
   */
  navigateToSellingPage(url: string, tabNum: string) {
    this.router.navigate([url], {queryParams: {tab: tabNum}});
  }

  /**
   * user log out
   */
  signOut() {
    // remove information from cookie storage
    this.sessionStorageService.clear();
    this.notify.showInfoNotificationMessage(this.youHaveLoggedOutSuccessfullyMess);
    // send request to logout
    this.accountService.logOut().subscribe(data => {
      // if user log out success
      if (data.status === 200) {
        // reset user details
        this.userDetails = new ProfileDetails();
        // when log out success will delete all account information save in local
        this.sessionStorageService.clear();
      }
    }, err => {
      // remove information from cookie storage
      this.sessionStorageService.clear();
    });
    const fcmToken = localStorage['tajr-fcm-token'];
    if (fcmToken) {
      localStorage.removeItem('tajr-fcm-token');
      localStorage.removeItem('tajr-fcm-expire');
      this.chatService.deleteToken(fcmToken).subscribe(data => {
        console.log("Delete token successfully")
      }, err => {
        
      });;
    }
    // redirect on home page
    this.router.navigate([HOME_PAGE]);
  }

  /**
   * detect click outside component will reset myTajr button board
   * @param event
   */
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isCloseTajrLogoBtn = true;
      this.isCloseMyAccountMenuBtn = true;
    }
  }

  /**
   * check user login status
   */
  checkUserLoginStatus(): boolean {
    let loginStatus = false;
    if (this.sessionStorageService.get(TAJR_PERSONAL_DETAILS)) {
      this.userDetails = JSON.parse(this.sessionStorageService.get(TAJR_PERSONAL_DETAILS));
      // show my-Tajr account information example selling number, unsold number, withdraw listing number
      loginStatus = true;
    }
    return loginStatus;
  }

  showAddListAnItemButton(): boolean {
    return this.showAddListAnItemButtonPageList.includes(this.currentUrl)
  }

  getMemberListingSummary() {
    if (this.isCloseMyAccountMenuBtn || !this.checkUserLoginStatus()) {
      return;
    }
    // send request to get listing
    this.accountService.getMemberListingSummary().subscribe(data => {
      // if user has information response
      if (200 === data.status && data.body) {
        this.memberListingSummary = data.body;
      }
    }, err => {
      // get error message response from server
      if (err && err.error) {
        // show error
        this.errorMessage = this.languageService.messageFromServer(err.error.message);
      } else {
        // service error message
        this.errorMessage = this.someThingWentWrongMessage;
      }
      // close account menu
      this.isCloseMyAccountMenuBtn = true;
      this.notify.showWarnmingNotificationMessage(this.errorMessage)
    });
  }

}
