import {Component, HostListener, OnInit} from '@angular/core';
import {SearchModel} from '../../modules/models/search.model';
import {Subscription} from 'rxjs';
import {SearchService} from '../../services/search.service';
import {SearchListingDetails} from '../../modules/models/search.listing.details';
import {AR, EN, FIRST_ITEM, HOME_PAGE, LATEST, SEARCH_PAGE, SearchConstant} from '../../shared/global';
import {FormControl} from '@angular/forms';
import {Util} from '../../utils/utils';
import {LanguageService} from '../../services/language.service';
import {ActivatedRoute, Router} from '@angular/router';


class SearchOption {
  label = '';
  category = '';
  categoryBreadcrumb = '';
}

@Component({
  selector: 'app-default-search-board',
  templateUrl: './default-search-board.component.html',
  styleUrls: ['./default-search-board.component.css']
})
export class DefaultSearchBoardComponent implements OnInit {
  // width of screen size on browser
  public screenWidth: number;
  public keySearch: string;
  private subscription: Subscription;
  public getListings: SearchModel;
  public searchListingDetails: SearchListingDetails;
  myFormControl = new FormControl();
  public searchOption: SearchOption[] = [];

  constructor(
    // service send request to login
    private searchService: SearchService,
    // get language message from local
    private languageService: LanguageService,
    private router: Router,
    // service use to get value from router
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.screenWidth = window.innerWidth;

    this.activatedRoute.queryParamMap.subscribe((queryParams) => {
      if (queryParams.has(SearchConstant.QUERY)) {
        this.keySearch = queryParams.get(SearchConstant.QUERY);
      }
    });
  }

  searchItem() {
    if (Util.isNullOrEmpty(this.keySearch)) {
      return;
    }

    this.getListings = new SearchModel();
    this.getListings.sort_by = LATEST;
    this.getListings.from = FIRST_ITEM.toString();
    this.getListings.size = '5';
    this.getListings.query = this.keySearch;

    // send request to get listing
    this.subscription = this.searchService.searchGeneralListing(this.getListings).subscribe(data => {
      // if user has information response
      if (200 === data.status && data.body) {
        this.searchListingDetails = data.body;
        this.showSearchOptions();
      } else {
        this.searchListingDetails = new SearchListingDetails();
      }
    }, err => {
      this.searchListingDetails = new SearchListingDetails();
    });
  }

  showSearchOptions() {
    if (Util.isNotNullOrEmpty(this.keySearch) && Util.isNotNullOrEmpty(this.searchListingDetails) && Util.isNotNullOrEmpty(this.searchListingDetails.hits)) {
      this.searchOption = [];
      this.searchListingDetails.hits.forEach(item => {
        if (this.languageService.language === AR) {
          let option = new SearchOption();
          option.category = item.full_category_id;
          option.label = item.category_arabic_level_3;
          option.categoryBreadcrumb = item.category_arabic_level_1 + ' > ' + item.category_arabic_level_2 + ' > ' + item.category_arabic_level_3;
          this.searchOption.push(option);
        }

        if (this.languageService.language === EN) {
          let option = new SearchOption();
          option.category = item.full_category_id;
          option.label = item.category_english_level_3;
          option.categoryBreadcrumb = item.category_english_level_1 + ' > ' + item.category_english_level_2 + ' > ' + item.category_english_level_3;
          this.searchOption.push(option);
        }

      });
    } else {
      this.searchOption = [];
    }

  }

  /**
   * use HostListener to  updated on resize:
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  getScreenSize(event) {
    this.screenWidth = window.innerWidth;
  }

  /**
   * navigate to search  page
   * @param querySearch query to search
   */
  navigateToSearchPage() {
    this.router.navigate([SEARCH_PAGE],
      {
        queryParams: {
          query: this.keySearch
        }
      })
  }

  /**
   * navigate to search  page
   * @param listingId listingId
   */
  navigateToSearchPageWithListing(category: string, label: string) {
    if (Util.isNotNullOrEmpty(category)) {
      this.keySearch = label;
      this.router.navigateByUrl(HOME_PAGE, {skipLocationChange: true}).then(() =>
        this.router.navigate([SEARCH_PAGE],
          {
            queryParams: {
              query: this.keySearch,
              categoryId: category
            }
          }));

    }
  }
}

