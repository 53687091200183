<!-- blue line  -->
<div class="bg-primary pt-2"></div>
<!--content-->
<div class="bg-light">
  <!-- content on large screen -->
  <div class="container-fluid d-none d-lg-block" *ngIf="userDetails">
    <div class="row pt-5">
      <div class="col pr-4 align-self-center">
        <div>
          <div class="row">
            <div class="col text-right">
              <h4 class="text-secondary pr-0 mb-3 sellerName">
                {{userDetails.username}}
              </h4>
            </div>
          </div>
          <div class="row">
            <div class="col text-right pr-0">
              <p class="text-secondary mb-3 first_name">
                {{userDetails.first_name}}
              </p>
              <p class="text-secondary mb-3 district_id">
                {{showLocation()}}
              </p>
              <p class="text-secondary mb-3 date_created">
                {{showMemberRegisterDate()}}
              </p>
            </div>
            <div class="col-auto text-right pl-0 ml-1">
              <p class="font-weight-bold text-secondary mb-3" dir="rtl">
                <!--name --> الاسم :
              </p>
              <p class="font-weight-bold text-secondary mb-3" dir="rtl">
                <!--Location --> موقع البائع :
              </p>
              <p class="font-weight-bold text-secondary mb-0" dir="rtl">
                <!-- Member since--> عضو منذ :
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-2">
        <div
          class="embed-responsive embed-responsive-1by1 rounded-circle d-flex justify-content-center align-items-center userAvatar">
          <img class="embed-responsive-item img-fluid " alt="tajr.sa" src="../../../assets/images/logo-images/tajr-round-logo.png">
        </div>
      </div>
    </div>
  </div>
  <!-- content on small screen -->
  <div class="container-fluid d-lg-none" *ngIf="userDetails">

    <!-- seller avatar -->
    <div class="row pt-3">
      <div class="col text-center">
        <div class="row">
          <div class="col d-none d-sm-block"></div>
          <div class="col-4 col-sm-3 col-md-2 offset-4 offset-sm-0">
            <div
              class="embed-responsive embed-responsive-1by1 rounded-circle d-flex justify-content-center align-items-center userAvatar">
              <img class="embed-responsive-item img-fluid " alt="tajr.sa"
                   src="../../../assets/images/logo-images/tajr-round-logo.png">
            </div>
          </div>
          <div class="col d-none d-sm-block"></div>
        </div>
      </div>
    </div>
    <!-- seller information -->
    <div class="row pt-3">
      <div class="col">
        <div>
          <div class="row">
            <div class="col text-center">
              <h4 class="text-dark pr-0 mb-3 sellerName">{{userDetails.username}}</h4>
            </div>
          </div>
          <div class="row">
            <div class="col d-none d-sm-block"></div>
            <div class="col-12 col-sm-10">
              <div class="d-flex justify-content-around">
                <div>
                  <p class="text-dark mb-3 first_name">{{userDetails.first_name}}</p>
                  <p class="text-dark mb-3 district_id"> {{showLocation()}}</p>
                  <p class="text-dark mb-3 date_created">{{showMemberRegisterDate()}}</p>
                </div>
                <div class="text-right">
                  <p class="font-weight-bold text-dark mb-3" dir="rtl">
                    <!--name --> الاسم :
                  </p>
                  <p class="font-weight-bold text-dark mb-3" dir="rtl">
                    <!--Location --> موقع البائع :
                  </p>
                  <p class="font-weight-bold text-dark mb-0" dir="rtl">
                    <!-- Member since--> عضو منذ :
                  </p>
                </div>
              </div>
            </div>
            <div class="col d-none d-sm-block"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid" *ngIf="totalNumberItem > 0">
    <!-- sort -->
    <div class="row" dir="rtl" *ngIf="screenWidth <=575">
      <div class="col bg-light px-2 pt-2 pb-0 ">
        <form>
          <div class="form-row align-items-center" [ngClass]="screenWidth > 575?'mb-2':''">
            <div class="col-12 col-sm d-flex justify-content-start align-items-center">

              <swiper
                slidesPerView='auto'
                [spaceBetween]="8"
                class="bg-light py-1 w-100">
                <ng-template swiperSlide class="card-header p-0 w-auto border-0 MARGIN_LEFT_8">
                  <button (click)="getMemberPrivateListing('latest')"
                          type="button"
                          [ngClass]="selected == 'latest' ? 'selected' : ''"
                          class="btn btn-xs rounded-pill ml-1 scrollBtn">
                      <!--Latest listings-->اخر الإعلانات
                  </button>
                  <button (click)="getMemberPrivateListing('closing_soon')"
                          type="button"
                          [ngClass]="selected == 'closing_soon' ? 'selected' : ''"
                          class="btn btn-xs rounded-pill ml-1 scrollBtn">
                      <!--Closing soon-->تنتهي قريبا
                  </button>

                </ng-template>
              </swiper>

            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="row" dir="ltr" *ngIf="screenWidth >575">
      <div class="col">
        <div class="d-flex justify-content-end align-content-center bg-light p-2 mt-2 mb-3">
          <div class="d-flex justify-content-start align-items-center">
            <div class="form-group text-right">
              <label class="font-weight-bold text-warning mb-0">
                <!--sort -->ترتيب حسب‎</label>
              <select dir="rtl" class="form-control form-control-sm text-dark py-0"
                      [(ngModel)]='selected'
                      (change)="getMemberPrivateListing()">
                <option value="latest">
                  <!--Latest listings-->اخر الإعلانات
                </option>
                <option value="closing_soon">
                  <!--Closing soon-->تنتهي قريبا
                </option>
              </select>
            </div>
          </div>
          <div class="d-flex justify-content-end align-items-center">
          </div>
        </div>
      </div>
    </div>
    <!--number of listing-->
    <div class="row">
      <div class="col">
        <p class="text-primary text-right mb-0 px-2">
          <small>{{numberItemOfListingsMess}}</small>
        </p>
      </div>
    </div>
    <!-- selling items area when has item  -->
    <div class="sellingItemsArea">
      <div class="row">
        <div class="col col-lg-10 offset-lg-2 pt-2">
          <ul class="list-unstyled" *ngIf="sellingListingDetails != null" dir="ltr">
            <a class="media border rounded-lg carouselStyle position-relative bg-white row m-0 mb-3"
                *ngFor="let slide of sellingListingDetails.hits; index as index"
                [routerLink]="navigateService.generateListingPageUrl(slide.listing_id, slide.title)">
              <mat-card class="align-self-end text-left p-2 shadow-none d-none d-sm-block mb-1 ml-1 position-absolute"
                        dir="rtl">
                <mat-card-title class="font-weight-bold text-dark text-break mb-0 h6" dir="rtl">
                  ‬ {{listingService.composedPrice(slide)}}
                </mat-card-title>
              </mat-card>
              <div class="media-body col-8 col-md-9 p-2" dir="rtl">
                <mat-card class="text-right shadow-none p-0">

                  <mat-card-subtitle class="small mb-0" *ngIf="listingService.checkListingStatus(slide) == 'deleted' ">
                            <span class="badge badge-danger px-2 py-1">
                                   {{deletedMess}}
                            </span>
                  </mat-card-subtitle>
                  <mat-card-subtitle class="small mb-0" *ngIf="listingService.checkListingStatus(slide) == 'expired' ">
                            <span class="badge badge-primary px-2 py-1">
                                   {{expiredMess}}
                            </span>
                  </mat-card-subtitle>
                  <mat-card-content class="text-dark mb-2 cardGalleryLineBreak" dir="rtl"
                                    [ngClass]="screenWidth <= 575 ? 'small' : ''">
                    {{slide.locality_arabic}} | {{ listingService.endDateText(slide.endDate) }}
                  </mat-card-content>

                  <mat-card-title class="font-weight-bold text-dark cardGalleryLineBreak"
                                  [ngClass]="screenWidth <= 575 ? 'FONT_SIZE_14' : 'h6'">
                    {{slide.title}}
                  </mat-card-title>
                  <mat-card-subtitle class="text-dark text-break align-self-end  cardGalleryLineBreak"
                                     *ngIf="slide.subtitle != null">
                    {{slide.subtitle}}
                  </mat-card-subtitle>
                  <mat-card-title class="font-weight-bold text-dark text-break text-left d-sm-none"
                                  [ngClass]="screenWidth <= 575 ? 'FONT_SIZE_14' : 'h6'">
                    ‬ {{listingService.composedPrice(slide)}}
                  </mat-card-title>
                </mat-card>
              </div>
              <mat-card
                class="col-4 col-sm-4 col-md-3 embed-responsive embed-responsive-16by9 shadow-none align-self-center"
                dir="rtl">
                <img class="img-fluid embed-responsive-item w-100 h-100 tajrImage"
                     [src]="listingService.showPhoto(slide.photo_1, slide.title)"
                     [alt]="slide.photo_1 != null ? titleService.generateArabicLable(slide.title): titleService.generateArabicLable(titleService.defaultTitle)">
              </mat-card>
              <!-- card icon -->
              <img class="img-fluid p-1 position-absolute tajrCardIcon" alt="tajr.sa"
                   *ngIf="slide?.watchlist_id"
                   src="../../../assets/images/icon-images/tajr-icon-000041.png">
            </a>
          </ul>
        </div>
      </div>

      <!-- pagination  -->
      <div class="row" dir="ltr" *ngIf="loading == false && totalNumberItem > getListingToItem">
        <div class="col-2 d-none d-lg-block"></div>
        <div class="col" dir="rtl">
          <pagination class="d-flex justify-content-center"
                      [boundaryLinks]="showBoundaryLinks"
                      [totalItems]="totalNumberItem"
                      (pageChanged)="onChangepaginationMemberPage($event)"
                      [maxSize]="maxSize"
                      [itemsPerPage]="getListingToItem"
                      [(ngModel)]="currentPage"
                      previousText="&lsaquo;"
                      nextText="&rsaquo;"
                      firstText="&laquo;"
                      lastText="&raquo;"
          ></pagination>
        </div>
      </div>

    </div>
  </div>

  <!--the spinner when page load not finished-->
  <div class="d-flex justify-content-center min-vh-100 py-5 my-5" *ngIf="!sellingListingDetails">
    <mat-spinner class="mt-5"></mat-spinner>
  </div>
</div>
<!-- blue line  -->
<div class="bg-primary pt-2"></div>
