<!-- blue line  -->
<div class="bg-primary pt-2"></div>
<!--content when empty notifications-->
<div class="container-fluid d-none">
  <div class="row pt-4">
    <div class="col">
      <h3 class="font-weight-bold text-right text-warning ">Notifications</h3>
    </div>
  </div>
  <div class="row">
    <div class="col text-center mt-4">
      <img class="img-fluid" [width]="screenWidth > 575? '128':'64'"
           src="../../../assets/images/icon-images/tajr-icon-000050.png">
    </div>
  </div>
  <div class="row">
    <div class="col text-center mt-5">
      <h5>All up to date!</h5>
      <p class="text-secondary mt-4">Notifications help you to stay up to date with your buying and
        selling.</p>
      <p class="text-secondary mt-4">For example, if you're outbid or asked a question, or receive a
        Fixed
        Price Offer a notification will appear here.</p>
    </div>
  </div>
</div>
<!--
When has api for notification, notification list want to redesign
-->
<!--content when not empty notifications for large screen-->
<div class="container-fluid d-none d-sm-block">
  <div class="row pt-4">
    <div class="col">
      <h3 class="font-weight-bold text-right text-warning">Notifications</h3>
    </div>
  </div>
  <!-- Answer question -->
  <div class="row mt-3 ml-0 mr-0">
    <div class="col bg-white nav-link position-relative border border-right-0 rounded-left p-2">
      <div class="row">
        <div class="col text-right">
          <small class="text-warning">Questions asked</small>
        </div>
      </div>
      <div class="row">
        <div class="col position-absolute">
          <p class="font-weight-bold text-secondary text-right mb-0">
            star wars force awakens stormtrooper xmas jumper
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col pt-5">
          <p class="text-secondary text-right mb-0">
            Sub Title
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col text-right">
          <small class="text-secondary">Today, 1:24pm</small>
        </div>
      </div>
      <div class="row">
        <div class="col text-right">
          <p class="text-secondary text-right mb-0">
            Is this the jumber I looking for: Q
          </p>

        </div>
      </div>
    </div>
    <div class="col-auto d-flex align-items-center bg-white border border-left-0 rounded-right p-0">
      <!-- image on large screen size 144 x ... -->
      <img class="img-fluid rounded" src="../../../assets/images/icon-images/tajr-icon-000051.jpg">
    </div>
  </div>
  <div class="row">
    <div class="col">
      <a href="javascript:void(0);" class="nav-link bg-white border border-top-0 rounded-bottom">ANSWER QUESTION</a>
    </div>
  </div>
  <!-- outbid -->
  <div class="row mt-3 ml-0 mr-0">
    <div class="col bg-white nav-link position-relative border border-right-0 rounded-left p-2">
      <div class="row">
        <div class="col text-right">
          <small class="text-warning">Out bid</small>
        </div>
      </div>
      <div class="row">
        <div class="col position-absolute">
          <p class="font-weight-bold text-secondary text-right mb-0">
            star wars force awakens stormtrooper xmas jumper
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col pt-5">
          <p class="text-secondary text-right mb-0">
            Sub Title
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col text-right">
          <small class="text-secondary">Today, 1:24pm</small>
        </div>
      </div>
      <div class="row">
        <div class="col text-right">
          <p class="text-secondary text-right mb-0">
            You were outbid !
          </p>

        </div>
      </div>
    </div>
    <div class="col-auto d-flex align-items-center bg-white border border-left-0 rounded-right p-0">
      <!-- image on large screen size 144 x ... -->
      <img class="img-fluid rounded" src="../../../assets/images/icon-images/tajr-icon-000052.jpg">
    </div>
  </div>
  <div class="row">
    <div class="col">
      <a href="javascript:void(0);" class="nav-link bg-white border border-top-0 rounded-bottom">PLACE BID</a>
    </div>
  </div>
  <!-- closing soon -->
  <div class="row mt-3 ml-0 mr-0">
    <div class="col bg-white nav-link position-relative border border-right-0 rounded-left p-2">
      <div class="row">
        <div class="col text-right">
          <small class="text-warning">Closing soon</small>
        </div>
      </div>
      <div class="row">
        <div class="col position-absolute">
          <p class="font-weight-bold text-secondary text-right mb-0">
            star wars force awakens stormtrooper xmas jumper
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col pt-5">
          <p class="text-secondary text-right mb-0">
            Sub Title
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col text-right">
          <small class="text-secondary">Today, 1:24pm</small>
        </div>
      </div>
      <div class="row">
        <div class="col text-right">
          <p class="text-secondary text-right mb-0">
            This listing closes at 1:50 pm
          </p>

        </div>
      </div>
    </div>
    <div class="col-4 col-sm-auto d-flex align-items-center bg-white border border-left-0 rounded-right p-0">
      <!-- image on large screen size 144 x ... -->
      <img class="img-fluid rounded" src="../../../assets/images/icon-images/tajr-icon-000053.jpg">
    </div>
  </div>
  <div class="row bg-white border border-top-0 rounded-bottom  ml-0 mr-0 ">
    <div class="col-auto pl-0">
      <a href="javascript:void(0);" class="nav-link ">PLACE BID</a>

    </div>
    <div class="col-auto">
      <a href="javascript:void(0);" class="nav-link">BUY NOW</a>
    </div>
  </div>
  <!-- reserve met -->
  <div class="row mt-3 ml-0 mr-0">
    <div class="col bg-white nav-link position-relative border border-right-0 rounded-left p-2">
      <div class="row">
        <div class="col text-right">
          <small class="text-warning">Reserve met</small>
        </div>
      </div>
      <div class="row">
        <div class="col position-absolute">
          <p class="font-weight-bold text-secondary text-right mb-0">
            star wars force awakens stormtrooper xmas jumper
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col pt-5">
          <p class="text-secondary text-right mb-0">
            Sub Title
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col text-right">
          <small class="text-secondary">Today, 1:24pm</small>
        </div>
      </div>
      <div class="row">
        <div class="col text-right">
          <p class="text-secondary text-right mb-0">
            The reserve price has been met .
          </p>

        </div>
      </div>
    </div>
    <div class="col-auto d-flex align-items-center bg-white border border-left-0 rounded-right p-0">
      <!-- image on large screen size 144 x ... -->
      <img class="img-fluid rounded" src="../../../assets/images/icon-images/tajr-icon-000053.jpg">
    </div>
  </div>
  <br/>
</div>
<!--content not empty for mobile screen-->
<div class="container-fluid bg-light pb-5 d-sm-none">
  <div class="row pt-4">
    <div class="col">
      <h3 class="font-weight-bold text-right text-warning">Notifications</h3>
    </div>
  </div>
  <!-- Answer quyestion -->
  <div class="row mt-3 ml-0 mr-0">
    <div class="col bg-white nav-link position-relative border-top  border-left rounded-left p-2">
      <div class="row">
        <div class="col text-right">
          <small class="text-warning">Questions asked</small>
        </div>
      </div>
    </div>
    <div class="col-4 text-right bg-white border-top border-right rounded-right p-0">
      <!-- image on large screen size 144 x ... -->
      <img class="img-fluid rounded" src="../../../assets/images/icon-images/tajr-icon-000051.jpg">
    </div>
  </div>
  <div class="row ml-0 mr-0">
    <div class="col bg-white nav-link border border-top-0 p-2">
      <div class="row">
        <div class="col">
          <p class="font-weight-bold text-secondary text-right mb-0">
            star wars force awakens stormtrooper xmas jumper
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="text-secondary text-right mb-0">
            Sub Title
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col text-right">
          <small class="text-secondary">Today, 1:24pm</small>
        </div>
      </div>
      <div class="row">
        <div class="col text-right">
                            <span class="text-secondary text-right mb-0">
                                Is this the jumber I looking for :Q
                            </span>

        </div>
      </div>
    </div>

  </div>
  <div class="row ml-0 mr-0">
    <div class="col pl-0 pr-0">
      <a href class="nav-link bg-white border border-top-0 rounded-bottom pl-1">ANSWER QUESTION</a>
    </div>
  </div>
  <!-- outbid -->
  <div class="row mt-3 ml-0 mr-0">
    <div class="col bg-white nav-link position-relative border-top  border-left rounded-left p-2">
      <div class="row">
        <div class="col text-right">
          <small class="text-warning">Out bid</small>
        </div>
      </div>
    </div>
    <div class="col-4 text-right bg-white border-top border-right rounded-right p-0">
      <!-- image on large screen size 144 x ... -->
      <img class="img-fluid rounded" src="../../../assets/images/icon-images/tajr-icon-000052.jpg">
    </div>
  </div>
  <div class="row ml-0 mr-0">
    <div class="col bg-white nav-link border border-top-0 p-2">
      <div class="row">
        <div class="col">
          <p class="font-weight-bold text-secondary text-right mb-0">
            star wars force awakens stormtrooper xmas jumper
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="text-secondary text-right mb-0">
            Sub Title
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col text-right">
          <small class="text-secondary">Today, 1:24pm</small>
        </div>
      </div>
      <div class="row">
        <div class="col text-right">
                            <span class="text-secondary text-right mb-0">
                                You were outbid
                            </span>

        </div>
      </div>
    </div>

  </div>
  <!-- closing soon -->
  <div class="row mt-3 ml-0 mr-0">
    <div class="col bg-white nav-link position-relative border-top  border-left rounded-left p-2">
      <div class="row">
        <div class="col text-right">
          <small class="text-warning">Closing soon</small>
        </div>
      </div>
    </div>
    <div class="col-4 text-right bg-white border-top border-right rounded-right p-0">
      <!-- image on large screen size 144 x ... -->
      <img class="img-fluid rounded" src="../../../assets/images/icon-images/tajr-icon-000051.jpg">
    </div>
  </div>
  <div class="row ml-0 mr-0">
    <div class="col bg-white nav-link border border-top-0 p-2">
      <div class="row">
        <div class="col">
          <p class="font-weight-bold text-secondary text-right mb-0">
            star wars force awakens stormtrooper xmas jumper
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="text-secondary text-right mb-0">
            Sub Title
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col text-right">
          <small class="text-secondary">Today, 1:24pm</small>
        </div>
      </div>
      <div class="row">
        <div class="col text-right">
                            <span class="text-secondary text-right mb-0">
                                This listing closes at 1:50 pm
                            </span>

        </div>
      </div>
    </div>

  </div>
  <div class="row ml-0 mr-0">
    <div class="col text-center pr-0 pl-0">
      <a href class="nav-link bg-white border border-top-0 rounded-bottom  pl-1">PLACE BID</a>
    </div>
    <div class="col text-center pl-0 pr-0">
      <a href class="nav-link bg-white border border-top-0 rounded-bottom  pl-1">BUY NOW</a>
    </div>
  </div>
  <!-- reserve met -->
  <div class="row mt-3 ml-0 mr-0">
    <div class="col bg-white nav-link position-relative border-top  border-left rounded-left p-2">
      <div class="row">
        <div class="col text-right">
          <small class="text-warning">Reserve met</small>
        </div>
      </div>
    </div>
    <div class="col-4 text-right bg-white border-top border-right rounded-right p-0">
      <!-- image on large screen size 144 x ... -->
      <img class="img-fluid rounded" src="../../../assets/images/icon-images/tajr-icon-000051.jpg">
    </div>
  </div>
  <div class="row ml-0 mr-0">
    <div class="col bg-white nav-link border border-top-0 p-2">
      <div class="row">
        <div class="col">
          <p class="font-weight-bold text-secondary text-right mb-0">
            star wars force awakens stormtrooper xmas jumper
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="text-secondary text-right mb-0">
            Sub Title
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col text-right">
          <small class="text-secondary">Today, 1:24pm</small>
        </div>
      </div>
      <div class="row">
        <div class="col text-right">
                            <span class="text-secondary text-right mb-0">
                                The reserve price has been met .
                            </span>

        </div>
      </div>
    </div>
  </div>
</div>
<!-- blue line  -->
<div class="bg-primary mt-3 pt-2"></div>
