import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {Subscription} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ClosestDistrict} from '../../modules/models/closestDistrict';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountService} from '../../services/account.service';
import {HttpHelperService} from '../../services/http-helper.service';
import {LanguageService} from '../../services/language.service';
import {LOCALITIES_RESOURCE, SMOOTH, TAJR_PERSONAL_DETAILS} from '../../shared/global';
import {Locality} from '../../modules/models/locality';
import {ProfileDetails} from '../../modules/models/profile.details';
import {UserEditDetails} from '../../modules/models/user.edit.details';
import {NotificationServices} from '../../services/notification.services';
import {SessionStorageService} from "../../server-side-rendering/storages/session.storage.service";
import {LocalStorageService} from "../../server-side-rendering/storages/local.storage.service";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {
// message show when valid input value
  requiredFirstnameMessage = this.languageService.findMessageByLanguage('requiredFirstname');
  regexFirstnameMessage = this.languageService.findMessageByLanguage('regexFirstname');
  requiredLastnameMessage = this.languageService.findMessageByLanguage('requiredLastname');
  regexLastnameMessage = this.languageService.findMessageByLanguage('regexLastname');
  someThingWentWrongMessage = this.languageService.findMessageByLanguage('someThingWentWrong');
  loadingMess = this.languageService.findMessageByLanguage('loading');
  editMess = this.languageService.findMessageByLanguage('edit');
  yourProfileHasBeenChangedSuccessfullyMess = this.languageService.findMessageByLanguage('yourProfileHasBeenChangedSuccessfully');
  // verify code input regex
  firstNameRegexPattern = /^.{1,50}$/;
  lastNameRegexPattern = /^.{1,50}$/;

  // form builder to manage login modal form
  editProfileForm: FormGroup;
  // attribute to show loading button
  loading = false;
  // error message response from server
  errorMessage: string;
  // mobileNumber received from url
  mobileNumber: string;
  // verificationCode received from url
  verificationCode: string;
  // Observable request when get location details
  private subscription: Subscription;
  // variable show closest district list
  public closestDistrictList = '';
  // locationList contain list of localities
  public locationList: Array<ClosestDistrict> = [];
  // user edit details send to server
  public userEditDetails: UserEditDetails;
  // tab localities default value
  public selected: string;
  // retrieve button submitted or not
  public submitted = false;
  // public LocalityCatalogue : Locality;
  public LocalityCatalogue: Array<Locality> = [];
  // user details receive from server
  public profileDetails: ProfileDetails;
  // width of screen size on browser
  public screenWidth: number;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    // service use to get value from router
    private activatedRoute: ActivatedRoute,
    // manage form input
    private formBuilder: FormBuilder,
    // service send request to login
    private accountService: AccountService,
    // contain api
    private httpHelper: HttpHelperService,
    // get language message from local
    private languageService: LanguageService,
    // go to redirect profile page
    private router: Router,
    // show notify sercice
    private notify: NotificationServices,
    private sessionStorageService: SessionStorageService,
    private localStorageService: LocalStorageService
  ) {
    // Form validation
    this.editProfileForm = this.formBuilder.group({
        // input first_name: default value empty, require has value.
        first_name: ['', Validators.compose([
          Validators.required,
          Validators.pattern(this.firstNameRegexPattern)
        ])],
        // input last_name: default value empty, require has value.
        last_name: ['', Validators.compose([
          Validators.required,
          Validators.pattern(this.lastNameRegexPattern)
        ])]
        ,  // input location: default value empty, require has value.
        location: ['', Validators.compose([])]
      }
    );
  }

  ngOnInit() {
    // get width of device
    this.screenWidth = window.innerWidth;
    // get all localties
    this.LocalityCatalogue = this.activatedRoute.snapshot.data[LOCALITIES_RESOURCE];
    // loop and get localities information
    this.closestDistrictList = '';
    this.LocalityCatalogue.forEach(locality => {
      locality.districts.forEach(district => {
        // import locality detail.
        const location = new ClosestDistrict();
        location.localityId = locality.locality_id;
        location.name = locality.name;
        location.districts = district;
        location.localityMess = this.languageService.messageFromServer(locality.name) + '-' + this.languageService.messageFromServer(district.name);
        location.value = locality.locality_id + '-' + district.district_id;
        // push locality detail to location list
        this.locationList.push(location);
      });
    });
    // get user information
    if (this.sessionStorageService.get(TAJR_PERSONAL_DETAILS)) {
      this.profileDetails = JSON.parse(this.sessionStorageService.get(TAJR_PERSONAL_DETAILS));
      // set value for input location
      this.selected = this.profileDetails.locality.locality_id + '-' + this.profileDetails.district.district_id;
    }
  }

  /**
   * send request to edit user profile
   */
  editProfileDetails() {
    this.submitted = true;
    // reset previous error message
    this.errorMessage = '';
    // If editProfileForm input is valid
    if (this.editProfileForm.valid) {
      // change loading status to show loading when sending request
      this.loading = true;
      // import edit detail information, item position follow constructor of userEditDetails
      this.userEditDetails = new UserEditDetails(
        this.editProfileForm.value.first_name,
        this.editProfileForm.value.last_name,
        this.editProfileForm.value.location.split('-', 2)[0],
        this.editProfileForm.value.location.split('-', 2)[1]
      );
      // Send request to Update mobile number status and create a member.
      this.accountService.editDetails(this.userEditDetails).subscribe(data => {
        // if user edit details success
        if (data.status === 200) {
          // If update success will send request to get user login information from server
          this.subscription = this.accountService.getUserDetail().subscribe(userDetails => {
            // if user has information response
            if (200 == userDetails.status && userDetails.body) {
              // save value for cookie storage
              this.sessionStorageService.set(TAJR_PERSONAL_DETAILS, JSON.stringify(userDetails.body));
              // show notify to user
              this.notify.showInfoNotificationMessage(this.yourProfileHasBeenChangedSuccessfullyMess);
              // edit details success goto home page
              this.router.navigate(['/my-profile']);
              // close loading status on button
              this.loading = false;
            }
          });
          // scroll to top to see error response from serve
          if (isPlatformBrowser(this.platformId)) {
            window.scroll({top: 0, behavior: SMOOTH});
          }
          // close loading status on button
          this.loading = false;
        }
      }, err => {
        // User login fail, close loading status on button
        this.loading = false;
        // get error message response from server
        if (err && err.error) {
          // show error
          this.errorMessage = this.languageService.messageFromServer(err.error.message);
        } else {
          // service error message
          this.errorMessage = this.someThingWentWrongMessage;
        }
      });
    }
  }
}
