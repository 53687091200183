<!-- blue line  -->
<div class="bg-primary pt-2"></div>

<!-- content -->
<div class="bg-light">
  <div class="container-fluid position-relative pb-5">
    <br>
    <br>
    <!-- image -->
    <div class="row">
      <div class="col-4 offset-4 text-center">
        <img class="img-fluid pt-3" src="../../../assets/images/icon-images/tajr-icon-000037.png">
      </div>
    </div>
    <!-- Text -->
    <div class="row pb-5">
      <div class="col position-absolute ">
        <h2 class="font-weight-bold text-center text-dark ">خطأ</h2>
        <h4 class="font-weight-bold text-center text-secondary pt-2">لم يتم العثور على الإعلان</h4>
      </div>
    </div>
  </div>
  <br>
  <br>
</div>
<!-- blue line  -->
<div class="bg-primary pt-2"></div>