import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpHelperService} from 'src/app/services/http-helper.service';
import {ApiReferenceService} from 'src/app/shared/api-reference.service';
@Injectable({
  providedIn: 'root'
})
export class ChatService {

  // public getListings: GetListings;

  constructor(
    // sent request service helper
    private http: HttpHelperService,
    // contain api
    private apiReference: ApiReferenceService,
  ) {
  }


  getConversationList(params): Observable<any> {
    return this.http.getAllConversations(this.apiReference.getAllConversations, params).pipe(response => {
      return response;
    });
  }

  getConversationMessages(params): Observable<any> {
    return this.http.getConversationMessages(this.apiReference.getConversationMessages, params).pipe(response => {
      return response;
    });
  }

  registrationToken(token): Observable<any> {
    return this.http.registrationToken(this.apiReference.registrationToken, token).pipe(response => {
      return response;
    });
  }

  deleteToken(token): Observable<any> {
    return this.http.deleteToken(this.apiReference.registrationToken, token).pipe(response => {
      return response;
    });
  }

  sendMessage(data): Observable<any> {
    return this.http.sendMessage(this.apiReference.getConversationMessages, data).pipe(response => {
      return response;
    });
  }

  updateLastSeen(id):  Observable<any> {
    const url = `${this.apiReference.getAllConversations}/${id}/seen`
    return this.http.updateLastSeen(url).pipe(response => {
      return response;
    });
  }
}
